import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siteData: { id: '', name: '', layerTitle: [] },
};

export const mapListSlice = createSlice({
  name: 'mapListSlice',
  initialState,
  reducers: {
    setSiteData: (state, action) => {
      state.siteData = {
        id: action.payload.id,
        name: action.payload.name,
        layerTitle: action.payload.layerTitle ?? [],
      };
    },
  },
});

export const {
  setSiteData,
} = mapListSlice.actions;

export default mapListSlice.reducer;
