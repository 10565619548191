import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bindIsOpen: false,
  selectRobot: {
    id: '',
    name: '',
    currentLocation: {},
    scanPoint: [],
  },
};

export const binMapSlice = createSlice({
  name: 'binMapSlice',
  initialState,
  reducers: {
    setBindIsOpen: (state, action) => {
      state.bindIsOpen = action.payload;
    },
    setSelectRobot: (state, action) => {
      state.selectRobot = { ...state.selectRobot, ...action.payload };
    },
    resetSelectRobot: (state) => {
      state.selectRobot = { ...initialState.selectRobot };
    },
  },
});

export const {
  setBindIsOpen,
  setSelectRobot,
  resetSelectRobot,
} = binMapSlice.actions;

export default binMapSlice.reducer;
