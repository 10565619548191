// 檢驗是否含有特殊符號
export const inspectSpecialText = (value) => {
  const re = /[~#^$@%&!?%*]/;
  let result = false;
  if (re.test(value)) result = true;

  return result;
};

// 檢驗是否為有效資料
export const isValidData = (value) => {
  if (value === undefined || value === null || String(value).trim().length === 0) {
    return false;
  }
  return true;
};
