import React, { useState, useEffect, useContext, useMemo } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { List, ListItemButton } from '@mui/material';
import { AppBar, Toolbar, Menu, MenuItem, Button, Modal, Fade, CircularProgress, Breadcrumbs, Typography } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
// import { apiOrganizationInfoList } from '@/cloud_api/organization';
import jwt_decode from 'jwt-decode';
import MoreIcon from '@/static/icon/More/moreInfo1.svg';
import WorkIcon from '@/static/icon/More/ic_workwithBG.svg';
import SelectedWorkIcon from '@/static/icon/More/ic_work_selectedwithbg.svg';
import MenuIcon from '@/static/icon/Menu/menu.svg';
import { LoginContext } from '@/Main.jsx';
import Success from '@/static/image/success.png';
import ErrorImg from '@/static/image/error.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { isValidData } from '@/common/validation.js';
import { pathIsExist } from '@/router/routeUtils';
// import AmrButton from '@/components/Button/AmrButton.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { patchUpdateUser, apiUserInfo } from '@/cloud_api/user.js';
import Loading from '@/components/Loading/Loading.jsx';
import { setModal } from '@/reducer/notificationSlice';
import actions from '@/actions';
import { GetModalStyle, onlyMessageModalStyle } from '../Modal/ModalStyle.js';
import { NavDiv, NavLink } from './NavStyle';
import NotificationModal from '../Modal/NotificationModal.jsx';
import { sidebarItems } from '../Data.jsx';

let isTablet = false;

const Nav = (props) => {
  const { t, triggerLogout, refreshPermissionTable } = props; // use i18n
  const history = useHistory();
  const classesModal = onlyMessageModalStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginMes, setLoginMes] = useState('');
  const [isLogout, setIsLogout] = useState('no');
  const [modalStyle] = useState(GetModalStyle);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const [organizationMap, setOrganizationMap] = useState({});
  const nowPath = history.location.pathname;
  const navTitleName = useSelector((x) => x.commonSlice.navTitleName);
  const [organizationId, setOrganizationId] = useState(0);
  const contextValue = useContext(LoginContext);
  const { userInfo, organizationList } = contextValue;
  let { currentPageName, menuIsOpen, navShow } = contextValue;
  const [isLoading, setIsLoading] = useState(false);
  const packageName = require('../../../package.json').name;
  if (typeof currentPageName === 'undefined') currentPageName = sessionStorage.getItem('currentPageName');
  if (navShow === -98) navShow = Number(JSON.parse(sessionStorage.getItem('navShow')));

  // useEffect(() => {
  //   console.log('organizationMap : ', organizationMap);
  // }, [organizationMap]);

  // useEffect(() => {
  //   organizationMapInit();
  // }, []);

  // const organizationMapInit = async () => {
  //   const { data } = await apiOrganizationInfoList();
  //   const organizationMapData = data.reduce((map, v) => {
  //     map[v.id] = v;
  //     return map;
  //   });
  //   setOrganizationMap(organizationMapData);
  // };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePage = (id, name) => {
    contextValue.setCurrentPage(id);
    contextValue.setCurrentPageName(name);
    sessionStorage.setItem('currentPage', id);
    sessionStorage.setItem('currentPageName', name);
    history.push(`/${id}`);
    handleClose();
  };

  const openSideBar = () => {
    if (!menuIsOpen) {
      if (!isTablet) menuIsOpen = 280; else menuIsOpen = 220;
    } else {
      menuIsOpen = 0;
    }
    contextValue.setMenuIsOpen(menuIsOpen);
  };

  const logoutClick = () => {
    setOpen(true);
    setLoginMes(t('LOGOUT_SUCCESS'));
    setIsLogout('success');

    setTimeout(() => {
      setOpen(false);
      setIsLogout('no');
      sessionStorage.clear();
      contextValue.setToken(false);
      contextValue.setRefresh(false);
      contextValue.setUserInfo({ username: '' });
      props.history.push('/');
      contextValue.setMenuIsOpen(0);
      contextValue.setNavShow(-98);
    }, 1000);
  };

  const breadcrumbs = useMemo(() => {
    const list = nowPath.split('/').filter((x) => isValidData(x));

    // 任務管理 > 複製地圖 & 編輯地圖 麵包屑修改
    if (list.includes('Tasks') && (list.includes('CopyTask') || list.includes('EditTask'))) {
      list.splice(1, 1);
    }
    return list;
  }, [nowPath]);

  useEffect(() => {
    if (triggerLogout) {
      logoutClick();
    }
  }, [triggerLogout]);

  useEffect(() => {
    const { outerWidth } = window;
    if (outerWidth > 1024) isTablet = false; else isTablet = true;

    if (userInfo.username === '') {
      const user = JSON.parse(sessionStorage.getItem('userInfo'));
      openSideBar();
      if (user !== null) contextValue.setUserInfo(user);
    }
  }, [userInfo]);

  const modalBody = (
    <div style={modalStyle} className={classesModal.paper}>
      <div id="simple-modal-description">
        <div className={classesModal.body}>
          {isLogout === 'success' && <img alt="success" src={Success} className={classesModal.img} />}
          {!isLogout && <img alt="error" src={ErrorImg} className={classesModal.img} />}
          {
            !loginMes
              ? <Fade in style={{ transitionDelay: '0ms' }} className={classesModal.loading} unmountOnExit><CircularProgress /></Fade>
              : ''
          }
          <h4 className={classesModal.message}>{loginMes}</h4>
        </div>
      </div>
    </div>
  );

  const updateOrganization = async (id) => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    await patchUpdateUser(userInfo.id, { organization: id }); // 更新使用者組織

    sessionStorage.setItem('organization', id);
    setOrganizationId(parseInt(id, 10));
    authMapReset(id); // 修改權限

    userInfo.organization = id;
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

    refreshOrganization(); // 通知刷新群組
  };

  const changeOrganization = async (id) => {
    const originalId = sessionStorage.getItem('organization'); // 原始組織
    try {
      const organizationId = sessionStorage.getItem('organization');

      // 如果選擇的組織不是當前組織，則更新組織
      if (String(id) !== organizationId) {
        setIsLoading(true);

        await updateOrganization(id);

        await handleSetUserInfo(); // 更新使用者資訊
        hrefAndReload(); // 跳轉 & 刷新頁面
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error : ', error);
      setIsLoading(false);
      dispatch(setModal({ open: true, title: t('SWITCH_FAIL'), message: '', status: 'warning' }));

      // 失敗則回復原始組織
      await updateOrganization(originalId);
    }
  };

  const handleSetUserInfo = async () => {
    const user = JSON.parse(sessionStorage.getItem('userInfo'));
    const userInfo = await apiUserInfo(user.id);
    const userData = userInfo.data;
    sessionStorage.setItem('userInfo', JSON.stringify(userData));
    contextValue.setUserInfo(userData);
  };

  const hrefAndReload = () => {
    refreshPermissionTable(); // 刷新 sideBar

    sessionStorage.setItem('currentPage', sidebarItems.dashboard.id);
    sessionStorage.setItem('currentPageName', sidebarItems.dashboard.name);
    const id = sessionStorage.getItem('currentPage');
    if (id === sidebarItems.dashboard.id) history.push('/Setting'); // 如果是 Dashboard 頁面，則跳轉到 Setting 頁面
    history.push('/Dashboard'); // 跳轉到 Dashboard 頁面
  };

  useEffect(() => {
    setOrganizationId(parseInt(sessionStorage.getItem('organization'), 10));
  }, [organizationList]);

  // 刷新權限。   這邊有調整 <Login.jsx> authMapInit() 也要調整
  const authMapReset = (organization) => {
    console.log('organization : ', organization);
    try {
      const accessWicentral = sessionStorage.getItem('tokenWicentral');
      if (accessWicentral) {
        const portalName = `PORTAL_${organization}_`;
        const { permissions } = jwt_decode(accessWicentral);
        const filterRole = permissions.filter((v) => v.includes(portalName)); // 取得目前的 role
        // 取得 permission
        const permissionSet = new Set(filterRole.map((v) => v.split(portalName)[1]));
        for (const key in contextValue.authMap) {
          if (contextValue.authMap[key] !== null) {
            let permissionExist = permissionSet.has(key.toUpperCase());

            // 腳本管理
            if (key === 'Order') permissionExist = permissionSet.has('ORDER_MGMT');
            if (key === 'Script') permissionExist = permissionSet.has('SCRIPT_MGMT');
            contextValue.authMap[key] = permissionExist;
          }
        }
        contextValue.authMap.Init = true;

        sessionStorage.setItem('authMap', JSON.stringify(contextValue.authMap));
      }
    } catch (error) {
      console.log('error : ', error);
    }
  };

  // 通知頁面刷新群組
  const refreshOrganization = () => {
    actions.setGlobalState({
      ignore: packageName,
      user: {
        name: 'master',
        page: 'Refresh',
      },
    });
  };

  return (
    <NavDiv style={{ zIndex: navShow }}>
      <Loading open={isLoading} />
      <AppBar position="static">
        <Toolbar>
          <button
            type="button"
            onClick={openSideBar}
            className="menuBtn"
            style={{ marginLeft: `${menuIsOpen}px` }}
          >
            <MenuIcon />
          </button>
          <h2 style={{ paddingLeft: '8px' }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {
                breadcrumbs.map((x) => {
                  const upperCaseWord = x.toUpperCase();
                  const getKey = t(upperCaseWord);
                  const getIndex = breadcrumbs.indexOf(x);
                  const currentUrl = '/' + breadcrumbs.filter((y) => breadcrumbs.indexOf(y) <= getIndex).join('/');
                  if (upperCaseWord === getKey && getIndex === 0) {
                    return null;
                  }

                  const keyIndex = getIndex === breadcrumbs.length - 2 ? 1 : 2;
                  const key = breadcrumbs[breadcrumbs.length - keyIndex];
                  const pathName = navTitleName[x + key];
                  const showText = pathName || (t(x.toUpperCase()) || x.toUpperCase());
                  if (pathIsExist(currentUrl) && getIndex !== (breadcrumbs.length - 1)) {
                    return (
                      <NavLink key={getIndex} style={{ textDecoration: 'none' }} color="inherit" to={currentUrl}>
                        {showText}
                      </NavLink>
                    );
                  }

                  return (
                    <Typography variant="h6" key={getIndex} style={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#555' }}>
                      {showText}
                    </Typography>
                  );
                })
              }
            </Breadcrumbs>
          </h2>
          <article className="navUser">
            <span>Hi, {userInfo.first_name} {userInfo.last_name}</span>
          </article>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className="navVert"
          >
            <MoreIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                borderRadius: '12px',
              },
            }}
          >
            <div style={{ padding: '16px', width: '350px' }}>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', color: '#006E94' }}>{userInfo.username}</h3>
            </div>
            {organizationList && organizationList.length > 0 && (
              <List
                component="nav"
                style={{
                  backgroundColor: '#F4FBFC',
                  borderTop: '1px solid #AFE1F1',
                  borderBottom: '1px solid #AFE1F1',
                }}
              >
                {organizationList.map((organization) => (
                  <ListItemButton
                    onClick={() => { changeOrganization(organization.organization_id); }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '8px 16px', // 要加這行，否則微前端切回來會跑版
                      borderLeft: organizationId === organization.organization_id ? '5px solid #006E94' : '5px solid #F4FBFC',
                      pointerEvents: organization.banned ? 'none' : 'auto', // 禁用
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        opacity: organization.banned ? 0.5 : 1, // 改變顏色
                      }}
                    >
                      {organizationId === organization.organization_id ? (
                        <SelectedWorkIcon style={{ marginRight: '10px' }} />
                      ) : (
                        <WorkIcon style={{ marginRight: '10px' }} />
                      )}
                      <p style={organizationId === organization.organization_id ? { fontWeight: 'bold' } : {}}>
                        {organization.organization_name}
                      </p>
                    </div>
                    {organization.banned && (
                      <div style={{ pointerEvents: 'none' }}>
                        <ErrorOutlineOutlinedIcon style={{ fontSize: '15px', color: 'red' }} />
                        {t('SUSPENSION')}
                      </div>
                    )}
                  </ListItemButton>
                ))}
              </List>
            )}
            {/* <div style={{ maxWidth: '90%', minWidth: '70%' }}>
              <AmrButton style={{ color: 'black' }} onClick={() => changePage('Personal', 'USER')}>{t('USER')}</AmrButton>
            </div>
            <div style={{ maxWidth: '90%', minWidth: '70%' }}>
              <AmrButton style={{ color: 'black' }} onClick={logoutClick}>{t('LOGOUT')}</AmrButton>
            </div> */}
            <MenuItem onClick={() => changePage('Personal', 'USER')} style={{ borderBottom: '1px solid #DDDDDD' }}>
              <PersonIcon style={{ marginRight: '10px', height: '24px', width: '24px' }} />
              {t('USER')}
            </MenuItem>
            <MenuItem onClick={logoutClick}>
              <LogoutIcon style={{ marginRight: '10px', height: '24px', width: '24px' }} />
              {t('LOGOUT')}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
      <NotificationModal />
    </NavDiv>
  );
};

export default withRouter(Nav);
