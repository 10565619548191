import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: {
    open: false,
    status: 'loading',
    title: '',
    message: '',
  },
  snackbar: {},
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
  },
});

export const {
  setModal,
} = notificationSlice.actions;

export default notificationSlice.reducer;
