import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  makeStyles,
  Button,
  LinearProgress,
} from '@material-ui/core';
import {
  HighlightOff,
  CheckCircle,
  ReportProblem,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getCloudList } from '@/cloud_api/cms';
import { initMedia } from '@/reducer/playlistSlice';
import useUploadManagement from '../UploadManagement/useUploadManagement.jsx';
import useSessionStorage from '../UploadManagement/useSessionStorage.jsx';
import JoinPlaylist from './JoinPlaylist.jsx';

export const MsgModalStyle = makeStyles(() => ({
  uploadBox: {
    position: 'fixed',
    height: '106px',
    width: '350px',
    backgroundColor: '#F4FBFC',
    bottom: '10px',
    left: '10px',
    zIndex: '50000',
    borderRadius: '10px',
    boxShadow: '3px 3px 6px #0000004D',
    margin: 'auto',
    padding: '10px 16px',
  },
  joinBox: {
    position: 'fixed',
    height: '64px',
    width: '350px',
    backgroundColor: '#F4FBFC',
    bottom: '10px',
    left: '10px',
    zIndex: '50001',
    borderRadius: '10px',
    border: '2px solid #d9d9d9',
    boxShadow: '3px 3px 6px #0000004D',
    margin: 'auto',
    padding: '20px',
  },
  doneBox: {
    position: 'fixed',
    height: '91px',
    width: '350px',
    backgroundColor: '#F4FBFC',
    bottom: '10px',
    left: '10px',
    zIndex: '50000',
    borderRadius: '10px',
    border: '2px solid #d9d9d9',
    boxShadow: '3px 3px 6px #0000004D',
    margin: 'auto',
    padding: '10px 16px',
  },
  uploadClose: {
    position: 'absolute',
    top: '32px',
    right: '5px',
    cursor: 'pointer',
  },
  stopButton: {
    position: 'absolute',
    top: '52px',
    right: '16px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '56px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
  },
  addButton: {
    position: 'fixed',
    height: '33px',
    width: '136px',
    left: '50px',
    cursor: 'pointer',
    textAlign: 'center',
    margin: 'auto',
    padding: '5px',
    border: '1px solid #006E94',
    background: '#FFFFFF',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#006E94',
  },
  boldContent: {
    textAlign: 'left',
    padding: '2px',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#454545',
  },
  normalContent: {
    textAlign: 'left',
    padding: '2px',
    font: 'normal normal normal 14px/19px Microsoft JhengHei',
    color: '#707070',
  },
  checkButton: {
    position: 'absolute',
    width: 'auto',
    height: '33px',
    top: '15px',
    right: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '4px',
    border: '1px solid #006E94',
    background: '#FFFFFF',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#006E94',
  },
  linearProgress: {
    top: '5px',
    right: '11px',
    width: '340px',
    height: '8px',
    color: 'red',
  },
  colorPrimary: {
    backgroundColor: '#34C759',
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: '#dee4e3',
    borderRadius: 5,
  },
  textWithIcon: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginLeft: 10,
  },
  textBullet: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
}));

const initState = {
  total: 0,
  uploaded: 0,
  canceled: 0,
  failed: 0,
  upload_progress: 0,
  done: false,
};

const initBottomState = {
  closeState: true,
  closeJoinState: true,
  cancel: null,
  files: null,
  msgType: null,
};

const BottomLeftMsg = (props) => {
  const {
    bottomState,
    setBottomState,
  } = props;
  const location = useLocation();
  const bottomBox = MsgModalStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openJoin, setOpenJoin] = useState(false);
  const [joinVideo, setJoinVideo] = useState([]);
  const [valueUpload, setValueUpload] = useSessionStorage('bottomState', 'storageChange', initState);
  const { setUploadVideo, setCancel } = useUploadManagement();
  const CMSPage = location.pathname.split('/')[1] === 'CMS';

  const fetchCloudList = async () => {
    try {
      const { data } = await getCloudList();
      dispatch(initMedia({ data }));
    } catch (err) {
      console.log(err);
    }
  };

  const stopButtonHandler = () => {
    setCancel();
  };

  const closeButtonHandler = () => {
    setValueUpload(initState);
    setBottomState((prevState) => ({
      ...prevState,
      closeState: true,
      files: null,
    }));
    sessionStorage.setItem('editState', JSON.stringify({}));
    sessionStorage.setItem('playlistState', JSON.stringify({}));
    sessionStorage.setItem('joinVideo', JSON.stringify([]));
  };

  const addButtonHandler = () => {
    const getJoinVideo = JSON.parse(sessionStorage.getItem('joinVideo'));
    if (getJoinVideo.length > 0) {
      setJoinVideo(getJoinVideo);
      setOpenJoin(true);
      setValueUpload(initState);
      setBottomState((prevState) => ({
        ...prevState,
        closeState: true,
      }));
    }
  };

  function CloseButton() {
    return (
      <div className={bottomBox.uploadClose} onClick={() => closeButtonHandler()}>
        <HighlightOff style={{ color: '#006E94', fontSize: '24px', position: 'absolute', margin: 'auto', right: '5px' }} />
      </div>
    );
  }

  function StopButton() {
    return (
      <Button
        variant="contained"
        onClick={() => stopButtonHandler()}
        className={bottomBox.stopButton}
      >
        {t('STOP')}
      </Button>
    );
  }

  function AddButton() {
    return (
      <Button
        variant="contained"
        onClick={() => addButtonHandler()}
        className={bottomBox.addButton}
      >
        {t('CMS_ADD_TO_PLAY_LIST')}
      </Button>
    );
  }

  function UploadingContent() {
    return (
      <div>
        <div className={bottomBox.normalContent}>{t('CMS_ON_UPLOAD')}</div>
        <div className={bottomBox.boldContent} style={{ marginTop: 10 }}>{t('CMSCLOUD')}</div>
        <div className={bottomBox.normalContent}>{t('CMS_UPLOADED')}{valueUpload.uploaded}{t('CMS_ITEMS')} {'， '} {(t('DATA_TOTAL'))}{valueUpload.total}{t('CMS_ITEMS')}</div>
        <LinearProgress classes={{ root: bottomBox.linearProgress, barColorPrimary: bottomBox.colorPrimary, colorPrimary: bottomBox.barColorPrimary }} variant="determinate" value={valueUpload.upload_progress} />
      </div>
    );
  }

  function UploadedFailSuccess() {
    return (
      <div style={{ marginTop: 6 }}>
        <div className={bottomBox.boldContent}>
          <CheckCircle className={bottomBox.textBullet} style={{ fontSize: '16px', color: '#1DBE00' }} />
          <span className={bottomBox.textWithIcon}>{valueUpload.uploaded}{t('CMS_SUCCESS_UPLOADED_1')}</span>
        </div>
        <div className={bottomBox.boldContent}>
          <ReportProblem className={bottomBox.textBullet} style={{ fontSize: '16px', color: '#DB4242' }} />
          <span className={bottomBox.textWithIcon}>{valueUpload.failed}{t('CMS_FAILED_UPLOADED')}</span>
        </div>
      </div>
    );
  }

  function CancelUpload() {
    return (
      <div style={{ marginTop: 6 }}>
        <div className={bottomBox.boldContent}>{t('CMS_CANCELED_UPLOAD')}</div>
        <div className={bottomBox.normalContent}>{t('CMS_UPLOADED')}{valueUpload.uploaded}{t('CMS_ITEMS')} {'， '} {valueUpload.canceled}{t('CMS_UPLOAD_UNFINISHED')}</div>
      </div>
    );
  }

  function SuccessUploaded() {
    return (
      <div>
        <div className={bottomBox.boldContent} style={{ marginBottom: 6 }}>
          <CheckCircle className={bottomBox.textBullet} style={{ fontSize: '16px', color: '#1DBE00' }} />
          <span className={bottomBox.textWithIcon}>{t('CMS_SUCCESS_UPLOADED')} {valueUpload.uploaded} {t('CMS_ITEMS')}</span>
        </div>
        <AddButton />
      </div>
    );
  }

  function JoinContent() {
    return (
      <div className={bottomBox.boldContent}>
        {t('CMS_JOIN_PLAYLIST_MSG')}
      </div>
    );
  }

  useEffect(() => {
    if (bottomState.files) {
      setUploadVideo(bottomState.files);
    }
  }, [bottomState.files]);

  useEffect(() => {
    if (bottomState.cancel) {
      setCancel(bottomState.cancel);
      setBottomState((prevState) => ({
        ...prevState,
        cancel: null,
      }));
    }
  }, [bottomState.cancel]);

  useEffect(() => {
    setBottomState(initBottomState);
  }, []);

  useEffect(() => {
    if (valueUpload.done) {
      fetchCloudList();
    }
  }, [valueUpload.done]);

  const uploadMessage = () => {
    if (valueUpload.total > 0 && !valueUpload.done) {
      return (
        <>
          { !bottomState.closeState && (
            <div className={bottomBox.uploadBox}>
              <UploadingContent />
              {StopButton()}
            </div>
          )}
        </>
      );
    }

    if (valueUpload.failed > 0 && valueUpload.done) {
      setTimeout(() => {
        closeButtonHandler();
      }, 5000);

      return (
        <>
          { !bottomState.closeState && (
            <div className={bottomBox.doneBox}>
              <UploadedFailSuccess />
              <CloseButton />
            </div>
          )}
        </>
      );
    }

    if (valueUpload.failed === 0 && valueUpload.canceled === 0 && valueUpload.done) {
      setTimeout(() => {
        closeButtonHandler();
      }, 5000);

      return (
        <>
          { !bottomState.closeState && (
            <div className={bottomBox.doneBox}>
              <SuccessUploaded />
              <CloseButton />
            </div>
          )}
        </>
      );
    }

    if (valueUpload.canceled > 0 && valueUpload.done) {
      setTimeout(() => {
        closeButtonHandler();
      }, 5000);

      return (
        <>
          { !bottomState.closeState && (
            <div className={bottomBox.doneBox}>
              <CancelUpload />
              <CloseButton />
            </div>
          )}
        </>
      );
    }
  };

  const bottomMessage = () => {
    if (bottomState.msgType === 'joinPlayList') {
      setTimeout(() => {
        setBottomState((prevState) => ({
          ...prevState,
          closeJoinState: true,
          msgType: null,
        }));
      }, 3000);

      return (
        <>
          { !bottomState.closeJoinState && (
            <div className={bottomBox.joinBox}>
              <JoinContent />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      {uploadMessage()}
      {bottomMessage()}
      {CMSPage && <JoinPlaylist openJoin={openJoin} setOpenJoin={setOpenJoin} joinVideo={joinVideo} />}
    </>
  );
};

export default BottomLeftMsg;
