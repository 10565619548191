import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  group: {
    robot_status: [],
  },
  elevator: [],
  task: [],
  notify: [],
  notification: [],
  amr: [],
  site: [],
};
/**
 * 打group/xxx的mqtt格式如下
 {
  device_id: 2152,
  state_measurements:[ //永遠只有一
    key: "robot_status",
    value:{
      ....
    }
  ]
 }
 */

export const mqttSlice = createSlice({
  name: 'mqttSlice',
  initialState,
  reducers: {
    setGroupMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      const { state_measurements, device_id } = data;
      const state_measurementsData = state_measurements[0];
      const { value, key } = state_measurementsData;
      value.device_id = device_id;
      value.updateTime = updateTime;
      const currentData = state.group[key] || [];
      const oldValue = currentData.filter((x) => x.device_id !== device_id && x.updateTime > updateTime - 5000);
      state.group[key] = [...oldValue, value];
    },
    disconnectedMqtt: (state) => {
      state.group = {
        robot_status: [],
      };
      state.elevator = [];
      state.task = [];
    },
    setElevatorMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      const oldValue = state.elevator.filter((x) => x.id !== data.id);
      state.elevator = [...oldValue, { ...data }];
    },
    setTaskMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      const oldValue = state.task.filter((x) => x.task_id !== data.task_id);
      state.task = [...oldValue, { ...data }];
    },
    setNotifyMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      const oldValue = state.notify.filter((x) => x.id !== data.id);
      state.notify = [...oldValue, { ...data }];
    },
    setNotificationMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      // const oldValue = state.notification;
      // state.notification = [...oldValue, { ...data }];
      // data.isRead = false;
      // state.notification = [...oldValue, { ...data }];
      state.notification = [{ ...data }];
      // console.log('state.notification', state.notification);
    },
    setAmrMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      // const oldValue = state.amr.filter((x) => x.id !== data.id);
      state.amr = [{ ...data }];
    },
    setSiteMqtt: (state, action) => {
      const updateTime = new Date().getTime();
      const { data } = action.payload;
      data.updateTime = updateTime;
      // const oldValue = state.amr.filter((x) => x.id !== data.id);
      state.site = [{ ...data }];
    },
    clearGroupMqtt: (state) => {
      state.group = {
        robot_status: [],
      };
    },
    clearElevatorMqtt: (state) => {
      state.elevator = [];
    },
    clearTaskMqtt: (state) => {
      state.task = [];
    },
    clearNotifyMqtt: (state) => {
      state.notify = [];
    },
    clearNotificationMqtt: (state) => {
      state.notification = [];
    },
    clearNotificationMqttById: (state, updateTime) => {
      const notificationToDelete = state.notification.filter((x) => x.updateTime !== updateTime);
      state.notification = notificationToDelete;
    },
    clearAmrMqtt: (state) => {
      state.amr = [];
    },
    clearSiteMqtt: (state) => {
      state.site = [];
    },
  },
});

export const {
  setGroupMqtt,
  setElevatorMqtt,
  setTaskMqtt,
  setNotifyMqtt,
  setNotificationMqtt,
  disconnectedMqtt,
  clearGroupMqtt,
  clearElevatorMqtt,
  clearTaskMqtt,
  clearNotifyMqtt,
  clearNotificationMqtt,
  clearNotificationMqttById,
  setAmrMqtt,
  clearAmrMqtt,
  setSiteMqtt,
  clearSiteMqtt,
} = mqttSlice.actions;

export default mqttSlice.reducer;
