import { pathToRegexp } from 'path-to-regexp';
import { routes } from './index';

const pathList = (() => {
  const paths = [];
  function pathPushRecursive(rroutes, rootPath) {
    rroutes.forEach((route) => {
      paths.push(rootPath + route.path);
      if (route.children) pathPushRecursive(route.children, rootPath + route.path);
    });
  }
  pathPushRecursive(routes, '');
  return paths;
})();

export const pathIsExist = (path) => {
  if (path === '/Dashboard/Robots') return false;
  if (path === '/Dashboard/Elevator') return false;
  if (pathList.includes(path)) return true;
  const pathWithParams = pathList.filter((p) => p.includes(':'));
  const match = pathWithParams.find((pwp) => {
    const reg = pathToRegexp(pwp);
    const matchRes = reg.exec(path);
    return !!matchRes;
  });
  return !!match;
};
