import {
  setModal,
} from '@/reducer/notificationSlice';

export const errorTable = (t, str) => {
  let key = str;
  if (Array.isArray(key)) {
    key = str.join('');
  }

  switch (key) {
    case 'Enter a valid email address.':
      return t('ENTER_A_VALID_EMAIL_ADDRESS');
    case 'still exist map':
      return t('SITE_ALREADY_EXIST');
    case 'UNVERIFIED':
      return t('UNVERIFIED');
    case 'TOKEN_NOT_EXIST':
      return t('TOKEN_NOT_EXIST');
    case 'This field may not be blank.':
      return t('ACCOUNT_REQUIRED');
    case 'CANT_CREATE_APPOINTED_MANAGER':
      return t('CANT_CREATE_APPOINTED_MANAGER');
    case 'ALREADY_EXIST':
      return t('ALREADY_EXIST');
    case 'PLAYLIST_EXIST':
      return t('PLAYLIST_EXIST');
    case 'The site still exists in the CMS playlist':
      return t('SITE_EXIST_PLAYLIST');
    default:
      return key;
  }
};

const errorResponse = (t, error, columns) => {
  const { data } = error;
  if (error.status === 404) {
    return t('PERMISSION_DENIED');
  }
  if (typeof data === 'string') {
    return t('FAIL');
  }

  const errorlist = Object.entries(data).map(([key, value]) => {
    const showName = columns[key] || errorTable(t, key);
    return key === 'error' ? errorTable(t, value) : `${showName}:${errorTable(t, value)}`;
  });

  return errorlist;
};

export const connectAPI = async (props) => {
  const {
    t,
    dispatch,
    api,
    postData,
    msg,
    onSuccess,
    onFail,
    onFinish,
    yesText,
    columns = {},
  } = props;
  dispatch(setModal({ open: true, message: '', title: '', status: 'loading' }));
  try {
    const result = await api({ ...postData });
    if (result === undefined) throw new Error();
    dispatch(setModal({ title: `${msg} ${t('API_SUCCESS')}`, message: yesText || '', status: 'success' }));
    if (typeof onSuccess === 'function') onSuccess(result);
  } catch (e) {
    const errorMsg = errorResponse(t, e, columns);
    const message = Array.isArray(errorMsg) ? errorMsg.join(',') : errorMsg;

    dispatch(setModal({
      title: `${msg} ${t('FAIL')}`,
      message: `${t('DASHBOARD_ERROR_MSG')}:${message}`,
      status: 'fail',
    }));
    if (typeof onFail === 'function') onFail(e);
  }
  if (typeof onFinish === 'function') onFinish();
};
