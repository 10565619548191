import axios from 'axios';
import { AppConfig } from '@/config/index';
import { refreshHandler } from './refreshHandler.js';

const { API_URL } = AppConfig;

// request URL 與 headers 設定
const requestBlob = axios.create({
  baseURL: `${API_URL}/api/`,
  responseType: 'blob', // 伺服器回應的數據類型
});

// 檢查 request 的 token 是否過期需 Refresh token
requestBlob.interceptors.response.use(
  (response) => response,
  refreshHandler(requestBlob),
);

export { requestBlob };
