import L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import {
  addPoint,
  deletePoint,
  setCurrentItem,
  addGroup,
  setCurrentGroupItem,
  deleteGroup,
  updatePoint,
  setMapInfo,
  deletePointsByType,
  deleteGroupsByType,
  addPolyline,
  addPolygon,
  deletePolylineByType,
  deletePolygonByType,
  updatePositionX,
  updateGroup,
  addNotifications,
  deleteParkingBindingTarget,
  updatePolyline,
  updatePolylineById,
  deletePointByGroupId,
  deletePolylineByGroupId,
  deletePolygonByGroupId,
  addSteps,
  updateSteps,
  toggleShowMapArea,
  deletePolyline,
  updateDialog,
  updatePolygon,
  deletePolygon,
  updatePolygonById,
  updateStepIndex,
  setUVCZoneListState,
  addRectangle,
  updateRectangle,
  deleteRectangle,
  updateRectangleById,
  updateConnectMapPointId,
  setOpenIOChannelListModal,
  updateAddOnly,
} from '@/reducer/mapSlice';
import store from '@/reducer/store';
import { isPointInPolygon } from 'geolib';
import {
  apiMapsInfo,
  apiMapsPng,
  apiMaps,
} from '@/cloud_api/maps.js';
import { cursorIcon, markerTypes, polygonTypes, polylineTypes, rectangleTypes, siteTypes } from '@/page/EditMap/EditMapUtils.js';
// import { de } from 'date-fns/locale';
import MapStatus from './Variable.js';
import MapClass from './Class.js';
import leafletMapColor from './Color.js';
import IconStyle from './IconStyle.js';
import WorkingAreaPoint from '../../static/image/image_iotgate_workingArea.png';
import {
  nodeMarkerDrag,
  layerEditEvent,
  layerEditEventClickStart,
  UVCZoneEditEvent,
  gateEditEvent,
  gateMousedown,
  pointEditEvent,
  siteEditEvent,
  taskPointEditEvent,
  dockPointEditEvent,
  homePointEditEvent,
  pointEditEventByRedux,
  initReduxEvent,
} from './Event.js';
import 'leaflet-arrowheads';
import 'leaflet-rotatedmarker';

/* --------------------------- 物件變數設定 --------------------------- */
// 格線圖層物件，並設定初始大小與比例尺一樣。
const gridLayer = new MapClass.GridLayer({
  cellSize: 20,
  style: {
    opacity: 0.2,
    color: leafletMapColor.Grid,
    weight: 1,
    fill: false,
    fillColor: null, // same as color by default
    fillOpacity: 0,
  },
});
const largeGridLayer = new MapClass.GridLayer({
  cellSize: 200,
  style: {
    color: leafletMapColor.Grid,
    weight: 3,
    opacity: 0.2,
    fill: false,
    fillColor: null, // same as color by default
    fillOpacity: 0,
  },
});
/* ------------------------------------------------------------------- */

/* --------------------------- robot --------------------------- */
// 建立 Point 的 options
export const createRobotOption = (scale) => {
  const icon = IconStyle.robotIcon(scale);

  const options = {
    icon,
    zIndexOffset: 9000, // 將 z-index 設高於底圖 marker
    draggable: false, // 是否能拖拉
    toolTip: 'robot',
    type: 'robot',
    id: '',
  };

  return options;
};

// 建立 robot 的 maker
export const createRobot = (latlng, scale) => {
  const options = createRobotOption(scale);

  const {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
  } = options;

  const marker = L.marker(latlng, {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
    rosAngle: 90,
  }).addTo(MapStatus.map);

  MapStatus.robot = marker;
};

// 畫面上 建立/更新 掃描點
export const setScanPointIntoMap = (data, resolution = 0.05) => {
  const { height } = document.querySelector('#canvasMap');
  if (!data) return;
  data.forEach((item) => {
    const latLng = {
      lat: (height - item.y) * resolution,
      lng: item.x * resolution,
    };
    createBindScanPoint(latLng, 'Scan');
  });
};

// 建立綁定地圖的掃描點
export const createBindScanPoint = (latlng, PointType) => {
  const options = createPointOption(PointType);

  const {
    icon,
    zIndexOffset,
    type,
    id,
  } = options;

  const marker = L.marker(latlng, {
    icon,
    apiType: 'post',
    zIndexOffset,
    draggable: false,
    type,
    id,
  }).addTo(MapStatus.map);
};

// 建立錨點綁定 arrow line 的 options
export const createAnchorLineOption = () => {
  const options = {
    color: '#69f542',
    zIndexOffset: 99999,
    weight: 5,
  };

  return options;
};

// 建立錨點綁定 arrow line
export const createAnchorLine = (latLng, scale = 100) => {
  const options = createAnchorLineOption();
  const { zIndexOffset } = options;
  const size = `${(scale / 100) * 18}px`;

  const polyline = L.polyline(latLng, {
    ...options,
  });

  polyline.arrowheads({
    size,
    fillColor: '#69f542',
    fill: true,
    stroke: false,
    zIndexOffset,
  });

  polyline.addTo(MapStatus.map);
  MapStatus.AnchorLine = polyline;
};

// 清除所有掃描點
export const clearAllScanPoint = () => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'Scan') {
      layer.remove();
    }
  });
};
/* ------------------------------------------------------------------- */

/* --------------------------- path mode layer --------------------------- */
// 建立 task path arrow line 的 options
export const createTaskPathOption = () => {
  const options = {
    color: leafletMapColor.DottedLine,
    fillColor: 'transparent',
    type: 'DottedLine',
    zIndexOffset: 1000,
    dashArray: '3,6',
    weight: 1.8,
  };

  return options;
};

// 建立 task path arrow line
export const createTaskPathLine = (latLng, id, isView = true, scale = 100) => {
  const options = createUVCZoneLineOption();
  const { color, fillColor, type, zIndexOffset, weight, dashArray } = options;
  const size = `${(scale / 100) * 8}px`;

  const polyline = L.polyline(latLng, {
    color,
    fillColor,
    type,
    weight,
    dashArray,
    zIndexOffset,
    originalLatLng: [],
    id,
  });

  polyline.arrowheads({
    size,
    fillColor: color,
    fill: true,
    stroke: false,
    zIndexOffset,
  });

  if (isView) polyline.addTo(MapStatus.map);

  MapStatus.pathArrowLines.push(polyline);
};

// 刪除 path 模式下之圖層物件
export const deletePathLayerObj = (type, id, scale = 100) => {
  switch (type) {
    case 'taskPoint':
      MapStatus.layerArr.taskPoint.forEach((point, index) => {
        if (point.options.id === id) {
          point.remove();
          MapStatus.layerArr.taskPoint.splice(index, 1);
        }
      });
      groupByPathLine(scale);
      break;
    case 'arrowLine':
      groupByPathLine(scale);
      break;
    case 'dock':
      MapStatus.layerArr.dockPoint.forEach((dock, index) => {
        if (dock.options.id === id) {
          dock.remove();
          MapStatus.layerArr.dockPoint.splice(index, 1);
        }
      });
      break;
    case 'home':
      MapStatus.layerArr.homePoint.forEach((home, index) => {
        if (home.options.id === id) {
          home.remove();
          MapStatus.layerArr.homePoint.splice(index, 1);
        }
      });
      break;
    default:
  }
};

// 回復 point 未選取前狀態
export const recoveryPointNotSelect = (scale) => {
  const { type } = MapStatus.selectPoint.options;

  switch (type) {
    case 'TaskPoint':
      MapStatus.selectPoint.setIcon(IconStyle.TaskIcon(scale));
      break;
    case 'Dock':
      MapStatus.selectPoint.setIcon(IconStyle.DockIcon(scale));
      break;
    case 'Home':
      MapStatus.selectPoint.setIcon(IconStyle.HomeIcon(scale));
      break;
    default:
  }

  MapStatus.selectPoint = null;
};

// 建立 home point 的 maker
export const createHomePoint = (latlng, PointType, stateSet) => {
  const options = createPointOption(PointType);

  const {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

  const marker = L.marker(latlng, {
    icon,
    apiType: 'post',
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
    rosAngle: 90,
    originalLatLng,
  }).addTo(MapStatus.map);

  // 設定標籤名稱
  marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
  if (!MapStatus.toolTip) marker.closeTooltip();

  MapStatus.layerArr.homePoint.push(marker);
  homePointEditEvent(marker, stateSet);

  const homeObj = {
    id,
    name: toolTip,
    x: originalLatLng.lng.toFixed(2),
    y: originalLatLng.lat.toFixed(2),
    angle: 90,
    apiType: 'post',
    type: 'home',
  };

  const arr = [...MapStatus.homeData];
  arr.push(homeObj);

  stateSet.setHomeData(arr);
  MapStatus.homeData = arr;
};

export const createPointByRedux = (latlng, pointType, defaultData = {}, showDialog) => {
  const options = createPointOption(pointType, defaultData.id);
  const mapAngle = store.getState().mapSlice.mapRotateAngle;
  if (defaultData.rosAngle == null) defaultData.rosAngle = 90 - mapAngle;
  const {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
  } = options;

  const marker = L.marker(latlng, {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
    rosAngle: defaultData.rosAngle,
    // connectMapData: defaultData.connectMapData,
    // _idConnectMap: defaultData._idConnectMap,
  }).addTo(MapStatus.map);
  rotatePoint(marker, defaultData.rosAngle);
  // 設定標籤名稱
  marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
  if (!MapStatus.toolTip) marker.closeTooltip();

  marker._icon.id = 'div' + id;

  store.dispatch(addPoint({
    ...defaultData,
    _id: marker._leaflet_id,
    type,
    id,
    tmpId: id,
    name: toolTip,
    ...latlng,
    rosAngle: defaultData.rosAngle,
  }));

  if (showDialog) {
    store.dispatch(setCurrentItem({
      _id: marker._leaflet_id,
      type,
      mode: 'add',
    }));
    store.dispatch(setCurrentGroupItem({}));
  }
  pointEditEventByRedux(marker, defaultData.setLayerToolMode);
  if (type === 'ConnectionPoint') {
    stopContinueAddAction(defaultData.setLayerToolMode);
  }
  return marker;
};

const stopContinueAddAction = (setLayerToolMode) => {
  setLayerToolMode('select');
  MapStatus.mode = 'edit';
  MapStatus.editLayer = null;
  MapStatus.map.off('click');
  MapStatus.map.off('mousemove');
  if (MapStatus.addLayer !== null) {
    MapStatus.addLayer.remove();
    MapStatus.addLayer = null;
  }
};

export const checkUnboundMapAreaFlag = () => {
  const layerType = [...markerTypes, ...siteTypes, 'Elevator'];
  const points = store.getState().mapSlice.points.filter((item) => layerType.includes(item.type));
  const mapAreas = Object.values(MapStatus.map._layers).filter((layer) => layer.options.name?.includes('MapArea'));
  const chargerGroup = store.getState().mapSlice.groups.filter((x) => points.filter((y) => y.type === 'Charger')
    .map((item) => item.groupID).includes(x.id))
    .reduce((prev, current) => {
      prev[current.id] = current.name;
      return prev;
    }, {});
  points.forEach((item) => {
    const { name, groupID } = item;
    const pointLayer = MapStatus.map._layers[item._id];
    const isPointContained = !mapAreas.some((mapLayer) => isPointInPolygon(pointLayer.getLatLng(), mapLayer.getLatLngs()[0]));
    if (pointLayer && isPointContained) {
      const { type, groupName, toolTip } = pointLayer.options;
      switch (type) {
        case 'Remark':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_REMARK', type: 'error', data: { name } }));
          break;
        case 'Store':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_STORE', type: 'error', data: { name } }));
          break;
        case 'Delivery':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_DELIVERY', type: 'error', data: { name } }));
          break;
        case 'Elevator':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_ELEVATOR', type: 'error', data: { name } }));
          break;
        case 'ParkingArea':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_PARKINGAREA', type: 'error', data: { groupName, toolTip } }));
          break;
        case 'Charger':
          store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_CHARGER', type: 'error', data: { groupName: chargerGroup[groupID], toolTip } }));
          break;
        default:
          break;
      }
    }
  });
};
export const clearFocusLayerLine = () => {
  const leafletId = store.getState().mapSlice.focusLayerId;
  if (leafletId === null) return;
  const layer = MapStatus.map._layers[leafletId];
  if (layer !== null && layer._icon !== null) layer._icon.style.border = null;
};
export const resetElevator = (rotationAngle, setLayerToolMode) => {
  const { centerPosition } = MapStatus;
  const _type = 'Elevator';
  // 抓到的是沒有轉的
  const elevator = store.getState().mapSlice.points.filter((x) => x.type === _type);
  store.dispatch(deletePointsByType({ type: _type }));

  elevator.forEach((x) => {
    const _point = { ...x };
    delete _point._id;

    // 取得旋轉後的座標
    const rotateLatLng = getRotateLeafletPosition({ lat: _point.lat, lng: _point.lng }, centerPosition, rotationAngle);

    _point.rosAngle -= rotationAngle;
    _point.center_x = rotateLatLng.lng;
    _point.center_y = rotateLatLng.lat;
    delete _point.lng;
    delete _point.lat;

    createPointByRedux(rotateLatLng, _type, { ..._point, setLayerToolMode });
  });
};

export const resetIotGate = (setSelectLayerType, setLayerToolMode) => {
  const type = 'IotGate';
  const iotGate = store.getState().mapSlice.groups.filter((x) => x.type === type && x.apiType !== 'delete');
  const points = store.getState().mapSlice.points.filter((x) => x.type === type && x.apiType !== 'delete');
  store.dispatch(deleteGroupsByType({ type }));
  store.dispatch(deletePointsByType({ type }));
  store.dispatch(deletePolylineByType({ type }));
  store.dispatch(deletePolygonByType({ type }));
  iotGate.forEach((item) => {
    const { point1, point2, insidePoint, outsidePoint } = points.filter((x) => x.groupID === item.id).reduce((prev, current) => {
      prev[current.name] = getRotateLeafletPosition({ lat: current.lat, lng: current.lng }, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      return prev;
    }, {});
    const { id, name, passageLength, robotWaitingDistance, apiType, insidePointID, outsidePointID, insideWorkingArea, outsideWorkingArea, IOChannel } = item;
    const info = {
      id,
      insidePointID, // uuid/number/null
      outsidePointID, // uuid/number/null,
      insideWorkingArea, // uuid/number/null
      outsideWorkingArea, // uuid/number/null,
      name,
      apiType,
      passageLength, // 閘門長度
      robotWaitingDistance, // 機器人等候距離
      IOChannel,
      enableActionList: [],
      originalLatLng: [],
      mode: 'view',
    };
    createLineByRedux(point1, 'IotGate', info, {
      insideWorkingAreaLatLng: insidePoint,
      outsideWorkingAreaLatLng: outsidePoint,
      point1LatLng: point1,
      point2LatLng: point2,
      init: true,
      setLayerToolMode,
      setSelectLayerType,
    });
  });
};

// 補足旋轉時 point1, point2 點位
export const rotateIotGatePoint = (groupId, defaultData, point, rotateAngle) => {
  const { centerPosition } = MapStatus;
  const { lat, lng } = defaultData;
  const newData = store
    .getState()
    .mapSlice.points.filter((item) => item.groupID === groupId)
    .reduce((prev, current) => {
      prev[current.name] = current;
      return prev;
    }, {});
  newData[point] = getRotateLeafletPosition({ lat, lng }, centerPosition, rotateAngle);
  updateMapIotGate(groupId, newData);
};

export const createLineByRedux = (mouseDownLatLng, layerType, defaultData, option) => {
  let initFunction;
  if (layerType === 'IotGate') initFunction = initIotGate;
  if (layerType === 'NarrowGate') initFunction = initNarrowGate;
  initFunction(mouseDownLatLng, layerType, defaultData, option);
};
export const initNarrowGate = (mouseDownLatLng, layerType, defaultData, option) => {
  const { id } = defaultData;
  const {
    init,
    name,
    point1LatLng,
    point2LatLng,
    setLayerToolMode,
    setEditLayerData,
    setSelectLayerType,
    setCreateLayerOpen,
  } = option;

  const groupID = id || uuidv4();
  const apiType = init ? 'edit' : 'post';
  const currentItem = { ...store.getState().mapSlice.currentItem };
  const polylineRedux = store.getState().mapSlice.polylines.find((item) => item.id === id);
  const pointOptions = {
    type: 'NarrowGate',
    zIndexOffset: 1000,
    draggable: true,
    groupID,
    rosAngle: 0,
    apiType,
  };
  let pointStartLanLng = null;
  // 計算點的原始位置
  const point1OriginLeaflet = point1LatLng || mouseDownLatLng;
  const point2OriginLeaflet = point2LatLng || mouseDownLatLng;
  // 起訖點產生時執行
  if (currentItem.mode === 'drawing') {
    MapStatus.map.off('mousemove');
    const ploylineRedux = store.getState().mapSlice.polylines.find((item) => item._id === currentItem._id);
    const { point1, point2, gate, polyline } = getGroupLayers(ploylineRedux.id, 'NarrowGate');
    updateNarrowGatePosition(point1.getLatLng(), point2.getLatLng(), ploylineRedux.id, point2._leaflet_id, point2OriginLeaflet, 80);
    store.dispatch(setCurrentItem({ _id: currentItem._id, type: layerType, mode: 'add' }));
    toggleLayers([point1._leaflet_id, point2._leaflet_id]);
    MapStatus.addLayer = polyline;
    gate.setStyle({ color: 'transparent' });
    setCreateLayerOpen(true);
    return;
  }

  // 建立第一個點，並將其添加到地圖上
  const { _leaflet_id: point1LeafletId } = L.marker(point1LatLng || mouseDownLatLng, { ...pointOptions, name: 'point1', icon: IconStyle.NodeIcon, id: uuidv4(), ...point1OriginLeaflet }).addTo(MapStatus.map);

  // 建立第二個點，並將其添加到地圖上
  const { _leaflet_id: point2LeafletId } = L.marker(point2LatLng || mouseDownLatLng, { ...pointOptions, name: 'point2', icon: IconStyle.NodeIcon, id: uuidv4(), ...point2OriginLeaflet }).addTo(MapStatus.map);

  // 建立窄門外框
  const gateOptions = createGateOption(layerType, groupID);
  createGateByRedux([mouseDownLatLng, mouseDownLatLng], layerType, gateOptions);

  // 建立窄門的多邊形
  createPolylineByRedux(layerType, [point1OriginLeaflet, point2OriginLeaflet], { name: 'polyline', apiType, id: groupID, groupID, nodeArr: [point1LeafletId, point2LeafletId] });

  const {
    point1,
    point2,
    polyline,
  } = getGroupLayers(groupID, 'NarrowGate');

  polyline.bindTooltip(defaultData?.name, createToolTipOption(pointOptions.type));
  if (!MapStatus.toolTip) polyline.closeTooltip();

  // 設定對兩個點的拖曳事件，以便更新窄門
  point1
    .on('dragstart', (e) => {
      pointStartLanLng = e.target.getLatLng();
    })
    .on('drag', (e) => {
      const { latlng } = e;
      updateMapNarrowGate(groupID, { point1: latlng });
    })
    .on('dragend', (e) => {
      const { _leaflet_id, options } = e.target;
      const { point1: pointLatLng } = store.getState().mapSlice.polylines.find((item) => item.groupID === groupID);
      const isGatePositionUpdated = updateNarrowGatePosition(e.target.getLatLng(), point2.getLatLng(), groupID, _leaflet_id, e.target.getLatLng(), 80, pointStartLanLng);
      if (!isGatePositionUpdated) updateStepsInRedux('nodeMove', { leafletId: _leaflet_id, groupID: options.groupID, name: 'point1', ...pointLatLng }, { leafletId: _leaflet_id, groupID: options.groupID, name: 'point1', ...e.target.getLatLng() });
    });
  point2
    .on('dragstart', (e) => {
      pointStartLanLng = e.target.getLatLng();
    })
    .on('drag', (e) => {
      const { latlng } = e;
      updateMapNarrowGate(groupID, { point2: latlng });
    })
    .on('dragend', (e) => {
      const { _leaflet_id, options } = e.target;
      const { point2: pointLatLng } = store.getState().mapSlice.polylines.find((item) => item.groupID === groupID);
      const isGatePositionUpdated = updateNarrowGatePosition(point1.getLatLng(), e.target.getLatLng(), groupID, _leaflet_id, e.target.getLatLng(), 80, pointStartLanLng);
      if (!isGatePositionUpdated) updateStepsInRedux('nodeMove', { leafletId: _leaflet_id, groupID: options.groupID, name: 'point2', ...pointLatLng }, { leafletId: _leaflet_id, groupID: options.groupID, name: 'point2', ...e.target.getLatLng() });
    });
  store.dispatch(addPoint({ ...pointOptions, name: 'point1', id: point1.options.id, _id: point1._leaflet_id, ...point1OriginLeaflet, apiType: undefined }));
  store.dispatch(addPoint({ ...pointOptions, name: 'point2', id: point2.options.id, _id: point2._leaflet_id, ...point2OriginLeaflet, apiType: undefined }));

  if (polylineRedux) {
    store.dispatch(updatePolylineById({ id, _id: polyline._leaflet_id, nodeArr: [point1._leaflet_id, point2._leaflet_id] }));
  } else {
    store.dispatch(addPolyline({
      ...polyline.options,
      point1: point1OriginLeaflet,
      point2: point2OriginLeaflet,
      _id: polyline._leaflet_id,
      nodeArr: [point1._leaflet_id, point2._leaflet_id],
      name: name || polyline.options.toolTip,
    }));
  }

  // 設定窄門區域編輯監聽事件
  layerEditEvent(polyline, setLayerToolMode, null, setEditLayerData, setSelectLayerType);

  // 一開始載入時 不用觸發事件監聽
  if (init) {
    updateMapNarrowGate(groupID, {
      point1: point1OriginLeaflet,
      point2: point2OriginLeaflet,
    });
    toggleLayers([point1._leaflet_id, point2._leaflet_id]);
    return polyline;
  }
  MapStatus.map.on('mousemove', (e) => {
    const { latlng } = e;
    updateMapNarrowGate(groupID, { point2: latlng });
  });
  store.dispatch(setCurrentItem({
    _id: polyline._leaflet_id,
    type: layerType,
    mode: 'drawing',
  }));
};

export const checkGateLength = (latlng_1, latlng_2, length = 80, resolution = 0.05) => {
  const a = Math.abs(latlng_1.lat - latlng_2.lat);
  const b = Math.abs(latlng_1.lng - latlng_2.lng);
  const c = Math.sqrt(((a * a) + (b * b)));
  const leastLength = (length / 5) * resolution;

  let result = false;
  if (c < leastLength) result = true;
  return result;
};
export const initIotGate = (mouseDownLatLng, layerType, defaultData, option) => {
  const { id, insidePointID, outsidePointID } = defaultData;
  const {
    setLayerToolMode,
    setSelectLayerType,
    point1LatLng,
    point2LatLng,
    insideWorkingAreaLatLng,
    outsideWorkingAreaLatLng,
    init,
    update,
    initAreaLatLng,
    positionX,
  } = option;
  const groupId = id || uuidv4();
  const apiType = init ? 'edit' : 'post';
  const currentGroupItem = { ...store.getState().mapSlice.currentGroupItem };

  const point1OriginLeaflet = point1LatLng || mouseDownLatLng;
  const point2OriginLeaflet = point2LatLng || mouseDownLatLng;
  let point1StartLanLng = null;
  let point2StartLanLng = null;

  // 起訖點產生時執行
  if (currentGroupItem.mode === 'drawing') {
    MapStatus.map.off('mousemove');
    const group = store.getState().mapSlice.groups.find((item) => item.id === currentGroupItem.id && item.apiType !== 'delete');
    const { insidePoint, outsidePoint, polyline, insideWorkingArea, outsideWorkingArea, point1, point2 } = getGroupLayers(group.id, 'IotGate');
    updateIotGatePosition(point1.getLatLng(), point2.getLatLng(), currentGroupItem.id, group.point2LeafletId, point2OriginLeaflet, 70);
    const insideRosAngle = getRotatedAngle([insidePoint.getLatLng(), polyline.getCenter()]);
    const outsideRosAngle = getRotatedAngle([outsidePoint.getLatLng(), polyline.getCenter()]);
    store.dispatch(updatePoint({ _id: insidePoint._leaflet_id, ...insideWorkingArea.getBounds().getCenter(), rosAngle: insideRosAngle }));
    store.dispatch(updatePoint({ _id: outsidePoint._leaflet_id, ...outsideWorkingArea.getBounds().getCenter(), rosAngle: outsideRosAngle }));
    store.dispatch(setCurrentItem({ _id: currentGroupItem.id, type: layerType, mode: 'add' }));
    store.dispatch(setCurrentGroupItem({ id: currentGroupItem.id, type: layerType, mode: 'add' }));
    toggleLayers([point1._leaflet_id, point2._leaflet_id]);

    if (positionX) store.dispatch(updatePositionX(positionX));
    rebindIotGateMapArea(currentGroupItem.id);
    return;
  }

  const pointOptions = {
    type: 'IotGate',
    zIndexOffset: 1000,
    draggable: true,
    groupID: groupId,
    rosAngle: 0,
    apiType,
  };

  // 建立起始點
  const { _leaflet_id: point1LeafletId } = L.marker(point1LatLng || mouseDownLatLng, { ...pointOptions, name: 'point1', icon: IconStyle.NodeIcon, id: uuidv4(), ...point1OriginLeaflet }).addTo(MapStatus.map);

  // 建立結束點
  const { _leaflet_id: point2LeafletId } = L.marker(point2LatLng || mouseDownLatLng, { ...pointOptions, name: 'point2', icon: IconStyle.NodeIcon, id: uuidv4(), ...point2OriginLeaflet }).addTo(MapStatus.map);

  // 建立閘門線
  createPolylineByRedux(layerType, [point1OriginLeaflet, point2OriginLeaflet], { groupID: groupId, name: 'polyline', apiType: 'init', id: uuidv4() });
  // 閘門整體區塊
  const iotGateArea = [
    { ...createGateOptionByRedux(layerType, groupId, 'door'), dashArray: null, fillColor: '#FE913E', fillOpacity: 0.5, apiType, nodeArr: [point1LeafletId, point2LeafletId] },
    { ...createGateOptionByRedux(layerType, groupId, 'insidePassageLength'), color: '#FE913E', fillColor: '*', fillOpacity: 0.0, apiType },
    { ...createGateOptionByRedux(layerType, groupId, 'outsidePassageLength'), color: '#FE913E', fillColor: '*', fillOpacity: 0.0, apiType },
    { ...createGateOptionByRedux(layerType, groupId, 'insideWorkingArea'), color: '#5EAA76', fillColor: '#5EAA76', dashArray: null, apiType },
    { ...createGateOptionByRedux(layerType, groupId, 'outsideWorkingArea'), color: '#5EAA76', fillColor: '#5EAA76', dashArray: null, apiType },
  ];
  const layers = iotGateArea.map((item, index) => createGateByRedux(initAreaLatLng === undefined ? [] : initAreaLatLng[index], layerType, item));

  // 建立inSide區域中心點
  L.marker(
    insideWorkingAreaLatLng || point1OriginLeaflet,
    {
      ...pointOptions,
      name: 'insidePoint',
      icon: IconStyle.IotGateWorkingAreaIcon(),
      id: insidePointID || uuidv4(),
      draggable: false,
      apiType: insidePointID ? 'edit' : 'post',
    },
  ).addTo(MapStatus.map);

  // 建立outSide區域中心點
  L.marker(
    outsideWorkingAreaLatLng || point2OriginLeaflet,
    {
      ...pointOptions,
      name: 'outsidePoint',
      icon: IconStyle.IotGateWorkingAreaIcon(),
      id: outsidePointID || uuidv4(),
      draggable: false,
      apiType: outsidePointID ? 'edit' : 'post',
    },
  ).addTo(MapStatus.map);

  const {
    door,
    point1,
    point2,
    polyline,
    insidePoint,
    outsidePoint,
    insideWorkingArea,
    outsideWorkingArea,
  } = getGroupLayers(groupId, 'IotGate');

  const insideRosAngle = getRotatedAngle([insidePoint.getLatLng(), polyline.getCenter()]);
  const outsideRosAngle = getRotatedAngle([outsidePoint.getLatLng(), polyline.getCenter()]);

  // 拖曳閘門兩邊點
  point1
    .on('dragstart', (e) => {
      point1StartLanLng = e.target.getLatLng();
    })
    .on('drag', (e) => {
      const { latlng } = e;
      updateMapIotGate(groupId, { point1: latlng });
    })
    .on('dragend', (e) => {
      const { _leaflet_id } = e.target;
      const _insideRosAngle = getRotatedAngle([insidePoint.getLatLng(), polyline.getCenter()]);
      const _outsideRosAngle = getRotatedAngle([outsidePoint.getLatLng(), polyline.getCenter()]);
      const { lat, lng } = store.getState().mapSlice.points.find((item) => item._id === _leaflet_id);
      const isGatePositionUpdated = updateIotGatePosition(e.target.getLatLng(), point2.getLatLng(), groupId, _leaflet_id, e.target.getLatLng(), 70, point1StartLanLng);

      store.dispatch(setCurrentGroupItem({ id: groupId, type: layerType, mode: 'edit' }));
      store.dispatch(setCurrentItem({ _id: groupId, type: layerType, mode: 'edit', nodeArr: [_leaflet_id, point2._leaflet_id] }));
      store.dispatch(updatePoint({ _id: insidePoint._leaflet_id, ...insideWorkingArea.getBounds().getCenter(), rosAngle: _insideRosAngle }));
      store.dispatch(updatePoint({ _id: outsidePoint._leaflet_id, ...outsideWorkingArea.getBounds().getCenter(), rosAngle: _outsideRosAngle }));
      if (!isGatePositionUpdated) updateStepsInRedux('nodeMove', { name: 'point1', lat, lng }, { name: 'point1', ...e.target.getLatLng() });
      rebindIotGateMapArea(groupId);
    });
  point2
    .on('dragstart', (e) => {
      point2StartLanLng = e.target.getLatLng();
    })
    .on('drag', (e) => {
      const { latlng } = e;
      updateMapIotGate(groupId, { point2: latlng });
    })
    .on('dragend', (e) => {
      const { _leaflet_id } = e.target;
      const _insideRosAngle = getRotatedAngle([insidePoint.getLatLng(), polyline.getCenter()]);
      const _outsideRosAngle = getRotatedAngle([outsidePoint.getLatLng(), polyline.getCenter()]);
      const { lat, lng } = store.getState().mapSlice.points.find((item) => item._id === _leaflet_id);
      const isGatePositionUpdated = updateIotGatePosition(point1.getLatLng(), e.target.getLatLng(), groupId, _leaflet_id, e.target.getLatLng(), 70, point2StartLanLng);

      store.dispatch(setCurrentGroupItem({ id: groupId, type: layerType, mode: 'edit' }));
      store.dispatch(setCurrentItem({ _id: groupId, type: layerType, mode: 'edit', nodeArr: [_leaflet_id, point1._leaflet_id] }));
      store.dispatch(updatePoint({ _id: insidePoint._leaflet_id, ...insideWorkingArea.getBounds().getCenter(), rosAngle: _insideRosAngle }));
      store.dispatch(updatePoint({ _id: outsidePoint._leaflet_id, ...outsideWorkingArea.getBounds().getCenter(), rosAngle: _outsideRosAngle }));
      if (!isGatePositionUpdated) updateStepsInRedux('nodeMove', { name: 'point2', lat, lng }, { name: 'point2', ...e.target.getLatLng() });
      rebindIotGateMapArea(groupId);
    });

  // 設定閘門區域編輯監聽事件
  layerEditEvent(door, setLayerToolMode, null, null, setSelectLayerType);

  insideWorkingArea._path.style.display = 'none';
  outsideWorkingArea._path.style.display = 'none';

  store.dispatch(addPoint({ ...pointOptions, name: 'point1', id: point1.options.id, _id: point1._leaflet_id, ...point1OriginLeaflet }));
  store.dispatch(addPoint({ ...pointOptions, name: 'point2', id: point2.options.id, _id: point2._leaflet_id, ...point2OriginLeaflet }));
  store.dispatch(addPoint({ ...pointOptions, name: 'insidePoint', id: insidePoint.options.id, _id: insidePoint._leaflet_id, of: 'marker', apiType: undefined, ...insideWorkingAreaLatLng, rosAngle: insideRosAngle }));
  store.dispatch(addPoint({ ...pointOptions, name: 'outsidePoint', id: outsidePoint.options.id, _id: outsidePoint._leaflet_id, of: 'marker', apiType: undefined, ...outsideWorkingAreaLatLng, rosAngle: outsideRosAngle }));

  store.dispatch(addPolyline({ ...polyline.options }));
  layers.map((item) => store.dispatch(addPolygon(item.options)));
  if (update) {
    store.dispatch(updateGroup({
      ...defaultData,
      point1LeafletId: point1._leaflet_id,
      point2LeafletId: point2._leaflet_id,
      point1: point1.options.id,
      point2: point2.options.id,
      insidePointID: insidePoint.options.id,
      outsidePointID: outsidePoint.options.id,
      type: 'iterateNestedObjects',
      apiType,
      id: groupId,
    }));
  } else {
    store.dispatch(addGroup({
      ...defaultData,
      type: layerType,
      id: groupId,
      point1LeafletId: point1._leaflet_id,
      point2LeafletId: point2._leaflet_id,
      point1: point1.options.id,
      point2: point2.options.id,
      insidePointID: insidePoint.options.id,
      outsidePointID: outsidePoint.options.id,
    }));
  }
  // 一開始載入時 不用觸發事件監聽
  if (init) {
    toggleLayers([point1._leaflet_id, point2._leaflet_id]);
    updateMapIotGate(groupId, { point1: point1OriginLeaflet, point2: point2OriginLeaflet, name: defaultData?.name });
    return;
  }
  if (!MapStatus.toolTip) door.closeTooltip();

  MapStatus.map.on('mousemove', (e) => {
    const { latlng } = e;
    updateMapIotGate(groupId, { point2: latlng });
  });

  store.dispatch(setCurrentGroupItem({
    id: groupId,
    type: layerType,
    mode: 'drawing',
  }));
};
export const updateIotGatePosition = (point1LatLng, point2LatLng, groupId, leafletId, originLatLng, length, startLatLng = null) => {
  let updatedGatePoint = null;
  let isGatePositionUpdated = false;

  if (checkGateLength(point1LatLng, originLatLng, length) && !point1LatLng.equals(originLatLng)) {
    updatedGatePoint = point1LatLng;
  } else if (checkGateLength(point2LatLng, originLatLng, length) && !point2LatLng.equals(originLatLng)) {
    updatedGatePoint = point2LatLng;
  }
  if (updatedGatePoint !== null) {
    const angle = getAngleOfTwoPoints([updatedGatePoint, originLatLng]);
    const newLatLng = startLatLng || getForwardRotatePosition(updatedGatePoint, length / 100, angle);
    const updatedPoint = (updatedGatePoint === point1LatLng) ? { point2: newLatLng } : { point1: newLatLng };
    updateMapIotGate(groupId, updatedPoint);
    store.dispatch(addNotifications({ message: 'EDIT_MAP_IOT_GATE_LENGTH', type: 'error' }));
    store.dispatch(updatePoint({ _id: leafletId, ...newLatLng }));
    isGatePositionUpdated = true;
  } else {
    store.dispatch(updatePoint({ _id: leafletId, ...originLatLng }));
  }
  return isGatePositionUpdated;
};
export const updateNarrowGatePosition = (point1LatLng, point2LatLng, polylineId, leafletId, originLatLng, length, startLatLng = null) => {
  let updatedGatePoint = null;
  let isGatePositionUpdated = false;

  if (checkGateLength(point1LatLng, originLatLng, length) && !point1LatLng.equals(originLatLng)) {
    updatedGatePoint = point1LatLng;
  } else if (checkGateLength(point2LatLng, originLatLng, length) && !point2LatLng.equals(originLatLng)) {
    updatedGatePoint = point2LatLng;
  }
  if (updatedGatePoint !== null) {
    const angle = getAngleOfTwoPoints([updatedGatePoint, originLatLng]);
    const newLatLng = startLatLng || getForwardRotatePosition(updatedGatePoint, length / 100, angle);
    const updatedPoint = (updatedGatePoint === point1LatLng) ? { point2: newLatLng } : { point1: newLatLng };
    updateMapNarrowGate(polylineId, updatedPoint);
    store.dispatch(addNotifications({ message: 'EDIT_MAP_NARROW_GATE_LENGTH', type: 'error' }));
    store.dispatch(updatePoint({ _id: leafletId, ...newLatLng }));
    store.dispatch(updatePolylineById({ id: polylineId, ...updatedPoint }));
    isGatePositionUpdated = true;
  } else {
    const layerName = MapStatus.map._layers[leafletId]?.options.name;
    store.dispatch(updatePoint({ _id: leafletId, ...originLatLng }));
    store.dispatch(updatePolylineById({ id: polylineId, [layerName]: originLatLng }));
  }
  return isGatePositionUpdated;
};

// 隱藏/顯示 閘門、地圖區塊
export const displayIotGate = (display) => {
  MapStatus.map.eachLayer((layer) => {
    const { options } = layer;
    // 忽略閘門相關flag
    const isIotGate = options.type?.includes('IotGate');
    // 保留地圖區塊polygon
    if (options.name?.includes('MapArea')) {
      layer._path.style.display = display === 'none' ? '' : 'none';
      return;
    }
    if (isIotGate === false && options.type !== 'Map') {
      if (layer._icon) layer._icon.style.display = display;
      if (layer._path) layer._path.style.display = display;
      if (layer._container) layer._container.style.display = display;
      if (layer._tooltip) layer._tooltip._container.style.display = display;
    }
  });
};
// init api 取得的 home point
export const initHomePoint = (stateSet) => {
  MapStatus.initHomePoint.forEach((point) => {
    const options = createPointOption('Home');

    const {
      icon,
      zIndexOffset,
      draggable,
      type,
    } = options;
    const latlng = { lat: point.y, lng: point.x };
    const rotateLatLng = getRotateLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

    const marker = L.marker(rotateLatLng, {
      icon,
      apiType: 'init',
      zIndexOffset,
      draggable,
      toolTip: point.name,
      type,
      id: point.id,
      rosAngle: point.angle,
      latlng,
    }).addTo(MapStatus.map);

    // 設定標籤名稱
    marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
    if (!MapStatus.toolTip) marker.closeTooltip();

    marker.setRotationAngle(angleRosToLeaflet(point.angle));
    MapStatus.layerArr.homePoint.push(marker);
    homePointEditEvent(marker, stateSet);
  });
};

// 建立 dock point 的 maker
export const createDockPoint = (latlng, PointType, stateSet) => {
  const options = createPointOption(PointType);

  const {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

  const marker = L.marker(latlng, {
    icon,
    apiType: 'post',
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
    rosAngle: 90,
    originalLatLng,
  }).addTo(MapStatus.map);

  // 設定標籤名稱
  marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
  if (!MapStatus.toolTip) marker.closeTooltip();

  MapStatus.layerArr.dockPoint.push(marker);
  dockPointEditEvent(marker, stateSet);

  const dockObj = {
    id,
    name: toolTip,
    x: originalLatLng.lng.toFixed(2),
    y: originalLatLng.lat.toFixed(2),
    angle: 90,
    apiType: 'post',
    type: 'dock',
  };

  const arr = [...MapStatus.dockData];
  arr.push(dockObj);

  stateSet.setDockData(arr);
  MapStatus.dockData = arr;
};

// init api 取得的 dock point
export const initDockPoint = (stateSet) => {
  MapStatus.initDockPoint.forEach((point) => {
    const options = createPointOption('Dock');

    const {
      icon,
      zIndexOffset,
      draggable,
      type,
    } = options;
    const latlng = { lat: point.y, lng: point.x };
    const rotateLatLng = getRotateLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

    const marker = L.marker(rotateLatLng, {
      icon,
      apiType: 'init',
      zIndexOffset,
      draggable,
      toolTip: point.name,
      type,
      id: point.id,
      rosAngle: point.angle,
      latlng,
    }).addTo(MapStatus.map);

    // 設定標籤名稱
    marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
    if (!MapStatus.toolTip) marker.closeTooltip();

    marker.setRotationAngle(angleRosToLeaflet(point.angle));
    MapStatus.layerArr.dockPoint.push(marker);
    dockPointEditEvent(marker, stateSet);
  });
};

// 建立 task point 的 marker
export const createTaskPoint = (latlng, PointType, stateSet, angle = 90, baseMapControl) => {
  const options = createPointOption(PointType);
  const {
    icon,
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
  const { rotationAngle } = MapStatus.mapMarker.options;

  const taskData = { ...MapStatus.pointObj };
  taskData.pointId = id;
  taskData.pointName = toolTip;
  taskData.x = originalLatLng.lng.toFixed(2);
  taskData.y = originalLatLng.lat.toFixed(2);
  taskData.angle = angle;
  const arr = [...MapStatus.pathData];
  let isView = true;

  arr.forEach((path) => {
    if (path.pathId === MapStatus.selectPath.pathId) {
      if (path.taskPoint.length > 0) taskData.order = Math.max(...path.taskPoint.map((obj) => obj.order)) + 1;
      const stepsPath = {
        ...taskData,
        pathId: path.pathId,
        latLng: latlng,
        originalLatLng,
        id,
        name: toolTip,
      };
      path.taskPoint.push(taskData);
      isView = path.isView;
      if (path.actionApiType !== 'post') path.actionApiType = 'put';
      updateStepsInRedux('addPoint', stepsPath, stepsPath);
    }
  });

  stateSet.setPathData(arr);

  const marker = L.marker(latlng, {
    icon,
    apiType: 'post',
    zIndexOffset,
    draggable,
    toolTip,
    type,
    id,
    pathId: MapStatus.selectPath.pathId,
    rosAngle: angle,
    originalLatLng,
  });

  marker.setRotationAngle(angleRosToLeaflet(angle) + rotationAngle);

  MapStatus.layerArr.taskPoint.push(marker);
  taskPointEditEvent(marker, stateSet, baseMapControl);

  if (isView) {
    marker.addTo(MapStatus.map);
    groupByPathLine(baseMapControl.zoomSize);
  }
};

// init api 取得的 task point
export const initTaskPoint = (point, stateSet, baseMapControl) => {
  const options = createPointOption('TaskPoint');
  const { rotationAngle } = MapStatus.mapMarker.options;
  const marker = L.marker(point.latLng, {
    ...options,
    id: point.id,
    toolTip: point.name,
    pathId: point.pathId,
    rosAngle: point.angle,
    originalLatLng: point.originalLatLng,
  });

  marker.setRotationAngle(angleRosToLeaflet(point.angle) + rotationAngle);

  MapStatus.layerArr.taskPoint.push(marker);
  taskPointEditEvent(marker, stateSet, baseMapControl);

  marker.addTo(MapStatus.map);
};

// 依照 pathData 建立各 path 裡 task point 之間的箭頭線段
export const groupByPathLine = (scale = 100) => {
  MapStatus.pathArrowLines.forEach((item) => { item.remove(); });
  MapStatus.pathArrowLines = [];

  MapStatus.pathData.forEach((path) => {
    // 如果 path 裡的 task point 小於或等於 1 則不畫線
    if (path.taskPoint.length <= 1) return;

    let lineArr = [];
    let prePointLatLng;
    path.taskPoint.forEach((point) => {
      const latlng = getRotateLeafletPosition({ lat: point.y, lng: point.x }, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

      // 當 lineArr 裡已有前一點座標時，則將目前 task point 座標轉換成箭頭指向 icon 外圍的座標
      if (lineArr.length === 1) {
        const size = (scale / 100) * IconStyle.baseSize.task;
        const nowPointLatLng = getInterSetLatLng([prePointLatLng, latlng], size * 0.3);
        lineArr.push(nowPointLatLng);
      } else {
        lineArr.push(latlng);
      }

      // 當 lineArr 有兩個座標時，畫出箭頭線段，並清空 lineArr 再將目前 task point 座標 pash 進去
      if (lineArr.length >= 2) {
        createTaskPathLine(lineArr, path.pathId, path.isView, scale);
        lineArr = [];
        lineArr.push(latlng);
      }

      prePointLatLng = latlng;
    });
  });
};

const getThetaOfTwoPoints = (latLngs) => {
  const {
    deltaX,
    deltaY,
    vectorX,
    vectorY,
  } = getAnalysisData(latLngs);
  if (deltaX === 0 || vectorX === 0) return 0;
  const theta = Math.atan((vectorY * deltaY) / (vectorX * deltaX));
  return theta;
};
const getRealIdFromLeafletId = (leafletId, type) => {
  const point = store.getState().mapSlice.points.find((item) => item._id === leafletId);
  const polyline = store.getState().mapSlice.polylines.find((item) => item._id === leafletId);
  const rectangle = store.getState().mapSlice.rectangles.find((item) => item._id === leafletId);
  const polygon = store.getState().mapSlice.polygons.find((item) => item._id === leafletId);

  switch (type) {
    case 'Home':
    case 'Landmark':
    case 'Halfway':
    case 'Delivery':
    case 'Remark':
    case 'Store':
    case 'Charger':
    case 'ParkingArea':
    case 'Elevator':
    case 'ConnectionPoint':
      return point.id;
    case 'NarrowLane':
    case 'VirtualWall':
    case 'NarrowGate':
    case 'Passage':
      return polyline?.id;
    case 'LowSpeed':
    case 'Slope':
    case 'NonAvoidance':
    case 'OneWay':
      return rectangle?.id;
    case 'MapArea':
    case 'Forbidden':
    case 'Disinfection':
    case 'Prioritization':
      return polygon?.id;
    default:
      break;
  }
  return null;
};
// 取得 CurrentItem 用於 Step payload
export const getCurrentStep = () => {
  const { currentItem, currentGroupItem, mapRotateAngle } = store.getState().mapSlice;
  const step = {};
  step.id = getRealIdFromLeafletId(currentItem._id, currentItem.type) || currentGroupItem.id;
  step.groupID = currentGroupItem.id || null;
  step.layerType = currentItem.type || currentGroupItem.type;
  step.mapAngle = mapRotateAngle;
  return step;
};
/**

遍歷並修改深層嵌套物件中的值
@param {any} value - 要更新的值
@param {string[]} array - 嵌套物件的路徑，表示要修改的位置
@returns {object} - 包含更新後值的新物件
*/
export const updateNestedObjectValue = (value, array) => {
  let newValueObj = {};
  let currentObj = {};
  array.forEach((item, index) => {
    if (index + 1 === array.length) {
      currentObj[item] = value;
      if (array.length === 1) newValueObj = currentObj;
    } else {
      newValueObj[item] = {};
      currentObj = newValueObj[item];
    }
  });
  return newValueObj;
};
// 顯示/隱藏 task point & path
export const displayTaskPathAndPoint = (pathId, type, scale) => {
  MapStatus.layerArr.taskPoint.forEach((point) => {
    if (point.options.pathId === pathId) {
      if (type) point.addTo(MapStatus.map); else point.remove();
    }
  });

  MapStatus.pathArrowLines.forEach((path) => {
    if (path.options.id === pathId) {
      if (type) groupByPathLine(scale); else path.remove();
    }
  });
};

// 計算 task point 箭頭頂點之座標
const getInterSetLatLng = (latLngs, pointSize = 20) => {
  const size = MapStatus.map.unproject([pointSize / 2, pointSize / 2], MapStatus.map.getZoom());
  const radius = MapStatus.map.distance([0, 0], size);
  const y0 = latLngs[0].lat;
  const x0 = latLngs[0].lng;
  const y1 = latLngs[1].lat;
  const x1 = latLngs[1].lng;
  const vectorX = getVector(x0, x1);
  const vectorY = getVector(y0, y1);
  const theta = getThetaOfTwoPoints(latLngs);
  const x = x1 - (vectorX * radius * Math.cos(theta));
  const y = y1 - (vectorY * radius * Math.sin(theta));
  return { lat: y, lng: x };
};

// 建立 online mode 的掃描點/路線點
export const createScanPoint = (latlng, PointType) => {
  const options = createPointOption(PointType);

  const {
    icon,
    zIndexOffset,
    type,
    id,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

  const marker = L.marker(latlng, {
    icon,
    apiType: 'post',
    zIndexOffset,
    draggable: false,
    type,
    id,
    originalLatLng,
  }).addTo(MapStatus.map);

  switch (PointType) {
    case 'Scan':
      MapStatus.scanLayer.scan.push(marker);
      break;
    case 'Future':
      MapStatus.scanLayer.future.push(marker);
      break;
    default:
      break;
  }

  // console.log(MapStatus.scanLayer.future);
};

// 移除 path mode 地圖的 layer
export const removePathModeLayer = (init = false) => {
  MapStatus.layerArr.taskPoint.forEach((item) => {
    if (init) item.off('click');
    item.remove();
  });
  MapStatus.layerArr.taskPoint = [];

  MapStatus.layerArr.dockPoint.forEach((item) => {
    if (init) item.off('click');
    item.remove();
  });
  MapStatus.layerArr.dockPoint = [];

  MapStatus.layerArr.homePoint.forEach((item) => {
    if (init) item.off('click');
    item.remove();
  });
  MapStatus.layerArr.homePoint = [];

  MapStatus.pathArrowLines.forEach((item) => {
    item.remove();
  });
  MapStatus.pathArrowLines = [];
};

// 清空 path mode layer 物件與 layout data
export const clearPathModeLayer = (isClearAll = true) => {
  removePathModeLayer(true);
  MapStatus.layerArr.taskPoint = [];
  MapStatus.layerArr.dockPoint = [];
  MapStatus.layerArr.homePoint = [];
  MapStatus.pathArrowLines = [];
  MapStatus.pathData = [];
  MapStatus.dockData = [];
  MapStatus.homeData = [];
  MapStatus.deletePath = [];
  MapStatus.deleteDockHome = [];
  MapStatus.selectPath = {
    type: 'currentPoint',
    pathId: '',
    pointId: '',
  };

  if (isClearAll) {
    MapStatus.getPathData = [];
    MapStatus.initPathData = [];
    MapStatus.initDockPoint = [];
    MapStatus.initHomePoint = [];
  }
};

// 關閉 task/dock/home 等 point 的拖拉事件
export const closePathLayerDrag = () => {
  MapStatus.layerArr.taskPoint.forEach((item) => { if (item.dragging != null) item.dragging.disable(); });
  MapStatus.layerArr.dockPoint.forEach((item) => { if (item.dragging != null) item.dragging.disable(); });
  MapStatus.layerArr.homePoint.forEach((item) => { if (item.dragging != null) item.dragging.disable(); });
};

// 開啟 task/dock/home 等 point 的拖拉事件
export const openPathLayerDrag = () => {
  MapStatus.layerArr.taskPoint.forEach((item) => { if (item.dragging != null) item.dragging.enable(); });
  MapStatus.layerArr.dockPoint.forEach((item) => { if (item.dragging != null) item.dragging.enable(); });
  MapStatus.layerArr.homePoint.forEach((item) => { if (item.dragging != null) item.dragging.enable(); });
};
/* ------------------------------------------------------------------- */

/* --------------------------- Parking Area --------------------------- */
export const initParkingAreaGroupData = (data) => {
  data.parkingAreaData.forEach((item) => {
    const group = {
      id: item.id,
      name: item.name,
      type: 'ParkingArea',
      apiType: 'init',
      targetCharging: item.binding_dock_list,
      targetElevator: item.binding_elevator_list,
      targetIotGate: item.binding_iotgate_list,
      targetStore: classifyTargetPointType({
        bindData: item.binding_point_list,
        pointData: data.pointData,
        type: 'store',
        targetType: 'targetStore',
        parkingAreaID: item.id,
      }),
      targetDelivery: classifyTargetPointType({
        bindData: item.binding_point_list,
        pointData: data.pointData,
        type: 'delivery',
        targetType: 'targetDelivery',
        parkingAreaID: item.id,
      }),
      targetHalfway: classifyTargetPointType({
        bindData: item.binding_point_list,
        pointData: data.pointData,
        type: 'halfway',
        targetType: 'targetHalfway',
        parkingAreaID: item.id,
      }),
    };
    item.point_list.forEach((parking) => {
      const parkingPoint = data.pointData.find((point) => point.id === parking.point_id);
      parkingPoint.groupName = group.name;
      parkingPoint.groupID = group.id;
      parkingPoint.heading = calculateRosAngle(parkingPoint.heading, data.mapAngle, '-');
      createInitParkingPoint({
        parkingPoint,
        mapAngle: data.mapAngle,
        setLayerToolMode: data.setLayerToolMode,
        setIsStepEdit: data.setIsStepEdit,
        setEditLayerData: data.setEditLayerData,
        setSelectItemClose: data.setSelectItemClose,
      });
    });
    store.dispatch(addGroup(group));
  });
};

// 將 binding_point_list 裡的 point 分類為 store 或 delivery 的綁定目標物件
const classifyTargetPointType = (data) => {
  const result = data.pointData
    .filter((point) => point.point_type === data.type)
    .filter((point) => data.bindData.includes(point.id))
    .map((point) => point.id);

  return result;
};

// 將選擇到的駐停區的駐停點顯示外框
export const displayGroupPointBorder = (groupID = null) => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type !== 'ParkingArea') return;

    if (layer.options.groupID === groupID) {
      const pointClass = layer._icon.className;
      layer._icon.className = `${pointClass} iconSelect`;
    } else {
      const pointClass = layer._icon.className.split('iconSelect')[0];
      layer._icon.className = pointClass;
    }
  });
};

/* ------------------------------------------------------------------- */

/* --------------------------- Point --------------------------- */
// 設定新增的 Point default name
export const setAddPointName = (type, options) => {
  // 取得 layer name 尾段是 '_'+ number 的 Point
  let maxNum = 0;
  switch (type) {
    case 'TaskPoint':
      MapStatus.layerArr.taskPoint.forEach((point) => {
        const getLastNum = Number(point.options.toolTip.split('_')[1]);
        if (point.options.type === type && !isNaN(getLastNum) && getLastNum > maxNum) maxNum = getLastNum;
      });
      if (maxNum === 0) options.toolTip = `${type}_1`;
      else options.toolTip = `${type}_${maxNum + 1}`;
      break;
    // case 'Dock':
    //   lastTipArr = MapStatus.layerArr.dockPoint.filter((point) => {
    //     const getLastNum = Number(point.options.toolTip.split('_')[1]);
    //     return point.options.type === type && !isNaN(getLastNum);
    //   });
    //   break;
    // case 'Home':
    //   lastTipArr = MapStatus.layerArr.homePoint.filter((point) => {
    //     const getLastNum = Number(point.options.toolTip.split('_')[1]);
    //     return point.options.type === type && !isNaN(getLastNum);
    //   });
    //   break;
    default:
      store.getState().mapSlice.points.forEach((point) => {
        const getLastNum = Number(point.name?.split('_')[1]);
        if (point.type === type && !isNaN(getLastNum) && getLastNum > maxNum) maxNum = getLastNum;
      });
      let name = type;
      if (type === 'Halfway') name = 'Task'; // 原本中繼點改名 -> 任務點，預設名稱做修改
      if (maxNum === 0) options.toolTip = `${name}_1`;
      else options.toolTip = `${name}_${maxNum + 1}`;
      break;
  }
};

// 設定預設的駐停區名稱
export const setAddGroupName = (type, options) => {
  const { groups } = store.getState().mapSlice;
  let parkingGroup = [];
  if (type.includes('Manual')) parkingGroup = groups.filter((item) => item.type === type.replace('Manual', '') && item.autoCharging === false);
  else parkingGroup = groups.filter((item) => item.type === type);

  if (parkingGroup.length === 0) {
    options.toolTip = `${type}_1`;
  } else {
    let getLastNum = 1;
    parkingGroup.forEach((item) => {
      const groupNumber = Number(item.name.split('_')[1]);
      if (groupNumber > getLastNum) getLastNum = groupNumber;
    });
    options.toolTip = `${type}_${getLastNum + 1}`;
  }
};

// 建立 Point 的 options
export const createPointOption = (type, _id, oneWaySize = { width: 0, long: 0 }, image = null) => {
  const scale = (MapStatus.map.getZoom() + 100) / 100;
  let icon;
  let _type = type;
  const id = _id || uuidv4();
  switch (_type) {
    case 'Landmark':
      icon = IconStyle.LandmarkIcon(scale * 100);
      break;
    case 'Halfway':
      icon = IconStyle.HalfwayIcon(scale * 100);
      break;
    case 'Remark':
      icon = IconStyle.RemarkIcon(scale * 100);
      break;
    case 'Store':
      icon = IconStyle.StoreIcon(scale * 100);
      break;
    case 'Abandon':
      icon = IconStyle.AbandonIcon(scale * 100);
      break;
    case 'Delivery':
      icon = IconStyle.DeliveryIcon(scale * 100);
      break;
    case 'Dock':
      icon = IconStyle.DockIcon(scale * 100);
      break;
    case 'Home':
      icon = IconStyle.RemarkIcon(scale * 100);
      break;
    case 'TaskPoint':
      icon = IconStyle.TaskIcon(scale * 100);
      break;
    case 'Elevator':
      icon = IconStyle.ElevatorIcon(id);
      break;
    case 'Charger':
      icon = IconStyle.createChargerStationPointIcon(scale);
      break;
    case 'ManualCharger':
      icon = IconStyle.createManualChargerPointIcon(scale);
      _type = 'Charger';
      break;
    case 'ParkingArea':
      icon = IconStyle.createParkingAreaPointIcon(scale);
      break;
    case 'OneWayArrow':
      icon = IconStyle.OneWayArrowIcon(scale * 100, oneWaySize);
      break;
    case 'Scan':
      icon = IconStyle.ScanDotIcon;
      break;
    case 'ConnectMap':
      icon = IconStyle.MapIcon(scale, image, oneWaySize);
      break;
    case 'ConnectionPoint':
      icon = IconStyle.ConnectionPointIcon(scale * 100);
      break;
    default:
      console.log(`No matched icon for type '${type}'`);
      icon = IconStyle.RemarkIcon(scale * 100);
      break;
  }

  const options = {
    icon,
    zIndexOffset: 1500, // 將 z-index 設高於底圖 marker
    draggable: true, // 是否能拖拉
    toolTip: '',
    type: _type,
    id,
  };

  // 設定 polygon default name
  if (['ParkingArea', 'Charger', 'ConnectMap'].includes(_type)) {
    setAddGroupName(type, options);
  } else {
    setAddPointName(_type, options);
  }

  return options;
};

// 建立 Site 的 options
export const createSiteOption = (type, groupSize) => {
  const scale = (MapStatus.map.getZoom() + 100) / 100;
  let icon = null;

  if (type === 'Charger') {
    icon = IconStyle.createChargerIcon(groupSize, scale).icon;
  }

  const options = {
    icon,
    groupSize,
    zIndexOffset: 1000, // 將 z-index 設高於底圖 marker
    draggable: true, // 是否能拖拉
    toolTip: '',
    type,
    id: '',
  };

  // 取得 layer name 尾段是 '_'+ number 的 Point
  const lastTipArr = MapStatus.layerArr.chargingAreaList.filter((charger) => {
    const getLastNum = Number(charger.options.toolTip.split('_')[1]);
    return charger.options.type === type && !Number.isNaN(getLastNum);
  });

  if (lastTipArr.length === 0) {
    options.toolTip = `${type}_1`;
    options.id = `${type}_1`;
  } else {
    const getLastNum = Number(lastTipArr[lastTipArr.length - 1].options.toolTip.split('_')[1]);
    options.toolTip = `${type}_${getLastNum + 1}`;
    options.id = `${type}_${MapStatus.layerArr.chargingAreaList.length + 1}`;
  }

  return options;
};

// 建立 Point 的 marker
export const createPoint = (latlng, PointType, rosAngle = 90) => {
  const options = createPointOption(PointType);
  const {
    toolTip,
    type,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

  const marker = L.marker(latlng, {
    ...options,
    apiType: 'post',
    undoLatLng: latlng,
    redoLatLng: latlng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    rosAngle,
    undoRosAngle: rosAngle,
    redoRosAngle: rosAngle,
    originalLatLng,
    isRotate: false,
    storeNumber: '',
    services: '',
    phone: '',
    roomCode: '',
    isFood: false,
    layer: [],
    abandonPassword: '',
    abandonDays: '',
  }).addTo(MapStatus.map);

  MapStatus.addLayer = marker;
  MapStatus.addLayer.dragging.disable();
};

// 建立 Site 的 marker
export const createSite = (latlng, PointType, rosAngle = 90) => {
  const { groupSize = 1 } = MapStatus.addLayerExtra || {};
  const options = createSiteOption(PointType, groupSize);
  const {
    toolTip,
    type,
  } = options;

  const originalLatLng = getOriginLeafletPosition(latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

  const marker = L.marker(latlng, {
    ...options,
    apiType: 'post',
    undoLatLng: latlng,
    redoLatLng: latlng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    rosAngle,
    undoRosAngle: rosAngle,
    redoRosAngle: rosAngle,
    originalLatLng,
    isRotate: false,
    storeNumber: '',
    services: '',
    phone: '',
    roomCode: '',
    isFood: false,
    layer: [],
    abandonPassword: '',
    abandonDays: '',
  }).addTo(MapStatus.map);

  MapStatus.addLayer = marker;
  MapStatus.addLayer.dragging.disable();
};

// 建立 駐停點/充電點 maker
export const createSitePoint = (latlng, layerType, scale, mapAngle, rosAngle = 90, iconSize = 30) => {
  const { groupSize = 1, chargingMode } = MapStatus.addLayerExtra || {};
  const options = createPointOption(chargingMode || layerType);
  const { type, toolTip } = options;

  // 依據 resolution 換算 1 px 代表的 cm 值
  const unitCM = store.getState().mapSlice.mapInfo.resolution * 100;
  // 利用 icon 寬換算與相鄰下一個 icon 的 lng 值
  const distance = (iconSize * unitCM) / 100;
  // 判斷設定數量是奇數還是偶數
  const isOdd = (groupSize % 2) !== 0;

  const allPointLatLng = [];
  const firstPoint = {
    latlng: { lat: 0, lng: 0 },
    order: 1,
  };
  let firstDist = Math.floor(groupSize / 2);

  // 如果是偶數需扣 0.5 個 icon 單位
  if (!isOdd) firstDist = Math.floor(groupSize / 2) - 0.5;

  // 先算出第一個點位，後續點位在迭代推算出來
  firstPoint.latlng.lat = latlng.lat;
  firstPoint.latlng.lng = latlng.lng - (distance * firstDist);
  allPointLatLng.push(firstPoint);

  // 產生該 group 暫時的 id
  const groupID = uuidv4();

  for (let i = 0; i < groupSize - 1; i += 1) {
    const nextPoint = {
      latlng: {
        lat: allPointLatLng[i].latlng.lat,
        lng: allPointLatLng[i].latlng.lng + distance,
      },
      order: allPointLatLng[i].order + 1,
    };
    allPointLatLng.push(nextPoint);
  }
  // 產生該 group 數目的 marker 顯示於地圖上
  allPointLatLng.forEach((point) => {
    // 取得旋轉後的座標
    const rotateLatLng = getRotateLeafletPosition(point.latlng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);

    const marker = L.marker(rotateLatLng, {
      ...options,
      apiType: 'post',
      undoLatLng: latlng,
      redoLatLng: latlng,
      toolTip: `${point.order}`,
      order: `${point.order}`,
      isEditToolTip: false,
      isEditType: false,
      undoToolTip: `${point.order}`,
      redoToolTip: `${point.order}`,
      undoType: type,
      redoType: type,
      rosAngle,
      undoRosAngle: rosAngle,
      redoRosAngle: rosAngle,
      originalLatLng: point.latlng,
      groupID,
      chargingMode,
      groupName: toolTip,
    });
    marker.unbindTooltip();
    marker.bindTooltip(`${point.order}`, createToolTipOption(chargingMode, scale, rosAngle));
    rotatePoint(marker, rosAngle);
    marker.addTo(MapStatus.map);
    marker.dragging.disable();
    MapStatus.addLayer = marker;

    const tmpId = uuidv4();
    const pointBody = {
      _id: marker._leaflet_id,
      type,
      id: tmpId,
      tmpId,
      rosAngle,
      groupID,
      chargingMode,
      order: point.order,
      ...rotateLatLng,
    };

    if (type === 'Charger') pointBody.tag = '';
    store.dispatch(addPoint(pointBody));
  });

  const groupData = { id: groupID, name: toolTip, type: layerType, apiType: 'post' };

  if (layerType === 'ParkingArea') {
    groupData.targetCharging = [];
    groupData.targetElevator = [];
    groupData.targetIotGate = [];
    groupData.targetStore = [];
    groupData.targetDelivery = [];
    groupData.targetHalfway = [];
  }

  if (layerType === 'Charger') groupData.autoCharging = !chargingMode.includes('Manual');
  store.dispatch(addGroup(groupData));
  store.dispatch(setCurrentGroupItem({ id: groupID, type, mode: 'add' }));
};

// 建立 api 取得 Point 的 marker
export const createInitPoint = ({ latlng, data, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose }) => {
  const options = createPointOption(data.type);
  const { type } = options;
  const _options = {
    ...options,
    apiType: 'init',
    toolTip: data.toolTip,
    id: data.id,
    undoLatLng: latlng,
    redoLatLng: latlng,
    isEditToolTip: false,
    undoToolTip: data.toolTip,
    redoToolTip: data.toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    rosAngle: data.rosAngle,
    undoRosAngle: data.rosAngle,
    redoRosAngle: data.rosAngle,
    originalLatLng: data.originalLatLng,
    isRotate: true,
    storeNumber: data.store_number == null ? '' : data.store_number,
    services: data.service == null ? '' : data.service,
    phone: !data.phone ? '' : data.phone,
    roomCode: !data.room_code ? '' : data.room_code,
    isFood: !!data.working_group,
    layer: !data.layer_list ? [] : data.layer_list.map(((item) => ({ name: item, id: uuidv4() }))),
    abandonPassword: data.abandon_password ? data.abandon_password : '',
    abandonDays: data.abandon_days ? data.abandon_days : '',

  };
  const marker = L.marker(latlng, _options);
  rotatePoint(marker, data.rosAngle);
  marker.addTo(MapStatus.map);

  // 設定標籤名稱
  marker.bindTooltip(marker.options.toolTip, createToolTipOption(type));
  if (!MapStatus.toolTip) marker.closeTooltip();

  // 設定編輯監聽事件
  layerEditEvent(marker, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
  pointEditEvent(marker, setIsStepEdit, setEditLayerData);

  MapStatus.layerArr.point.push(marker);
};

/** 建立從 api 取得 Point 的 marker，並且講資料寫入 redux。 */
const createInitPointByRedux = ({ point, extraData, setLayerToolMode, mapAngle }) => {
  const type = getAreaType(point.point_type);
  const latLng = getRotateLeafletPosition({ lat: point.y, lng: point.x }, MapStatus.centerPosition, mapAngle);

  const options = createPointOption(type);
  const marker = L.marker(latLng, {
    ...options,
    id: point.id,
    toolTip: point.point_name,
    rosAngle: point.heading ?? 90,
    ...latLng,
    // TODO: 以下可能在重構後棄用
    isEditToolTip: false,
    isEditType: false,
    apiType: 'init',
    undoLatLng: latLng,
    redoLatLng: latLng,
    undoToolTip: point.point_name,
    redoToolTip: point.point_name,
    undoType: type,
    redoType: type,
    undoRosAngle: point.heading ?? 90,
    redoRosAngle: point.heading ?? 90,
  }).addTo(MapStatus.map);
  rotatePoint(marker, point.heading ?? 90);
  // 設定標籤名稱
  marker.bindTooltip(point.point_name, createToolTipOption(type));
  if (!MapStatus.toolTip) marker.closeTooltip();

  store.dispatch(addPoint({
    _id: marker._leaflet_id,
    type,
    id: point.id,
    tmpId: null,
    ...latLng,
    rosAngle: point.heading ?? 90,
    order: point.order,
    groupID: point.groupID,
    tag: point.tag,
    ...extraData,
  }));

  pointEditEventByRedux(marker, setLayerToolMode);
};

// 建立 api 取得 Point 的 marker
export const createInitChargingPoint = ({ point, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, rotateValue }) => {
  const { centerPosition } = MapStatus;
  const scale = (MapStatus.map.getZoom() + 100) / 100;
  const type = getAreaType(point.point_type);
  const latLng = { lat: point.y, lng: point.x };
  // 取得未旋轉前的座標
  const originalLatLng = latLng;
  // 取得旋轉後的座標
  const rotateLatLng = getRotateLeafletPosition(latLng, centerPosition, mapAngle);
  // dock_prepare 不會渲染在地圖上
  if (type.includes('Prepare')) {
    store.dispatch(addPoint({
      type,
      chargingMode: type,
      id: point.id,
      rosAngle: point.heading,
      order: point.order,
      groupID: point.groupID,
      tag: point.tag,
      ...rotateLatLng,
    }));
    return;
  }
  const options = createPointOption(type);
  const marker = L.marker(rotateLatLng, {
    ...options,
    type: 'Charger',
    id: point.id,
    chargingMode: type,
    apiType: 'init',
    undoLatLng: rotateLatLng,
    redoLatLng: rotateLatLng,
    toolTip: `${point.order}`,
    order: point.order,
    isEditToolTip: false,
    isEditType: false,
    undoToolTip: `${point.order}`,
    redoToolTip: `${point.order}`,
    undoType: type,
    redoType: type,
    rosAngle: point.heading,
    undoRosAngle: point.heading,
    redoRosAngle: point.heading,
    originalLatLng,
    groupID: point.groupID,
  });
  marker.bindTooltip(`${point.order}`, createToolTipOption(type, scale, rotateValue));
  rotatePoint(marker, point.heading);
  marker.addTo(MapStatus.map);

  layerEditEvent(marker, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
  pointEditEventByRedux(marker, setLayerToolMode);

  store.dispatch(addPoint({
    _id: marker._leaflet_id,
    chargingMode: type,
    id: point.id,
    tmpId: null,
    rosAngle: point.heading,
    order: point.order,
    groupID: point.groupID,
    tag: point.tag,
    ...rotateLatLng,
    type: 'Charger',
  }));
  return marker;
};

// 建立 api 取得 parking area 的 marker
export const createInitParkingPoint = (data) => {
  const { centerPosition } = MapStatus;
  const { setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, mapAngle } = data;
  const type = getAreaType(data.parkingPoint.point_type);
  const latlng = { lat: data.parkingPoint.y, lng: data.parkingPoint.x };

  const originalLatLng = getOriginLeafletPosition(latlng, centerPosition, mapAngle);
  // 取得旋轉後的座標
  const rotateLatLng = getRotateLeafletPosition(latlng, centerPosition, mapAngle);

  const options = createPointOption(type);
  const marker = L.marker(rotateLatLng, {
    ...options,
    id: data.parkingPoint.id,
    apiType: 'init',
    undoLatLng: rotateLatLng,
    redoLatLng: rotateLatLng,
    toolTip: data.parkingPoint.point_name,
    order: data.parkingPoint.point_name,
    isEditToolTip: false,
    isEditType: false,
    undoToolTip: data.parkingPoint.point_name,
    redoToolTip: data.parkingPoint.point_name,
    undoType: type,
    redoType: type,
    rosAngle: data.parkingPoint.heading,
    undoRosAngle: data.parkingPoint.heading,
    redoRosAngle: data.parkingPoint.heading,
    originalLatLng,
    groupID: data.parkingPoint.groupID,
    groupName: data.parkingPoint.groupName,
  });
  marker.bindTooltip(`${data.parkingPoint.point_name}`, createToolTipOption('ParkingArea'));
  rotatePoint(marker, data.parkingPoint.heading);
  marker.addTo(MapStatus.map);

  layerEditEvent(marker, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
  pointEditEventByRedux(marker, setLayerToolMode);

  store.dispatch(addPoint({
    _id: marker._leaflet_id,
    type,
    id: data.parkingPoint.id,
    tmpId: null,
    rosAngle: data.parkingPoint.heading,
    order: data.parkingPoint.point_name,
    groupID: data.parkingPoint.groupID,
    ...rotateLatLng,
  }));
  return marker;
};

export const createInitConnectMap = (data, image = null, oneWaySize) => {
  const markers = data.pointData.filter((point) => point.id !== 0).map((connectMap) => {
    const { centerPosition } = MapStatus;
    const { setLayerToolMode, mapAngle, setIsStepEdit, setEditLayerData, setSelectItemClose, setTipText, setCursor, setSelectLayerType } = data;
    // 用mapId 檢查該ConnectMap是否已經存在，已存在則將隱藏圖層顯示出來
    const marker = getLayerConnectMapByMapId(connectMap.id);
    if (marker) {
      store.dispatch(updateConnectMapPointId({ id: marker._leaflet_id }));
      store.dispatch(setCurrentItem({ _id: marker._leaflet_id, type, mode: 'edit' }));
      // 將隱藏圖層顯示出來
      showConnectMapById({ _idConnectMap: marker._leaflet_id });
      // 啟用新增ConnectionPoint功能
      addLayerConnectPointHandler(setTipText, setLayerToolMode, setCursor, setSelectLayerType);
      return marker;
    }
    connectMap.heading = calculateRosAngle(connectMap.heading, mapAngle, '-');
    const type = getAreaType(connectMap.point_type); // getAreaType('ConnectMap');
    const latlng = { lat: connectMap.y, lng: connectMap.x };

    const originalLatLng = getOriginLeafletPosition(latlng, centerPosition, mapAngle);
    // 取得旋轉後的座標
    const rotateLatLngX = getRotateLeafletPosition(latlng, centerPosition, mapAngle);
    const optionsX = createPointOption('ConnectMap', null, oneWaySize, image);
    const markerX = L.marker(rotateLatLngX, {
      ...optionsX,
      id: null,
      apiType: 'init',
      undoLatLng: rotateLatLngX,
      redoLatLng: rotateLatLngX,
      toolTip: connectMap.point_name,
      order: connectMap.point_name,
      isEditToolTip: false,
      isEditType: false,
      undoToolTip: connectMap.point_name,
      redoToolTip: connectMap.point_name,
      undoType: type,
      redoType: type,
      rosAngle: connectMap.heading,
      undoRosAngle: connectMap.heading,
      redoRosAngle: connectMap.heading,
      originalLatLng,
      // groupID: connectMap.groupID,
      // groupName: 'o',
      mapId: connectMap.id,
      height: oneWaySize.height,
      width: oneWaySize.width,
      mapBlobUrl: image,
      zIndexOffset: 0, // 修改圖層，要在 area list下
    });
    markerX.bindTooltip(connectMap.point_name, createToolTipOption('ParkingArea'));
    rotatePoint(markerX, connectMap.heading);
    markerX.addTo(MapStatus.map);

    layerEditEvent(markerX, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
    pointEditEventByRedux(markerX, setLayerToolMode);

    MapStatus.layerArr.connectMap.push(markerX);
    store.dispatch(updateConnectMapPointId({ id: markerX._leaflet_id }));
    store.dispatch(setCurrentItem({ _id: markerX._leaflet_id, type, mode: 'edit' }));
    const target = {
      id: null,
      groupID: null,
      UVC_id: null,
      type,
      toolTip: connectMap.point_name,
      nodeArr: null,
      originalLatLng,
      rosAngle: connectMap.heading,
    };
    layerEditEventClickStart(markerX, target, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

    store.dispatch(addPoint({
      _id: markerX._leaflet_id,
      type,
      id: 0,
      tmpId: null,
      rosAngle: connectMap.heading,
      order: connectMap.point_name,
      groupID: connectMap.groupID,
      mapName: connectMap.map_name,
      mapId: connectMap.id,
      height: oneWaySize.height,
      width: oneWaySize.width,
      mapBlobUrl: image,
      ...rotateLatLngX,
    }));
    return markerX;
  });
  return markers;
};

// export const createInitConnectMap = (data, image = null, oneWaySize) => {
//   data.pointData.filter((point) => point.id !== 0).map((parkingPoint) => {
//     parkingPoint.heading = calculateRosAngle(parkingPoint.heading, data.mapAngle, '-');
//     data.parkingPoint = parkingPoint;

//     const { centerPosition } = MapStatus;
//     const { setLayerToolMode, mapAngle, setIsStepEdit, setEditLayerData, setSelectItemClose } = data;
//     const type = getAreaType(data.parkingPoint.point_type); // getAreaType('ConnectMap');
//     const latlng = { lat: data.parkingPoint.y, lng: data.parkingPoint.x };

//     const originalLatLng = getOriginLeafletPosition(latlng, centerPosition, mapAngle);
//     // 取得旋轉後的座標
//     const rotateLatLngX = getRotateLeafletPosition(latlng, centerPosition, mapAngle);
//     const optionsX = createPointOption('ConnectMap', null, oneWaySize, image);
//     const markerX = L.marker(rotateLatLngX, {
//       ...optionsX,
//       id: null,
//       apiType: 'init',
//       undoLatLng: rotateLatLngX,
//       redoLatLng: rotateLatLngX,
//       toolTip: data.parkingPoint.point_name,
//       order: data.parkingPoint.point_name,
//       isEditToolTip: false,
//       isEditType: false,
//       undoToolTip: data.parkingPoint.point_name,
//       redoToolTip: data.parkingPoint.point_name,
//       undoType: type,
//       redoType: type,
//       rosAngle: data.parkingPoint.heading,
//       undoRosAngle: data.parkingPoint.heading,
//       redoRosAngle: data.parkingPoint.heading,
//       originalLatLng,
//       groupID: data.parkingPoint.groupID,
//       groupName: 'o',
//       mapId: parkingPoint.id,
//     });
//     markerX.bindTooltip(data.parkingPoint.point_name, createToolTipOption('ParkingArea'));
//     rotatePoint(markerX, data.parkingPoint.heading);
//     markerX.addTo(MapStatus.map);

//     // L.polyline(rotateLatLngX, {
//     //   color: 'blue', // 虚线颜色
//     //   weight: 2, // 虚线粗细
//     //   opacity: 0.5, // 虚线透明度
//     //   dashArray: '10, 10', // 虚线样式
//     // }).addTo(MapStatus.map);

//     // createPolylineByRedux('DottedLine', [{ lat: rotateLatLngX.lat, lng: rotateLatLngX.lng + 5 }, { lat: rotateLatLngX.lat, lng: rotateLatLngX.lng - 5 }], { groupID: data.parkingPoint.groupID, name: 'polyline', apiType: 'init', id: uuidv4() });

//     layerEditEvent(markerX, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
//     pointEditEventByRedux(markerX, setLayerToolMode);

//     MapStatus.layerArr.connectMap.push(markerX);
//     // setLayerToolMode(null);
//     // setBaseToolMode(null);
//     // setCursor('');

//     // MapStatus.mode = 'edit';
//     // MapStatus.addLayer = null;
//     // setEditMapType('connectMap');

//     // MapStatus.map.off('click');
//     // MapStatus.map.off('mousemove');
//     // MapStatus.map.off('mousedown');
//     // MapStatus.map.off('mouseup');
//     // MapStatus.layerArr.point.forEach((point) => {
//     //   if (point.dragging !== undefined) point.dragging.enable();
//     //   point.options.draggable = false;
//     // });

//     // MapStatus.map.dragging.disable();
//     // MapStatus.map.touchZoom.disable();
//     // MapStatus.map.doubleClickZoom.disable();
//     // MapStatus.map.scrollWheelZoom.disable();
//     // MapStatus.map.boxZoom.disable();
//     // MapStatus.map.keyboard.disable();
//     // if (MapStatus.map.tap) MapStatus.map.tap.disable();

//     // MapStatus.editType = 'site';
//     // setEditLayerData({
//     //   flagType: 'site',
//     //   layerType: 'ConnectMap',
//     //   layerName: data.parkingPoint.point_name,
//     //   data: {
//     //     id: 1000,
//     //     latlng: originalLatLng,
//     //     angle: data.parkingPoint.heading, // rosAngle,
//     //   },
//     // });
//     // store.dispatch(setCurrentGroupItem({ id: data.parkingPoint.groupID, type, mode: 'edit' }));
//     store.dispatch(updateConnectMapPointId({ id: markerX._leaflet_id }));
//     store.dispatch(setCurrentItem({ _id: markerX._leaflet_id, type, mode: 'edit' }));
//     const target = {
//       id: null,
//       groupID: null,
//       UVC_id: null,
//       type,
//       toolTip: data.parkingPoint.point_name,
//       nodeArr: null,
//       originalLatLng,
//       rosAngle: data.parkingPoint.heading,
//     };
//     layerEditEventClickStart(markerX, target, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

//     store.dispatch(addPoint({
//       _id: markerX._leaflet_id,
//       type,
//       id: 0,
//       tmpId: null,
//       rosAngle: data.parkingPoint.heading,
//       order: data.parkingPoint.point_name,
//       groupID: data.parkingPoint.groupID,
//       mapName: parkingPoint.map_name,
//       ...rotateLatLngX,
//     }));
//     return markerX;
//   });
// };

// 編輯 Point 的名稱
export const editPointName = (value, id, setIsStepEdit) => {
  MapStatus.map.eachLayer((layer) => {
    if (layer._leaflet_id === id && MapStatus.editLayer != null) {
      // 建立紀錄步驟
      MapStatus.editLayer.options.isEditToolTip = true;
      MapStatus.editLayer.options.isEditType = false;
      MapStatus.editLayer.options.undoToolTip = layer.options.toolTip;
      MapStatus.editLayer.options.redoToolTip = value;
      setIsStepEdit(true);

      // 變更 tool tip
      layer.options.toolTip = value;
      layer.unbindTooltip();
      layer.bindTooltip(value, createToolTipOption('Remark'));

      if (!MapStatus.toolTip) layer.closeTooltip();
    }
  });
};
/**
 * 刪除 LeafletLayer
 * @param {Number} id
 * @param {String} type
 */
const deleteLayerById = (id, type = '') => {
  const isMarkerMatch = (marker, targetType, targetId) => {
    const isIdMatch = marker.options.id === targetId;
    if (targetType === '') return isIdMatch;
    const isTypeMatch = marker.options.type === targetType;
    return isIdMatch && isTypeMatch;
  };
  MapStatus.map.eachLayer((layer) => {
    if (isMarkerMatch(layer, type, id)) layer.remove();
  });
};
/**
 * 新增 Icon 至 Polygon 中心
 */
const addPointTypeToLayer = ({ layer, pointType, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose }) => {
  const { id, rosAngle } = layer.options;
  // 獲取 Polygon 的邊界
  const bounds = layer.getBounds();
  // 獲取 Polygon 中心點
  const latLng = layer.getBounds().getCenter();
  // 從邊界中提取東北（northeast）和西南（southwest）角
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  // 計算經度差（寬度）和緯度差（長度）
  const width = Math.abs(northEast.lng - southWest.lng);
  const length = Math.abs(northEast.lat - southWest.lat);
  const angle = Number(rosAngle) + MapStatus.mapMarker.options.rotationAngle;
  const arrowOptions = createPointOption(pointType, id, { length, width });
  const marker = L.marker(latLng, {
    ...arrowOptions,
    id,
    originalLatLng: getOriginLeafletPosition(latLng, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle),
    angle,
  }).addTo(MapStatus.map);
  marker.dragging.disable();
  marker.setRotationAngle(angle);
  layerEditEvent(marker, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
};
/* ------------------------------------------------------------------- */

/* --------------------------- Gate --------------------------- */
// disable 地圖上所有窄門的虛線外框
export const disableNarrowGateBorder = () => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.border !== 'NarrowGate') return;
    layer.setStyle({ color: 'transparent' });
  });
};

// 建立 Gate 的 options
export const createGateOption = (type, groupID) => {
  const options = {
    color: leafletMapColor[type],
    fillColor: 'transparent',
    type,
    zIndexOffset: 1000,
    groupID,
    dashArray: '6, 6',
    weight: 2,
    border: type,
  };

  return options;
};

// 建立 Gate 的 options
export const createGateOptionByRedux = (type, groupID, name) => {
  const options = {
    id: uuidv4(),
    color: leafletMapColor[type],
    fillColor: 'transparent',
    fillOpacity: 0.9,
    type,
    zIndexOffset: 1000,
    gate_id: '',
    groupID,
    name,
    dashArray: '6, 6',
    weight: 2,
  };

  return options;
};
// 建立 Gate
export const createGate = (layerType) => {
  const latLng = MapStatus.nodeArr.map((node) => node.getLatLng());

  const options = createGateOption(layerType);
  const { color, fillColor, gate_id, type, zIndexOffset, dashArray, weight } = options;

  // TODO: The next refactoring needs to be adjust options
  const narrowGate = L.polygon(latLng, {
    color,
    fillColor,
    gate_id,
    type,
    zIndexOffset,
    dashArray,
    weight,
  }).addTo(MapStatus.map);

  MapStatus.currentGate = narrowGate;
};
export const createGateByRedux = (latlng, layerType, options) => {
  const defaultOptions = createGateOption(layerType);
  const { color, fillColor, groupID, type, zIndexOffset, dashArray, weight, fillOpacity, name, id, nodeArr, border } = options || defaultOptions;

  // 取得 NarrowGate 的四個點座標
  const polygon = L.polygon(latlng, {
    id,
    color,
    fillColor,
    groupID,
    name: !name ? 'gate' : name,
    type,
    dashArray,
    zIndexOffset,
    weight,
    fillOpacity,
    nodeArr,
    border,
  }).addTo(MapStatus.map);
  return polygon;
};

// 即時更新新增的 Gate 長度
export const updateAddGate = (gate, newLatLng) => {
  // 取得 NarrowGate 的四個點座標
  const narrowGateLatLng = calculateExtendPoint(newLatLng, 0.5);
  gate.options.nodeArr = newLatLng;
  gate.setLatLngs(narrowGateLatLng);
};

// 即時更新新增的 Gate 長度
export const updateAddGateIotGate = (gate, sourceLatLng, newLatLng, width = 0.8) => {
  const latLng = [
    sourceLatLng,
    newLatLng,
  ];
  // 取得 NarrowGate 的四個點座標 [左下,左上,右上,右下]
  const narrowGateLatLng = calculateExtendPoint(latLng, width);
  gate.options.node = latLng;
  gate.setLatLngs(narrowGateLatLng);
};

export const updateWorkInsideLengthGate = (gate, latLng, width) => {
  // 取得 NarrowGate 的四個點座標
  const narrowGateLatLng = calculateExtendPoint(latLng, width);

  narrowGateLatLng[0] = { ...latLng[0] };
  narrowGateLatLng[3] = { ...latLng[1] };

  gate.options.node = latLng;
  gate.setLatLngs(narrowGateLatLng);
};

export const updateWorkOutsideLengthGate = (gate, latLng, width) => {
  // 取得 NarrowGate 的四個點座標
  const narrowGateLatLng = calculateExtendPoint(latLng, width);

  narrowGateLatLng[1] = { ...latLng[0] };
  narrowGateLatLng[2] = { ...latLng[1] };

  gate.options.node = latLng;
  gate.setLatLngs(narrowGateLatLng);
};
// 建立編輯 Gate
export const createEditGate = (layerType, layer, setIsStepEdit, down_latlng, setLayerToolMode, setEditLayerData) => {
  const latLng = layer.getLatLngs();
  // 取得 NarrowGate 的四個點座標
  const narrowGateLatLng = calculateExtendPoint(latLng, 0.8);

  const options = createGateOption(layerType);
  const { color, fillColor, type, zIndexOffset, dashArray, weight } = options;

  // TODO: The next refactoring needs to be adjust options
  const narrowGate = L.polygon(narrowGateLatLng, {
    color,
    fillColor,
    gate_id: layer.options.id,
    type,
    zIndexOffset,
    dashArray,
    weight,
  }).addTo(MapStatus.map);

  MapStatus.currentGate = narrowGate;
  gateEditEvent(narrowGate, layer, down_latlng, setIsStepEdit);
  gateMousedown(narrowGate, layer, setLayerToolMode, setIsStepEdit, setEditLayerData);
};
/* ------------------------------------------------------------------- */

/* --------------------------- Passage --------------------------- */
// 建立 Passage 的 options
export const createPassageOption = (type) => {
  const options = {
    color: leafletMapColor[type],
    fillColor: leafletMapColor[type],
    toolTip: '',
    type,
    zIndexOffset: 1000,
    id: '',
  };

  // 設定 Passage default name
  setAddPolylineName(type, options);

  return options;
};

// 建立 Passage
export const createPassage = (layerType, apiType = 'post') => {
  const latLng = MapStatus.nodeArr.map((node) => node.getLatLng());
  const options = createPassageOption(layerType);
  const { toolTip, type } = options;

  const passage = L.polygon(latLng, {
    ...options,
    apiType,
    undoLatLng: latLng,
    redoLatLng: latLng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    id: uuidv4(),
  }).addTo(MapStatus.map);
  MapStatus.addLayer = passage;
};

// 建立 Passage
export const createInitPassage = (data, getLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, areaType) => {
  let optionsType = areaType;
  if (optionsType !== 'NarrowLane') optionsType = 'Passage';

  const options = createPassageOption(optionsType);
  const { type } = options;

  const passage = L.polygon(getLatLng, {
    ...options,
    id: data.id,
    toolTip: data.toolTip,
    apiType: 'init',
    undoLatLng: getLatLng,
    redoLatLng: getLatLng,
    isEditToolTip: false,
    undoToolTip: data.toolTip,
    redoToolTip: data.toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: data.originalLatLng,
  }).addTo(MapStatus.map);

  // 設定 Passage node 點編輯座標
  calculatePassageNode(passage);

  // 設定標籤名稱
  passage.bindTooltip(passage.options.toolTip, createToolTipOption('polygon'));
  if (!MapStatus.toolTip) passage.closeTooltip();

  // 設定編輯監聽事件
  layerEditEvent(passage, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  store.dispatch(addPolyline({ _id: passage._leaflet_id, id: data.id, type, name: data.toolTip, apiType: 'init' }));
};

// 即時更新新增的 Passage 長度
export const updateAddPassage = (passage, newLatLng) => {
  const latLng = [
    MapStatus.nodeArr[0].getLatLng(),
    newLatLng,
  ];
  // 取得 Passage 的四個點座標
  const passageLatLng = calculateExtendPoint(latLng);

  passage.options.node = latLng;
  passage.setLatLngs(passageLatLng);
};

// 依 Passage 座標推算兩點 node 座標
export const calculatePassageNode = (layer) => {
  const passageLatLng = layer.getLatLngs()[0];
  const node_1 = {
    lat: (passageLatLng[0].lat + passageLatLng[1].lat) / 2,
    lng: (passageLatLng[0].lng + passageLatLng[1].lng) / 2,
  };
  const node_2 = {
    lat: (passageLatLng[2].lat + passageLatLng[3].lat) / 2,
    lng: (passageLatLng[2].lng + passageLatLng[3].lng) / 2,
  };
  layer.options.node = [node_1, node_2];
};
/* ------------------------------------------------------------------- */

/* --------------------------- line --------------------------- */
// 建立 line 物件的 node point
export const createLineNodePoint = (latlng) => {
  const marker = L.marker(latlng, {
    icon: IconStyle.NodeIcon,
    zIndexOffset: 1000, // 將 z-index 設高於底圖 marker
    draggable: false, // 是否能拖拉
    index: MapStatus.nodeArr.length,
  }).addTo(MapStatus.map);

  MapStatus.nodeArr.push(marker);
  return marker;
};

// 設定新增的 Polyline default name
export const setAddPolylineName = (type, options) => {
  // 取得 layer name 尾段是 '_'+ number 的 Polyline
  const lastTipArr = store.getState().mapSlice.polylines.filter((point) => {
    const getLastNum = Number(point.name?.split('_')[1]);
    return point.type === type && !Number.isNaN(getLastNum);
  });
  if (lastTipArr.length === 0) {
    options.toolTip = `${type}_1`;
    options.id = `${type}_1`;
  } else {
    const getLastNum = Number(lastTipArr[lastTipArr.length - 1].name.split('_')[1]);
    options.toolTip = `${type}_${getLastNum + 1}`;
    options.id = `${type}_${lastTipArr.length + 1}`;
  }
};
// 設定新增的 Polyline default name
export const setAddPolylineNameByRedux = (type, options) => {
  // 取得 layer name 尾段是 '_'+ number 的 Polyline
  const lastTipArr = store.getState().mapSlice.polylines.filter((polyline) => {
    const getLastNum = Number(polyline.toolTip?.split('_')[1]);
    return polyline.type === type && !Number.isNaN(getLastNum) && options.id !== polyline.id;
  });

  if (lastTipArr.length === 0) {
    options.toolTip = `${type}_1`;
  } else {
    const getLastNum = Number(lastTipArr[lastTipArr.length - 1].toolTip?.split('_')[1]);
    options.toolTip = `${type}_${getLastNum + 1}`;
  }
};

// 建立 Polyline 的 options
export const createPolylineOption = (type, defaultOptions) => {
  const { id, weight, dashArray, name, groupID } = defaultOptions;
  const options = {
    color: leafletMapColor[type],
    fillColor: leafletMapColor[type],
    toolTip: '',
    type,
    name: name || type,
    zIndexOffset: 1000,
    id,
    groupID,
    weight: weight || 3,
    dashArray: dashArray || null,
  };
  // 設定 polygon default name
  setAddPolylineName(type, options);
  return options;
};
export const createPolylineOptionByRedux = (type, defaultOptions) => {
  const { id, weight, dashArray, name, groupID } = defaultOptions;
  const options = {
    color: leafletMapColor[type],
    fillColor: leafletMapColor[type],
    toolTip: '',
    type,
    name: name || type,
    zIndexOffset: 1000,
    id,
    groupID,
    weight: weight || 3,
    dashArray: dashArray || null,
  };
  // 設定 polygon default name
  setAddPolylineNameByRedux(type, options);
  return options;
};

// 建立 Polyline
export const createPolyline = (layerType) => {
  const latLng = MapStatus.nodeArr.map((node) => node.getLatLng());
  const options = createPolylineOption(layerType, { width: 5 });
  const { color, fillColor, toolTip, type, zIndexOffset, weight, dashArray, name } = options;
  // TODO: The next refactoring needs to be adjust options
  const polyline = L.polyline(latLng, {
    color,
    fillColor,
    toolTip,
    type,
    zIndexOffset,
    weight,
    name,
    dashArray,
    undoLatLng: latLng,
    redoLatLng: latLng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: [],
    apiType: 'post',
    id: uuidv4(),
  }).addTo(MapStatus.map);
  MapStatus.addLayer = polyline;
  return polyline;
};

export const createPolylineByRedux = (layerType, latLng, defaultOptions) => {
  const { groupID, apiType, id, name: defName, nodeArr } = defaultOptions;
  const options = createPolylineOptionByRedux(layerType, { width: 5, name: defName, groupID, id });
  const { color, fillColor, toolTip, type, zIndexOffset, weight, dashArray, name } = options;
  // TODO: The next refactoring needs to be adjust options
  const polyline = L.polyline(latLng, {
    color,
    fillColor,
    id,
    toolTip,
    type,
    zIndexOffset,
    apiType: apiType || 'post',
    weight,
    name,
    groupID,
    dashArray,
    isEditToolTip: false,
    isEditType: false,
    originalLatLng: [],
    nodeArr,
  }).addTo(MapStatus.map);
  return polyline;
};

// 建立 api 取得的 Polyline
export const createInitPolyline = (layerType, data, getLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose) => {
  const options = createPolylineOption(layerType, 5);
  const { color, fillColor, type, zIndexOffset, weight, dashArray } = options;
  createEditPolylineNode(getLatLng, setIsStepEdit, false);
  // TODO: The next refactoring needs to be adjust options
  const polyline = L.polyline(getLatLng, {
    color,
    fillColor,
    id: data.id,
    toolTip: data.toolTip,
    type,
    zIndexOffset,
    apiType: 'init',
    weight,
    dashArray,
    undoLatLng: getLatLng,
    redoLatLng: getLatLng,
    isEditToolTip: false,
    undoToolTip: data.toolTip,
    redoToolTip: data.toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: data.originalLatLng,
    node: [...MapStatus.nodeEditArr],
  }).addTo(MapStatus.map);

  if (type === 'VirtualWall') {
    polyline.options.node.forEach((item) => { item.remove(); });
  } else {
    polyline.options.node.forEach((item, index) => { item.options.originalLatLng = data.originalLatLng[index]; });
  }
  MapStatus.nodeEditArr = [];
  // 設定標籤名稱
  polyline.bindTooltip(polyline.options.toolTip, createToolTipOption('line'));
  if (!MapStatus.toolTip) polyline.closeTooltip();

  // 設定編輯監聽事件
  layerEditEvent(polyline, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  store.dispatch(addPolyline({ _id: polyline._leaflet_id, id: data.id, type, name: data.toolTip, apiType: 'init' }));
};

// 即時更新新增的 Polyline 長度
export const updateAddPolyline = (polyline, newLatLng) => {
  const latLng = [
    polyline.getLatLngs()[0],
    newLatLng,
  ];
  polyline.setLatLngs(latLng);
};
// 即時更新新增的 Polyline 長度
export const updatePolylineWithLatLng = (polyline, point1, point2) => {
  const latLng = [
    point1,
    point2,
  ];
  polyline.setLatLngs(latLng);
};
// 即時更新新增的 Point 位置
export const updateAddPoint = (point, latLng) => {
  point.setLatLng(latLng);
};

// 建立編輯 Polyline 的 node point
export const createEditPolylineNode = (latlngs, setIsStepEdit, draggable = true) => {
  const nodeArr = [];
  latlngs.forEach((latlng) => {
    const marker = L.marker(latlng, {
      icon: IconStyle.NodeIcon,
      zIndexOffset: 2000, // 將 z-index 設高於底圖 marker
      draggable, // 是否能拖拉
      index: MapStatus.nodeEditArr.length,
      originalLatLng: null,
    }).addTo(MapStatus.map);
    nodeMarkerDrag(marker, 'edit', setIsStepEdit);
    MapStatus.nodeEditArr.push(marker);
    nodeArr.push(marker._leaflet_id);
  });
  return nodeArr;
};

/* ------------------------------------------------------------------- */

/* --------------------------- UVC Zone --------------------------- */
// 設定 UVC Zone 的外圍 point 座標 (Daniel HC Cheng 雪霸算出來的，很猛)
export const setUVCZonePoint = (originalLatLngData, guard = 0.5) => {
  const pointData = [];
  const latLngData = forceClockWise(originalLatLngData);

  latLngData.forEach((latLng, index) => {
    // 取得計算一角所需的 3 點
    const threePoints = [
      latLngData.at(index - 1),
      latLng,
      latLngData.at((index + 1) % latLngData.length),
    ];
    // 利用 guard 計算所需延伸之距離
    let distance = calculateDistance(threePoints, guard);
    // 取得該角是凸點還是凹點，若是凹點，distance 取負值
    if (!isAngleClockwise(threePoints)) {
      distance *= -1;
    }
    const p1 = calculateExtendedPoint(threePoints[0], threePoints[1], distance);
    const p2 = calculateExtendedPoint(threePoints[2], threePoints[1], distance);
    pointData.push({
      lng: p1[0] + p2[0] - latLng.lng,
      lat: p1[1] + p2[1] - latLng.lat,
    });
  });
  return pointData;
};

// 判斷凸點 (Daniel HC Cheng 雪霸算出來的，很猛)
const forceClockWise = (vertices) => {
  if (vertices.length < 3) return vertices;

  // 找凸點，在 x 或 y 最大或最小的地方，凸點必可判斷該多邊形之順逆
  let index = 0;
  for (let i = 1; i < vertices.length; i += 1) {
    const element = vertices[i];
    if (element.lat > vertices[index].lat) {
      index = i;
    }
  }
  // 凸點前線段的向量 與 凸點後線段的向量 外積，右手定則，負值表示順時針
  const threePoints = [
    vertices.at(index - 1),
    vertices.at(index),
    vertices.at((index + 1) % vertices.length),
  ];
  if (!isAngleClockwise(threePoints)) {
    // 起始點不動，reverse 剩餘節點
    const [head, ...body] = vertices;
    return [
      head,
      ...body.reverse(),
    ];
  }
  return vertices;
};

// 角度順時針判斷 (Daniel HC Cheng 雪霸算出來的，很猛)
const isAngleClockwise = (vertices) => {
  const vector1 = [
    vertices[1].lng - vertices[0].lng,
    vertices[1].lat - vertices[0].lat,
    0,
  ];
  const vector2 = [
    vertices[2].lng - vertices[1].lng,
    vertices[2].lat - vertices[1].lat,
    0,
  ];
  const [, , z] = cross(vector1, vector2);
  if (z > 0) return false;
  return true;
};

// 向量外積 (Daniel HC Cheng 雪霸算出來的，很猛)
const cross = (u = [0, 0, 0], v = [0, 0, 0]) => ([
  u[1] * v[2] - u[2] * v[1],
  u[2] * v[0] - u[0] * v[2],
  u[0] * v[1] - u[1] * v[0],
]);

// 計算延伸之距離 (Daniel HC Cheng 雪霸算出來的，很猛)
const calculateDistance = (vertices, guard) => {
  const v1 = [
    vertices[0].lng - vertices[1].lng,
    vertices[0].lat - vertices[1].lat,
    0,
  ];
  const v2 = [
    vertices[2].lng - vertices[1].lng,
    vertices[2].lat - vertices[1].lat,
    0,
  ];
  const v1Length = Math.sqrt(v1[0] ** 2 + v1[1] ** 2 + v1[2] ** 2);
  const v2Length = Math.sqrt(v2[0] ** 2 + v2[1] ** 2 + v2[2] ** 2);
  const [x, y, z] = cross(v1, v2);
  const cpLength = Math.sqrt(x ** 2 + y ** 2 + z ** 2);
  const guardNum = Number(guard).valueOf();
  const distance = (guardNum * v1Length * v2Length) / cpLength;
  return distance;
};

// 計算延伸之 point 座標 (Daniel HC Cheng 雪霸算出來的，很猛)
const calculateExtendedPoint = (center, target, distance) => {
  const c = {
    x: Number(center.lng),
    y: Number(center.lat),
  };
  const p = {
    x: Number(target.lng),
    y: Number(target.lat),
  };
  const r = Math.abs(
    Math.sqrt(Math.pow(p.x - c.x, 2) + Math.pow(p.y - c.y, 2)),
  ); // r: c 點到 p 點的距離
  const x = (distance * (p.x - c.x)) / r + p.x;
  const y = (distance * (p.y - c.y)) / r + p.y;
  return [x, y];
};

// 計算 UVC Zone 中心座標 (Daniel HC Cheng 雪霸算出來的，很猛)
export const getCenterLatLng = (nodes = []) => {
  let totalLat = 0;
  let totalLng = 0;

  nodes.forEach((node) => {
    totalLat += node.lat;
    totalLng += node.lng;
  });

  return {
    lat: (totalLat / nodes.length),
    lng: (totalLng / nodes.length),
  };
};

// const calculateUVCPoint = (center, target) => {
//   // type casting
//   const guardNum = Number(0.5).valueOf();
//   const c = {
//     x: Number(center.lng),
//     y: Number(center.lat),
//   };
//   const p = {
//     x: Number(target.lng),
//     y: Number(target.lat),
//   };

//   const r = Math.abs(
//     Math.sqrt(Math.pow(p.x - c.x, 2) + Math.pow(p.y - c.y, 2)),
//   ); // r: C 點到 P 點的距離
//   const x = (guardNum * (p.x - c.x)) / r + p.x;
//   const y = (guardNum * (p.y - c.y)) / r + p.y;

//   return [x, y];
// };

// 建立 UVC Zone 的外圍 polygon
const createUVCZonePolygon = (setLayerToolMode, setIsStepEdit, setEditLayerData, createType = 'add', Disinfection, setUVCZoneData = null) => {
  const { currentItem } = store.getState().mapSlice;
  let layer = MapStatus.addLayer;
  switch (createType) {
    case 'add':
      layer = MapStatus.addLayer;
      break;
    case 'edit':
      layer = MapStatus.map._layers[currentItem._id];
      break;
    case 'init':
      layer = Disinfection;
      break;
    default:
      break;
  }

  const UVCZoneLatLng = setUVCZonePoint(layer.getLatLngs()[0]);
  const options = createPolygonOption('UVCZone');

  const originalLatLng = UVCZoneLatLng.map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));

  const polygon = L.polygon(UVCZoneLatLng, {
    ...options,
    fillColor: 'transparent',
    UVC_id: layer.options.id,
    apiType: false,
    originalLatLng,
  }).addTo(MapStatus.map);

  UVCZoneEditEvent(polygon, setLayerToolMode, setIsStepEdit, setEditLayerData, setUVCZoneData);
  layer.options.UVCZoneId = polygon._leaflet_id;

  return polygon;
};

// 建立 UVC Zone arrow line 的 options
export const createUVCZoneLineOption = () => {
  const options = {
    color: leafletMapColor.DottedLine,
    fillColor: 'transparent',
    type: 'DottedLine',
    zIndexOffset: 1000,
    dashArray: '3,6',
    weight: 1.8,
  };

  return options;
};

// 建立 UVC Zone arrow line
export const createUVCZoneLine = (latLng) => {
  const options = createUVCZoneLineOption();
  const { color, zIndexOffset } = options;

  const originalLatLng = latLng.map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));

  const polyline = L.polyline(latLng, {
    ...options,
    originalLatLng,
  });

  polyline.arrowheads({
    size: '12px',
    fillColor: color,
    fill: true,
    stroke: false,
    zIndexOffset,
  });

  polyline.addTo(MapStatus.map);

  MapStatus.UVCZoneArrowLines.push(polyline);
};
/* ------------------------------------------------------------------- */

/* --------------------------- Polygon --------------------------- */
// 即時變更新增 Polygon 的 style
export const changeAddPolygonStyle = (type) => {
  const style = {
    color: leafletMapColor[type],
    fillColor: leafletMapColor[type],
  };

  // 根據變換的 style 重新設定 polygon 的 default name
  setAddPolygonName(type, style);

  MapStatus.addLayer.options.type = type;
  MapStatus.addLayer.setStyle(style);
};

// 即時更新新增的 Polygon 位置
export const updateAddPolygon = (polygon, newLatLng) => {
  const latLng = polygon.getLatLngs()[0];
  latLng.push(newLatLng);
  polygon.setLatLngs(latLng);
};

// 設定新增的 polygon default name
export const setAddPolygonName = (type, options) => {
  const polygons = [...store.getState().mapSlice.polygons, ...store.getState().mapSlice.rectangles];
  const lastTipArr = polygons.filter((polygon) => {
    const name = polygon.name || polygon.toolTip;
    const getLastNum = Number(name.split('_')[1]);
    return polygon.type === type && !Number.isNaN(getLastNum);
  });
  if (lastTipArr.length === 0) {
    options.toolTip = `${type}_1`;
    options.id = `${type}_1`;
  } else {
    const getLastNum = Number(lastTipArr[lastTipArr.length - 1].name.split('_')[1]);
    options.toolTip = `${type}_${getLastNum + 1}`;
    options.id = `${type}_${lastTipArr.length + 1}`;
  }
};

// 建立 Polygon 的 options
export const createPolygonOption = (type) => {
  const options = {
    color: leafletMapColor[type],
    fillColor: leafletMapColor[type],
    toolTip: '',
    type,
    zIndexOffset: 1000,
    id: '',
  };

  // 設定 polygon default name
  setAddPolygonName(type, options);

  return options;
};

// 建立 new Polygon
export const createPolygon = (layerType, apiType = 'post', option = {}) => {
  const latLng = MapStatus.nodeArr.map((node) => node.getLatLng());
  const options = createPolygonOption(layerType);
  const { toolTip, type, groupID, name } = { ...options, ...option };
  const id = uuidv4();
  const polygon = L.polygon(latLng, {
    ...options,
    toolTip,
    type,
    apiType,
    draggable: true,
    undoLatLng: latLng,
    redoLatLng: latLng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: [],
    UVCZone: '',
    name,
    id,
    groupID,
    isDelete: false,
  }).addTo(MapStatus.map);
  MapStatus.addLayer = polygon;
  store.dispatch(addPolygon({
    _id: polygon._leaflet_id,
    id,
    type: layerType,
    name: toolTip,
    apiType,
  }));
  if (layerType === 'MapArea') return;
  store.dispatch(setCurrentItem({
    _id: polygon._leaflet_id,
    type: layerType,
    mode: 'add',
  }));
};

// init api 取得的 Polygon
export const createInitPolygon = (layerType, data, getLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData) => {
  const options = createPolygonOption(layerType);
  const name = data.toolTip || data.name;
  const { type } = options;
  const polygon = L.polygon(getLatLng, {
    ...options,
    id: data.id,
    toolTip: name,
    name,
    type,
    apiType: 'init',
    draggable: true,
    undoLatLng: getLatLng,
    redoLatLng: getLatLng,
    isEditToolTip: false,
    undoToolTip: name,
    redoToolTip: name,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: data.originalLatLng,
    UVCZone: '',
    isDelete: false,
    rosAngle: data.angle,
    undoRosAngle: data.angle,
    redoRosAngle: data.angle,
    groupID: data.groupID,
  }).addTo(MapStatus.map);

  // 設定標籤名稱
  polygon.bindTooltip(polygon.options.toolTip, createToolTipOption('polygon'));
  if (!MapStatus.toolTip) polygon.closeTooltip();

  // type 為 Disinfection 生成 UVC Zone
  let uvcZoneLayer = null;
  if (polygon.options.type === 'Disinfection') {
    uvcZoneLayer = createUVCZonePolygon(setLayerToolMode, setIsStepEdit, setEditLayerData, 'init', polygon, setUVCZoneData);
  }

  if (rectangleTypes.includes(polygon.options.type)) calculateRectangleCenter(polygon);

  if (polygon.options.type === 'OneWay') {
    addPointTypeToLayer({
      layer: polygon,
      pointType: 'OneWayArrow',
      setLayerToolMode,
      setIsStepEdit,
      setEditLayerData,
      setSelectItemClose,
    });
  }
  // 設定編輯監聽事件
  layerEditEvent(polygon, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  if (rectangleTypes.includes(polygon.options.type)) {
    const { originalLatLng } = polygon.options;
    const length = Number(Math.abs(originalLatLng[0].lng - originalLatLng[2].lng).toFixed(1));
    const width = Number(Math.abs(originalLatLng[0].lat - originalLatLng[2].lat).toFixed(1));
    const exists = store.getState().mapSlice.rectangles.some((item) => item.id === data.id);
    if (exists) {
      store.dispatch(updateRectangleById({
        id: data.id,
        _id: polygon._leaflet_id,
        type,
        name,
        apiType: 'init',
        rosAngle: data.angle,
        nodes: getLatLng,
        length,
        width,
      }));
    } else {
      store.dispatch(addRectangle({
        id: data.id,
        _id: polygon._leaflet_id,
        type,
        name,
        apiType: 'init',
        rosAngle: data.angle,
        nodes: getLatLng,
        length,
        width,
      }));
    }
  } else {
    const exists = store.getState().mapSlice.polygons.some((item) => item.id === data.id);
    if (exists) {
      store.dispatch(updatePolygonById({
        id: data.id,
        _id: polygon._leaflet_id,
        type,
        name,
        apiType: 'init',
        uvcLayerId: uvcZoneLayer?._leaflet_id,
      }));
    } else {
      store.dispatch(addPolygon({
        _id: polygon._leaflet_id,
        id: data.id,
        type,
        name,
        apiType: 'init',
        uvcLayerId: uvcZoneLayer?._leaflet_id,
      }));
    }
  }
};

// 第一點 node point 點擊觸發新增圖層視窗
export const setOpenCreatePolygonLayerModal = (setCreateLayerOpen) => {
  // node point 數目需大於 2，才可創建 Polygon
  if (MapStatus.nodeArr.length <= 2) return;

  // 建立未旋轉前之座標
  const latLngs = MapStatus.addLayer.getLatLngs();
  const originalLatLng = latLngs[0].map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
  MapStatus.addLayer.options.originalLatLng = originalLatLng;

  // 打開新增圖層視窗
  setCreateLayerOpen(true);
};

// 第一點 node point 點擊觸發新增圖層視窗
export const setCreatePolygonLayerModal = (option) => {
  const { setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, name } = option;
  // node point 數目需大於 2，才可創建 Polygon
  if (MapStatus.nodeArr.length <= 2) return;

  // 建立未旋轉前之座標
  const latLngs = MapStatus.addLayer.getLatLngs();
  const originalLatLng = latLngs[0].map((item) => getOriginLeafletPosition(item, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle));
  MapStatus.addLayer.options.originalLatLng = originalLatLng;

  MapStatus.nodeArr.forEach((node) => {
    node.off('dragstart');
    node.off('dragend');
    node.off('drag');
    node.remove();
  });

  MapStatus.nodeArr = [];

  if (MapStatus.originRectangleNode != null) MapStatus.originRectangleNode = [];

  // 設定是否編輯過的 value
  MapStatus.addLayer.options.isEdit = false;

  // 設定 tool tip 給完成的 layer
  MapStatus.addLayer.bindTooltip(name, createToolTipOption(MapStatus.layerType));

  // 如果目前標記名稱開關是關閉的話則先將這個 layer 的 tool tip 關閉
  if (!MapStatus.toolTip) MapStatus.addLayer.closeTooltip();
  layerEditEvent(MapStatus.addLayer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  // 清除 MapStatus addLayer value
  MapStatus.addLayer = null;
};

// 建立 polygon 物件的 node point
export const createPolygonNodePoint = (latlng, draggable = false, setCreateLayerOpen, option, setTipText) => {
  let icon;
  // 判斷是否為第一點決定使用的 node icon
  if (MapStatus.nodeArr.length > 0) {
    icon = IconStyle.NodeIcon;
  } else {
    icon = IconStyle.FirstNodeIcon;
  }

  const marker = L.marker(latlng, {
    icon,
    zIndexOffset: 1000, // 將 z-index 設高於底圖 marker
    draggable, // 是否能拖拉
    index: MapStatus.nodeArr.length,
  }).addTo(MapStatus.map);

  // 在第一點下監聽 click 結束新增 layer
  if (MapStatus.nodeArr.length === 0) {
    marker.on('click', () => {
      const { _id } = store.getState().mapSlice.currentItem;
      let targetLayer;
      MapStatus.map.eachLayer((layer) => {
        if (layer._leaflet_id === _id) targetLayer = layer;
      });
      if (targetLayer !== undefined && checkLayerSize(targetLayer)) {
        setTipText('CREATELAYER_FLAG_SMALL');
        return;
      }
      if (setCreateLayerOpen !== null) setOpenCreatePolygonLayerModal(setCreateLayerOpen);
      else {
        rebindIotGateMapArea();
        setCreatePolygonLayerModal(option);
      }
    });
  }
  nodeMarkerDrag(marker);
  MapStatus.nodeArr.push(marker);
};

// 建立 rectangle 物件的 node point
export const createRectangleNodePoint = (latlng, layerType, draggable = false) => {
  const icon = IconStyle.NodeIcon;

  const nodes = [];
  for (let i = 0; i < 4; i += 1) {
    const marker = L.marker(latlng, {
      icon,
      zIndexOffset: 1000, // 將 z-index 設高於底圖 marker
      draggable, // 是否能拖拉
      index: i,
    }).addTo(MapStatus.map);
    nodes.push(marker._leaflet_id);
    MapStatus.nodeArr.push(marker);
    MapStatus.originRectangleNode.push(marker.getLatLng());
  }

  const latLng = nodes.map((_id) => MapStatus.map._layers[_id].getLatLng());
  const options = createPolygonOption(layerType);

  const { toolTip, type } = options;
  const id = uuidv4();

  const polygon = L.polygon(latLng, {
    ...options,
    id,
    name: toolTip,
    toolTip,
    type,
    apiType: 'post',
    draggable: true,
    undoLatLng: latLng,
    redoLatLng: latLng,
    isEditToolTip: false,
    undoToolTip: toolTip,
    redoToolTip: toolTip,
    isEditType: false,
    undoType: type,
    redoType: type,
    originalLatLng: [],
    UVCZone: '',
    isDelete: false,
    rosAngle: 0,
    undoRosAngle: 0,
    redoRosAngle: 0,
  }).addTo(MapStatus.map);

  MapStatus.addLayer = polygon;
  // save to redux
  store.dispatch(addRectangle({ _id: polygon._leaflet_id, id, type, name: toolTip, apiType: 'post', rosAngle: 0 }));
  store.dispatch(setCurrentItem({ _id: polygon._leaflet_id, type: layerType, mode: 'add', nodeArr: nodes }));
};
// 建立 IotGate 和 MapArea 重新綁定
export const rebindIotGateMapArea = (groupID) => {
  let iotGate = [];
  const mapAreas = Object.values(MapStatus.map._layers).filter((layer) => layer.options.name?.includes('MapArea'));
  if (groupID) iotGate = store.getState().mapSlice.groups.filter((item) => item.id === groupID);
  else iotGate = store.getState().mapSlice.groups.filter((item) => item.type === 'IotGate' && item.apiType !== 'delete');
  iotGate.forEach((x) => {
    const { insidePoint, outsidePoint } = getGroupLayers(x.id, 'IotGate');
    const includesMapArea = mapAreas.reduce((prev, current) => {
      const insidepoint = isPointInPolygon(insidePoint.getLatLng(), current.getLatLngs()[0]);
      const outsidepoint = isPointInPolygon(outsidePoint.getLatLng(), current.getLatLngs()[0]);
      if (insidepoint) prev.insideWorkingArea = [...prev.insideWorkingArea || [], { id: current.options.id, name: current.options.name }];
      if (outsidepoint) prev.outsideWorkingArea = [...prev.outsideWorkingArea || [], { id: current.options.id, name: current.options.name }];
      return prev;
    }, { insideWorkingArea: null, outsideWorkingArea: null });

    const data = Object.entries(includesMapArea).reduce((prev, [key, value]) => {
      if (value != null && value.length === 1) prev[key] = value[0].id;
      if (value != null && value.length > 1) store.dispatch(addNotifications({ message: 'EDIT_MAP_MAPAREA_IOTGATE', type: 'error', data: { name: x.name } }));
      return prev;
    }, { insideWorkingArea: null, outsideWorkingArea: null });
    if (data.insideWorkingArea !== x.insideWorkingArea || data.outsideWorkingArea !== x.outsideWorkingArea) {
      store.dispatch(updateGroup({ id: x.id, type: 'iterateNestedObjects', ...data }));
    }
  });
};
// 建立編輯 polygon 的 node point
export const createEditPolygonNode = (latlngs, setIsStepEdit, setEditLayerData) => {
  const nodeArr = [];
  latlngs[0].forEach((latlng) => {
    const marker = L.marker(latlng, {
      icon: IconStyle.NodeIcon,
      zIndexOffset: 1000, // 將 z-index 設高於底圖 marker
      draggable: true, // 是否能拖拉
      index: MapStatus.nodeEditArr.length,
    }).addTo(MapStatus.map);

    nodeMarkerDrag(marker, 'edit', setIsStepEdit, setEditLayerData);
    MapStatus.nodeEditArr.push(marker);
    nodeArr.push(marker._leaflet_id);

    if (MapStatus.editType === 'rectangle') MapStatus.originRectangleNode.push({ ...latlng });
  });

  return nodeArr;
};

// 編輯 polygon 的名稱
export const editLayerName = (value, id, setIsStepEdit) => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.id === id) {
      // 建立紀錄步驟
      MapStatus.editLayer.options.isEditToolTip = true;
      MapStatus.editLayer.options.isEditType = false;
      MapStatus.editLayer.options.undoToolTip = layer.options.toolTip;
      MapStatus.editLayer.options.redoToolTip = value;
      setIsStepEdit(true);

      // 變更 tool tip
      layer.options.toolTip = value;
      layer.unbindTooltip();
      layer.bindTooltip(value, createToolTipOption());

      if (!MapStatus.toolTip) layer.closeTooltip();
    }
  });
};

// 編輯 polygon 的 style
export const editPolygonStyle = (value, id) => {
  const { _id } = store.getState().mapSlice.polygons.find((polygon) => polygon.id === id) || {};
  const layer = MapStatus.map._layers[_id];
  if (layer) {
    MapStatus.editLayer.options.isEditType = true;
    MapStatus.editLayer.options.isEditToolTip = false;
    // setIsStepEdit(true);
    // 變更 style
    const style = {
      color: leafletMapColor[value],
      fillColor: leafletMapColor[value],
    };
    layer.setStyle(style);
    layer.options.type = value;
  }
};
/* ------------------------------------------------------------------- */

/* --------------------------- other --------------------------- */

// 旋轉 2D Point
const rotatePoint2D = (x, y, angle) => {
  const radians = (Math.PI / 180) * angle; // 將角度轉換為弧度
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const newX = x * cos - y * sin;
  const newY = x * sin + y * cos;
  return { x: newX, y: newY };
};

// 讀取圖片並調整大小
export const loadImageAndResize = (name, width, height, scale, callback) => {
  const img = new Image();
  const image = cursorIcon[name];
  if (image) {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const _width = width * scale;
      const _height = height * scale;

      canvas.width = _width;
      canvas.height = _height;
      ctx.drawImage(img, 0, 0, _width, _height);

      const data = canvas.toDataURL('image/png');
      callback(data, _width, _height);
    };
    img.src = image;
  } else {
    document.getElementById('map').style.cursor = null;
  }
};
// 顯示/隱藏 layer
export const toggleLayers = (Arr, type = 'none') => {
  Arr.forEach((leafletID) => {
    if (MapStatus.map._layers[leafletID] != null) {
      MapStatus.map._layers[leafletID]._icon.style.display = type;
    }
  });
};

// path 名稱排序 data
export const sortPath = (a, b) => {
  const reText = /[^a-zA-Z]/g;
  const reNumber = /[^0-9]/g;

  // 將字母的部分提取出來
  const aName = a.replace(reText, '');
  const bName = b.replace(reText, '');

  // 若字母相同比較數字
  if (aName === bName) {
    const aNumber = parseInt(a.replace(reNumber, ''), 10);
    const bNumber = parseInt(b.replace(reNumber, ''), 10);

    return (aNumber - bNumber);
  }

  // 字母不同則比較字母順序性
  return aName.localeCompare(bName);
};

// 判斷 input 改變長度相對應點位
export const modifyRectangleLength = (latLng, value) => {
  switch (true) {
    // 左上拉至右下矩形
    case latLng[0].lng < latLng[2].lng && latLng[0].lat > latLng[2].lat:
      latLng[1].lng = latLng[0].lng + value;
      latLng[2].lng = latLng[0].lng + value;
      break;
    // 右下拉至左上矩形
    case latLng[0].lng > latLng[2].lng && latLng[0].lat < latLng[2].lat:
      latLng[2].lng = latLng[0].lng - value;
      latLng[1].lng = latLng[0].lng - value;
      break;
    // 左下拉至右上矩形
    case latLng[0].lng < latLng[2].lng && latLng[0].lat < latLng[2].lat:
      latLng[2].lng = latLng[0].lng + value;
      latLng[1].lng = latLng[0].lng + value;
      break;
    // 右上拉至左下矩形
    case latLng[0].lng > latLng[2].lng && latLng[0].lat > latLng[2].lat:
      latLng[2].lng = latLng[0].lng - value;
      latLng[1].lng = latLng[0].lng - value;
      break;
    default:
      break;
  }
};

// 判斷 input 改變寬度相對應點位
export const modifyRectangleWidth = (latLng, value) => {
  switch (true) {
    // 左上拉至右下矩形
    case latLng[0].lng < latLng[2].lng && latLng[0].lat > latLng[2].lat:
      latLng[3].lat = latLng[0].lat - value;
      latLng[2].lat = latLng[0].lat - value;
      break;
    // 右下拉至左上矩形
    case latLng[0].lng > latLng[2].lng && latLng[0].lat < latLng[2].lat:
      latLng[3].lat = latLng[0].lat + value;
      latLng[2].lat = latLng[0].lat + value;
      break;
    // 左下拉至右上矩形
    case latLng[0].lng < latLng[2].lng && latLng[0].lat < latLng[2].lat:
      latLng[3].lat = latLng[0].lat + value;
      latLng[2].lat = latLng[0].lat + value;
      break;
    // 右上拉至左下矩形
    case latLng[0].lng > latLng[2].lng && latLng[0].lat > latLng[2].lat:
      latLng[3].lat = latLng[0].lat - value;
      latLng[2].lat = latLng[0].lat - value;
      break;
    default:
      break;
  }
};

// 計算矩形調整縮放點位
export const modifyRectangleSize = (index, getLatLng, layer) => {
  const mapAngle = store.getState().mapSlice.mapRotateAngle;
  // 取得矩形旋轉的角度
  const { rosAngle } = layer.options;

  // 設定點位的 index
  const pointIndex = {
    diagonal: 0, // 對角點
    near_1: 0, // 鄰近點 1
    near_2: 0, // 鄰近點 2
  };

  // 根據拖移點的 index 設定其他相關點位
  switch (index) {
    case 0:
      pointIndex.diagonal = 2;
      pointIndex.near_1 = 1;
      pointIndex.near_2 = 3;
      break;
    case 1:
      pointIndex.diagonal = 3;
      pointIndex.near_1 = 2;
      pointIndex.near_2 = 0;
      break;
    case 2:
      pointIndex.diagonal = 0;
      pointIndex.near_1 = 1;
      pointIndex.near_2 = 3;
      break;
    case 3:
      pointIndex.diagonal = 1;
      pointIndex.near_1 = 0;
      pointIndex.near_2 = 2;
      break;
    default:
      break;
  }

  // 計算拖移之後的新中心點座標
  const newCenter = {
    x: (getLatLng[pointIndex.diagonal].lat + getLatLng[pointIndex.diagonal].lat) / 2,
    y: (getLatLng[index].lng + getLatLng[index].lng) / 2,
  };

  // 計算對角的兩個點位轉回 0 度時之座標
  const originNode_1 = getRotateLeafletPosition(getLatLng[pointIndex.diagonal], newCenter, 360 - (rosAngle + mapAngle));
  const originNode_2 = getRotateLeafletPosition(getLatLng[index], newCenter, 360 - (rosAngle + mapAngle));

  // 計算矩形拖移完後的長、寬
  const length = originNode_2.lng - originNode_1.lng;
  const width = originNode_2.lat - originNode_1.lat;

  // 利用長、寬來計算鄰近兩點在未選轉時的座標
  const node_1 = {
    lat: originNode_1.lat,
    lng: (originNode_1.lng + length).toFixed(2),
  };
  const node_2 = {
    lat: (originNode_1.lat + width).toFixed(2),
    lng: originNode_1.lng,
  };

  // 將鄰近兩點的座標依矩形要旋轉的角度做換算
  const rotateNode_1 = getRotateLeafletPosition(node_1, newCenter, (rosAngle + mapAngle));
  const rotateNode_2 = getRotateLeafletPosition(node_2, newCenter, (rosAngle + mapAngle));

  // 將鄰近兩點的旋轉後的座標設定進 polygon
  getLatLng[pointIndex.near_1] = rotateNode_1;
  getLatLng[pointIndex.near_2] = rotateNode_2;
  layer.setLatLngs(getLatLng);
  calculateRectangleCenter(layer);

  // 更新 node point icon 的座標
  MapStatus.nodeEditArr.forEach((item, nodeIndex) => {
    if (index !== nodeIndex) item.setLatLng(getLatLng[nodeIndex]);
  });
};

// 計算矩形的長寬
export const calculateRectangleSpec = (latLng) => {
  if (latLng.length < 4) return { length: '0.0', width: '0.0' };

  let length = Math.abs(latLng[0].lng - latLng[1].lng).toFixed(1);
  let width = Math.abs(latLng[0].lat - latLng[3].lat).toFixed(1);

  if (length === undefined) length = 0;
  if (width === undefined) width = 0;

  if (length === '0.0' && width === '0.0') {
    length = Math.abs(latLng[0].lat - latLng[1].lat).toFixed(1);
    width = Math.abs(latLng[0].lng - latLng[3].lng).toFixed(1);
  }

  const { center } = MapStatus.editLayer.options;
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.id === MapStatus.editLayer.options.id && layer.options.type === 'OneWayArrow') {
      layer.setLatLng({ lat: center.y, lng: center.x });
      const originalLatLng = getOriginLeafletPosition(layer.getLatLng(), MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      layer.options.originalLatLng = originalLatLng;
      const scale = (MapStatus.map.getZoom() + 100);
      let size = (scale / 100) * IconStyle.baseSize.oneWayArrow;

      if (width < 0.7 || length < 1) {
        size = 10;
      } else if ((width < 1.3 && width >= 0.7) || (length < 2 && length >= 1)) {
        size = 20;
      }

      const centerSize = (scale / 100) * size;
      layer.setIcon(IconStyle.OneWayArrowIcon(scale, { long: length, width }));
      layer.setRotationOrigin(`${centerSize / 2}px ${centerSize / 2}px`);
      layer._icon.style.transformOrigin = '';
    }
  });

  return { length, width };
};

/**
 * 計算矩形的中心位置
 * @param {Object} LeafletLayer
 * @returns {Object}
 */
export const calculateRectangleCenter = (layer) => {
  if (layer.getLatLngs()[0].length < 4) return;

  const rectangleCenter = {
    x: (layer.getLatLngs()[0][1].lng - layer.getLatLngs()[0][3].lng) / 2 + layer.getLatLngs()[0][3].lng,
    y: (layer.getLatLngs()[0][1].lat - layer.getLatLngs()[0][3].lat) / 2 + layer.getLatLngs()[0][3].lat,
  };
  layer.options.center = rectangleCenter;
  return rectangleCenter;
};

// 刪除圖層物件
export const deleteLayerObj = (obj, setUVCZoneData) => {
  switch (true) {
    case obj.flagType === 'polygon' || obj.flagType === 'rectangle':
      MapStatus.layerArr.polygon.forEach((polygon, index) => {
        if (polygon.options.id === obj.data.id) {
          polygon.remove();
          MapStatus.layerArr.polygon.splice(index, 1);

          // 如果移除的是正在編輯的 polygon 則需將該 polygon 的 node 清除
          if (MapStatus.editLayer !== null && polygon.options.id === MapStatus.editLayer.options.id) clearEditNodePoint();

          // 如果移除的是 Disinfection，則需將對應的 UVC Zone 從地圖上移除
          if (polygon.options.type === 'Disinfection') {
            const UVCZone = MapStatus.map._layers[polygon.options.UVCZoneId];
            UVCZone.remove();
            polygon.options.isDelete = true;

            // 檢查該 Disinfection 是否有加入至 UVC Zone 排序 list
            MapStatus.UVCZoneListData.forEach((item, UVCIndex) => {
              if (item.options.id === polygon.options.id) MapStatus.UVCZoneListData.splice(UVCIndex, 1);
            });
            const copyUVCListData = [...MapStatus.UVCZoneListData];
            setUVCZoneData(copyUVCListData);
          }
        }
      });
      break;
    case obj.flagType === 'line':
      MapStatus.layerArr.polyline.forEach((polyline, index) => {
        if (polyline.options.id === obj.data.id) {
          MapStatus.layerArr.polyline.splice(index, 1);
          polyline.remove();
        }

        // 如果移除的是正在編輯的 polyline 則需將該 polyline 的 node 清除
        if (MapStatus.editLayer !== null && polyline.options.id === MapStatus.editLayer.options.id) clearEditNodePoint();

        // 如果是編輯中的 Gate 則需移除
        if (MapStatus.currentGate !== null && polyline.options.id === MapStatus.editLayer.options.id) MapStatus.currentGate.remove();
        // 如果是 Gate 需將 node point 一起移除
        if (polyline.options.type === 'NarrowGate' || polyline.options.type === 'IotGate') {
          polyline.options.node[0].remove();
          polyline.options.node[1].remove();
        }
      });
      break;
    case obj.flagType === 'point':
      MapStatus.layerArr.point.forEach((point, index) => {
        if (point.options.id === obj.data.id) {
          point.remove();
          MapStatus.layerArr.point.splice(index, 1);
        }
      });
      break;
    case obj.flagType === 'site':
      if (obj.layerType === 'Charger') {
        MapStatus.layerArr.chargingAreaList.forEach((point, index) => {
          if (point.options.id === obj.data.id) {
            point.remove();
            MapStatus.layerArr.chargingAreaList.splice(index, 1);
          }
        });
      } else {
        console.log('尚未實作');
      }
      break;
    default:
      break;
  }
};

export const deleteLayerByRedux = ({ saveSteps = true, setUVCZoneData = () => { } }) => {
  const { currentItem, currentGroupItem } = store.getState().mapSlice;
  const layer = MapStatus.map._layers[currentItem._id];
  const ployline = store.getState().mapSlice.polylines.find((p) => p._id === currentItem._id);
  const point = store.getState().mapSlice.points.find((p) => p._id === currentItem._id);
  const rectangle = store.getState().mapSlice.rectangles.find((p) => p._id === currentItem._id);
  const polygon = store.getState().mapSlice.polygons.find((p) => p._id === currentItem._id);
  const group = store.getState().mapSlice.groups.find((item) => item.id === currentGroupItem?.id && item.apiType !== 'delete');
  const type = currentItem.type || currentGroupItem.type;
  switch (true) {
    case [...markerTypes, 'Elevator'].includes(type):
      if (saveSteps) {
        hiddenAllConnectMap();
        updateStepsInRedux('delete', { ...point }, { ...point });
      }
      break;
    case siteTypes.includes(type):
      const _points = store.getState().mapSlice.points.filter((item) => item.groupID === currentGroupItem.id && item.type === currentItem.type);
      const payload = { ...group, points: _points };
      if (saveSteps) updateStepsInRedux('delete', { ...payload }, { ...payload });
      deleteGroupLayerByRedux();
      break;
    case type === 'IotGate':
      const { id, insidePointID, outsidePointID, insideWorkingArea, outsideWorkingArea } = group;
      if (saveSteps) {
        const initLatLng = getGroupLayersLatLng(id, 'IotGate');
        updateStepsInRedux(
          'delete',
          { ...group, ...initLatLng, id, insidePointID, outsidePointID, insideWorkingArea, outsideWorkingArea },
          { ...group, ...initLatLng, id, insidePointID, outsidePointID, insideWorkingArea, outsideWorkingArea },
        );
      }
      deleteGroupLayerByRedux();
      store.dispatch(toggleShowMapArea(false));
      store.dispatch(deletePointByGroupId({ id }));
      store.dispatch(deletePolylineByGroupId({ id }));
      store.dispatch(deletePolygonByGroupId({ id }));
      store.dispatch(setCurrentItem({}));

      MapStatus.mode = null;
      break;
    case polylineTypes.includes(type):
      if (saveSteps) updateStepsInRedux('delete', { ...ployline, ...layer.options }, { ...ployline, ...layer.options });
      if (ployline) {
        const { gate } = getGroupLayers(ployline.id, 'NarrowGate');
        ployline.nodeArr?.forEach((leafletID) => {
          const _layer = MapStatus.map._layers[leafletID];
          store.dispatch(deletePoint({ _id: leafletID }));
          _layer?.remove();
        });
        gate?.remove();
        store.dispatch(deletePolyline({ _id: ployline._id }));
        store.dispatch(deletePointByGroupId({ id: ployline.id }));
      }
      break;
    case polygonTypes.includes(type):
      if (type === 'Disinfection') {
        // 檢查該 Disinfection 是否有加入至 UVC Zone 排序 list
        setUVCZoneData((prev) => prev.filter((item) => item.options.id !== polygon.id));
        // 移除UVC框線
        MapStatus.map._layers[polygon.uvcLayerId]?.remove();
      }
      if (type === 'MapArea') {
        if (layer) {
          clearEditNodePoint();
          layer.remove();
        }
        rebindIotGateMapArea();
      }
      if (saveSteps) updateStepsInRedux('delete', { ...polygon, ...layer.options }, { ...polygon, ...layer.options });
      store.dispatch(deletePolygon(currentItem));
      break;
    case rectangleTypes.includes(type):
      if (saveSteps) updateStepsInRedux('delete', { ...rectangle, ...layer.options, angle: rectangle.rosAngle }, { ...rectangle, ...layer.options, angle: rectangle.rosAngle });
      if (type === 'OneWay') deleteLayerById(layer.options.id, 'OneWayArrow');
      // 從 redux 中移除
      store.dispatch(deleteRectangle(currentItem));
      break;
    default:
      break;
  }
  // 若 currentItem nodeArr 非空值 node 清除
  currentItem.nodeArr?.forEach((node) => {
    const nodeLayer = MapStatus.map._layers[node];
    if (nodeLayer != null) {
      nodeLayer.off('dragstart');
      nodeLayer.off('drag');
      nodeLayer.off('dragend');
      nodeLayer.remove();
    }
  });
  if (layer) {
    layer.remove();
    store.dispatch(deletePoint(currentItem));
  }
};
export const deleteGroupLayerByRedux = () => {
  const { currentGroupItem } = store.getState().mapSlice;
  if (!currentGroupItem || !currentGroupItem.id) {
    return;
  }

  if (currentGroupItem.type === 'Charger') {
    store.dispatch(deleteParkingBindingTarget({
      targetId: currentGroupItem.id,
      targetType: 'targetCharging',
    }));
  } else if (currentGroupItem.type === 'IotGate') {
    store.dispatch(deleteParkingBindingTarget({
      targetId: currentGroupItem.id,
      targetType: `target${currentGroupItem.type}`,
    }));
  }

  MapStatus.map.eachLayer((layer) => {
    if (layer.options.groupID === currentGroupItem.id) {
      layer.remove();
      store.dispatch(deletePoint({ _id: layer._leaflet_id }));
    }
  });

  store.dispatch(setCurrentGroupItem({}));
  store.dispatch(deleteGroup({ id: currentGroupItem.id }));
};

export const updateMapPoint = (point) => {
  MapStatus.map.eachLayer((layer) => {
    if (layer._leaflet_id === point._id) {
      layer.setLatLng(new L.LatLng(point.lat, point.lng));
      rotatePoint(layer, point.rosAngle);
    }
  });
};

export const getElevatorInsideEnter = (newPoint) => {
  // 電梯內的點
  const insideLatLng = calculateCoord(newPoint.insideCm * 0.01,
    newPoint.rosAngle - 180,
    { x: newPoint.lng, y: newPoint.lat });
  // 電梯外的點
  const _enterCm = (newPoint.enterCm + newPoint.doorCm);
  const enterCenterLatLng = calculateCoord(_enterCm * 0.01,
    newPoint.rosAngle,
    { x: newPoint.lng, y: newPoint.lat });

  let pointOffset = newPoint.offset;
  if (newPoint.align === 'right') {
    pointOffset = -newPoint.offset;
  }
  const enterOffsetLatLng = calculateCoord(pointOffset * 0.01,
    newPoint.rosAngle - 90,
    { x: enterCenterLatLng.x, y: enterCenterLatLng.y });

  return {
    center_x: newPoint.lng,
    center_y: newPoint.lat,
    inside_x: insideLatLng.x,
    inside_y: insideLatLng.y,
    enter_x: enterOffsetLatLng.x,
    enter_y: enterOffsetLatLng.y,
    exit_x: enterOffsetLatLng.x,
    exit_y: enterOffsetLatLng.y,
  };
};

// 更新 IotGate 區域
export const updateMapIotGate = (groupId, options = {}) => {
  const group = store.getState().mapSlice.groups.find((item) => item.id === groupId && item.type === 'IotGate' && item.apiType !== 'delete');
  const points = store.getState().mapSlice.points.filter((item) => item.groupID === groupId).reduce((prev, current) => {
    prev[current.name] = { lat: current.lat, lng: current.lng };
    return prev;
  }, {});
  const { passageLength, robotWaitingDistance, point1, point2, name } = { ...group, ...points, ...options };
  const robotWaitAreaLength = 0.6; // default 0.6 機器人等候區距離
  const layers = getGroupLayers(groupId, 'IotGate');

  updateAddPoint(layers.point1, options.point1 || point1);
  updateAddPoint(layers.point2, options.point2 || point2);
  updatePolylineWithLatLng(layers.polyline, layers.point1.getLatLng(), layers.point2.getLatLng());
  updateAddGateIotGate(layers.door, layers.point1.getLatLng(), layers.point2.getLatLng(), passageLength);
  updateWorkInsideLengthGate(
    layers.insidePassageLength,
    [
      layers.door._latlngs[0][1],
      layers.door._latlngs[0][2],
    ], passageLength + robotWaitingDistance,
  );
  updateWorkInsideLengthGate(
    layers.insideWorkingArea,
    [
      layers.insidePassageLength._latlngs[0][1],
      layers.insidePassageLength._latlngs[0][2],
    ], robotWaitAreaLength,
  );
  updateWorkOutsideLengthGate(
    layers.outsidePassageLength,
    [
      layers.door._latlngs[0][0],
      layers.door._latlngs[0][3],
    ], passageLength + robotWaitingDistance,
  );
  updateWorkOutsideLengthGate(
    layers.outsideWorkingArea,
    [
      layers.outsidePassageLength._latlngs[0][0],
      layers.outsidePassageLength._latlngs[0][3],
    ], robotWaitAreaLength,
  );
  updateAddPoint(layers.insidePoint, layers.insideWorkingArea.getBounds().getCenter());
  updateAddPoint(layers.outsidePoint, layers.outsideWorkingArea.getBounds().getCenter());

  const insideWorkingAngle = getRotatedAngle([layers.insidePoint.getLatLng(), layers.polyline.getCenter()]);
  const outsideWorkingAngle = getRotatedAngle([layers.outsidePoint.getLatLng(), layers.polyline.getCenter()]);

  layers.insidePoint.options.rotationAngle = insideWorkingAngle;
  layers.outsidePoint.options.rotationAngle = outsideWorkingAngle;

  layers.insidePoint.setRotationAngle(insideWorkingAngle);
  layers.outsidePoint.setRotationAngle(outsideWorkingAngle);

  if (name) {
    layers.door.unbindTooltip();
    layers.door.bindTooltip(name, createToolTipOption('IotGate'));
    if (!MapStatus.toolTip) layers.door.closeTooltip();
  }
};

// 更新 NarrowGate 區域
export const updateMapNarrowGate = (groupId, options = {}) => {
  const polyline = store.getState().mapSlice.polylines.find((item) => item.id === groupId);
  const { point1, point2, name } = { ...polyline, ...options };
  const layers = getGroupLayers(groupId, 'NarrowGate');
  if (name) {
    layers.polyline.unbindTooltip();
    layers.polyline.bindTooltip(`${name}`, createToolTipOption('NarrowGate'));
    if (!MapStatus.toolTip) layers.polyline.closeTooltip();
  }
  updateAddPoint(layers.point1, options.point1 || point1);
  updateAddPoint(layers.point2, options.point2 || point2);
  updatePolylineWithLatLng(layers.polyline, layers.point1.getLatLng(), layers.point2.getLatLng());
  updateAddGate(layers.gate, [layers.point1.getLatLng(), layers.point2.getLatLng()]);
};
// 切換至別的 tool 時，清除 node point 和 addLayer/editLayer 以及關閉前一個 map click 事件
export const clearCurrentAddType = (setLayerToolMode, setIsStepEdit, setEditLayerData, setUVCZoneData) => {
  // clear node
  if (MapStatus.nodeArr.length !== 0) {
    MapStatus.nodeArr.forEach((node) => {
      node.off('dragstart');
      node.off('dragend');
      node.off('drag');
      node.remove();
    });
    MapStatus.nodeArr = [];
  }

  // 清除和移除 MapStatus addLayer value
  if (MapStatus.addLayer !== null) {
    if (MapStatus.layerType !== 'ParkingArea') {
      MapStatus.addLayer.remove();
    } else {
      deleteGroupLayerByRedux();
      MapStatus.addLayer = null;
    }
  }

  // 清除 MapStatus editLayer value
  if (MapStatus.editLayer !== null) {
    const { currentItem } = store.getState().mapSlice;
    if (currentItem.type === 'Disinfection') {
      const disinfection = store.getState().mapSlice.polygons.find((p) => p._id === currentItem._id);
      if (disinfection.uvcLayerId == null) {
        const uvcZoneLayer = createUVCZonePolygon(setLayerToolMode, setIsStepEdit, setEditLayerData, 'edit', '', setUVCZoneData);
        store.dispatch(updatePolygon({ _id: disinfection._id, uvcLayerId: uvcZoneLayer._leaflet_id }));
      } else {
        const UVCZoneLatLng = setUVCZonePoint(MapStatus.editLayer.getLatLngs()[0]);
        const UVCZone = MapStatus.map._layers[MapStatus.editLayer.options.UVCZoneId];
        UVCZone.setLatLngs(UVCZoneLatLng);
        UVCZone.addTo(MapStatus.map);
      }
    }

    if (MapStatus.currentGate !== null) {
      MapStatus.currentGate.remove();
      MapStatus.currentGate = null;
    }

    // 將畫面上的 gate node point 補回 map 上
    MapStatus.layerArr.polyline.forEach((polyline) => {
      if (polyline.options.type === 'NarrowGate' || polyline.options.type === 'IotGate') {
        polyline.options.node[0].addTo(MapStatus.map);
        polyline.options.node[1].addTo(MapStatus.map);
      }
    });

    MapStatus.editLayer = null;
  }

  // 關閉 map 新增 click、mousemove、mousedown、mouseup 事件
  MapStatus.map.off('click');
  MapStatus.map.off('mousemove');
  MapStatus.map.off('mousedown');
  MapStatus.map.off('mouseup');
};

// line mode 下切換至別的 type，清除目前的 node 以及 mousemove 監聽
export const clearLineEvent = () => {
  // clear node
  if (MapStatus.nodeArr.length !== 0) {
    MapStatus.nodeArr.forEach((node) => {
      node.off('dragstart');
      node.off('dragend');
      node.off('drag');
      node.remove();
    });
    MapStatus.nodeArr = [];
  }

  if (MapStatus.addLayer !== null) {
    MapStatus.addLayer.remove();
    MapStatus.addLayer = null;
    MapStatus.currentGate?.remove();
    MapStatus.currentGate = null;
  }

  MapStatus.map.off('mousemove');
};

// 移除 node point 相關的監聽事件，並結束新增 gate 事件
export const doneCreateGate = (setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose) => {
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;

  // 將目前的 node point 放進 gate 物件中並綁上 gate id
  MapStatus.addLayer.options.node = MapStatus.nodeArr;
  MapStatus.addLayer.options.node.forEach((item) => {
    item.options.gate_id = MapStatus.addLayer.options.id;
    item.options.originalLatLng = getOriginLeafletPosition(item.getLatLng(), centerPosition, rotationAngle);
  });

  // clear MapStatus.nodeArr & MapStatus.currentGate
  MapStatus.nodeArr = [];
  MapStatus.currentGate.remove();
  MapStatus.currentGate = null;

  // 將創建好的 gate 放進 MapStatus.layerArr
  MapStatus.layerArr.polyline.push(MapStatus.addLayer);
  MapStatus.map.off('mousemove');

  // 設定 tool tip 給完成的 layer
  MapStatus.addLayer.bindTooltip(MapStatus.addLayer.options.toolTip, createToolTipOption());

  // 如果目前標記名稱開關是關閉的話則先將這個 layer 的 tool tip 關閉
  if (!MapStatus.toolTip) MapStatus.addLayer.closeTooltip();

  // 完成後建立編輯的監聽事件
  layerEditEvent(MapStatus.addLayer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  // 清除 MapStatus addLayer value
  MapStatus.addLayer = null;
};

// 清除 node point 和移除相關的監聽事件，並結束新增 layer 事件
export const doneCreateLayer = (type, layerName, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData) => {
  const { currentItem } = store.getState().mapSlice;

  // clear node
  MapStatus.nodeArr.forEach((node) => {
    node.off('dragstart');
    node.off('dragend');
    node.off('drag');
    node.remove();
  });
  MapStatus.nodeArr = [];

  if (MapStatus.originRectangleNode != null) MapStatus.originRectangleNode = [];

  // 將 input 裡的 name 寫入 MapStatus.addLayer
  MapStatus.addLayer.options.toolTip = layerName;

  // 設定是否編輯過的 value
  MapStatus.addLayer.options.isEdit = false;

  // 將創建好的 layer 放進 MapStatus.layerArr
  switch (type) {
    case 'polygon':
      let uvcZoneLayer = null;
      if (currentItem.type === 'Disinfection') {
        uvcZoneLayer = createUVCZonePolygon(setLayerToolMode, setIsStepEdit, setEditLayerData, setUVCZoneData, 'add', setUVCZoneData);
      }
      store.dispatch(updatePolygon({
        _id: currentItem._id,
        name: layerName,
        uvcLayerId: uvcZoneLayer?._leaflet_id,
      }));
      break;
    case 'rectangle':
      const rectangle = MapStatus.map._layers[currentItem._id];
      const { options } = rectangle;
      const { originalLatLng } = options;
      const length = Number(Math.abs(originalLatLng[0].lng - originalLatLng[2].lng).toFixed(1));
      const width = Number(Math.abs(originalLatLng[0].lat - originalLatLng[2].lat).toFixed(1));
      const nodes = MapStatus.map._layers[currentItem._id].getLatLngs()[0].map((node) => ({
        lat: Number(node.lat.toFixed(2)),
        lng: Number(node.lng.toFixed(2)),
      }));
      store.dispatch(updateRectangle({ _id: currentItem._id, nodes, length, width }));
      if (options.type === 'OneWay') {
        addPointTypeToLayer({
          layer: rectangle,
          pointType: 'OneWayArrow',
          setLayerToolMode,
          setIsStepEdit,
          setEditLayerData,
          setSelectItemClose,
        });
      }
      break;
    case 'line':
      store.dispatch(updatePolyline({ _id: currentItem._id, name: layerName }));
      MapStatus.map.off('mousemove');
      break;
    case 'point':
      MapStatus.addLayer.dragging.enable();
      MapStatus.layerArr.point.push(MapStatus.addLayer);
      break;
    case 'site':
      const { currentGroupItem } = store.getState().mapSlice;
      switch (MapStatus.layerType) {
        case 'Charger':
          MapStatus.map.eachLayer((layer) => {
            if (layer.options.groupID === currentGroupItem.id) {
              layer.dragging.enable();
              layerEditEvent(layer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
              pointEditEventByRedux(layer, setLayerToolMode);
            }
          });
          break;
        case 'ParkingArea':
          MapStatus.map.eachLayer((layer) => {
            if (layer.options.groupID === currentGroupItem.id) {
              layer.dragging.enable();
              layerEditEvent(layer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
              pointEditEventByRedux(layer, setLayerToolMode);
            }
          });
          break;
        default: // for Dock and Home
          MapStatus.addLayer.dragging.enable();
          MapStatus.layerArr.point.push(MapStatus.addLayer);
          break;
      }
      break;
    default:
      break;
  }

  if (MapStatus.layerType === 'ParkingArea' || MapStatus.layerType === 'Charger') {
    // 清除 MapStatus addLayer value
    MapStatus.addLayer = null;
    return;
  }

  // 設定 tool tip 給完成的 layer
  MapStatus.addLayer.bindTooltip(MapStatus.addLayer.options.toolTip, createToolTipOption(MapStatus.layerType));

  // 如果目前標記名稱開關是關閉的話則先將這個 layer 的 tool tip 關閉
  if (!MapStatus.toolTip) MapStatus.addLayer.closeTooltip();

  // 完成後建立編輯的監聽事件
  if (type === 'point') {
    pointEditEvent(MapStatus.addLayer, setIsStepEdit, setEditLayerData);
  } else if (type === 'site') {
    siteEditEvent(MapStatus.addLayer, setIsStepEdit, setEditLayerData);
  }

  layerEditEvent(MapStatus.addLayer, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);

  // 清除 MapStatus addLayer value
  MapStatus.addLayer = null;
  store.dispatch(setCurrentItem({}));
};

// 清除編輯 layer 的 node point & 監聽事件
export const clearEditNodePoint = () => {
  const { nodeArr } = store.getState().mapSlice.currentItem;
  MapStatus.nodeEditArr.forEach((node) => {
    node.off('dragstart');
    node.off('drag');
    node.off('dragend');
    node.remove();
  });
  toggleLayers(nodeArr || []); // FIXME: 刪除多邊形的 nodes 時做這件事不合理
  MapStatus.nodeEditArr = [];
  MapStatus.originRectangleNode = [];
};
// 產生 Layer Focus 輔助線
export const drawLayerLine = (layer) => {
  const { type } = layer.options;
  switch (type) {
    case 'Elevator':
      // 顯示電梯輔助線
      const elements = layer._icon.firstChild.querySelectorAll('.horizontal, .vertical');
      elements.forEach((element) => { element.style.display = 'block'; });
      break;
    case 'ParkingArea':
    case 'Charger':
      const pointClass = layer._icon.className;
      layer._icon.className = `${pointClass} iconSelect`;
      break;
    default:
      break;
  }
};
// 清除 Layer Focus 輔助線
export const clearLayerLine = () => {
  const { type, _id } = store.getState().mapSlice.currentItem;
  const layer = MapStatus.map._layers[_id];
  if (layer === null) return;
  switch (type) {
    case 'Elevator':
      const elements = layer._icon.firstChild.querySelectorAll('.horizontal, .vertical');
      elements.forEach((element) => { element.style.display = 'none'; });
      break;
    case 'ParkingArea':
    case 'Charger':
      const pointClass = layer._icon.className.split('iconSelect')[0];
      layer._icon.className = `${pointClass}`;
      break;
    default:
      break;
  }
};
export const clearUVCZoneList = (onOff) => {
  store.dispatch(setUVCZoneListState(onOff));
};
export const closeCrrentItem = () => {
  clearLayerLine();
  store.dispatch(setCurrentItem({}));
};
// 建立所選擇 layer 的 node point
export const setEditNodePoint = (layer, setIsStepEdit, setEditLayerData) => {
  const { type } = layer.options;
  const latlng = layer.getLatLngs();
  let nodeArr = [];

  switch (true) {
    case type === 'VirtualWall':
      nodeArr = createEditPolylineNode(latlng, setIsStepEdit);
      return nodeArr;
    case type === 'Passage' || type === 'NarrowLane':
      calculatePassageNode(layer);
      const passageNode = layer.options.node;
      nodeArr = createEditPolylineNode(passageNode, setIsStepEdit);
      return nodeArr;
    default:
      nodeArr = createEditPolygonNode(latlng, setIsStepEdit, setEditLayerData);
      return nodeArr;
  }
};

// 格線圖層顯示
export const displayGrid = (isOpen) => {
  if (!isOpen) {
    gridLayer.addTo(MapStatus.map);
    largeGridLayer.addTo(MapStatus.map);
    MapStatus.grid = [gridLayer, largeGridLayer];
  } else {
    gridLayer.remove();
    largeGridLayer.remove();
    MapStatus.grid = null;
  }
};

// 計算以各兩點座標為中心點算出向左右各自延伸距離的座標，預設為0.25 m
export const calculateExtendPoint = (endPoints, width = 0.25) => {
  if (endPoints.length !== 2) return endPoints;

  let pointWidth = width;

  if (MapStatus.layerType === 'NarrowLane') pointWidth = 0.5;

  const theta = getAngleOfTwoPoints(endPoints);
  const rightAngle0 = theta - (Math.PI / 2);
  const leftAngle0 = theta + (Math.PI / 2);
  const rightAngle1 = theta + (Math.PI / 2);
  const leftAngle1 = theta + ((3 * Math.PI) / 2);
  return [
    getForwardRotatePosition(endPoints[0], pointWidth, rightAngle0),
    getForwardRotatePosition(endPoints[0], pointWidth, leftAngle0),
    getForwardRotatePosition(endPoints[1], pointWidth, rightAngle1),
    getForwardRotatePosition(endPoints[1], pointWidth, leftAngle1),
  ];
};

const getDelta = (v0, v1) => {
  const delta = v1 - v0;
  return delta;
};

const getVector = (v0, v1) => {
  const delta = getDelta(v0, v1);
  if (delta === 0) return 0;
  const vector = delta / Math.abs(delta);
  return vector;
};

const getAnalysisData = (latLngs) => {
  if (latLngs.length !== 2) return null;
  const y0 = latLngs[0].lat;
  const x0 = latLngs[0].lng;
  const y1 = latLngs[1].lat;
  const x1 = latLngs[1].lng;
  const deltaX = getDelta(x0, x1);
  const deltaY = getDelta(y0, y1);
  const vectorX = getVector(x0, x1);
  const vectorY = getVector(y0, y1);
  return {
    deltaX,
    deltaY,
    vectorX,
    vectorY,
  };
};

const getAngleOfTwoPoints = (latLngs) => {
  if (latLngs.length !== 2) return null;
  const { deltaX, deltaY } = getAnalysisData(latLngs);
  const theta = Math.atan2(deltaY, deltaX);
  return theta;
};

const getRotatedAngle = (latLngs) => {
  const countAngle = -(getAngleOfTwoPoints(latLngs) * 180) / Math.PI;
  return Math.round(countAngle) + 90;
};

const getForwardRotatePosition = (point, distance, angle) => {
  const x1 = point.lng;
  const y1 = point.lat;
  const x2 = x1 + (distance * Math.cos(angle));
  const y2 = y1 + (distance * Math.sin(angle));
  return {
    lat: y2,
    lng: x2,
  };
};

// 計算 layer 移動的距離
export const getLatLngDistance = (p1, p2) => {
  const lat_minus = p2.lat - p1.lat;
  const lng_minus = p2.lng - p1.lng;
  return { lat: lat_minus, lng: lng_minus };
};

export const setLatLngWithDistance = (p1, distance) => {
  const latlng = [p1.lat + distance.lat, p1.lng + distance.lng];
  return latlng;
};

// ros 角度換算成 leaflet 角度 (slider 與 input 用)
export const angleRosToLeaflet = (rosAngle) => {
  const result = -(rosAngle - 90);
  return result;
};

// 計算存入 DB 的 ros 角度
export const calculateRosAngle = (currentRosAngle, mapAngle, type = '+') => {
  if (type === '-') return currentRosAngle - mapAngle;
  return currentRosAngle + mapAngle;
};

// 旋轉 point
export const rotatePoint = (point, value) => {
  point.setRotationAngle(angleRosToLeaflet(value));
};

// 取得旋轉前的 leaflet 座標
export const getOriginLeafletPosition = (latlng, centerPosition, rotateAngle) => {
  const x = latlng.lng - centerPosition.x;
  const y = latlng.lat - centerPosition.y;
  const radians = (-rotateAngle / 180) * Math.PI;
  const newX = (Math.cos(radians) * x) + (Math.sin(radians) * y) + centerPosition.x;
  const newY = (Math.cos(radians) * y) - (Math.sin(radians) * x) + centerPosition.y;

  return { lat: Number(newY), lng: Number(newX) };
};

// 取得旋轉後的 leaflet 座標
export const getRotateLeafletPosition = (latlng, centerPosition, rotateAngle) => {
  const x = latlng.lng - centerPosition.x;
  const y = latlng.lat - centerPosition.y;
  const radians = (-rotateAngle / 180) * Math.PI;
  const newX = (Math.cos(radians) * x) - (Math.sin(radians) * y) + centerPosition.x;
  const newY = (Math.cos(radians) * y) + (Math.sin(radians) * x) + centerPosition.y;

  return { lat: Number(newY), lng: Number(newX) };
};

export const getRotateLeafletPositionNew = (latlng, centerPosition, rotateAngle) => {
  const x = latlng.lng - centerPosition.x;
  const y = latlng.lat - centerPosition.y;
  const radians = (rotateAngle / 180) * Math.PI;
  const newX = (Math.cos(radians) * x) - (Math.sin(radians) * y) + centerPosition.x;
  const newY = (Math.cos(radians) * y) + (Math.sin(radians) * x) + centerPosition.y;

  return { lat: Number(newY), lng: Number(newX) };
};

// 取得 api 所需的 geo json body
export const transMapToPic = (geojson, height, resolution, angle = 0, layerType) => {
  const { type, coordinates } = geojson;
  const transCoords = type === 'Polygon' ? coordinates[0] : coordinates;
  const transType = type === 'Polygon' ? 'MultiPolygon' : 'MultiLineString';
  const transArr = [];
  transCoords.forEach((coord) => {
    const x = Math.round(coord[0] / resolution);
    const y = Math.round(height - (coord[1] / resolution));
    transArr.push([x, y]);
  });
  const resultArr = type === 'Polygon' ? [[transArr]] : [transArr];
  return {
    type: transType,
    coordinates: resultArr,
    angle,
    heading: (layerType === 'OneWay' && angle !== 0) ? 360 - angle : 0,
    isPassage: layerType === 'Passage',
  };
};

// geo 轉回 map 格式
const transPicToMap = (coords, height, resolution) => {
  const transArr = [];
  coords.forEach((coord) => {
    transArr.push([coord[0] * resolution, (height - coord[1]) * resolution]);
  });
  return transArr;
};

// 取得 api 的 working area/point 的 type
export const setAreaType = (typeName) => {
  const type = {
    Prioritization: 'preferred_zone',
    Forbidden: 'Forbidden_zone',
    Passage: 'preferred_zone',
    Disinfection: 'Disinfection',
    VirtualWall: 'virtual_wall',
    Remark: 'remark',
    NarrowGate: 'Narrow_gate',
    IotGate: 'Iot_gate',
    Charger: 'dock',
    ChargerPrepare: 'dock_prepare',
    ManualCharger: 'manual_dock',
    ManualChargerPrepare: 'manual_dock_prepare',
    Home: 'home',
    Elevator: 'elevator',
    Store: 'store',
    Landmark: 'landmark',
    Halfway: 'halfway',
    Delivery: 'delivery',
    NarrowLane: 'Narrow_lane',
    ParkingArea: 'parking',
    LowSpeed: 'Slow',
    Slope: 'Slope',
    NonAvoidance: 'Non_avoidance',
    OneWay: 'one_way',
    ConnectionPoint: 'connection',
    Abandon: 'abandon',
  };

  if (type[typeName] === undefined) return typeName;
  return type[typeName];
};

// 取得 leaflet 的 working area/point 的 type
const getAreaType = (typeName) => {
  const type = {
    preferred_zone: 'Prioritization',
    Forbidden_zone: 'Forbidden',
    Passage: 'Passage',
    Disinfection: 'Disinfection',
    virtual_wall: 'VirtualWall',
    remark: 'Remark',
    Narrow_gate: 'NarrowGate',
    Iot_gate: 'IotGate',
    dock: 'Charger',
    dock_prepare: 'ChargerPrepare',
    manual_dock: 'ManualCharger',
    manual_dock_prepare: 'ChargerPrepare',
    home: 'Home',
    elevator: 'Elevator',
    store: 'Store',
    landmark: 'Landmark',
    halfway: 'Halfway',
    delivery: 'Delivery',
    Narrow_lane: 'NarrowLane',
    parking: 'ParkingArea',
    Slow: 'LowSpeed',
    Slope: 'Slope',
    Non_avoidance: 'NonAvoidance',
    one_way: 'OneWay',
    MapArea: 'MapArea',
    ConnectMap: 'ConnectMap',
    connection: 'ConnectionPoint',
    abandon: 'Abandon',
  };

  if (type[typeName] === undefined) return typeName;
  return type[typeName];
};

// 初始化 api 取得的 working area
export const initWorkingArea = (data, height, resolution, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData, setSelectLayerType) => {
  data.filter((item) => item.area_type !== 'Iot_gate').forEach((layer) => {
    const { id, area_type, working_area_name } = layer;
    const { type, coordinates, angle, groupID, isPassage } = layer.geo_json;
    const { centerPosition } = MapStatus;
    const areaType = getAreaType(area_type);
    let transCoords;
    let latLngs;
    let originalLatLng;
    let rotateLatLng;
    let options;
    switch (type) {
      case 'MultiPolygon':
        // 將 geojson 轉回 leaflet 座標
        transCoords = transPicToMap(coordinates[0][0], height, resolution);
        latLngs = L.GeoJSON.coordsToLatLngs(transCoords);
        latLngs.pop();
        originalLatLng = latLngs;
        // 取得旋轉後的座標
        rotateLatLng = latLngs.map((item) => getRotateLeafletPosition(item, centerPosition, mapAngle));

        // 生成 polygon
        options = {
          id,
          toolTip: working_area_name,
          name: working_area_name,
          originalLatLng: latLngs,
          angle,
          groupID,
        };
        // 檢查是否為 Narrow_lane
        const isNarrowLane = area_type.includes('Narrow_lane');
        if (isPassage !== true && !isNarrowLane) {
          createInitPolygon(areaType, options, rotateLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData);
        } else {
          createInitPassage(options, rotateLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, areaType);
        }
        break;
      case 'MultiLineString':
        transCoords = transPicToMap(coordinates[0], height, resolution);
        latLngs = L.GeoJSON.coordsToLatLngs(transCoords);
        originalLatLng = latLngs;
        // 取得旋轉後的座標
        rotateLatLng = latLngs.map((item) => getRotateLeafletPosition(item, centerPosition, mapAngle));

        // 生成 polyline
        options = {
          id,
          toolTip: working_area_name,
          originalLatLng,
        };
        // TODO: 未來全部整合至Redux後 則可移除判斷
        // TODO: NarrowGate 的 init function 與其他不同，暫時還不能移除判斷
        if (areaType === 'NarrowGate') {
          store.dispatch(deletePointsByType({ areaType }));
          createLineByRedux(rotateLatLng[0], 'NarrowGate', { id }, {
            name: working_area_name,
            toolTip: working_area_name,
            point1LatLng: rotateLatLng[0],
            point2LatLng: rotateLatLng[1],
            init: true,
            setIsStepEdit,
            setLayerToolMode,
            setEditLayerData,
            setSelectLayerType,
          });
        } else {
          createInitPolyline(areaType, options, rotateLatLng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
        }
        break;
      default:
        break;
    }
  });
};

// 初始化 api 取得的 point
export const initPoint = ({ data, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose }) => {
  const validPointTypes = ['Dock', 'Elevator'];
  console.log('data : ', data);
  data
    .filter((point) => validPointTypes.includes(getAreaType(point.point_type)))
    .forEach((point) => {
      const {
        id,
        heading,
        point_type,
        point_name,
        x,
        y,
      } = point;
      const { phone, room_code, service, store_number, working_group, layer_list, abandon_days, abandon_password } = point.info;
      const { centerPosition } = MapStatus;
      const type = getAreaType(point_type);
      const latlng = { lat: y, lng: x };

      // 取得旋轉後的座標
      const rotateLatLng = getRotateLeafletPosition(latlng, centerPosition, mapAngle);

      // 生成 point
      const options = {
        id,
        rosAngle: heading - mapAngle,
        type,
        toolTip: point_name,
        originalLatLng: latlng,
        phone,
        room_code,
        service,
        store_number,
        working_group,
        layer_list,
        abandon_days,
        abandon_password,
      };

      createInitPoint({ latlng: rotateLatLng, data: options, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose });
    });
};

/** 渲染從 API 取得的 points 並且儲存到 redux。 */
export const initPointsByRedux = ({ points, mapAngle, setLayerToolMode }) => {
  points.forEach((point) => {
    const extraData = {
      name: point.point_name,
      isRotate: true,
      storeNumber: point.info.store_number ?? '',
      services: point.info.service ?? '',
      phone: point.info.phone ?? '',
      roomCode: point.info.room_code ?? '',
      isFood: !!point.info.working_group, // TODO: 等 spec 出來才能確定如何接 API
      layer: point.info?.layer_list ?? point.info.layer_list ?? [],
      connectMapData: point.connectMapData ?? {},
      _idConnectMap: point._idConnectMap ?? null,
      connectionChannelId: point.connectionChannelId ?? null,
      abandonPassword: point.info.abandon_password ?? '',
      abandonDays: point.info.abandon_days ?? '',
    };
    switch (point.point_type) {
      case 'landmark':
      case 'home':
      case 'halfway':
      case 'remark':
      case 'store':
      case 'delivery':
      case 'connection':
      case 'abandon':
        createInitPointByRedux({ point: { ...point, heading: calculateRosAngle(point.heading, mapAngle, '-') }, extraData, setLayerToolMode, mapAngle });
        break;
      default:
        console.log(`point_type ${point.point_type} is not handled`);
        break;
    }
  });
};

export const initChargingAreaList = ({ groupData, pointData, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose }) => {
  const chargingPoints = [];
  groupData.forEach((group) => {
    group.point_list.forEach((point) => {
      chargingPoints.push({
        id: point.point_id,
        groupID: group.id,
        order: point.order,
        tag: point.tag,
      });
      chargingPoints.push({
        id: point.prepare_id,
        groupID: group.id,
        order: point.order,
        tag: point.tag,
      });
    });
    store.dispatch(addGroup({ id: group.id, name: group.name, type: 'Charger', apiType: 'init', autoCharging: group.auto_charging }));
  });

  // render charging points
  chargingPoints.forEach((cp) => {
    const point = pointData.find((p) => p.id === cp.id);
    point.heading = calculateRosAngle(point.heading, mapAngle, '-');
    createInitChargingPoint({
      point: { ...point, ...cp },
      mapAngle,
      setLayerToolMode,
      setIsStepEdit,
      setEditLayerData,
      setSelectItemClose,
    });
  });
};

// 重新渲染充電站
export const resetChargingGroups = ({ mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose }) => {
  const groups = store.getState().mapSlice.groups.filter((g) => g.type === 'Charger' && g.apiType !== 'delete');
  const points = store.getState().mapSlice.points.filter((p) => p.type === 'Charger');
  store.dispatch(deleteGroupsByType({ type: 'Charger' }));
  store.dispatch(deletePointsByType({ type: 'Charger' }));
  groups.forEach((group) => {
    store.dispatch(addGroup({ id: group.id, name: group.name, type: 'Charger', apiType: 'init' }));
  });
  points.forEach((point) => {
    createInitChargingPoint({
      point: {
        id: point.id,
        point_type: setAreaType(point.chargingMode),
        x: point.lng,
        y: point.lat,
        heading: calculateRosAngle(point.rosAngle, mapAngle, '-'),
        groupID: point.groupID,
        order: point.order,
        tag: point.tag,
      },
      rotateValue: point.rotateValue,
      mapAngle,
      setLayerToolMode,
      setIsStepEdit,
      setEditLayerData,
      setSelectItemClose,
    });
  });
};

/** 重新渲染 redux 中的 points */
export const resetPoints = ({ rotateAngle, setLayerToolMode }) => {
  const points = store.getState().mapSlice.points.filter((p) => markerTypes.includes(p.type));
  markerTypes.forEach((type) => store.dispatch(deletePointsByType({ type })));

  points.forEach((point) => {
    // 取得旋轉後的座標
    const extraData = {
      name: point.name,
      isRotate: point.isRotate,
      storeNumber: point.storeNumber,
      services: point.services,
      phone: point.phone,
      roomCode: point.roomCode,
      isFood: point.isFood, // TODO: 等 spec 出來才能確定如何接 API
      layer: point.layer,
      _idConnectMap: point._idConnectMap,
      connectMapData: point.connectMapData,
      connectionChannelId: point.connectionChannelId,
      abandonPassword: point.abandonPassword,
      abandonDays: point.abandonDays,
    };
    createInitPointByRedux({
      point: {
        id: point.id,
        point_type: setAreaType(point.type),
        x: point.lng,
        y: point.lat,
        heading: calculateRosAngle(point.rosAngle, rotateAngle, '-'),
      },
      setLayerToolMode,
      extraData,
      mapAngle: rotateAngle,
    });
  });
};

// 計算 dock prepare 座標
export const getDockPrepareLatLng = (lat, lng, theta, distance = 1.15) => {
  // 預設為前方 1.15m
  const radians = theta * (Math.PI / 180);
  const y = Number(Number(lat + (distance * Math.sin(radians))).toFixed(2));
  const x = Number(Number(lng + (distance * Math.cos(radians))).toFixed(2));
  return { x, y };
};

// 清除 MapStatus layerArr
export const clearLayerArr = (baseIsEdit = false) => {
  const polygons = store.getState().mapSlice.polygons.filter((polygon) => polygon.type !== 'IotGate');
  const polylines = store.getState().mapSlice.polylines.filter((polyline) => polyline.type !== 'IotGate');
  const rectangles = [...store.getState().mapSlice.rectangles];
  polylines.forEach((polyline) => {
    polyline.nodeArr?.forEach((pointId) => MapStatus.map._layers[pointId]?.remove());
    MapStatus.map._layers[polyline._id]?.remove();
  });

  rectangles.forEach((rectangle) => {
    const layer = MapStatus.map._layers[rectangle._id];
    if (layer?.options.type === 'OneWay') deleteLayerById(layer.options.id, 'OneWayArrow');
    layer?.remove();
  });
  polygons.forEach((polygon) => {
    const { type, uvcLayerId } = polygon;
    const layer = MapStatus.map._layers[polygon._id];
    if (layer) {
      if (type === 'Disinfection') MapStatus.map._layers[uvcLayerId]?.remove();
      layer.remove();
    }
  });
  MapStatus.layerArr.polygon.forEach((polygon) => {
    polygon.remove();
  });

  MapStatus.layerArr.polyline.forEach((polyline) => {
    polyline.remove();
  });

  MapStatus.layerArr.point.forEach((point) => {
    point.remove();
  });

  MapStatus.layerArr.UVCZone.forEach((UVCZone) => {
    UVCZone.remove();
  });

  MapStatus.layerArr.taskPoint.forEach((taskPoint) => {
    taskPoint.remove();
  });

  MapStatus.layerArr.connectMap.forEach((connectMap) => {
    if (baseIsEdit) {
      connectMap.addTo(MapStatus.map);
    } else {
      connectMap.remove();
    }
  });

  MapStatus.layerArr.polygon = [];
  MapStatus.layerArr.polyline = [];
  MapStatus.layerArr.point = [];
  MapStatus.layerArr.UVCZone = [];
  MapStatus.layerArr.taskPoint = [];
  MapStatus.layerArr.chargingAreaList = [];
  if (!baseIsEdit) {
    MapStatus.layerArr.connectMap = [];
  }
  hiddenAllConnectMap();
};

// 依據 resolution 計算設置電梯 icon 的長寬與中心點
export const calculateElevatorCursorSize = (resolution) => {
  const scale = resolution * 100;
  const width = Number((110 / scale).toFixed(2));
  const long = Number((175 / scale).toFixed(2));
  const center_x = Number((width / 2).toFixed(2));
  const center_y = Number((long / 5).toFixed(2));

  return { width, long, center_x, center_y };
};

// 根據地圖 zoom in/out 的比例來改變 Elevator Icon 長寬與中心點
export const changeElevatorIconSize = () => {
  const elevatorPoints = store.getState().mapSlice.points.filter((x) => x.type === 'Elevator');
  MapStatus.map.eachLayer((layer) => {
    const _point = elevatorPoints.find((x) => x._id === layer._leaflet_id);
    if (_point) {
      const newElevatorIcon = IconStyle.ElevatorIcon(_point.id);
      layer.setIcon(newElevatorIcon);
    }
  });
};

export const changeElevatorIconSizeById = (id) => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.id === id) {
      const newElevatorIcon = IconStyle.ElevatorIcon(id);
      layer.setIcon(newElevatorIcon);
    }
  });
};

export const changeIotGateIconSize = (zoom) => {
  const scale = zoom / 100;
  const groups = store.getState().mapSlice.groups.filter((item) => item.type === 'IotGate').map((item) => item.id);
  MapStatus.map.eachLayer((layer) => {
    if (groups.includes(layer.options?.groupID) && layer.options?.type === 'IotGate' && layer.options?.name.includes('workingArea')) {
      const iconSize = [...layer.options.initIconSize];
      const icon = new L.Icon({
        iconUrl: WorkingAreaPoint,
        iconRetinaUrl: WorkingAreaPoint,
        iconSize: [20, 20],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
      });
      icon.options.iconSize = [iconSize[0] * scale, iconSize[1] * scale];
      layer.setIcon(icon);
    }
  });
};

// 根據地圖 zoom in/out 的比例來改變 Parking area point 的長寬與
export const changeParkingPointSize = (zoom) => {
  const scale = zoom / 100;
  const newIcon = IconStyle.createParkingAreaPointIcon(scale);
  const { iconAnchor } = newIcon.options;

  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'ParkingArea') {
      layer.setIcon(newIcon);
      layer.setRotationOrigin(`${iconAnchor[0]}px ${iconAnchor[1]}px`);
    }
  });
};

export const changeConnectMapPointSize = (zoom) => {
  const scale = zoom / 100;
  MapStatus.map.eachLayer((layer) => {
    try {
      if (layer.options.type === 'ConnectMap') {
        const { mapBlobUrl, height, width } = layer.options;
        const newIcon = IconStyle.MapIcon(scale, mapBlobUrl, { height, width });
        const { iconAnchor } = newIcon.options;
        layer.setIcon(newIcon);
        layer.setRotationOrigin(`${iconAnchor[0]}px ${iconAnchor[1]}px`);
      }
      if (layer.options.type === 'ConnectionPoint') {
        const newIconPoint = IconStyle.ConnectionPointIcon(scale * 100);
        layer.setIcon(newIconPoint);
        layer.setRotationOrigin(`${newIconPoint.options.iconAnchor[0]}px ${newIconPoint.options.iconAnchor[1]}px`);
      }
    } catch (ex) {
      console.log(ex);
    }
  });
};

// 根據地圖 zoom in/out 的比例來改變 Charging group Icon 長寬與中心點
export const changeChargingGroupSize = (zoom) => {
  const scale = zoom / 100;
  const stationIcon = IconStyle.createChargerStationPointIcon(scale);
  const { iconAnchor: stationIconAnchor } = stationIcon.options;
  const manualIcon = IconStyle.createManualChargerPointIcon(scale);
  const { iconAnchor: manualIconAnchor } = manualIcon.options;

  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'Charger') {
      const newToolTip = layer.getTooltip();
      if (layer.options.chargingMode === 'ManualCharger') {
        const options = rotatePoint2D(0, -10 * scale, layer.options.rotationAngle);
        newToolTip.options.offset = L.point(options.x, options.y);
        layer.unbindTooltip();
        layer.bindTooltip(newToolTip);
        layer.setIcon(manualIcon);
        layer.setRotationOrigin(`${manualIconAnchor[0]}px ${manualIconAnchor[1]}px`);
      } else {
        const options = rotatePoint2D(0, -15 * scale, layer.options.rotationAngle);
        newToolTip.options.offset = L.point(options.x, options.y);
        layer.unbindTooltip();
        layer.bindTooltip(newToolTip);
        layer.setIcon(stationIcon);
        layer.setRotationOrigin(`${stationIconAnchor[0]}px ${stationIconAnchor[1]}px`);
      }
    }
  });
};

// 四捨五入 number 到小數點後第 n 為，n >= 0
const roundNumByDigit = (number, n = 2) => {
  if (typeof n !== 'number') throw new Error('Cannot round a number due to invalid param: \'n\'');
  const digit = (n >= 0) ? n : 0;
  return Math.round(number * 10 ** digit) / 10 ** digit;
};

// 計算 point A 在 angle 方向、距離為 r 的 point A' 之座標
export const calculateCoord = (r, angle, center = { x: 0, y: 0 }) => {
  const radians = Math.PI * (angle / 180);
  const x = center.x + r * Math.cos(radians);
  const y = center.y + r * Math.sin(radians);
  return {
    x: roundNumByDigit(x),
    y: roundNumByDigit(y),
  };
};

export const checkMarkerGroupInMapArea = ({ groupID, types }) => {
  let markerArr = [];
  let mapAreaID = '';
  let prevGroupID = null;
  let isInMapArea = false;
  const mapAreas = Object.values(MapStatus.map._layers).filter((layer) => layer.options.name?.includes('MapArea'));
  if (groupID) markerArr = store.getState().mapSlice.points.filter((item) => item.groupID === groupID && types.includes(item.type));
  else markerArr = store.getState().mapSlice.points.filter((item) => types.includes(item.type));
  markerArr.sort((a, b) => a.groupID - b.groupID).forEach((x) => {
    if (x.groupID !== prevGroupID) {
      prevGroupID = x.groupID;
      mapAreaID = '';
    }
    const marker = MapStatus.map._layers[x._id];
    if (!marker) return;
    mapAreas.forEach((mapArea) => {
      const pointInPolygon = isPointInPolygon(marker.getLatLng(), mapArea.getLatLngs()[0]);
      if (pointInPolygon) {
        if (mapAreaID !== '' && mapAreaID !== mapArea.options.id) {
          store.dispatch(updateDialog({ open: true, message: 'MODAL_TARGET_MAPBAREA', type: 'error', title: types.join('/') }));
          isInMapArea = true;
        }
        mapAreaID = mapArea.options.id;
      }
    });
  });
  return { isInMapArea, mapAreaID };
};

// 變換所有 icon size
export const changeIconSize = (scale) => {
  let size;
  MapStatus.map.eachLayer((item) => {
    if (item.options.hasOwnProperty('type')) {
      switch (item.options.type) {
        case 'Home':
        case 'Remark':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.RemarkIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'Halfway':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.HalfwayIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'Landmark':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.LandmarkIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'Store':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.StoreIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'Abandon':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.AbandonIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'Delivery':
          size = (scale / 100) * IconStyle.baseSize.remark;
          item.setIcon(IconStyle.DeliveryIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          break;
        case 'OneWayArrow':
          size = (scale / 100) * IconStyle.baseSize.oneWayArrow;
          let oneWay;
          MapStatus.map.eachLayer((layer) => {
            if (layer.options.id === item.options.id && layer.options.type === 'OneWay') {
              oneWay = layer;
            }
          });
          const long = Math.abs(oneWay.options.originalLatLng[0].lng - oneWay.options.originalLatLng[2].lng);
          const width = Math.abs(oneWay.options.originalLatLng[0].lat - oneWay.options.originalLatLng[2].lat);
          if (width < 0.7 || long < 1) {
            size = 10;
          } else if ((width < 1.3 && width >= 0.7) || (long < 2 && long >= 1)) {
            size = 20;
          }
          item.setIcon(IconStyle.OneWayArrowIcon(scale, { long, width }));
          item.setRotationOrigin(`${size / 2}px ${size / 2}px`);
          item._icon.style.transformOrigin = '';
          break;
        case 'Dock':
          if (item.options.icon.options.isSelect) {
            size = (scale / 100) * IconStyle.baseSize.dockSelect;
            item.setIcon(IconStyle.DockSelectIcon(scale));
          } else {
            size = (scale / 100) * IconStyle.baseSize.dock;
            item.setIcon(IconStyle.DockIcon(scale));
          }
          item.setRotationOrigin(`${size / 2}px ${size * 0.59}px`);
          break;
        case 'TaskPoint':
          if (item.options.icon.options.isSelect) {
            size = (scale / 100) * IconStyle.baseSize.taskSelect;
            item.setIcon(IconStyle.TaskSelectIcon(scale));
          } else {
            size = (scale / 100) * IconStyle.baseSize.task;
            item.setIcon(IconStyle.TaskIcon(scale));
          }
          item.setRotationOrigin(`${size / 2}px ${size * 0.59}px`);
          break;
        case 'robot':
          size = (scale / 100) * IconStyle.baseSize.robot;
          item.setIcon(IconStyle.robotIcon(scale));
          item.setRotationOrigin(`${size / 2}px ${(size * 3) / 4}px`);
          break;
        case 'IotGate':
          if (item.options.hasOwnProperty('icon') && !['point1', 'point2'].includes(item.options.name)) {
            size = (scale / 100) * IconStyle.baseSize.iotGate;
            item.setIcon(IconStyle.IotGateWorkingAreaIcon(scale));
            item.setRotationOrigin(`${size / 2}px ${size / 4}px`);
          }
          break;
        default:
          break;
      }
    }
  });
};
/* ------------------------------------------------------------------- */

/* --------------------------- Map Marker --------------------------- */
// 改變底圖 Marker 的 size (zoom in/out)
export const changeMapSize = (canvas) => {
  const mapUrl = canvas.toDataURL('image/png', 1.0);
  const iconWidth = canvas.width;
  const iconHeight = canvas.height;

  const icon = new L.Icon({
    iconUrl: mapUrl,
    iconSize: [iconWidth, iconHeight],
    iconAnchor: [iconWidth / 2, iconHeight / 2],
    popupAnchor: [0, 0],
  });

  MapStatus.mapMarker.setIcon(icon);
  // 變換底圖 Marker 大小後，旋轉中心點需重新設定，差點被雷！
  MapStatus.mapMarker.setRotationOrigin(`${iconWidth / 2}, ${iconHeight / 2}`);
};

// 創建底圖 Marker
export const createMap = (MapObj, draggable = false) => {
  const { mapUrl, index, type, id, width, height, latLng } = MapObj;

  const icon = new L.Icon({
    iconUrl: mapUrl,
    iconSize: [width, height],
    iconAnchor: [width / 2, height / 2],
    popupAnchor: [0, 0],
  });

  const marker = L.marker(latLng, {
    icon,
    draggable,
    index,
    type,
    id,
    zIndexOffset: -5000,
  }).addTo(MapStatus.map);

  return marker;
};

// 旋轉底圖 Marker
export const rotateMap = (angle) => {
  const iotGateLatLng = {};
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  const rotateValue = angle - rotationAngle;
  const scale = (MapStatus.map.getZoom() + 100) / 100;
  MapStatus.mapMarker.setRotationAngle(angle);
  if (MapStatus.toolTip) displayLayerName(false);
  // 旋轉底圖時，畫面上的 chargingAreaList 本身角度也跟著先旋轉，並更新座標位置
  MapStatus.layerArr.chargingAreaList.forEach((chargingArea) => {
    const { rosAngle, originalLatLng } = chargingArea.options;

    rotatePoint(chargingArea, rosAngle - rotateValue);
    chargingArea.options.rosAngle -= rotateValue;

    const rotateLatLng = getRotateLeafletPosition(originalLatLng, centerPosition, angle);
    chargingArea.setLatLng(rotateLatLng);
  });

  // 旋轉底圖時，畫面上的 point 本身角度也跟著先旋轉，並更新座標位置
  MapStatus.layerArr.point.forEach((point) => {
    const { rosAngle, originalLatLng, type } = point.options;

    // 如果不是 Remark 就跟著地圖旋轉
    if (type !== 'Remark') {
      rotatePoint(point, rosAngle - rotateValue);
      point.options.rosAngle -= rotateValue;
    }
    const rotateLatLng = getRotateLeafletPosition(originalLatLng, centerPosition, angle);
    point.setLatLng(rotateLatLng);
  });
  // redux 裡的point、polygon跟著旋轉
  const reduxPoints = store.getState().mapSlice.points;
  const reduxPolygons = store.getState().mapSlice.polygons;
  const reduxRectangles = store.getState().mapSlice.rectangles;
  const reduxPolylines = store.getState().mapSlice.polylines;

  reduxPolylines.forEach((ployline) => {
    const { _id, id, type } = ployline;
    const layer = MapStatus.map._layers[_id];
    if (layer) {
      const latLng = (type === 'VirtualWall' || type === 'NarrowGate') ? layer.getLatLngs() : layer.getLatLngs()[0];
      const originLatLng = latLng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
      const rotateLatLng = originLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));

      switch (type) {
        case 'NarrowGate':
          store.dispatch(updatePolyline({ _id, point1: rotateLatLng[0], point2: rotateLatLng[1] }));
          updateMapNarrowGate(id);
          break;
        case 'VirtualWall':
        case 'Passage':
        case 'NarrowLane':
          store.dispatch(updatePolyline({ _id, nodes: rotateLatLng }));
          break;
        default:
          break;
      }
      layer.setLatLngs(rotateLatLng);
    }
  });
  reduxRectangles.forEach((rectangle) => {
    const layer = MapStatus.map._layers[rectangle._id];
    if (layer == null) return;
    const { originalLatLng, type } = layer.options;
    const rotateLatLng = originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
    layer.setLatLngs(rotateLatLng);

    const serializedLatLng = rotateLatLng.map((latLng) => ({
      lat: Number(latLng.lat.toFixed(2)),
      lng: Number(latLng.lng.toFixed(2)),
    }));
    if (type === 'OneWay') {
      const { x, y } = calculateRectangleCenter(layer);
      const arrow = Object.values(MapStatus.map._layers).find((item) => item.options.type === 'OneWayArrow' && item.options.id === layer.options.id);
      arrow?.setLatLng({ lat: y, lng: x });
      rotatePoint(arrow, 90 - angle - rectangle.rosAngle);
      arrow._icon.style.transformOrigin = '';
    }
    store.dispatch(updateRectangle({ _id: rectangle._id, nodes: serializedLatLng }));
  });
  reduxPolygons.forEach((polygon) => {
    const { type, uvcLayerId } = polygon;
    const layer = MapStatus.map._layers[polygon._id];
    if (layer) {
      const { originalLatLng } = layer.options;
      const rotateLatLng = originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
      layer.setLatLngs(rotateLatLng);

      if (type === 'Disinfection') {
        const uvcLayer = MapStatus.map._layers[uvcLayerId];
        const rotateUVCZoneLatLng = uvcLayer?.options.originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
        uvcLayer?.setLatLngs(rotateUVCZoneLatLng);
      }
    }
  });
  reduxPoints.forEach((point) => {
    const { rosAngle, lat, lng, _id, name, groupID, type, chargingMode } = point;
    const layer = MapStatus.map._layers[_id];
    if (layer) {
      const newRosAngle = rosAngle - rotateValue;
      rotatePoint(layer, newRosAngle);
      const originalLatLng = getOriginLeafletPosition({ lat, lng }, centerPosition, rotationAngle);
      const rotateLatLng = getRotateLeafletPosition(originalLatLng, centerPosition, angle);
      switch (type) {
        case 'IotGate':
          store.dispatch(updatePoint({ _id, rosAngle: newRosAngle, lat: rotateLatLng.lat, lng: rotateLatLng.lng }));
          layer.setLatLng(rotateLatLng);
          iotGateLatLng[groupID] = iotGateLatLng[groupID] || {};
          iotGateLatLng[groupID][name] = rotateLatLng;
          break;
        case 'Charger':
          layer.unbindTooltip();
          layer.bindTooltip(`${point.order}`, createToolTipOption(chargingMode || type, scale, newRosAngle));
          store.dispatch(updatePoint({ _id, rosAngle: newRosAngle, lat: rotateLatLng.lat, lng: rotateLatLng.lng }));
          layer.setLatLng(rotateLatLng);
          break;
        default:
          store.dispatch(updatePoint({ _id, rosAngle: newRosAngle, lat: rotateLatLng.lat, lng: rotateLatLng.lng }));
          layer.setLatLng(rotateLatLng);
          break;
      }
    }
  });
  // IotGate point1, point2 旋轉後再更新區域
  if (Object.values(iotGateLatLng).length !== 0) {
    Object.entries(iotGateLatLng).forEach(([groupID, object]) => updateMapIotGate(Number(groupID) || groupID, object));
  }
  // 旋轉時更新 polygon 座標
  MapStatus.layerArr.polygon.forEach((polygon) => {
    const { originalLatLng, type } = polygon.options;

    const rotateLatLng = originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
    polygon.setLatLngs(rotateLatLng);

    if (type === 'Disinfection') {
      const UVCZone = MapStatus.map._layers[polygon.options.UVCZoneId];

      const rotateUVCZoneLatLng = UVCZone.options.originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
      UVCZone.setLatLngs(rotateUVCZoneLatLng);
    }
  });

  // 旋轉時更新 polyline 座標
  MapStatus.layerArr.polyline.forEach((polyline) => {
    const { originalLatLng } = polyline.options;

    const rotateLatLng = originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
    polyline.setLatLngs(rotateLatLng);
    if (polyline.options.type === 'VirtualWall') {
      polyline.options.node.forEach((node) => {
        const nodeRotateLatLng = getRotateLeafletPosition(node.getLatLng(), centerPosition, angle);
        node.setLatLng(nodeRotateLatLng);
      });
    } else {
      calculatePassageNode(polyline);
    }
  });

  // 旋轉時更新 UVC Zone arrow line 座標
  MapStatus.UVCZoneArrowLines.forEach((line) => {
    const { originalLatLng } = line.options;
    const rotateLatLng = originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, angle));
    line.setLatLngs(rotateLatLng);
  });
  if (MapStatus.toolTip) displayLayerName(true);
};
/* ------------------------------------------------------------------- */

/* --------------------------- Step --------------------------- */
// 回復步驟中的 UVC Zone
export const resetStepUVCZone = (polygon) => {
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  const UVCZone = MapStatus.map._layers[polygon.options.UVCZoneId];
  const UVCZoneLatLng = setUVCZonePoint(polygon.getLatLngs()[0]);
  UVCZone.setLatLngs(UVCZoneLatLng);
  UVCZone.options.originalLatLng = UVCZoneLatLng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
};

// 加回新增步驟中的 UVC Zone
export const addStepUVCZone = (polygon) => {
  const UVCZone = MapStatus.map._layers[polygon.options.UVCZoneId];
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  const UVCZoneRotateLatLng = UVCZone.options.originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
  UVCZone.setLatLngs(UVCZoneRotateLatLng);
  if (MapStatus.mode === 'edit') {
    if (MapStatus.editLayer === null) {
      UVCZone.addTo(MapStatus.map);
    } else if (MapStatus.editLayer.options.id !== polygon.options.id) {
      UVCZone.addTo(MapStatus.map);
    }
  } else {
    UVCZone.addTo(MapStatus.map);
  }
  polygon.options.isDelete = false;
};

// 回復步驟中的 polygon
export const resetStepPolygon = (polygon, id, isEditType, isEditToolTip, stepToolTip, stepType, stepLatLng, stepRosAngle, setEditLayerData) => {
  if (polygon.options.id === id) {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;

    // 判斷編輯動作
    switch (true) {
      case isEditToolTip:
        polygon.options.toolTip = stepToolTip;
        polygon.unbindTooltip();
        polygon.bindTooltip(stepToolTip, createToolTipOption());
        break;
      case isEditType:
        const style = {
          color: leafletMapColor[stepType],
          fillColor: leafletMapColor[stepType],
        };
        polygon.setStyle(style);
        polygon.options.type = stepType;
        break;
      default:
        const latlng = stepLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
        polygon.setLatLngs(latlng);
        polygon.options.originalLatLng = latlng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
        polygon.options.rosAngle = stepRosAngle;
        setEditLayerData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            latlng: polygon.getLatLngs(),
          },
        }));

        // 如果回覆的是矩形，則需重新計算中心點座標＆未自身選轉前座標
        const { type } = polygon.options;
        if (rectangleTypes.includes(type)) {
          calculateRectangleCenter(polygon);
          const { center, rosAngle } = polygon.options;
          const notRotateLatLng = polygon.getLatLngs()[0].map((item) => getRotateLeafletPosition(item, center, 360 - rosAngle));
          polygon.options.notRotateLatLng = notRotateLatLng;
        }
        break;
    }

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) polygon.openTooltip(); else polygon.closeTooltip();
  }
};

// 回復步驟中的 polyline
export const resetStepPolyline = (polyline, id, isEditType, isEditToolTip, stepToolTip, stepType, stepLatLng) => {
  if (polyline.options.id === id) {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;

    // 判斷編輯動作
    if (isEditToolTip) {
      polyline.options.toolTip = stepToolTip;
      polyline.unbindTooltip();
      polyline.bindTooltip(stepToolTip, createToolTipOption());
    } else if (isEditType) {
      const style = {
        color: leafletMapColor[stepType],
        fillColor: leafletMapColor[stepType],
      };
      polyline.setStyle(style);
      polyline.options.type = stepType;
    } else {
      const latlng = stepLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
      polyline.setLatLngs(latlng);
      polyline.options.originalLatLng = latlng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));

      // 設定 gate node point 位置
      if (polyline.options.type === 'NarrowGate' || polyline.options.type === 'IotGate') {
        polyline.options.node.forEach((node, index) => {
          node.setLatLng(getRotateLeafletPosition(stepLatLng[index], centerPosition, rotationAngle));
          node.options.originalLatLng = getOriginLeafletPosition(node.getLatLng(), centerPosition, rotationAngle);
        });
      }

      // 設定 gate 位置
      if (MapStatus.currentGate !== null && MapStatus.currentGate.options.gate_id === polyline.options.id) {
        // 取得 NarrowGate 的四個點座標
        const narrowGateLatLng = calculateExtendPoint(stepLatLng, 0.8);
        const narrowGateRotateLatLng = narrowGateLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
        MapStatus.currentGate.setLatLngs(narrowGateRotateLatLng);
      }
    }

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) polyline.openTooltip(); else polyline.closeTooltip();
  }
};

// 回復步驟中的 polyline node
export const resetStepPolylineNode = (polyline, setIsStepEdit) => {
  // 如果回復的是正在編輯的 polyline 則需將該 polyline 的 node 依新座標建立
  if (MapStatus.editLayer !== null && polyline.options.id === MapStatus.editLayer.options.id) {
    if (polyline.options.type === 'Passage' || polyline.options.type === 'NarrowLane') {
      calculatePassageNode(polyline);
      setEditNodePoint(polyline, setIsStepEdit);
    } else {
      createEditPolylineNode(polyline.getLatLngs(), setIsStepEdit);
    }
  }
};

// 回復步驟中的 point
export const resetStepPoint = (point, id, isEditType, isEditToolTip, stepToolTip, stepType, stepLatLng, stepRosAngle) => {
  if (point.options.id === id) {
    const { centerPosition } = MapStatus;
    const { rotationAngle } = MapStatus.mapMarker.options;

    // 判斷編輯動作
    if (isEditToolTip) {
      point.options.toolTip = stepToolTip;
      point.unbindTooltip();
      point.bindTooltip(stepToolTip, createToolTipOption(point.options.type));
    } else if (isEditType) {
      const options = createPointOption(stepType);

      point.setIcon(options.icon);
      point.options.type = stepType;
    } else {
      const latlng = getRotateLeafletPosition(stepLatLng, centerPosition, rotationAngle);
      point.options.originalLatLng = getOriginLeafletPosition(stepLatLng, centerPosition, 0);
      point.setLatLng(latlng);
      rotatePoint(point, stepRosAngle);
    }

    // 如果目前標記名稱是打開的話則重新設定 tool tip 位置
    if (MapStatus.toolTip) point.openTooltip(); else point.closeTooltip();
  }
};

// 移除步驟中的 layer
export const removeStepLayer = (obj) => {
  const { id } = obj.layer.options;
  const { flag_type } = obj;

  switch (true) {
    case flag_type === 'polygon' || flag_type === 'rectangle':
      MapStatus.layerArr.polygon.forEach((polygon, index) => {
        if (polygon.options.id === id) {
          MapStatus.layerArr.polygon.splice(index, 1);

          // 如果移除的是正在編輯的 polygon 則需將該 polygon 的 node 清除
          if (MapStatus.editLayer !== null && polygon.options.id === MapStatus.editLayer.options.id) clearEditNodePoint();

          // 如果移除的是 Disinfection，則需將對應的 UVC Zone 從地圖上移除
          if (polygon.options.type === 'Disinfection') {
            const UVCZone = MapStatus.map._layers[polygon.options.UVCZoneId];
            UVCZone.remove();
          }
        }
      });
      obj.layer.remove();
      break;
    case flag_type === 'line':
      MapStatus.layerArr.polyline.forEach((polyline, index) => {
        if (polyline.options.id === id) MapStatus.layerArr.polyline.splice(index, 1);

        // 如果移除的是正在編輯的 polyline 則需將該 polyline 的 node 清除
        if (MapStatus.editLayer !== null && polyline.options.id === MapStatus.editLayer.options.id) clearEditNodePoint();

        // 如果是編輯中的 Gate 則需移除
        if (MapStatus.currentGate !== null && polyline.options.id === MapStatus.editLayer.options.id) MapStatus.currentGate.remove();
      });

      // 如果是 Gate 需將 node point 一起移除
      if (obj.layer.options.type === 'NarrowGate' || obj.layer.options.type === 'IotGate') {
        obj.layer.options.node[0].remove();
        obj.layer.options.node[1].remove();
      }

      obj.layer.remove();
      break;
    case flag_type === 'point':
      MapStatus.layerArr.point.forEach((point, index) => {
        if (point.options.id === id) MapStatus.layerArr.point.splice(index, 1);
      });
      obj.layer.remove();
      break;

    case flag_type === 'site':
      if (obj.layer.options.type === 'Charger') {
        MapStatus.layerArr.chargingAreaList.forEach((point, index) => {
          if (point.options.id === id) MapStatus.layerArr.chargingAreaList.splice(index, 1);
        });
        obj.layer.remove();
      } else {
        console.log('尚未實作');
      }
      break;
    default:
      break;
  }
};

// 新增步驟中的 layer
export const addStepLayer = (obj, setIsStepEdit, setEditLayerData) => {
  const { centerPosition } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  const { flag_type } = obj;

  switch (true) {
    case flag_type === 'polygon' || flag_type === 'rectangle':
      const polygonRotateLatLng = obj.layer.options.originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
      obj.layer.setLatLngs(polygonRotateLatLng);
      MapStatus.layerArr.polygon.push(obj.layer);
      obj.layer.addTo(MapStatus.map);
      if (!MapStatus.toolTip) obj.layer.closeTooltip();

      // 如果加回的是正在編輯的 polygon 則需將該 polygon 的 node 重新建立
      if (MapStatus.editLayer !== null && obj.layer.options.id === MapStatus.editLayer.options.id) createEditPolygonNode(MapStatus.editLayer.getLatLngs(), setIsStepEdit, setEditLayerData);

      // 如果加回的是 Disinfection 則需將對應的 UVC Zone 從地圖上加回
      if (obj.layer.options.type === 'Disinfection') addStepUVCZone(obj.layer);
      break;
    case flag_type === 'line':
      const lineRotateLatLng = obj.layer.options.originalLatLng.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
      obj.layer.setLatLngs(lineRotateLatLng);
      MapStatus.layerArr.polyline.push(obj.layer);
      obj.layer.addTo(MapStatus.map);

      // 如果加回的是正在編輯的 polyline 則需將該 polyline 的 node 重新建立
      if (MapStatus.editLayer !== null && obj.layer.options.id === MapStatus.editLayer.options.id) {
        if (MapStatus.editLayer.options.type === 'Passage' || MapStatus.editLayer.options.type === 'NarrowLane') {
          calculatePassageNode(MapStatus.editLayer);
          setEditNodePoint(MapStatus.editLayer, setIsStepEdit);
        } else {
          createEditPolylineNode(MapStatus.editLayer.getLatLngs(), setIsStepEdit);
        }
      }

      // 如果是 NarrowGate 需將 node point 加回 map
      if (obj.layer.options.type === 'NarrowGate' || obj.layer.options.type === 'IotGate') {
        obj.layer.options.node.forEach((node) => {
          node.setLatLng(getRotateLeafletPosition(node.options.originalLatLng, centerPosition, rotationAngle));
          node.options.originalLatLng = getOriginLeafletPosition(node.getLatLng(), centerPosition, rotationAngle);
          node.addTo(MapStatus.map);
        });
      }

      // 如果是編輯中的 Gate 則需加回
      if (MapStatus.currentGate !== null && obj.layer.options.id === MapStatus.editLayer.options.id) {
        MapStatus.currentGate.addTo(MapStatus.map);
      }
      if (!MapStatus.toolTip) obj.layer.closeTooltip();
      break;
    case flag_type === 'point':
      const pointRotateLatLng = getRotateLeafletPosition(obj.layer.options.originalLatLng, centerPosition, rotationAngle);
      obj.layer.setLatLng(pointRotateLatLng);
      MapStatus.layerArr.point.push(obj.layer);
      obj.layer.addTo(MapStatus.map);

      if (!MapStatus.toolTip) obj.layer.closeTooltip();
      break;
    case flag_type === 'site':
      const siteRotateLatLng = getRotateLeafletPosition(obj.layer.options.originalLatLng, centerPosition, rotationAngle);
      obj.layer.setLatLng(siteRotateLatLng);
      if (obj.layer.options.type === 'Charger') {
        MapStatus.layerArr.chargingAreaList.push(obj.layer);
        obj.layer.addTo(MapStatus.map);
      } else {
        console.log('尚未實作');
      }
      if (!MapStatus.toolTip) obj.layer.closeTooltip();
      break;
    default:
      break;
  }
};

// 回復編輯前的 layer
export const undoEditStepLayer = (obj, setIsStepEdit, setEditLayerData) => {
  const { layer } = obj;
  const { id, isEditToolTip, isEditType, undoType, undoToolTip, undoLatLng, type, undoRosAngle } = layer;
  switch (true) {
    case obj.flag_type === 'polygon' || obj.flag_type === 'rectangle':
      clearEditNodePoint();
      MapStatus.layerArr.polygon.forEach((polygon) => {
        // 回復 polygon 狀態
        resetStepPolygon(polygon, id, isEditType, isEditToolTip, undoToolTip, undoType, undoLatLng, undoRosAngle, setEditLayerData);

        // 如果回復的是正在編輯的 polygon 則需將該 polygon 的 node 依新座標建立
        if (MapStatus.editLayer !== null && polygon.options.id === MapStatus.editLayer.options.id) createEditPolygonNode(MapStatus.editLayer.getLatLngs(), setIsStepEdit, setEditLayerData);

        // 回復 UVC Zone
        if (polygon.options.type === 'Disinfection') resetStepUVCZone(polygon);
      });
      break;
    case obj.flag_type === 'line':
      clearEditNodePoint();
      MapStatus.layerArr.polyline.forEach((polyline) => {
        // 回復 polyline 狀態
        resetStepPolyline(polyline, id, isEditType, isEditToolTip, undoToolTip, undoType, undoLatLng);

        // 回復 polyline node
        resetStepPolylineNode(polyline, setIsStepEdit);
      });
      break;
    case obj.flag_type === 'point':
      MapStatus.layerArr.point.forEach((point) => {
        // 回復 point 狀態
        obj.layer.redoType = type;
        resetStepPoint(point, id, isEditType, isEditToolTip, undoToolTip, undoType, undoLatLng, undoRosAngle);
      });
      break;
    case obj.flag_type === 'site':
      if (obj.layer.type === 'Charger') {
        MapStatus.layerArr.chargingAreaList.forEach((point) => {
          // 回復 charger 狀態
          obj.layer.redoType = type;
          resetStepPoint(point, id, isEditType, isEditToolTip, undoToolTip, undoType, undoLatLng, undoRosAngle);
        });
      } else {
        console.log('尚未實作');
      }
      break;
    default:
      break;
  }
};

// 回復編輯後的 layer
export const redoEditStepLayer = (obj, setIsStepEdit, setEditLayerData) => {
  const { layer } = obj;
  const { id, isEditToolTip, isEditType, redoType, redoToolTip, redoLatLng, redoRosAngle } = layer;
  switch (true) {
    case obj.flag_type === 'polygon' || obj.flag_type === 'rectangle':
      clearEditNodePoint();
      MapStatus.layerArr.polygon.forEach((polygon) => {
        // 回復 polygon 狀態
        resetStepPolygon(polygon, id, isEditType, isEditToolTip, redoToolTip, redoType, redoLatLng, redoRosAngle, setEditLayerData);

        // 如果回復的是正在編輯的 polygon 則需將該 polygon 的 node 依新座標建立
        if (MapStatus.editLayer !== null && polygon.options.id === MapStatus.editLayer.options.id) createEditPolygonNode(MapStatus.editLayer.getLatLngs(), setIsStepEdit, setEditLayerData);

        // 回復 UVC Zone
        if (polygon.options.type === 'Disinfection') resetStepUVCZone(polygon);
      });
      break;
    case obj.flag_type === 'line':
      clearEditNodePoint();
      MapStatus.layerArr.polyline.forEach((polyline) => {
        // 回復 polyline 狀態
        resetStepPolyline(polyline, id, isEditType, isEditToolTip, redoToolTip, redoType, redoLatLng);

        // 回復 polyline node
        resetStepPolylineNode(polyline, setIsStepEdit);
      });
      break;
    case obj.flag_type === 'point':
      MapStatus.layerArr.point.forEach((point) => {
        // 回復 point 狀態
        resetStepPoint(point, id, isEditType, isEditToolTip, redoToolTip, redoType, redoLatLng, redoRosAngle);
      });
      break;
    case obj.flag_type === 'site':
      if (obj.layer.type === 'Charger') {
        MapStatus.layerArr.chargingAreaList.forEach((point) => {
          // 回復 Charger 狀態
          resetStepPoint(point, id, isEditType, isEditToolTip, redoToolTip, redoType, redoLatLng, redoRosAngle);
        });
      } else {
        console.log('尚未實作');
      }
      break;
    default:
      break;
  }
};

// 建立 step 物件
export const createStepObject = (action_type, layer, flag_type) => {
  const stepObj = {
    action_type,
    layer, // 新增的 layer 或編輯過 layer 的 obj
    flag_type,
  };

  // 如果目前已經有下一步驟，則將之前的所有下ㄧ步驟先移除
  if (MapStatus.currentStep < MapStatus.steps.length) {
    const deleteCount = MapStatus.steps.length - MapStatus.currentStep;
    MapStatus.steps.splice(MapStatus.currentStep, deleteCount);

    layer.undoRosAngle = MapStatus.editLayer.options.rosAngle;
  }

  MapStatus.steps.push(stepObj);
  MapStatus.currentStep += 1;
};

// 回復上一步驟
export const lastStep = (setIsStepEdit, setEditLayerData) => {
  const lastObj = MapStatus.steps[MapStatus.currentStep - 1];

  switch (lastObj.action_type) {
    case 'add':
      removeStepLayer(lastObj, setIsStepEdit);
      break;
    case 'edit':
      undoEditStepLayer(lastObj, setIsStepEdit, setEditLayerData);
      break;
    case 'delete':
      addStepLayer(lastObj, setIsStepEdit, setEditLayerData);
      removeDeleteLayer(lastObj.flag_type, lastObj.layer.options.id);
      break;
    default:
      break;
  }

  MapStatus.currentStep -= 1;
};

// 回復下一步驟
export const nextStep = (setIsStepEdit, setEditLayerData) => {
  const nextObj = MapStatus.steps[MapStatus.currentStep];

  switch (nextObj.action_type) {
    case 'add':
      addStepLayer(nextObj, setIsStepEdit, setEditLayerData);
      break;
    case 'edit':
      redoEditStepLayer(nextObj, setIsStepEdit, setEditLayerData);
      break;
    case 'delete':
      removeStepLayer(nextObj, setIsStepEdit);
      MapStatus.delete.push({
        type: nextObj.flag_type,
        id: nextObj.layer.options.id,
        layer_type: nextObj.layer.options.type,
        name: nextObj.layer.options.toolTip,
      });
      break;
    default:
      break;
  }

  MapStatus.currentStep += 1;
};

// 移除 MapStatus.delete 裏的 Layer
export const removeDeleteLayer = (type, id) => {
  MapStatus.delete.forEach((item, index) => {
    if (item.type === type && item.id === id) MapStatus.delete.splice(index, 1);
  });
};

// 計算 line 兩點間的距離
const calculateLineDistance = (latLngs) => {
  const xDiff = latLngs[1].lng - latLngs[0].lng;
  const yDiff = latLngs[1].lat - latLngs[0].lat;
  const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  return distance;
};
// 檢查 layer 尺寸是否過小
export const checkLayerSize = (targetLayer) => {
  const { type } = targetLayer.options;
  if (targetLayer.getLatLngs === undefined) return false;
  let latLng = targetLayer.getLatLngs()[0];
  if (['VirtualWall', 'NarrowGate'].includes(type)) {
    latLng = targetLayer.getLatLngs();
    if (calculateLineDistance(latLng) < 0.1) return true;
    return false;
  }
  let checkLng = false;
  let checkLat = false;
  latLng.forEach((item, index) => {
    if (index === latLng.length - 1) {
      if (Math.abs(item.lat - latLng[0].lat) > 0.1) checkLat = true;
      if (Math.abs(item.lng - latLng[0].lng) > 0.1) checkLng = true;
      return;
    }
    if (Math.abs(item.lat - latLng[index + 1].lat) > 0.1) checkLat = true;
    if (Math.abs(item.lng - latLng[index + 1].lng) > 0.1) checkLng = true;
  });
  if (!checkLat || !checkLng) return true;
  return false;
};
/* ------------------------------------------------------------------- */

/* --------------------------- Tool tip --------------------------- */
// 建立 tool tip 的 option
export const createToolTipOption = (type = '', scale = 1, rosAngle = 90) => {
  const option = {
    className: 'tooltip',
    permanent: true,
    direction: 'center',
    opacity: 1,
  };

  if (type === 'Dock' || type === 'Elevator' || markerTypes.includes(type)) {
    option.direction = 'bottom';
  } else if (type === 'ParkingArea') {
    option.className = 'siteOrderText';
  } else if (type === 'Charger') {
    option.className = 'siteOrderText';
    const rotate = rotatePoint2D(0, -15 * scale, angleRosToLeaflet(rosAngle));
    option.offset = L.point(rotate.x, rotate.y);
  } else if (type === 'ManualCharger') {
    option.className = 'siteOrderText';
    const rotate = rotatePoint2D(0, -10 * scale, angleRosToLeaflet(rosAngle));
    option.offset = L.point(rotate.x, rotate.y);
  }

  return option;
};

// 顯示/關閉 地圖上的標記名稱
export const displayLayerName = (isOpen) => {
  // 忽略充電站和駐停區layer tooltip
  MapStatus.map.eachLayer((layer) => {
    if (!siteTypes.includes(layer.options?.type)) {
      if (isOpen) layer.openTooltip();
      else layer.closeTooltip();
      if (layer._icon && layer._icon.style.display === 'none') layer.closeTooltip();
      if (layer._path && layer._path.style.display === 'none') layer.closeTooltip();
    }
  });
};
// 顯示/關閉 地圖上的地圖區塊
export const displayMapArea = (isOpen) => {
  // 隱藏/顯示 flag
  MapStatus.map.eachLayer((layer) => {
    const { options, _path } = layer;
    // 保留地圖區塊polygon
    if (options.name?.includes('MapArea')) {
      _path.style.display = isOpen ? '' : 'none';
    }
  });
};
/* ---------------------------------取得地圖資訊---------------------------------- */
export const updateMapInfo = async (id) => {
  try {
    const newMapInfo = await apiMapsInfo(id);
    store.dispatch(setMapInfo({
      map_id: id,
      map_name: newMapInfo.data.name,
      site_id: newMapInfo.data.site_id,
      resolution: newMapInfo.data.resolution,
      building_id: newMapInfo.data.building_id,
      floor: newMapInfo.data.floor,
    }));

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (e) {
    console.log('取得地圖資訊失敗');
  }
};
/* -------------------------------redo/undo 更新 Redux -------------------------------- */
export const undoRedoLayersManager = ({ payload, index, current, actionType, rotateAngle, setIsStepEdit, setLayerToolMode, setEditLayerData, setSelectItemClose, setSelectLayerType, stepType, setUVCZoneData }) => {
  const { layerType, id, groupID } = current;
  switch (true) {
    case layerType === 'IotGate':
      updateIotGateState(id, groupID, payload, current, actionType, rotateAngle, layerType);
      break;
    case markerTypes.includes(layerType):
      updateMarkerPointState(id, actionType, payload, current, setIsStepEdit, setLayerToolMode);
      break;
    case layerType === 'Charger':
      updateChargerState(
        id, groupID, actionType, payload, layerType, current,
        rotateAngle, setLayerToolMode,
        setIsStepEdit, setEditLayerData, setSelectItemClose,
        index,
      );
      break;
    case layerType === 'ParkingArea':
      updateParkingAreaState(
        id, groupID, actionType, payload, current,
        rotateAngle, setLayerToolMode,
        setIsStepEdit, setEditLayerData, setSelectItemClose,
        index,
      );
      break;
    case layerType === 'NarrowGate':
      updateNarrowGateState(id, actionType, payload, current, layerType, setIsStepEdit, setLayerToolMode, setEditLayerData, setSelectLayerType);
      break;
    case layerType === 'Elevator':
      updateElevatorState(id, actionType, payload, current, setLayerToolMode);
      break;
    case rectangleTypes.includes(layerType):
      updateRectangleState(id, payload, actionType, rotateAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData);
      break;
    case polylineTypes.includes(layerType):
      updatePolylineState(id, payload, actionType, current, rotateAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
      break;
    case polygonTypes.includes(layerType):
      updatePolygonState(id, payload, actionType, current, rotateAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData);
      break;
    default:
      console.log(`Type '${layerType}' is not handled`);
      break;
  }
  if (['undo', 'redo'].includes(stepType)) store.dispatch(updateStepIndex({ type: stepType }));
};
export const getGroupLayers = (groupID, type) => (
  Object.values(MapStatus.map._layers)
    .filter((current) => current.options.groupID === groupID && current.options.type === type)
    .reduce((prev, current) => {
      prev[current.options.name] = current;
      return prev;
    }, {})
);
export const getGroupLayersLatLng = (groupID, type) => (
  Object.values(MapStatus.map._layers)
    .filter((current) => current.options.groupID === groupID && current.options.type === type)
    .reduce((prev, current) => {
      prev[current.options.name] = current.getLatLng ? current.getLatLng() : current.getLatLngs();
      return prev;
    }, {})
);
export const updateStepsInRedux = (actionType, undoPayload, redoPayload, extraData = {}) => {
  const step = { ...getCurrentStep(), ...extraData };
  step.actionType = actionType;
  step.undoPayload = undoPayload;
  step.redoPayload = redoPayload;
  store.dispatch(addSteps(step));
};
// input 變更 point Info
export const modifyPointInfo = (type, value, currentPoint, currentItem, setIsStepEdit) => {
  switch (type) {
    case 'y':
    case 'x':
      store.dispatch(updatePoint({ _id: currentPoint._id, lat: currentPoint.lat, lng: currentPoint.lng }));
      updateMapPoint({
        _id: currentPoint._id,
        lng: currentPoint.lng,
        lat: currentPoint.lat,
        rosAngle: currentPoint.rosAngle,
      });

      // 對 marker 注入 id/uuid，後面需要計算 PointInfoLayout 如何調整位置
      const marker = MapStatus.map._layers[currentPoint._id];
      if (marker != null) {
        const domId = `leaflet-${currentPoint._id}`;
        marker._icon.id = domId;
        if (currentItem.mode === 'add') checkOriginPointX(domId);
      }
      break;
    case 'rosAngle':
    case 'angle':
      let rosAngle = Number(value);
      if (!rosAngle) rosAngle = 0;
      if (rosAngle > 360) rosAngle = 360;
      if (rosAngle < -360) rosAngle = -360;
      store.dispatch(updatePoint({ _id: currentPoint._id, rosAngle }));
      updateMapPoint({
        _id: currentPoint._id,
        lng: currentPoint.lng,
        lat: currentPoint.lat,
        rosAngle,
      });
      break;
    case 'isRotate':
      store.dispatch(updatePoint({ _id: currentPoint._id, isRotate: value }));
      break;
    case 'name':
      store.dispatch(updatePoint({ _id: currentPoint._id, name: value }));
      // TODO: 更新所有呼叫此函數的參數
      editPointName(value, currentPoint._id, setIsStepEdit);
      break;
    case 'storeNumber':
      store.dispatch(updatePoint({ _id: currentPoint._id, storeNumber: value }));
      break;
    case 'services':
      const services = Number(value);
      store.dispatch(updatePoint({ _id: currentPoint._id, services }));
      break;
    case 'phone':
      store.dispatch(updatePoint({ _id: currentPoint._id, phone: value }));
      break;
    case 'roomCode':
      store.dispatch(updatePoint({ _id: currentPoint._id, roomCode: value }));
      break;
    case 'food':
      store.dispatch(updatePoint({ _id: currentPoint._id, isFood: value }));
      break;
    case 'abandonPassword':
      store.dispatch(updatePoint({ _id: currentPoint._id, abandonPassword: value }));
      break;
    case 'abandonDays':
      store.dispatch(updatePoint({ _id: currentPoint._id, abandonDays: value }));
      break;
    case 'type':
      modifyPointType(value, currentPoint);
      break;
    default:
      break;
  }
};
export const modifyPointType = (type, currentPoint) => {
  const marker = MapStatus.map._layers[currentPoint._id];
  if (marker == null) {
    console.log(`Cannot find marker: '${currentPoint._id}'`);
    return;
  }

  const defaultPayload = {
    isRotate: false,
    storeNumber: '',
    services: '',
    phone: '',
    roomCode: '',
    isFood: false,
    layer: [],
    abandonPassword: '',
    abandonDays: '',
  };

  const options = createPointOption(type);
  marker.setIcon(options.icon);
  marker.options.toolTip = options.toolTip;
  marker.options.type = options.type;
  store.dispatch(updatePoint({
    ...defaultPayload,
    _id: currentPoint._id,
    type,
  }));

  store.dispatch(updateGroup({
    type: 'modifyTarget',
    targetID: currentPoint.id,
    fromType: `target${currentPoint.type}`,
    toType: `target${type}`,
  }));
};
export const checkOriginPointX = (domId, currentPoint) => {
  const img = document.querySelector(`#${domId}`);
  const { x, width } = img.getBoundingClientRect();
  const centerX = x + (width / 2);
  const currentWidth = window.screen.width;
  const center = currentWidth / 2;
  let modalX = 0;

  if (centerX < center) modalX = centerX + 300; else modalX = centerX - 350;

  store.dispatch(updatePoint({ _id: currentPoint._id, modalOffset: { x: modalX } }));
};
const getLeafletPosition = (position, centerPosition, mapAngle, isOrigin, actionType = 'move') => {
  if (actionType === 'edit') {
    const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
    return getRotateLeafletPosition(position, centerPosition, currentMapAngle);
  }
  if (isOrigin) return getOriginLeafletPosition(position, centerPosition, mapAngle);
  return getRotateLeafletPosition(position, centerPosition, mapAngle);
};
const getRosAngle = (currentMapAngle, mapAngle, rosAngle, angleDifference) => {
  if (currentMapAngle >= mapAngle) return (rosAngle - mapAngle) - angleDifference;
  return (rosAngle - mapAngle) + angleDifference;
};
const setPointAdjustAngle = (taskPoint, inputValue, pathId, pointId) => {
  const { layerArr, mapMarker } = MapStatus;

  const marker = layerArr.taskPoint.find((obj) => obj.options.id === pointId);
  const { rotationAngle } = mapMarker.options;
  let value = inputValue;
  if (!inputValue) value = 0;

  marker.options.rosAngle = value;
  marker.setRotationAngle(90 - value + rotationAngle);

  taskPoint.angle = marker.options.rosAngle;
  const angleInput = document.querySelector(`#taskPointAngle_${pathId}_${pointId}`);
  if (angleInput !== null) angleInput.value = marker.options.rosAngle;
};
const setPointInfo = (marker, value, type) => {
  let latlng;
  switch (true) {
    case type === 'x':
      latlng = getRotateLeafletPosition({ lat: marker.options.originalLatLng.lat, lng: value }, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      marker.setLatLng(latlng);
      marker.options.originalLatLng = { lat: Number(marker.options.originalLatLng.lat).toFixed(2), lng: Number(value).toFixed(2) };
      break;
    case type === 'y':
      latlng = getRotateLeafletPosition({ lat: value, lng: marker.options.originalLatLng.lng }, MapStatus.centerPosition, MapStatus.mapMarker.options.rotationAngle);
      marker.setLatLng(latlng);
      marker.options.originalLatLng = { lat: Number(value).toFixed(2), lng: Number(marker.options.originalLatLng.lng).toFixed(2) };
      break;
    case type === 'angle':
      marker.options.rosAngle = value;
      rotatePoint(marker, value);
      break;
    default:
  }
};
export const updateNarrowGateState = (id, actionType, payload, currentStep, layerType, setIsStepEdit, setLayerToolMode, setEditLayerData, setSelectLayerType) => {
  let currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    leafletId,
    groupID,
    name,
    lat,
    lng,
    point1,
    point2,
  } = currentPayload;
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const angleDifference = Math.abs(currentStep.mapAngle - currentMapAngle);
  const polyline = store.getState().mapSlice.polylines.find((item) => item.id === id);
  const currentItem = { _id: polyline?._id, type: polyline?.type, mode: 'edit' };

  // 如果有包含 lat, lng 需重新計算位置
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  if (point1) {
    currentPayload = {
      ...currentPayload,
      point1: getLeafletPosition(point1, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  if (point2) {
    currentPayload = {
      ...currentPayload,
      point2: getLeafletPosition(point2, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  switch (actionType) {
    case 'add':
      initNarrowGate(currentPayload.point1, layerType, { id },
        {
          point1LatLng: currentPayload.point1,
          point2LatLng: currentPayload.point2,
          init: true,
          setIsStepEdit,
          setLayerToolMode,
          setEditLayerData,
          setSelectLayerType,
        });
      break;
    case 'edit':
      updateMapNarrowGate(id, currentPayload);
      store.dispatch(updatePolylineById({ id, type: 'iterateNestedObjects', ...currentPayload }));
      break;
    case 'delete':
      store.dispatch(setCurrentItem(currentItem));
      deleteLayerByRedux({ saveSteps: false });
      store.dispatch(setCurrentItem({}));
      break;
    case 'nodeMove':
      store.dispatch(updatePoint({ _id: leafletId, ...currentPayload }));
      store.dispatch(updatePolylineById({ id: groupID, ...{ [name]: currentPayload } }));
      updateMapNarrowGate(groupID);
      break;
    case 'move':
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(updatePolylineById({ id, ...currentPayload }));
      store.getState().mapSlice.points.filter((_point) => _point.groupID === id).forEach((_point) => {
        store.dispatch(updatePoint({ _id: _point._id, ...currentPayload[_point.name] }));
      });
      updateMapNarrowGate(id, currentPayload);
      break;
    default:
      break;
  }
};
export const updateIotGateState = (id, groupID, payload, currentStep, actionType, rotateAngle, layerType) => {
  let currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    lat,
    lng,
    name,
    point1,
    point2,
    insidePoint,
    outsidePoint,
    insidePointID,
    outsidePointID,
    apiType,
  } = currentPayload;
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const angleDifference = Math.abs(currentStep.mapAngle - currentMapAngle);
  const groupKey = ['name', 'passageLength', 'robotWaitingDistance'];
  const pointKey = ['point1', 'point2'];
  const group = store.getState().mapSlice.groups.find((item) => item.id === groupID && item.type === layerType && item.apiType !== 'delete');
  const point = store.getState().mapSlice.points.find((item) => item.groupID === groupID && item.name === name);

  // 如果有包含 lat, lng 需重新計算位置
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  if (point1) {
    currentPayload = {
      ...currentPayload,
      point1: getLeafletPosition(point1, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  if (point2) {
    currentPayload = {
      ...currentPayload,
      point2: getLeafletPosition(point2, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle),
    };
  }
  if (actionType !== 'add') {
    store.dispatch(setCurrentItem({ _id: id, type: layerType, mode: 'edit' }));
    store.dispatch(setCurrentGroupItem({ id, type: layerType, mode: 'edit' }));
  }
  switch (actionType) {
    case 'add':
      createLineByRedux(point1, layerType, { ...currentPayload, id, insidePointID, outsidePointID }, {
        insideWorkingAreaLatLng: insidePoint,
        outsideWorkingAreaLatLng: outsidePoint,
        point1LatLng: currentPayload.point1,
        point2LatLng: currentPayload.point2,
        init: true,
        update: apiType !== 'post',
        ...currentPayload,
      });
      break;
    case 'edit':
      if (pointKey.some((text) => currentPayload.hasOwnProperty(text))) {
        const keys = Object.keys(currentPayload)[0];
        const _point = store.getState().mapSlice.points.find((item) => item.id === group[keys]);
        const OriginLatLng = { ...getOriginLeafletPosition(_point, centerPosition, rotateAngle), ...payload[keys] };
        rotateIotGatePoint(groupID, OriginLatLng, keys, rotateAngle);
        store.dispatch(updatePoint({ _id: _point._id, ...getRotateLeafletPosition(OriginLatLng, centerPosition, rotateAngle) }));
      }

      if (groupKey.some((text) => currentPayload.hasOwnProperty(text))) {
        updateMapIotGate(groupID, currentPayload);
        store.dispatch(updateGroup({ id, type: 'iterateNestedObjects', ...currentPayload }));
      }
      break;
    case 'delete':
      deleteLayerByRedux({ saveSteps: false });
      deleteGroupLayerByRedux();

      store.dispatch(deletePointByGroupId({ id }));
      store.dispatch(deletePolylineByGroupId({ id }));
      store.dispatch(deletePolygonByGroupId({ id }));

      store.dispatch(setCurrentItem({}));
      break;
    case 'nodeMove':
      const layers = getGroupLayers(groupID, layerType);
      const _insideRosAngle = getRotatedAngle([layers.insidePoint.getLatLng(), layers.polyline.getCenter()]);
      const _outsideRosAngle = getRotatedAngle([layers.outsidePoint.getLatLng(), layers.polyline.getCenter()]);

      store.dispatch(updatePoint({ _id: point._id, ...currentPayload }));
      store.dispatch(updatePoint({ _id: layers.insidePoint._leaflet_id, ...layers.insideWorkingArea.getBounds().getCenter(), rosAngle: _insideRosAngle }));
      store.dispatch(updatePoint({ _id: layers.outsidePoint._leaflet_id, ...layers.outsideWorkingArea.getBounds().getCenter(), rosAngle: _outsideRosAngle }));
      updateMapIotGate(groupID);
      break;
    case 'move':
      store.dispatch(updatePoint({ _id: group.point1LeafletId, ...currentPayload.point1 }));
      store.dispatch(updatePoint({ _id: group.point2LeafletId, ...currentPayload.point2 }));
      updateMapIotGate(groupID, currentPayload);
      break;
    default:
      break;
  }
  if (actionType !== 'delete') rebindIotGateMapArea(groupID);
};
export const updateRectangleState = (id, payload, actionType, rotationAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData) => {
  const currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    name,
    rosAngle,
    originalLatLng,
  } = currentPayload;
  const { _id, type } = store.getState().mapSlice.rectangles.find((item) => item.id === id) || { _id: null, type: '' };
  const layer = MapStatus.map._layers[_id];
  const { center } = layer?.options || {};
  const currentItem = { _id, type, mode: 'edit' };
  const latlng = originalLatLng?.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
  const notRotateLatLng = layer?.getLatLngs()[0].map((item) => getRotateLeafletPosition(item, center, 360 - rosAngle));
  clearEditNodePoint();
  switch (actionType) {
    case 'add':
      createInitPolygon(currentPayload.type, currentPayload, latlng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData);
      break;
    case 'edit':
      const keys = Object.keys(currentPayload);
      switch (true) {
        case keys.includes('name'):
          store.dispatch(updateRectangle({ _id: layer._leaflet_id, name }));
          break;
        default:
          break;
      }
      break;
    case 'delete':
      store.dispatch(setCurrentItem(currentItem));
      deleteLayerByRedux({ saveSteps: false });
      store.dispatch(setCurrentItem({}));
      break;
    case 'move':
    case 'nodeMove':
      layer.setLatLngs(latlng);
      layer.options.originalLatLng = originalLatLng;
      layer.options.rosAngle = rosAngle;
      layer.options.notRotateLatLng = notRotateLatLng;
      if (layer.options.type === 'OneWay') {
        const { x, y } = calculateRectangleCenter(layer);
        const arrow = Object.values(MapStatus.map._layers).find((item) => item.options.type === 'OneWayArrow' && item.options.id === layer.options.id);
        arrow?.setLatLng({ lat: y, lng: x });
      }
      store.dispatch(updateRectangle({ _id: layer._leaflet_id, nodes: latlng }));
      break;
    default:
      break;
  }
};
export const updatePolylineState = (id, payload, actionType, currentStep, rotationAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose) => {
  const currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    name,
    rosAngle,
    originalLatLng,
  } = currentPayload;
  const { _id, type } = store.getState().mapSlice.polylines.find((item) => item.id === id) || { _id: null, type: '' };
  const layer = MapStatus.map._layers[_id];
  const currentItem = { _id, type, mode: 'edit' };
  const latlng = originalLatLng?.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
  clearEditNodePoint();
  switch (actionType) {
    case 'add':
      if (currentPayload.type === 'NarrowLane' || currentPayload.type === 'Passage') {
        createInitPassage(currentPayload, latlng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, currentPayload.type);
      } else {
        createInitPolyline(currentPayload.type, currentPayload, latlng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose);
      }
      break;
    case 'edit':
      const keys = Object.keys(currentPayload);
      switch (true) {
        case keys.includes('name'):
          store.dispatch(updatePolyline({ _id: layer._leaflet_id, name }));
          break;
        default:
          break;
      }
      break;
    case 'delete':
      store.dispatch(setCurrentItem(currentItem));
      deleteLayerByRedux({ saveSteps: false });
      store.dispatch(setCurrentItem({}));
      break;
    case 'move':
    case 'nodeMove':
      layer.setLatLngs(latlng);
      layer.options.originalLatLng = originalLatLng;
      layer.options.rosAngle = rosAngle;
      store.dispatch(updatePolyline({ _id: layer._leaflet_id, nodes: latlng }));
      break;
    default:
      break;
  }
};
export const updatePolygonState = (id, payload, actionType, currentStep, rotationAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData) => {
  const currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    name,
    type: currentType,
    rosAngle,
    originalLatLng,
  } = currentPayload;
  const { _id, type } = store.getState().mapSlice.polygons.find((item) => item.id === id) || { _id: null, type: '' };
  const layer = MapStatus.map._layers[_id];
  const currentItem = { _id, type, mode: 'edit' };
  const latlng = originalLatLng?.map((item) => getRotateLeafletPosition(item, centerPosition, rotationAngle));
  clearEditNodePoint();

  switch (actionType) {
    case 'add':
      createInitPolygon(currentPayload.type, currentPayload, latlng, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, setUVCZoneData);
      break;
    case 'edit':
      const keys = Object.keys(currentPayload);
      switch (true) {
        case keys.includes('name'):
          store.dispatch(updatePolygon({ _id: layer._leaflet_id, name }));
          break;
        case keys.includes('type'):
          editPolygonStyle(currentType, id);
          store.dispatch(updatePolygon({ _id: layer._leaflet_id, type: currentType }));
          break;
        default:
          break;
      }
      break;
    case 'delete':
      store.dispatch(setCurrentItem(currentItem));
      deleteLayerByRedux({ saveSteps: false });
      store.dispatch(setCurrentItem({}));
      break;
    case 'move':
    case 'nodeMove':
      layer.setLatLngs(latlng);
      layer.options.originalLatLng = originalLatLng;
      layer.options.rosAngle = rosAngle;
      store.dispatch(updatePolygon({ _id: layer._leaflet_id, nodes: latlng }));
      if (type === 'Disinfection') {
        const UVCZone = MapStatus.map._layers[layer.options.UVCZoneId];
        const UVC_latlng = setUVCZonePoint(latlng);
        UVCZone.setLatLngs(UVC_latlng);
        UVCZone.options.originalLatLng = UVC_latlng.map((item) => getOriginLeafletPosition(item, centerPosition, rotationAngle));
      }
      break;
    default:
      break;
  }
};
export const updateMarkerPointState = (id, actionType, payload, currentStep, setIsStepEdit, setLayerToolMode) => {
  let currentPayload = { ...payload };
  const {
    x,
    y,
    lat,
    lng,
    rosAngle,
  } = currentPayload;
  const point = store.getState().mapSlice.points.find((item) => item.id === id);
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const currentItem = { _id: point?._id, type: point?.type, mode: 'edit' };
  const { centerPosition } = MapStatus;
  const angleDifference = Math.abs(currentStep.mapAngle - currentMapAngle);
  // 如果有包含 x, y, lat, lng 需重新計算位置
  if (y || x) {
    const { lat: _lat, lng: _lng } = getLeafletPosition({ lat: y, lng: x }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle, actionType);
    currentPayload = {
      ...currentPayload,
      lat: _lat,
      lng: _lng,
      x: _lng,
      y: _lat,
    };
  }
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle, actionType),
    };
  }
  // 如果有包含 rosAngle 需重新當前角度
  if (rosAngle) {
    currentPayload = {
      ...currentPayload,
      rosAngle: getRosAngle(currentMapAngle, currentStep.mapAngle, rosAngle, angleDifference),
    };
  }
  if (actionType !== 'add') store.dispatch(setCurrentItem(currentItem));
  switch (actionType) {
    case 'add':
      createPointByRedux({ lat: currentPayload.lat, lng: currentPayload.lng }, currentPayload.type, { ...currentPayload, setLayerToolMode }, false);
      break;
    case 'edit':
      const type = Object.keys(currentPayload)[0];
      modifyPointInfo(type, currentPayload[type], { ...point, ...currentPayload }, currentItem, setIsStepEdit);
      break;
    case 'delete':
      deleteLayerByRedux({ saveSteps: false });
      store.dispatch(setCurrentItem({}));
      break;
    case 'move':
      store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      MapStatus.map._layers[currentItem._id].setLatLng({ ...currentPayload });
      break;
    default:
      break;
  }
};
export const updateChargerState = (id, groupID, actionType, payload, layerType, currentStep, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, index) => {
  let currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    rosAngle,
    lat,
    lng,
    name,
    apiType,
    autoCharging,
  } = currentPayload;
  const point = store.getState().mapSlice.points.find((item) => item.id === id && item.type === 'Charger');
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const angleDifference = Math.abs(currentStep.mapAngle - mapAngle);
  const currentItem = { _id: point?._id, type: point?.type, mode: 'edit' };
  // 如果有包含 rosAngle 需重新當前角度
  if (rosAngle) {
    currentPayload = {
      ...currentPayload,
      rosAngle: getRosAngle(currentMapAngle, currentStep.mapAngle, rosAngle, angleDifference),
    };
  }
  // 如果有包含 lat, lng 需重新計算位置
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle, actionType),
    };
  }
  switch (actionType) {
    case 'add':
      const newCurrentStep = { ...currentStep };
      const points = [];
      if (apiType === 'post') store.dispatch(addGroup({ id: groupID, name, type: layerType, apiType, autoCharging }));
      else store.dispatch(updateGroup({ id: groupID, type: 'iterateNestedObjects', apiType: 'put' }));
      payload.points.forEach((item) => {
        let currentPoint = { ...item };
        // 如果有包含 lat, lng 需重新計算位置
        if (item.lat || item.lng) {
          const originLatLng = getOriginLeafletPosition({ lat: item.lat, lng: item.lng }, centerPosition, currentStep.mapAngle);
          currentPoint = {
            ...currentPoint,
            ...originLatLng,
          };
        }
        const { _leaflet_id } = createInitChargingPoint({
          point: {
            id: currentPoint.id,
            point_type: setAreaType(currentPoint.chargingMode),
            x: currentPoint.lng,
            y: currentPoint.lat,
            heading: calculateRosAngle(currentPoint.rosAngle, angleDifference, currentStep.mapAngle > currentMapAngle ? '+' : '-'),
            groupID: currentPoint.groupID,
            order: currentPoint.order,
            tag: currentPoint.tag,
          },
          rotateValue: calculateRosAngle(currentPoint.rosAngle, angleDifference, currentStep.mapAngle > currentMapAngle ? '+' : '-'),
          mapAngle,
          setLayerToolMode,
          setIsStepEdit,
          setEditLayerData,
          setSelectItemClose,
        });
        if (apiType === 'post') store.dispatch(updatePoint({ _id: _leaflet_id, tmpId: item.tmpId }));

        points.push({ ...item, _id: _leaflet_id });
      });
      newCurrentStep.undoPayload = { ...newCurrentStep.undoPayload, points };
      newCurrentStep.redoPayload = { ...newCurrentStep.redoPayload, points };
      store.dispatch(updateSteps({ index, data: { ...newCurrentStep } }));
      store.dispatch(setCurrentItem({}));
      store.dispatch(setCurrentGroupItem({}));
      break;
    case 'edit':
      const type = Object.keys(payload)[0];
      const scale = (MapStatus.map.getZoom() + 100) / 100;
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(setCurrentGroupItem({ id: groupID, type: layerType, mode: 'edit' }));
      if (type === 'rosAngle') {
        const layer = MapStatus.map._layers[currentItem._id];
        if (layer) {
          layer.unbindTooltip();
          layer.bindTooltip(`${point.order}`, createToolTipOption(currentItem.type, scale, calculateRosAngle(payload[type], currentMapAngle, '-')));
        }
      }
      if (type === 'name') store.dispatch(updateGroup({ id: groupID, type, value: payload[type] }));
      else store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      updateMapPoint({ ...point, ...currentPayload });
      break;
    case 'delete':
      store.dispatch(setCurrentGroupItem({ id: groupID, type: layerType, mode: 'edit' }));

      deleteLayerByRedux({ saveSteps: false });
      deleteGroupLayerByRedux();

      store.dispatch(deletePointByGroupId({ id: groupID }));
      store.dispatch(deletePolylineByGroupId({ id: groupID }));
      store.dispatch(deletePolygonByGroupId({ id: groupID }));

      store.dispatch(setCurrentItem({}));
      store.dispatch(setCurrentGroupItem({}));
      break;
    case 'move':
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(setCurrentGroupItem({ id: groupID, type: layerType, mode: 'edit' }));
      store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      MapStatus.map._layers[currentItem._id].setLatLng({ ...currentPayload });
      break;
    default:
      break;
  }
};
export const updateParkingAreaState = (id, groupID, actionType, payload, currentStep, mapAngle, setLayerToolMode, setIsStepEdit, setEditLayerData, setSelectItemClose, index) => {
  let currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    rosAngle,
    lat,
    lng,
    name,
    apiType,
    targetCharging,
    targetElevator,
    targetIotGate,
    targetStore,
    targetDelivery,
    targetHalfway,
  } = currentPayload;
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const point = store.getState().mapSlice.points.find((item) => item.id === id && item.type === 'ParkingArea');
  const currentItem = { _id: point?._id, type: point?.type, mode: 'edit' };
  const angleDifference = Math.abs(currentStep.mapAngle - mapAngle);

  // 如果有包含 rosAngle 需重新當前角度
  if (rosAngle) {
    currentPayload = {
      ...currentPayload,
      rosAngle: getRosAngle(currentMapAngle, currentStep.mapAngle, rosAngle, angleDifference),
    };
  }
  // 如果有包含 lat, lng 需重新計算位置
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle, actionType),
    };
  }
  switch (actionType) {
    case 'add':
      const newCurrentStep = { ...currentStep };
      const points = [];
      const group = {
        id: groupID,
        name,
        type: 'ParkingArea',
        apiType,
        targetCharging,
        targetElevator,
        targetIotGate,
        targetStore,
        targetDelivery,
        targetHalfway,
      };

      if (apiType === 'post') store.dispatch(addGroup(group));
      else store.dispatch(updateGroup({ id: groupID, type: 'iterateNestedObjects', apiType: 'put' }));

      currentPayload.points.forEach((parking) => {
        let currentParking = { ...parking };
        if (parking.lat || parking.lng) {
          const originLatLng = getOriginLeafletPosition({ lat: parking.lat, lng: parking.lng }, centerPosition, currentStep.mapAngle);
          currentParking = {
            ...currentParking,
            ...originLatLng,
          };
        }
        const parkingPoint = {
          ...parking,
          point_type: 'ParkingArea',
          x: currentParking.lng,
          y: currentParking.lat,
          point_name: currentParking.order,
          heading: calculateRosAngle(currentParking.rosAngle, angleDifference, currentStep.mapAngle > currentMapAngle ? '+' : '-'),
        };

        parkingPoint.groupName = name;
        const { _leaflet_id } = createInitParkingPoint({
          parkingPoint,
          mapAngle,
          setLayerToolMode,
          setIsStepEdit,
          setEditLayerData,
          setSelectItemClose,
        });
        if (apiType === 'post') store.dispatch(updatePoint({ _id: _leaflet_id, tmpId: parking.tmpId }));
        points.push({ ...parking, _id: _leaflet_id });
      });
      newCurrentStep.undoPayload = { ...newCurrentStep.undoPayload, points };
      newCurrentStep.redoPayload = { ...newCurrentStep.redoPayload, points };
      store.dispatch(updateSteps({ index, data: { ...newCurrentStep } }));
      store.dispatch(setCurrentItem({}));
      store.dispatch(setCurrentGroupItem({}));
      break;
    case 'edit':
      const targets = ['targetCharging', 'targetElevator', 'targetIotGate', 'targetStore', 'targetDelivery', 'targetHalfway'];
      const type = Object.keys(currentPayload)[0];

      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(setCurrentGroupItem({ id: groupID, type: 'ParkingArea', mode: 'edit' }));

      if (type === 'name') store.dispatch(updateGroup({ id: groupID, type, value: currentPayload[type] }));
      else if (targets.includes(type)) store.dispatch(updateGroup({ id: groupID, type: 'target', targetType: type, value: currentPayload[type] }));
      else store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));

      updateMapPoint({ ...point, ...currentPayload });
      break;
    case 'delete':
      store.dispatch(setCurrentGroupItem({ id: groupID, type: 'ParkingArea', mode: 'edit' }));
      deleteLayerByRedux({ saveSteps: false });
      deleteGroupLayerByRedux();

      store.dispatch(deletePointByGroupId({ id: groupID }));
      store.dispatch(deletePolylineByGroupId({ id: groupID }));
      store.dispatch(deletePolygonByGroupId({ id: groupID }));

      store.dispatch(setCurrentItem({}));
      store.dispatch(setCurrentGroupItem({}));
      break;
    case 'move':
      MapStatus.map._layers[currentItem._id].setLatLng({ ...currentPayload });
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(setCurrentGroupItem({ id: groupID, type: 'ParkingArea', mode: 'edit' }));
      store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      break;
    default:
      break;
  }
};
export const updateElevatorState = (id, actionType, payload, currentStep, setLayerToolMode) => {
  let currentPayload = { ...payload };
  const { centerPosition } = MapStatus;
  const {
    rosAngle,
    lat,
    lng,
    type,
  } = currentPayload;
  const currentMapAngle = store.getState().mapSlice.mapRotateAngle;
  const point = store.getState().mapSlice.points.find((item) => item.id === id);
  const currentItem = { _id: point?._id, type: point?.type, mode: 'edit' };
  const angleDifference = Math.abs(currentStep.mapAngle - currentMapAngle);

  // 如果有包含 rosAngle 需重新當前角度
  if (rosAngle) {
    currentPayload = {
      ...currentPayload,
      rosAngle: getRosAngle(currentMapAngle, currentStep.mapAngle, rosAngle, angleDifference),
    };
  }
  // 如果有包含 lat, lng 需重新計算位置
  if (lat || lng) {
    currentPayload = {
      ...currentPayload,
      ...getLeafletPosition({ lat, lng }, centerPosition, angleDifference, currentStep.mapAngle > currentMapAngle, actionType),
    };
  }
  switch (actionType) {
    case 'add':
      createPointByRedux({ lat: currentPayload.lat, lng: currentPayload.lng }, type, { ...currentPayload, setLayerToolMode }, false);
      break;
    case 'edit':
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      changeElevatorIconSizeById(point.id);
      break;
    case 'delete':
      store.dispatch(setCurrentItem({}));
      store.dispatch(deletePoint({ _id: currentItem._id }));
      MapStatus.map._layers[currentItem._id].remove();
      break;
    case 'move':
      store.dispatch(setCurrentItem(currentItem));
      store.dispatch(updatePoint({ _id: currentItem._id, ...currentPayload }));
      break;
    default:
      break;
  }
};
export const updateTaskPointState = (actionType, crrent, payload, setPathData, setSelectPath, setRightLayoutType, topicData, baseMapControl) => {
  const { centerPosition, pathData, layerArr } = MapStatus;
  const { rotationAngle } = MapStatus.mapMarker.options;
  const { id, pathId, pointId, lat, lng, pathApiType, actionId, order } = { ...crrent, ...payload };
  let arr = [...pathData];
  const pathIndex = arr.findIndex((path) => path.pathId === pathId);
  const pointIndex = arr[pathIndex]?.taskPoint.findIndex((point) => point.pointId === pointId);
  const actionIndex = arr[pathIndex]?.taskPoint[pointIndex]?.actionList.findIndex((action) => action.actionId === actionId);

  switch (actionType) {
    case 'setAutoAdjustAngle':
      const taskPoint = JSON.parse(JSON.stringify(arr[pathIndex].taskPoint));
      taskPoint.forEach((point, index) => {
        const currentData = payload.taskPoint[index];
        point.angle = currentData.angle;
        setPointAdjustAngle(taskPoint, currentData.angle, pathId, point.pointId);
      });
      arr[pathIndex] = JSON.parse(JSON.stringify({ ...arr[pathIndex], taskPoint }));
      break;
    case 'addPoint':
      if (pathIndex !== -1) {
        initTaskPoint(payload, { setPathData, setSelectPath, setRightLayoutType }, baseMapControl);
        const insertIndex = arr[pathIndex].taskPoint.filter((item) => item.order < payload.order).length;
        arr[pathIndex].taskPoint.splice(insertIndex, 0, JSON.parse(JSON.stringify(payload)));
        groupByPathLine(baseMapControl.zoomSize);
      }
      break;
    case 'deletePoint':
      const taskPointIndex = arr[pathIndex].taskPoint.findIndex((point) => point.pointId === pointId);
      if (pathIndex === -1 || taskPointIndex === -1) return;
      deletePathLayerObj('taskPoint', pointId);
      arr[pathIndex].taskPoint.splice(taskPointIndex, 1);
      groupByPathLine(baseMapControl.zoomSize);
      break;
    case 'addPath':
      const path = JSON.parse(JSON.stringify(payload));
      arr.push(path);
      path.taskPoint.forEach((point) => {
        initTaskPoint({
          id: point.pointId,
          name: point.pointName,
          pathId: path.pathId,
          latLng: { lat: point.y, lng: point.x },
          originalLatLng: { lat: point.y, lng: point.x },
          ...point,
        }, { setPathData, setSelectPath, setRightLayoutType }, baseMapControl);
      });
      MapStatus.pathData = arr;
      groupByPathLine(baseMapControl.zoomSize);
      break;
    case 'deletePath':
      arr = arr.filter((_path) => {
        if (_path.pathId === pathId) {
          _path.taskPoint.forEach((point) => {
            deletePathLayerObj('taskPoint', point.pointId);
          });
          if (pathApiType !== 'post') MapStatus.deletePath.push({ id: _path.pathId });
        }
        return _path.pathId !== pathId;
      });
      MapStatus.pathData = arr;
      deletePathLayerObj('arrowLine', pathId);
      setSelectPath({ type: '', pathId: '', pointId: '' });
      MapStatus.selectPath = { type: '', pathId: '', pointId: '' };
      MapStatus.isPathModeEdit = true;
      break;
    case 'addAction':
      const insertIndex = arr[pathIndex].taskPoint[pointIndex].actionList.filter((item) => item.order < order).length;
      arr[pathIndex].taskPoint[pointIndex].actionList.splice(insertIndex, 0, { ...payload });
      break;
    case 'deleteAction':
      const index = arr[pathIndex].taskPoint[pointIndex].actionList.findIndex((point) => point.actionId === actionId);
      arr[pathIndex].taskPoint[pointIndex].actionList.splice(index, 1);
      break;
    case 'edit':
      const key = Object.keys(payload)[0];
      const value = payload[key];

      const selectObj = { type: 'taskPoint', pointId, pathId };

      setSelectPath(selectObj);
      MapStatus.selectPath = selectObj;

      switch (key) {
        case 'pathName':
          arr[pathIndex].pathName = value;
          break;
        case 'pointName':
          arr[pathIndex].taskPoint[pointIndex].pointName = value;
          break;
        case 'x':
        case 'y':
        case 'angle':
          const marker = MapStatus.layerArr.taskPoint.find((obj) => obj.options.id === pointId);
          setPointInfo(marker, value, key);
          setPathData(arr);
          MapStatus.pathData = arr;
          arr[pathIndex].taskPoint.forEach((point, PointIndex) => {
            if (PointIndex === pointIndex) {
              point.x = marker.options.originalLatLng.lng;
              point.y = marker.options.originalLatLng.lat;
              point.angle = marker.options.rosAngle;
              const inputX = document.querySelector(`#taskPointX_${pathId}_${point.pointId}`);
              const inputY = document.querySelector(`#taskPointY_${pathId}_${point.pointId}`);
              const angleInput = document.querySelector(`#taskPointAngle_${pathId}_${point.pointId}`);
              if (!inputX) return;
              inputX.value = point.x;
              inputY.value = point.y;
              angleInput.value = marker.options.rosAngle;
            }
          });
          groupByPathLine(baseMapControl.zoomSize);
          break;
        case 'Light':
          arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Light = value;
          if (!value) {
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].PIR = true;
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].isTimeOut = false;
          } else {
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].PIR = false;
          }
          break;
        case 'isTimeOut':
          arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].isTimeOut = value;
          if (!arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].isTimeOut) {
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].PIR = false;
          }
          break;
        case 'LED':
          arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Light = value;
          if (!value) arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Twinkle = false;
          break;
        case 'Event':
          arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Event = value;
          if (value === 'door_open') {
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Topic = '';
          } else {
            const topic = topicData.filter((topicItem) => topicItem.name === value);
            arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].Topic = topic[0].text;
          }
          break;
        case 'Speed':
        case 'name':
        case 'Color':
        case 'switch':
        case 'SFX':
        case 'Twinkle':
        case 'timeOut':
        case 'PIR':
        case 'isLoop':
        case 'eventSFX':
        case 'Time':
        case 'Message':
        case 'eventSwitch':
          arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex][key] = value;
          break;
        default:
          break;
      }
      if (pointIndex !== undefined && pointIndex > -1) arr[pathIndex].taskPoint[pointIndex].isOpen = true;
      if (actionIndex !== undefined && actionIndex > -1) arr[pathIndex].taskPoint[pointIndex].actionList[actionIndex].isOpen = true;
      break;
    case 'move':
      const point = layerArr.taskPoint.find((item) => item.options.id === id);
      point.options.originalLatLng = getOriginLeafletPosition({ lat, lng }, centerPosition, rotationAngle);
      arr.forEach((_path) => {
        if (_path.pathId === pathId) {
          _path.taskPoint.forEach((_taskPoint) => {
            if (_taskPoint.pointId === id) {
              _taskPoint.x = point.options.originalLatLng.lng.toFixed(2);
              _taskPoint.y = point.options.originalLatLng.lat.toFixed(2);

              // 如果該 task point 的 list 是展開狀態，則更新 x/y 的 input value
              if (_taskPoint.isOpen) {
                const inputX = document.querySelector(`#taskPointX_${pathId}_${id}`);
                const inputY = document.querySelector(`#taskPointY_${pathId}_${id}`);
                inputX.value = point.options.originalLatLng.lng.toFixed(2);
                inputY.value = point.options.originalLatLng.lat.toFixed(2);
              }

              if (_path.actionApiType !== 'post') _path.actionApiType = 'put';
            }
          });
        }
      });
      point.setLatLng({ lat, lng });
      groupByPathLine(baseMapControl.zoomSize);
      MapStatus.isPathModeEdit = true;
      break;
    default:
      break;
  }
  setPathData(arr);
  MapStatus.pathData = arr;
};

export const hiddenAllConnectMap = () => {
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'ConnectMap') {
      // layer.remove();
      layer.setOpacity(0);
      layer.off('click');
      layer.dragging.disable();
      layer.off('mousedown');
      layer.off('mouseup');
      layer.setZIndexOffset(-5000); // 修改圖層 防止點擊不到原圖
    }
  });
};

export const removeLastConnecMap = () => {
  let lastConnectMapLayer = null;

  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'ConnectMap') {
      lastConnectMapLayer = layer;
    }
  });

  if (lastConnectMapLayer) {
    // 删除最后一个 ConnectMap 类型的图层
    MapStatus.map.removeLayer(lastConnectMapLayer);
  }
};

export const showConnectMapById = (currentPoint) => {
  const _id = currentPoint._idConnectMap;
  MapStatus.map.eachLayer((layer) => {
    if (layer.options.type === 'ConnectMap' && layer._leaflet_id === _id) {
      // layer.remove();
      layer.setOpacity(1);
      layer.setZIndexOffset(0); // 修改圖層
    }
  });
};

export const getLayerConnectMapByMapId = (mapId) => {
  let layer = null;
  MapStatus.map.eachLayer((item) => {
    if (item.options.type === 'ConnectMap' && item.options.mapId === mapId) {
      layer = item;
    }
  });
  return layer;
};

export const addLayerConnectPointHandler = (setTipText, setLayerToolMode, setCursor, setSelectLayerType, layerType = 'ConnectionPoint') => {
  store.dispatch(setCurrentItem({}));
  store.dispatch(setOpenIOChannelListModal(false));
  store.dispatch(updateAddOnly(true));
  setTipText('TIP_POINT');
  const defaultData = {
    isRotate: false,
    storeNumber: '',
    services: '',
    phone: '',
    isFood: false,
    layer: [],
    setLayerToolMode,
    abandonPassword: '',
    abandonDays: '',
  };
  if (layerType === 'ConnectionPoint') {
    const { pointId, connectMapData } = store.getState().mapSlice.connectMap;
    defaultData._idConnectMap = pointId;
    defaultData.connectMapData = connectMapData;
  }
  initReduxEvent('point', { defaultData });
  setCursor(`cursor${layerType}`);
  MapStatus.layerType = layerType;
  MapStatus.map.dragging.disable();
  setSelectLayerType(layerType);
  // line 切換 style 後，先 rest 地圖狀態
  if (MapStatus.addType === 'line') clearLineEvent();
};

const getMapImgInfo = async (id) => {
  try {
    const map_png = await apiMapsPng(id);
    const imageURL = URL.createObjectURL(map_png.data);
    return new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('load', async () => {
        const updateMapData = {
          height: img.height,
          width: img.width,
          imageURL,
        };
        resolve(updateMapData);
      }, false);
      img.src = imageURL;
    });
  } catch (err) {
    console.log(err);
  }
};

// export const setConnectionMaps = () => {
//   const list = store.getState().mapSlice.points.filter((p) => p.type === 'ConnectionPoint');
//   const oldMaps = store.getState().mapSlice.points.filter((p) => p.type === 'ConnectMap');
//   const markers = [];
//   const map_ids = [...new Set(list.map((item) => item.connectMapData?.id))]; // list.map((item) => item.info?.id);
//   for (let i = 0; i < map_ids.length; i += 1) {
//     const id = map_ids[i];
//     const points = list.filter((item) => item.connectMapData?.id === id);
//     const row = points[0];
//     const connectMapInfo = row.connectMapData;
//     const oneWaySize = { // 兩圖比例尺換算，同步成一個比例尺，以底圖比例尺為準(resoultion)
//       height: connectMapInfo.connectMapHeight, // (connectMapInfo.height / rowConnectMap.resolution) * resolution,
//       width: connectMapInfo.connectMapWidth, // (connectMapInfo.width / rowConnectMap.resolution) * resolution,
//     };
//     const image = connectMapInfo.connectMapBlobUrl;

//     // // init ConnectMap
//     // const marker = createInitConnectMap(data, connectMapInfo.imageURL, oneWaySize)[0];
//     const connectMap = oldMaps.find((item) => item._id === row._idConnectMap);
//     const rotateLatLngX = {
//       lat: connectMap.lat,
//       lng: connectMap.lng,
//     }
//     const type = 'ConnectMap';
//     const options = createPointOption('ConnectMap', null, oneWaySize, image);
//     const marker = L.marker(rotateLatLngX, {
//       ...options,
//       id: null,
//       apiType: 'init',
//       undoLatLng: rotateLatLngX,
//       redoLatLng: rotateLatLngX,
//       toolTip: '',
//       order: '',
//       isEditToolTip: false,
//       isEditType: false,
//       undoToolTip: '',
//       redoToolTip: '',
//       undoType: type,
//       redoType: type,
//       rosAngle: connectMap.rosAngle,
//       undoRosAngle: connectMap.rosAngle,
//       redoRosAngle: connectMap.rosAngle,
//       originalLatLng: rotateLatLngX,
//       mapId: id,
//       height: oneWaySize.height,
//       width: oneWaySize.width,
//       mapBlobUrl: image,
//       zIndexOffset: 500,
//     });
//     marker.bindTooltip('', createToolTipOption('ParkingArea'));
//     rotatePoint(marker, connectMap.heading);
//     marker.addTo(MapStatus.map);

//     MapStatus.layerArr.connectMap.push(marker);
//     markers.push(marker);
//   };
//   return markers;
// }

export const setConnectionPoints = async (site_id, list, mapAngle, events, resolution = 0.05) => {
  const filterString = 'site_id=' + site_id;
  let mapList = [];
  try {
    mapList = await apiMaps(filterString);
  } catch (ex) {
    console.log(ex);
    console.log(resolution);
  }

  // init ConnectMap
  // const map_ids = list.map((item) => item.map_id);
  const map_ids = [...new Set(list.map((item) => item.info?.id))]; // list.map((item) => item.info?.id);
  for (let i = 0; i < map_ids.length; i += 1) {
    const id = map_ids[i];
    if (!id) return;
    const rowConnectMap = mapList.data.find((x) => x.id === id);
    const connectMapInfo = await getMapImgInfo(id);
    const oneWaySize = { // 兩圖比例尺換算，同步成一個比例尺，以底圖比例尺為準(resoultion)
      height: connectMapInfo.height, // (connectMapInfo.height / rowConnectMap.resolution) * resolution,
      width: connectMapInfo.width, // (connectMapInfo.width / rowConnectMap.resolution) * resolution,
    };

    const points = list.filter((item) => item.info?.id === id);
    const pointData = {
      ...points[0].info,
      point_name: '',
    };

    const {
      setLayerToolMode,
    } = events;

    const data = {
      pointData: [pointData],
      mapAngle,
      ...events,
    };

    // init ConnectMap
    const marker = createInitConnectMap(data, connectMapInfo.imageURL, oneWaySize)[0]; // setBaseToolMode, setCursor

    // init ConnectionPoint
    const reduxPoints = [];

    points.forEach((item) => {
      const connectMapDataRaw = {
        id,
        // name,
        connectMapHeight: connectMapInfo.height,
        connectMapWidth: connectMapInfo.width,
        connectMapBlobUrl: connectMapInfo.imageURL,
        ...rowConnectMap,
      };
      const row = {
        _idConnectMap: marker._leaflet_id, // pointId,
        connectMapData: connectMapDataRaw,
        ...item,
        connectionChannelId: item.info?.connection_channel_id,
      };
      reduxPoints.push(row);
    });
    initPointsByRedux({ points: reduxPoints, mapAngle, setLayerToolMode });
  }
  // 關閉 SelectItem
  store.dispatch(setCurrentGroupItem({}));
  store.dispatch(setCurrentItem({}));
  // 隱藏所有 ConnectMap
  hiddenAllConnectMap();
};
