import styled, { css } from 'styled-components';
import color from '@/color/theme';

export const CalendarStyle = css`
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 97;
    padding-top: 150px;

    p {
      margin-top: 10px;
      color: ${color.blue1};
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    z-index: 99;
  }

  .rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0; }

  button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer; }

  button[disabled].rbc-btn {
    cursor: not-allowed; }

  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0; }

  .rbc{
    &-calendar {
      box-sizing: border-box;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch; 
      /* min-height: calc(100vh - 160px); */

      @media screen and (max-width: 1124px) {
        margin: 0 20px!important;
      }
      *, *:before, *:after{
        box-sizing: inherit;
      }
    }
    &-abs-full, &-row-bg {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &-ellipsis, &-event-label, &-row-segment &-event-content, &-show-more {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-off-range { color: #999999; }
    &-row{
      &-content {
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        /* z-index: 4; */
        &-scrollable {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        &-scrollable, &-scroll-container{
          height: 100%;
          overflow-y: scroll;
          /* Hide scrollbar for Chrome, Safari and Opera */
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
          &::-webkit-scrollbar { display: none; }
        }
      }
    }
    &-rtl {
      direction: rtl;
      .rbc{

      }
      .rbc-header + .rbc-header {
        border-left-width: 0;
        border-right: 1px solid #DDD;
      }
    }
  }
  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px; }
    .rbc-toolbar .rbc-toolbar-label {
      flex-grow: 1;
      padding: 0 10px;
      text-align: center; }
    .rbc-toolbar button {
      color: #373a3c;
      display: inline-block;
      margin: 0;
      text-align: center;
      vertical-align: middle;
      background: none;
      background-image: none;
      border: 1px solid #ccc;
      padding: .375rem 1rem;
      border-radius: 4px;
      line-height: normal;
      white-space: nowrap; }
      .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
        background-image: none;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        background-color: #e6e6e6;
        border-color: #adadad; }
        .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
          color: #373a3c;
          background-color: #d4d4d4;
          border-color: #8c8c8c; }
      .rbc-toolbar button:focus {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad; }
      .rbc-toolbar button:hover {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad; }

  .rbc-btn-group {
    display: inline-block;
    white-space: nowrap; }
    .rbc-btn-group > button:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .rbc-btn-group > button:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .rbc-btn-group > button:not(:first-child):not(:last-child) {
      border-radius: 0; }
    .rbc-btn-group button + button {
      margin-left: -1px; }
    .rbc-rtl .rbc-btn-group button + button {
      margin-left: 0;
      margin-right: -1px; }
    .rbc-btn-group + .rbc-btn-group,
    .rbc-btn-group + button {
      margin-left: 10px; }

  .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left; }
    .rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
      cursor: inherit;
      pointer-events: none; }
    .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
      border-color: #265985; }
    .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
      outline: 5px auto #3b99fc; }

  .rbc-event-label {
    font-size: 80%; }

  .rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

  .rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

  .rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

  .rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

  .rbc-row {
    display: flex;
    flex-direction: row; }

  .rbc-row-segment {
    padding: 0 1px 1px 1px; }
  
  .rbc-row-segment .rbc-event-content {
    width: 100%;
  }

  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1); }

  .rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    text-decoration: none;
    text-align: left;
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    color: #006E94;
    height: auto;
    line-height: normal; }

  .rbc-month-view {
    background-color: #ffffff;
    position: relative;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%; }

  .rbc-month-header {
    display: flex;
    flex-direction: row; }

  .rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%; }
    .rbc-month-row + .rbc-month-row {
      border-top: 1px solid #DDD; }

    .rbc-date-cell.rbc-now {
      font-weight: bold; }
    .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
      color: inherit;
      text-decoration: none; }

  .rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden; }

  .rbc-day-bg {
    flex: 1 0 0%; }
    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-day-bg + .rbc-day-bg {
      border-left-width: 0;
      border-right: 1px solid #DDD; }

  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    margin: -200px 50px;
    padding: 10px; }
    .rbc-overlay > * + * {
      margin-top: 1px; }

  .rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px; }

  .rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto; }
    .rbc-agenda-view table.rbc-agenda-table {
      width: 100%;
      border: 1px solid #DDD;
      border-spacing: 0;
      border-collapse: collapse; }
      .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
        padding: 5px 10px;
        min-width: 300px;
      }
      .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
        padding-left: 15px;
        padding-right: 15px;
        text-transform: lowercase; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
        border-left-width: 0;
        border-right: 1px solid #DDD; }
      .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
        border-top: 1px solid #DDD; }
      .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        padding: 3px 10px;
        text-align: left;
        min-width: 300px;
        border-bottom: 1px solid #DDD;
        color: ${color.blue1};
        font: normal normal bold 16px Microsoft JhengHei;
        background-color: white;
        height: 40px;
        letter-spacing: 0px;}
        .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
          text-align: right; }

  .rbc-agenda-time-cell {
    text-transform: lowercase;
  }
    .rbc-agenda-time-cell .rbc-continues-after:after {
      content: ' »'; }
    .rbc-agenda-time-cell .rbc-continues-prior:before {
      content: '« '; }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    white-space: nowrap; }

  .rbc-agenda-date-cell {
    background-color: #ffffff;
  }
  .rbc-agenda-event-cell {
    width: 100%; }

  .rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    .rbc-time-column .rbc-timeslot-group {
      flex: 1; }

  .rbc-timeslot-group {
    border-bottom: 1px solid #DDD;
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap; }

  .rbc-time-gutter,
  .rbc-header-gutter {
    flex: none; }

  .rbc-day-slot {
    position: relative; }
    .rbc-day-slot .rbc-events-container {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
      .rbc-day-slot .rbc-events-container.rbc-rtl {
        left: 10px;
        right: 0; }
    .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
      /* border: 1px solid #265985; */
      display: flex;
      max-height: 100%;
      min-height: 20px;
      flex-flow: column wrap;
      align-items: flex-start;
      padding: 0;
      overflow: hidden;
      position: absolute;
      background: #999;
    }
    .rbc-day-slot .rbc-background-event {
      opacity: 0.75; }
    .rbc-day-slot .rbc-event-label {
      flex: none;
      padding-right: 5px;
      width: auto; }
    .rbc-day-slot .rbc-event-content {
      width: 100%;
      flex: 1 1 0;
      word-wrap: break-word;
      line-height: 1;
      height: 100%;
      min-height: 1em; 
    }
    .rbc-day-slot .rbc-time-slot {
      border-top: 1px solid #f7f7f7; }

  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #DDD;
    z-index: 10;
    margin-right: -1px; }

  .rbc-time-view-resources .rbc-time-header {
    overflow: hidden; }

  .rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px; }

  .rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none; }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: 140px; }

  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0 px; }

  .rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px; }

  .rbc-time-slot {
    flex: 1 0 0; }
    .rbc-time-slot.rbc-now {
      font-weight: bold; }

  .rbc-day-header {
    text-align: center; }

  .rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px; }

  .rbc-slot-selecting {
    cursor: move; }

  .rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid #DDD;
    background-color: #FFFFFF;
    }
    .rbc-time-view .rbc-time-gutter {
      white-space: nowrap; }
    .rbc-time-view .rbc-allday-cell {
      display: none;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      position: relative; }
    .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
      border-left: 1px solid #DDD; }
    .rbc-time-view .rbc-allday-events {
      position: relative;
      z-index: 4; }
    .rbc-time-view .rbc-row {
      box-sizing: border-box;
      min-height: 20px; }

  .rbc-time-header.rbc-overflowing { border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing { border-right-width: 0; border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child { border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource { border-bottom: 1px solid #DDD; }

  .rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid #DDD;
  }
  .rbc-allday-cell { display: none; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0;
  }

    scrollbar-width: none;
    .rbc-time-content > .rbc-time-gutter {
      flex: none; }
    .rbc-time-content > * + * > * {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-time-content > * + * > * {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-time-content > .rbc-day-slot {
      width: 100%;
      user-select: none;
      -webkit-user-select: none; }

  /* .rbc-time-content::-webkit-scrollbar {
    display: none;
  } */
  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #DB4242;
    pointer-events: none;
    height: 2px;

    &::before{
      content: '';
      background-color: #DB4242;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  .rbc-timeslot-group {
    min-height: 65px;
  }
  .rbc-time-slot:nth-child(2) {
    border-top: 1px solid #c8e4e8;
  }
  .rbc-event-label {
    display: none;
  }
  button.calendarBar {
    width: 72px;
    height: 36px;
    border: 0px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .calendarToolBar {
    display: flex;
    justify-content: space-between;

  }

  .toolbar-left {
    display: flex;
    margin-bottom: 20px;

    .toolbar-left-left {
      display: inline-flex;
      flex-wrap: nowrap;
      margin-right: 20px;
    }
    
    .toolbar-left-right {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      clear: both;
      border-top: solid 1px #dedede;
      border-bottom: solid 1px #dedede;
      border-radius: 20px;
      background-color: #DEF3FA;
  
      .toolbar-title {
        display: inline-block;
        font-size: 16px;
        color: #3C3C3C;
        width: 200px;
      }
    }
  }



  .toolbar-right {
    display: flex;
    margin-bottom: 20px;
    
    .toolbar-right-right {
      float: right;
      display: inline-flex;
      flex-wrap: nowrap;
    }
    .toolbar-right-left {
      display: flex;
      flex-wrap: nowrap;
      .siteSelect {
        padding: 8px 8px;
        width: 180px;
        min-width: 150px;
        max-width: 180px;
        height: 35px;
        color: #4D4D4D;
        font-size: 16px;
        margin-right: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: #fff;
      }
    }
  }

  .toolbar-newTask {
    float: right;
    margin: 30px 0px 10px 0px;
  }

  button.midBtn {
    width: 72px;
    font-size: 16px!important;
  }

  button.largeBtn {
    width: 84px;
    margin-right: 20px;
    font-size: 16px!important;
  }

  button.newTaskBtn {
    width: 120px;
    margin-left: 40px;
    background-color: #fff;
    font-size: 16px!important;
  }
  div.circle {
    font-size: 20px;
    margin: auto;
    margin-top: 4px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    color: black;
    text-align: center;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #D9D9D9;
  }
  .rbc-off-range-bg {
    background-color: #FFFFFF;
    z-index: 10;
    opacity: 0.7;
  }
  .calendarHeader-week {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    height: 80px;
    display: flex-start;
    text-align: left;
    font: normal normal bold 16px Arial;
    letter-spacing: 0px;
    color: #454545;
    .circle {
      margin: 10px;
      width: 56px;
      height: 56px;
      line-height: 56px;
      font-size: 24px;
      color: white;
      background: #D9D9D9 0% 0% no-repeat padding-box;

      @media screen and (max-width: 1124px) {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 16px;
      }
    }

    .monthText, .weekText {
      text-align: 'left';
      font-size: '16px';

      @media screen and (max-width: 1124px) {
        font-size: 13px;
      }
    }
  }
  .rbc-header.rbc-today > .rbc-button-link > .calendarHeader-week {
    color: #028BBA;
  }
  .rbc-header.rbc-today > .rbc-button-link > .calendarHeader-week > .circle {
    border: 1px solid #006e94;
    background: #028BBA 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  .circle.current {
    border: 1px solid #006e94;
    background: #028BBA 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  .calendarHeader-day {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    height: 80px;
    color: #028BBA;
    font-size: 16px;
    .circle {
      margin: 10px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      color: white;
      border: 1px solid #006e94;
      background: #028BBA 0% 0% no-repeat padding-box;
      font-weight: bold;
    }
  }
  .showMoreDiv {
    background-color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 20px;
  }
  .monthDateHeader {
    border-bottom: solid 0px gray;
    height: 44px;
  }
  .eventBulb.expired {
    color: #1DBE00;
  }
  .eventBulb.stop {
    color: red;
  }
  .rbc-event {
    /* padding: 2px 5px; */
    line-height: 1.5;
  }
  .react-datepicker__tab-loop {
    z-index: 15;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: none;
    .rbc-time-header-content {
      margin-right: -20px;
    }
  }
  .rbc-header > a {
    cursor: default;
    pointer-events: none;        
    text-decoration: none;
    color: grey;
  }
  .react-datepicker__navigation-icon {
    color: black!important;
    border-color: black!important;
    border-style: solid;
    border-width: 3px 3px 0 0;
    display: block;
    height: 9px;
    position: absolute;
    top: 10px;
    width: 9px;
    transform: rotate(225deg);
    right: 8px;
  }
  .react-datepicker__navigation-icon--next {
    transform: rotate(45deg);
    left: 8px;
  }
  .react-datepicker__navigation-icon::before {
    content:none!important;
  }
  .react-datepicker__navigation-icon::after {
    content:none!important;
  }
  .calendar{
    &ToolBar{
      .MuiButton-root{
        background-color: #fff;
        
        &.selected {
          color: #fff;
          background-color: ${color.blue1};
        }
      }
    }
  }
`;

const CalendarCustomStyle = css`
  .rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #DDD;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 320px);
  }
  .rbc{
    &-header {
      overflow: hidden;
      flex: 1 0 0%;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      font-size: 90%;
      min-height: 0;
      border-bottom: 1px solid #DDD;
      + .rbc-header {
        border-left: 1px solid #DDD;
      }
      > a, > a:active, > a:visited{
        color: inherit;
        text-decoration: none;
      }
    }
  }
  .calendarHeader-month {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    font-size: 16px;
  }
  .rbc-time-header-cell-single-day > .rbc-header, .rbc-time-header-cell > .rbc-header {
    border-bottom: 0px;
  }
  .rbc-button-link {
    display: flex;
    background: none;
    margin-right: auto;
    padding: 0;
    border: none;
    cursor: pointer;
    user-select: text;
  }
  .rbc-label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px !important;
    height: 100%;
    font-size: 14px;
  }
  .rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
  }
  .rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    padding-right: 20px;
  }
`;

export const TaskStyle = css`
  padding: 110px 60px 60px 60px;
  min-height: 100vh;
`;

export const TaskContainer = styled.div`
  ${TaskStyle}
  ${CalendarStyle}
  ${CalendarCustomStyle}
`;

export const TaskPopoverContainer = styled.div`
  width: 400px;
  min-height: 300px;
  ${'' /* max-height: 400px; */}
  display: flex;
  flex-direction: column;
  background: #F4FBFC;
  border-radius: 8px;
  overflow: hidden;

  .header {
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    background-color: #006E94;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
  }

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 16px;
  }

  .body {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px 40px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .footer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    gap: 16px;
  }

.popover{
  &Body{
    /* min-height: 230px; */
    max-height: 300px;
    overflow: auto;
    padding: 20px 30px;
  }
  &List{
    list-style: none;
    &Item{
      display: flex;
      align-items: flex-start;
      color: #454545;
      margin-bottom: 15px;

      figure{
        margin-right: 10px;
      }
      article {
        flex: 1
      }
      &-icon{
        color: #727272;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin: 5px 0;

        svg{
          margin-right: 5px;
        }
      }
      &-small{
        display: block;
        color: #888;
        font-size: 12px;
      }
    }
  }
}
`;
