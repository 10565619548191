import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  playList: [],
  media: [],
  isReady: false,
  templates: [],
  site: [],
};

const initLengthFormat = (hasLength) => {
  const newFormat = {
    videoHours: '00',
    videoMinutes: '00',
    videoSecond: '00',
  };

  if (hasLength) {
    const date = new Date(0);
    date.setSeconds(hasLength);
    const lengthFormat = moment.utc(date);
    newFormat.videoHours = lengthFormat.format('HH');
    newFormat.videoMinutes = lengthFormat.format('mm');
    newFormat.videoSecond = lengthFormat.format('ss');
  }
  return newFormat;
};

const initPlaylistObject = (playlist, initChecked = true) => {
  const newObjectValue = {
    ...playlist,
    humstartdate: playlist?.start_datetime ? moment.unix(playlist.start_datetime).format('YYYY/MM/DD') : '',
    humenddate: playlist?.end_datetime ? moment.unix(playlist.end_datetime).format('YYYY/MM/DD') : '',
    videos_failed: playlist?.videos ? playlist.videos.filter((item) => item.upload_status === 'failed').length : 0,
    ...initLengthFormat(playlist?.playback_length || false),
  };
  if (initChecked) {
    newObjectValue.checked = false;
  }
  return newObjectValue;
};

const initMedias = (media) => {
  const newObjectValue = {
    ...media,
    checked: false,
    customer: media.customer || '',
    ...initLengthFormat(media?.length || false),
  };
  return newObjectValue;
};

export const playlistSlice = createSlice({
  name: 'playlistSlice',
  initialState,
  reducers: {
    initPlaylist: (state, action) => {
      state.playList = action.payload.data.map((object) => initPlaylistObject(object));
    },
    setPlaylists: (state, action) => {
      state.playList = action.payload.data;
    },
    updatePlaylist: (state, action) => {
      const { id, ...payload } = action.payload.data;
      const index = state.playList.findIndex((obj) => obj.id === id);
      state.playList[index] = { ...state.playList[index], ...initPlaylistObject(payload, false) };
    },
    updateMultiPlaylist: (state, action) => {
      action.payload.data.forEach(({ id, ...payload }) => {
        const index = state.playList.findIndex((obj) => obj.id === id);
        state.playList[index] = { ...state.playList[index], ...initPlaylistObject(payload) };
      });
    },
    updatePlaylistMedia: (state, action) => {
      action.payload.playlist.forEach((x) => {
        const index = state.playList.findIndex((obj) => obj.id === x.id);
        state.playList[index].videos.forEach((video) => {
          if (video.id === action.payload.id) video.name = action.payload.mediaName;
        });
      });
    },
    insertPlaylist: (state, action) => {
      state.playList = [
        ...state.playList,
        initPlaylistObject(action.payload.data),
      ];
    },
    deletePlaylist: (state, action) => {
      state.playList = state.playList.filter((elem) => action.payload.every((ele) => ele.id !== elem.id));
    },
    initMedia: (state, action) => {
      state.media = action.payload.data.map((object) => initMedias(object));
    },
    setMedias: (state, action) => {
      state.media = action.payload.data;
    },
    updateMedia: (state, action) => {
      const index = state.media.findIndex((obj) => obj.id === action.payload.data.id);
      state.media[index] = { ...state.media[index], ...action.payload.data };
    },
    insertMedia: (state, action) => {
      state.media = [
        ...state.media,
        initMedias(action.payload.data),
      ];
    },
    setReady: (state, action) => {
      state.isReady = action.payload.isReady;
    },
    initTemplates: (state, action) => {
      state.templates = action.payload;
    },
    deleteTemplate: (state, action) => {
      state.templates = state.templates.filter((elem) => elem.template_id !== action.payload);
    },
    initSite: (state, action) => {
      state.site = action.payload.data;
    },
  },
});

export const {
  initPlaylist,
  setPlaylists,
  updatePlaylist,
  updateMultiPlaylist,
  updatePlaylistMedia,
  insertPlaylist,
  deletePlaylist,
  initMedia,
  setMedias,
  updateMedia,
  insertMedia,
  setReady,
  initTemplates,
  deleteTemplate,
  initSite,
} = playlistSlice.actions;

export default playlistSlice.reducer;
