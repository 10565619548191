import { createSlice } from '@reduxjs/toolkit';
import { getElevatorInsideEnter } from '@/components/MapLeaflet/Utils.js';

/**
 *
 * polygons:[{
 *  id: 'xxx', // uuid
 *  name,
 *  apiType, // 作為 call api 時對應需要的行為,type 分別有 init, post, put, delete
 *  ...data }]
 *
 * points:[{
 *  _id: 'xxx', // _leaflet_id
 *  type,
 *  id, // data id (若是 uuid 表示該點尚未新增，若是 number 表示從 GET api 獲得)
 *  rosAngle,
 *  lat,
 *  lng,
 *  groupID, // 如果是充電點或駐停點，則需 groupID
 *  tmpId,   // 若是 uuid 則為新增的 point，若是 null 則為既成 point (init point)
 *  ...data }]
 *
 * modal: {
 *  modalOpen: false,
 *  title: '',
 *  message: '' ,
 *  layerType: '', IotGate, NarrowGate ...
 *  type: '', // error , success
 * }
 *
 * groups:[{
 *  id: '',
 *  name,
 *  type,    // 作為辨別充電站或駐停區的 type
 *  apiType, // 作為 call api 時對應需要的行為,type 分別有 init, post, put, delete
 *  ...data
 * }]
 *
 * 後續控制開關狀態使用
 * tools: {
 *  showMapArea: false // 控制顯示地圖區塊
 *  settingMapArea: false //控制設定地圖區塊
 *  positionX: 0, // 儲存Modal顯示位置(避免擋住 flag)
 * }
 * 上下步儲存異動值
 * steps: [{
 *  id // uuid/number Redux points polygon id
 *  groupID // uuid/number/null
 *  actionType: 'add' // ex: add, edit, move, delete ...
 *  layerType: 'iotGate' // layerType: Elevator, iotGate ...
 *  payload: {} // 修改值
 * }]
 * currentStep: 0 // number
 */
const initialState = {
  points: [],
  polylines: [],
  polygons: [],
  rectangles: [],
  groups: [], // point 的 group 資料
  robots: [],
  currentItem: {},
  currentGroupItem: {},
  IOChannelType: [], // 先將API上的所有IOChannel存到Redux
  IOChannel: [], // 該site的IOChannel
  currentIOChannelModal: { open: false, targetType: '', modalType: '' },
  currentChargerModal: { open: false, targetType: '', modalType: '' },
  robotLocationModal: { open: false },
  elevatorList: [],
  currentMapId: null,
  mapInfo: {},
  openIOChannelListModal: false,
  tools: {
    addOnly: false, // true:只能新增layer
    showMapArea: false, // 顯示/隱藏地圖區塊
    settingMapArea: false, // 開啟/關閉設定地圖區塊
    positionX: 0, // 儲存Modal顯示位置(避免擋住 flag)
    UVCZoneListOpen: false, // 開/關 UVC排序列表
    mouseCursor: '', // 儲存當前滑鼠樣式
    mapBlobUrl: '', // 儲存 客戶端地圖網址
    // connectMapBlobUrl: '', // 儲存 連通地圖網址
  },
  dialog: {
    open: false,
    title: '',
    message: '',
    layerType: '',
    type: '',
  },
  steps: [],
  mapRotateAngle: 0, // 地圖旋轉角度
  currentStep: 0, // 紀錄當前步驟的索引 ex: 1 2 3 ...
  notifications: [], // 用於顯示 Snackbar 的訊息使用
  parkingAreaInitData: [], // 儲存完後駐停區的 init data
  parkingPointInitData: [], // 儲存完後駐停區的 point init data
  connectMap: {
    connectMapBlobUrl: '', // 儲存 連通地圖網址
    connectMapTable: [], // 儲存 連通地圖列表
    connectMapData: {}, // 儲存 連通地圖原始資料
    connectMapHeight: 0, // 儲存 連通地圖高度
    connectMapWidth: 0, // 儲存 連通地圖寬度
    connectMapModalOpen: false,
    connectMapId: null, // 儲存 目前連通地圖ID
    // connectMapName: '', // 儲存 目前連通地圖名稱
    originMapId: null, // 儲存 目前原始地圖ID
    pointId: null, // 對應到 point的 point Id，目前是ConnetMap的"中心點"
  },
};

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setOpenIOChannelListModal: (state, action) => {
      state.openIOChannelListModal = action.payload;
    },
    setIOChannelType: (state, action) => {
      state.IOChannelType = [...action.payload.list];
    },
    setIOChannel: (state, action) => {
      state.IOChannel = [...action.payload.list];
    },
    setCurrentIOChannelModal: (state, action) => {
      state.currentIOChannelModal = { ...action.payload };
    },
    setCurrentChargerModal: (state, action) => {
      state.currentChargerModal = { ...action.payload };
    },
    setRobotLocationModal: (state, action) => {
      state.robotLocationModal = { ...action.payload };
    },
    setCurrentMapId: (state, action) => {
      state.currentMapId = action.payload.map;
    },
    setMapInfo: (state, action) => {
      state.mapInfo = { ...action.payload };
      state.currentMapId = action.payload.map_id;
    },
    // robot reducers
    addRobot: (state, action) => {
      state.robots = [...action.payload.list.map((item) => ({ ...item, originChargingArea: item.chargingarea_group, apiType: 'init' }))];
    },
    updateRobot: (state, action) => {
      state.robots = state.robots.map((el) => {
        if (el.id === action.payload.id) {
          return { ...el, apiType: 'put', ...action.payload };
        }
        return el;
      });
    },
    // point reducers
    addPoint: (state, action) => {
      let extraData = {};
      if (action.payload.type === 'Elevator') {
        extraData = getElevatorInsideEnter(action.payload);
      }
      state.points.push({ ...action.payload, ...extraData });
    },
    updatePoint: (state, action) => {
      state.points = state.points.map((el) => {
        if (el._id === action.payload._id) {
          const currentData = { ...el, ...action.payload };
          let extraData = {};
          if (el.type === 'Elevator') {
            extraData = getElevatorInsideEnter(currentData);
          }
          return { ...currentData, ...extraData };
        }
        return el;
      });
    },
    updatePositionX: (state, action) => {
      state.tools.positionX = action.payload;
    },
    toggleShowMapArea: (state, action) => {
      state.tools.showMapArea = action.payload;
    },
    toggleSettingMapArea: (state, action) => {
      state.tools.settingMapArea = action.payload;
    },
    setCurrentItem: (state, action) => {
      state.currentItem = {
        _id: action.payload._id,
        type: action.payload.type,
        mode: action.payload.mode,
        nodeArr: action.payload.nodeArr,
      };
    },
    deletePoint: (state, action) => {
      state.points = state.points.filter((x) => x._id !== action.payload._id);
      if (state.currentItem._id === action.payload._id) {
        state.currentItem = {};
      }
    },
    deletePointByGroupId: (state, action) => {
      state.points = state.points.filter((x) => x.groupID !== action.payload.id);
    },
    deletePointsByType: (state, action) => {
      const { type } = action.payload;
      if (type === 'all') {
        state.points = [];
        state.currentItem = {};
      } else {
        state.points = state.points.filter((x) => x.type !== action.payload.type);
        if (state.currentItem.type === type) {
          state.currentItem = {};
        }
      }
    },

    // groups reducers
    addGroup: (state, action) => {
      state.groups.push({ ...action.payload });
    },
    updateGroup: (state, action) => {
      switch (action.payload.type) {
        case 'name':
          state.groups = state.groups.map((el) => {
            if (el.id === action.payload.id) {
              el.name = action.payload.value;
              if (el.apiType === 'init') el.apiType = 'put';
            }
            return el;
          });
          break;
        case 'target': // select 下拉更改駐停區的綁定目標
          state.groups = state.groups.map((el) => {
            if (el.id === action.payload.id) {
              el[action.payload.targetType] = action.payload.value;
              if (el.apiType === 'init') el.apiType = 'put';
            }
            return el;
          });
          break;
        case 'modifyTarget': // 綁定目標 type 變更時，更新駐停區的綁定目標資料
          const { targetID, fromType, toType } = action.payload;
          for (let i = 0; i < state.groups.length; i += 1) {
            if (state.groups[i][fromType]?.includes(targetID)) {
              state.groups[i][fromType] = state.groups[i][fromType].filter((item) => item !== targetID);
              state.groups[i][toType].push(targetID);
              if (state.groups[i].apiType === 'init') state.groups[i].apiType = 'put';
            }
          }
          break;
        case 'iterateNestedObjects':
          state.groups = state.groups.map((el) => {
            if (el.id === action.payload.id) {
              const payload = { ...action.payload };
              delete payload.id;
              delete payload.type;
              const elValue = { ...el, ...payload };
              if (elValue.apiType === 'init') elValue.apiType = 'put';
              return elValue;
            }
            return el;
          });
          break;
        case 'touch':
          const index = state.groups.findIndex((g) => g.id === action.payload.id);
          state.groups[index].apiType = 'put';
          break;
        default:
          break;
      }
    },
    replaceGroupId: (state, action) => {
      const { uuid, apiId } = action.payload;
      const groupIndex = state.groups.findIndex((g) => g.id === uuid);
      state.groups[groupIndex].id = apiId;
      for (let i = 0; i < state.points.length; i += 1) {
        if (state.points[i].groupID === uuid) {
          state.points[i].groupID = apiId;
        }
      }
    },
    deleteGroup: (state, action) => {
      const targetIndex = state.groups.findIndex((el) => el.id === action.payload.id && el.apiType !== 'delete');
      if (state.groups[targetIndex].apiType === 'post') {
        state.groups.splice(targetIndex, 1);
      } else {
        state.groups[targetIndex].apiType = 'delete';
      }
    },
    clearGroups: (state) => {
      state.groups = [];
    },
    deleteGroupsByType: (state, action) => {
      const { type } = action.payload;
      if (type === 'all') {
        state.groups = [];
        state.currentGroupItem = {};
      } else {
        state.groups = state.groups.filter((item) => item.type !== action.payload.type);
        if (state.currentGroupItem.type === type) {
          state.currentGroupItem = {};
        }
      }
    },
    deleteParkingBindingTarget: (state, action) => {
      const { targetId, targetType } = action.payload;
      for (let i = 0; i < state.groups.length; i += 1) {
        if (state.groups[i][targetType]?.includes(targetId)) {
          state.groups[i][targetType] = state.groups[i][targetType].filter((item) => item !== targetId);
        }
      }
    },
    setCurrentGroupItem: (state, action) => {
      state.currentGroupItem = {
        id: action.payload.id,
        type: action.payload.type,
        mode: action.payload.mode,
      };
    },
    setElevatorList: (state, action) => {
      state.elevatorList = action.payload.data;
    },
    addParkingPointInitData: (state, action) => {
      state.parkingPointInitData = action.payload;
    },
    clearParkingPointInitData: (state) => {
      state.parkingPointInitData = [];
    },
    addParkingAreaInitData: (state, action) => {
      state.parkingAreaInitData = action.payload;
    },
    clearParkingAreaInitData: (state) => {
      state.parkingAreaInitData = [];
    },
    // polyline reducers
    addPolyline: (state, action) => {
      state.polylines.push({ ...action.payload });
    },
    updatePolyline: (state, action) => {
      state.polylines = state.polylines.map((el) => {
        if (el._id === action.payload._id) {
          return { ...el, ...action.payload };
        }
        return el;
      });
    },
    updatePolylineById: (state, action) => {
      const targetIndex = state.polylines.findIndex((item) => item.id === action.payload.id);
      state.polylines[targetIndex] = { ...state.polylines[targetIndex], ...action.payload };
    },
    deletePolyline: (state, action) => {
      state.polylines = state.polylines.filter((x) => x._id !== action.payload._id);
      if (state.currentItem._id === action.payload._id) {
        state.currentItem = {};
      }
    },
    deletePolylineByType: (state, action) => {
      const { type } = action.payload;
      if (type === 'all') {
        state.polylines = [];
        state.currentItem = {};
      } else {
        state.polylines = state.polylines.filter((x) => x.type !== action.payload.type);
        if (state.currentItem.type === type) {
          state.currentItem = {};
        }
      }
    },
    deletePolylineByGroupId: (state, action) => {
      state.polylines = state.polylines.filter((x) => x.groupID !== action.payload.id);
    },
    // polygon reducers
    addPolygon: (state, action) => {
      state.polygons.push({ ...action.payload });
    },
    updatePolygon: (state, action) => {
      state.polygons = state.polygons.map((el) => {
        if (el._id === action.payload._id) {
          return { ...el, ...action.payload };
        }
        return el;
      });
    },
    updatePolygonById: (state, action) => {
      state.polygons = state.polygons.map((el) => {
        if (el.id === action.payload.id) {
          return { ...el, ...action.payload };
        }
        return el;
      });
    },
    deletePolygon: (state, action) => {
      const targetIndex = state.polygons.findIndex((el) => el._id === action.payload._id);
      state.polygons.splice(targetIndex, 1);
      if (state.currentItem._id === action.payload._id) {
        state.currentItem = {};
      }
    },
    deletePolygonById: (state, action) => {
      const targetIndex = state.polygons.findIndex((el) => el.id === action.payload.id);
      if (state.polygons[targetIndex].apiType === 'post') {
        state.polygons.splice(targetIndex, 1);
      } else {
        state.polygons[targetIndex].apiType = 'delete';
      }
      if (state.currentItem.id === action.payload.id) {
        state.currentItem = {};
      }
    },
    deletePolygonByType: (state, action) => {
      const { type } = action.payload;
      if (type === 'all') {
        state.polygons = [];
        state.currentItem = {};
      } else {
        state.polygons = state.polygons.filter((x) => x.type !== action.payload.type);
        if (state.currentItem.type === type) {
          state.currentItem = {};
        }
      }
    },
    deletePolygonByGroupId: (state, action) => {
      state.polygons = state.polygons.filter((x) => x.groupID !== action.payload.id);
    },
    // rectangle reducers
    addRectangle: (state, action) => {
      state.rectangles.push({ ...action.payload });
    },
    updateRectangle: (state, action) => {
      const { _id, ...payload } = action.payload;
      const index = state.rectangles.findIndex((rect) => rect._id === _id);
      state.rectangles[index] = { ...state.rectangles[index], ...payload };
    },
    updateRectangleById: (state, action) => {
      const { id, ...payload } = action.payload;
      const index = state.rectangles.findIndex((rect) => rect.id === id);
      state.rectangles[index] = { ...state.rectangles[index], ...payload };
    },
    deleteRectangle: (state, action) => {
      const index = state.rectangles.findIndex((rect) => rect._id === action.payload._id);
      state.rectangles.splice(index, 1);
      if (state.currentItem._id === action.payload._id) {
        state.currentItem = {};
      }
    },
    deleteRectangleByType: (state, action) => {
      const { type } = action.payload;
      if (type === 'all') {
        state.rectangles = [];
        state.currentItem = {};
      } else {
        state.rectangles = state.rectangles.filter((rect) => rect.type !== type);
        if (state.currentItem.type === type) {
          state.currentItem = {};
        }
      }
    },
    addNotifications: (state, action) => {
      let id = 0;
      if (state.notifications.length !== 0) id = state.notifications[state.notifications.length - 1].id + 1;
      state.notifications.push({ vertical: 'top', horizontal: 'right', ...action.payload, id });
    },
    deleteNotifications: (state, action) => {
      state.notifications = state.notifications.filter((item) => item.id !== action.payload);
    },
    updateStepIndex: (state, action) => {
      const { type } = action.payload;
      switch (type) {
        case 'undo':
          state.currentStep -= 1;
          break;
        case 'redo':
          state.currentStep += 1;
          break;
        default:
          break;
      }
    },
    initStep: (state) => {
      state.currentStep = 0;
      state.steps = [];
    },
    addSteps: (state, action) => {
      state.steps = state.steps.slice(0, state.currentStep);
      state.steps.push({ ...action.payload });
      state.currentStep = state.steps.length;
    },
    updateSteps: (state, action) => {
      const targetIndex = action.payload.index;
      state.steps[targetIndex] = { ...state.steps[targetIndex], ...action.payload.data };
    },
    updateMapRotateAngle: (state, action) => {
      state.mapRotateAngle = action.payload;
    },
    updateDialog: (state, action) => {
      state.dialog = { ...state.dialog, ...action.payload };
    },
    setUVCZoneListState: (state, action) => {
      state.tools.UVCZoneListOpen = action.payload;
    },
    resetMapSlice: (state) => {
      Object.keys(initialState).forEach((key) => {
        const value = initialState[key];
        state[key] = value;
      });
    },
    updateAddOnly: (state, action) => {
      state.tools.addOnly = action.payload;
    },
    updateMouseCursor: (state, action) => {
      state.tools.mouseCursor = action.payload;
    },
    setMapBlobUrl: (state, action) => {
      state.tools.mapBlobUrl = action.payload;
    },
    setConnectMap: (state, action) => {
      state.connectMap = action.payload;
    },
    updateConnectMap: (state, action) => {
      const { id, connectMapHeight, connectMapWidth, connectMapBlobUrl } = action.payload.connectMapData;
      const exist = state.connectMap.connectMapTable.find((x) => x.id === id);
      const connectMapTable = [...state.connectMap.connectMapTable];
      if (!exist) {
        connectMapTable.push({ ...action.payload.connectMapData });
      }
      state.connectMap = { ...action.payload, connectMapTable, connectMapHeight, connectMapWidth, connectMapBlobUrl };
    },
    updateConnectMapPointId: (state, action) => {
      state.connectMap.pointId = action.payload.id;
    },
    switchConnectMapById: (state, action) => {
      const { connectMapId } = action.payload;
      const row = state.connectMap.connectMapTable.find((x) => x.id === connectMapId);
      if (row) {
        const updateRow = { ...state.connectMap };
        updateRow.connectMapId = connectMapId;
        updateRow.connectMapHeight = row.connectMapHeight;
        updateRow.connectMapWidth = row.connectMapWidth;
      }
    },
    clearConnectMap: (state) => {
      const updateRow = { ...state.connectMap };
      updateRow.connectMapBlobUrl = '';
      updateRow.connectMapData = {};
      updateRow.connectMapHeight = 0;
      updateRow.connectMapWidth = 0;
      updateRow.connectMapModalOpen = false;
      updateRow.connectMapId = null;
      updateRow.pointId = null;
      state.connectMap = updateRow;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addPoint,
  updatePoint,
  addRobot,
  updateRobot,
  setCurrentItem,
  deletePoint,
  deletePointByGroupId,
  deletePointsByType,
  addGroup,
  updateGroup,
  replaceGroupId,
  deleteGroup,
  clearGroups,
  deleteGroupsByType,
  deleteParkingBindingTarget,
  setCurrentGroupItem,
  addParkingPointInitData,
  clearParkingPointInitData,
  addParkingAreaInitData,
  clearParkingAreaInitData,
  setIOChannelType,
  setIOChannel,
  addPolyline,
  updatePolyline,
  updatePolylineById,
  deletePolyline,
  deletePolylineByType,
  deletePolygonByType,
  deletePolylineByGroupId,
  setElevatorList,
  setCurrentMapId,
  setMapInfo,
  addPolygon,
  updatePolygon,
  updatePolygonById,
  deletePolygon,
  deletePolygonById,
  deletePolygonByGroupId,
  addRectangle,
  updateRectangle,
  updateRectangleById,
  deleteRectangle,
  deleteRectangleByType,
  setCurrentIOChannelModal,
  setCurrentChargerModal,
  setRobotLocationModal,
  setOpenIOChannelListModal,
  toggleSettingMapArea,
  toggleShowMapArea,
  updatePositionX,
  addNotifications,
  deleteNotifications,
  updateStepIndex,
  addSteps,
  updateSteps,
  initStep,
  updateMapRotateAngle,
  updateDialog,
  setUVCZoneListState,
  resetMapSlice,
  updateAddOnly,
  updateMouseCursor,
  setMapBlobUrl,
  setConnectMap,
  updateConnectMap,
  switchConnectMapById,
  updateConnectMapPointId,
  clearConnectMap,
} = mapSlice.actions;

export default mapSlice.reducer;
