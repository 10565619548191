import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import theme from '@/color/theme';

const Button = withStyles({
  root: {
    height: 36,
    '&:hover': {
      backgroundColor: `${theme.blue3} !important`,
      color: '#fff !important',
    },
    '&:active': {
      backgroundColor: '#005b7a',
    },
  },
  contained: {
    backgroundColor: theme.blue1,
    color: '#fff',
  },
  outlined: {
    backgroundColor: '#fff',
    color: theme.blue1,

    '&:hover': {
      color: '#fff',
      outline: 'none',
    },
    '&:active': {
      color: '#fff',
      outline: 'none',
    },
  },
  disabled: {
    outline: `1px solid ${theme.gray4}`,
  },
})(MuiButton);

const AmrButton = (props) => {
  const { color, disabled, onClick, style, children, startIcon, endIcon } = props;

  return (
    <Button
      variant={(color === 'primary' ? 'contained' : 'outlined')}
      disableElevation
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
};

AmrButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'default']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

AmrButton.defaultProps = {
  color: 'default',
  disabled: false,
  onClick: () => { },
  style: {},
  startIcon: null,
  endIcon: null,
};

export default AmrButton;
