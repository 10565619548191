import { makeStyles } from '@material-ui/core';
import color from '@/color/theme.js';

export const GetModalStyle = () => ({
  top: '20%',
  left: '50%',
  transform: 'translateX(-50%)',
  border: 'none',
  borderRadius: '10px',
});

export const ModalBodyStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '600px',
    backgroundColor: color.white1,
    padding: '0, 0',
  },
  body: {
    minHeight: '220px',
    lineHeight: '220px',
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
  },
}));

export const NoTitleModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: color.white1,
    padding: '0, 0',
    borderRadius: '8px',
  },
  body: {
    minHeight: '244px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  img: {
    textAlign: 'center',
    minHeight: '86px',
    lineHeight: '86px',
  },
  footer: {
    textAlign: 'center',
    minHeight: '80px',
    lineHeight: '80px',
  },
}));

export const BasicModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '600px',
    backgroundColor: color.white1,
    padding: '0, 0',
  },
  body: {
    minHeight: '220px',
    lineHeight: '220px',
    height: 'auto',
    textAlign: 'center',
    padding: '24px 24px',
  },
  title: {
    textAlign: 'center',
    height: '64px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
  },
  cancelBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    color: color.gray2,
    fontSize: '14px',
    cursor: 'pointer',
  },
  confirmBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));

export const fullHeightModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '600px',
    backgroundColor: color.white1,
    padding: '0, 0',
  },
  body: {
    minHeight: '624px',
    height: 'auto',
    textAlign: 'center',
    padding: '24px, 24px',
  },
  title: {
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
  },
  cancelBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    color: color.gray2,
    fontSize: '14px',
    cursor: 'pointer',
  },
  confirmBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
  confirmBtnDisabled: {
    width: '88px',
    height: '36px',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    opacity: 0.8,
  },
}));

export const confirmModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: color.white1,
    padding: '0, 0',
  },
  body: {
    minHeight: '240px',
    height: 'auto',
    textAlign: 'center',
    padding: '24px 24px',
  },
  img: {
    display: 'block',
    margin: '0 auto',
    width: '86px',
    height: '86px',
    marginTop: '8px',
  },
  title: {
    color: color.gray2,
    fontSize: '20px',
    marginTop: '33px',
  },
  text: {
    color: color.gray2,
    fontSize: '14px',
    opacity: '0.6',
    marginTop: '8px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
  },
  cancelBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    color: color.gray2,
    fontSize: '14px',
    cursor: 'pointer',
  },
  confirmBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));

export const elevatorMesModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    minWidth: '600px',
    backgroundColor: color.white1,
    padding: '0, 0',
    top: '50%!important',
    left: '50%!important',
    transform: 'translate(-50%, -50%)!important',
  },
  title: {
    textAlign: 'center',
    height: '65px',
    lineHeight: '65px',
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    position: 'relative',
  },
  search: {
    textAlign: 'center',
    height: '50px',
    lineHeight: '43px',
    fontSize: '21px',
    fontWeight: 'bold',
    backgroundColor: '#F4FBFC',
    border: '1px solid #CADDE3',
    position: 'relative',
  },
  body: {
    minHeight: '560px',
    height: 'auto',
    textAlign: 'center',
    overflowY: 'auto',
  },
  input: {
    width: '553px',
    height: '36px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    paddingLeft: '12px',
  },
  searchIcon: {
    position: 'absolute',
    top: '55%',
    right: '30px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  elevatorItem: {
    height: '50px',
    lineHeight: '50px',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    textAlign: 'left',
    padding: '2px 24px',
    '& button': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: '#E9E9E9',
      '& button': {
        display: 'inline-block',
      },
    },
  },
  elevatorBtn: {
    width: '68px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '16px',
    float: 'right',
    marginTop: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#006E94',
      color: '#fff',
    },
  },
  closeBtn: {
    position: 'absolute',
    top: '50%',
    right: '28px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  deleteModal: {
    width: '400px',
    height: '300px',
    position: 'fixed',
    zIndex: '999999',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: color.white1,
    borderRadius: '10px',
  },
  deleteBody: {
    minHeight: '155px',
    height: 'auto',
    textAlign: 'center',
    padding: '24px 24px',
  },
  deleteFooter: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
    border: '1px solid #EEEEEE',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  deleteTitle: {
    fontWeight: 'bold',
  },
  deleteText: {
    padding: '10px 40px 0 40px',
  },
  cancelBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    color: color.gray2,
    fontSize: '14px',
    cursor: 'pointer',
  },
  confirmBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: color.blue1,
    border: 'none',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
  dNone: {
    display: 'none!important',
  },
  disable: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: '9',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
  },
  titleDisable: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    zIndex: '9',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  editInput: {
    width: '300px',
    height: '36px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    paddingLeft: '12px',
    position: 'absolute',
    top: '50%',
    left: '15px',
    transform: 'translateY(-50%)',
    display: 'none',
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
  },
  backBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    color: '#fff',
    display: 'flex',
  },
  backText: {
    display: 'inline-block',
    width: '40px',
    height: '20px',
    paddingTop: '2px',
  },
  viewTitle: {
    padding: '5px 0',
    border: '1px solid #D9D9D9',
  },
  floorTag: {
    width: 'auto',
    height: '30px',
    lineHeight: '30px',
    backgroundColor: '#E8F8FA',
    padding: '0px 15px',
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '5px',
    transform: 'translateY(-50%)',
  },
  mapImg: {
    width: '55px',
    height: '40px',
    border: '1px solid #B9B9B9',
    position: 'absolute',
    top: '50%',
    left: '75px',
    transform: 'translateY(-50%)',
  },
  mpaName: {
    width: 'auto',
    position: 'absolute',
    top: '50%',
    left: '145px',
    transform: 'translateY(-50%)',
  },
}));

export const modalWithObjectStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    minWidth: '400px',
    backgroundColor: color.white1,
    padding: '0, 0',
    outline: 'none',
  },
  title: {
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  body: {
    minHeight: '220px',
    height: 'auto',
    textAlign: 'center',
    padding: '45px 24px',
  },
  elevatorTitle: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#454545',
  },
  elevatorText: {
    textAlign: 'center',
    fontSize: '16px',
    color: '#454545',
    width: '269px',
    margin: '0 auto',
  },
  siteSearch: {
    minHeight: '36px',
    height: 'auto',
    textAlign: 'center',
    padding: '6px 24px',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    marginTop: '8px',
    marginBottom: '16px',
  },
  selectInput: {
    width: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    color: '#000',
    fontSize: '16px',
    padding: '0 8px',
    '&:focus': {
      backgroundColor: 'transparent',
      outline: '1px red solid',
    },
  },
  text: {
    color: color.gray2,
    fontSize: '16px',
    textAlign: 'left',
  },
  tips: {
    color: color.gray2,
    fontSize: '12px',
  },
  tipsLeft: {
    color: color.gray2,
    fontSize: '12px',
    textAlign: 'left',
    marginLeft: '20px',
  },
  limitTips: {
    color: color.blue1,
    fontSize: 14,
    textAlign: 'left',
    width: 320,
    margin: '-10px auto 20px auto',
  },
  errorTips: {
    color: 'red',
    fontSize: '12px',
  },
  input: {
    width: '320px',
    height: '36px',
    marginTop: '8px',
    marginBottom: '16px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    color: '#000',
    fontSize: '16px',
    padding: '0 8px',
    backgroundColor: '#fff',
  },
  itemBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '16px',
  },
  itemTitle: {
    width: '36px',
    fontSize: '16px',
    textAlign: 'left',
    marginRight: '16px',
    color: color.blue1,
  },
  itemText: {
    fontSize: '14px',
    color: color.gray2,
    letterSpacing: '0.7px',
  },
  itemDate: {
    width: '160px',
    height: '36px',
    border: '1px solid #d9d9d9!important',
    borderRadius: '4px',
    paddingLeft: '12px!important',
    fontSize: '14px',
    backgroundColor: '#fff!important',
  },
  itemNumber: {
    width: '100px',
    height: '36px',
    paddingLeft: '12px',
    borderRadius: '4px',
    border: '1px solid #d9d9d9!important',
    marginRight: '8px',
  },
  itemDay: {
    paddingLeft: '85px',
    fontSize: '12px',
    color: color.gray2,
  },
  itemWeekBtn: {
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    color: color.gray2,
    fontSize: '12px',
    border: '1px solid #d9d9d9',
    borderRadius: '50%',
    cursor: 'pointer',
    marginRight: '8px',
    backgroundColor: '#fff',
  },
  itemWeekBtnAct: {
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    color: '#fff',
    fontSize: '12px',
    backgroundColor: color.blue1,
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    marginRight: '8px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
    borderTop: '1px solid #EEEEEE',
  },
  cancelBtn: {
    width: '68px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '16px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#028BBA!important',
      color: '#fff',
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#005B7A!important',
      color: '#fff',
    },
  },
  checkBtn: {
    height: '36px',
    margin: 5,
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '16px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#028BBA',
      color: '#fff',
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#005B7A',
      color: '#fff',
    },
  },
  confirmBtn: {
    width: '68px',
    height: '36px',
    border: 'none',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff!important',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#028BBA!important',
    },
    '&:active': {
      backgroundColor: '#005B7A!important',
    },
  },
  elevatorBtn: {
    width: '68px',
    height: '36px',
    border: 'none',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  dNone: {
    display: 'none',
  },
}));

export const onlyMessageModalStyle = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '400px',
    backgroundColor: color.white1,
    padding: '0, 0',
    maxWidth: '90%',
    outline: 'none',
  },
  body: {
    minHeight: '220px',
    height: 'auto',
    textAlign: 'center',
    padding: '24px 15px',
  },
  nav: {
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    fontSize: '21px',
    color: '#fff',
    backgroundColor: '#006E94',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
  },
  title: {
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: color.blue1,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  footer: {
    textAlign: 'center',
    height: '80px',
    lineHeight: '80px',
    border: '1px solid #EEEEEE',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  loading: {
    display: 'block',
    margin: '40px auto',
  },
  img: {
    display: 'block',
    margin: '0 auto',
    width: '86px',
    height: '86px',
    marginTop: '8px',
  },
  message: {
    color: color.gray2,
    fontSize: '20px',
    marginTop: '33px',
  },
  text: {
    color: '#414141',
    fontSize: '16px',
    marginTop: '8px',
  },
  cancelBtn: {
    width: '88px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '16px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#028BBA',
      color: '#fff',
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#005B7A',
      color: '#fff',
    },
  },
  confirmBtn: {
    width: '88px',
    height: '36px',
    border: 'none',
    backgroundColor: color.blue1,
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#028BBA',
    },
    '&:active': {
      backgroundColor: '#005B7A',
    },
  },
  dNone: {
    display: 'none',
  },
  onlyOkBtn: {
    width: '224px',
    height: '36px',
    border: 'none',
    backgroundColor: '#FF5050',
    borderRadius: '24px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));

export const connectMapListModalStyle = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    minHeight: 300,
  },
  tableRow: {
    '&$selected, &$selected:hover': {
      backgroundColor: '#006E94',
    },
  },
  tableCell: {
    '$selected &': {
      color: '#FFFFFF',
    },
  },
  // hover: {},
  // selected: {},
}));
