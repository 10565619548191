const AuthMapTable = {
  Init: false,
  Dashboard: true,
  Robots: true,
  RobotsGroups: true,
  Maps: true,
  Tasks: true,
  Account: true,
  Serial: true,
  Organization: true,
  Order: true,
  Setting: true,
  Versioning: true,
  Notification: true,
  Script: true,
  Services: true,
  Product: true,
  Inspection: true,
};

export { AuthMapTable };
