import { request } from './request.js';
import getToken from '../common/getToken.js';
import { requestImage, aspectRequest } from './requestImage.js';
//  user 相關的 api
// const { requestImage } = requestImageApi.requestImage;
// const { aspectRequest } = requestImageApi.aspectRequest;

// 取得all user 資訊
export const apiUserInfoList = (id) => request.get(`framework/User/${id ? `?organization_id=${id}` : ''}`, getToken());

// 新增 user
// 新增連至 wif-wic-aspect處理
export const apiUserCreate = ({ data, file }) => {
  const postData = new FormData();

  for (const key of Object.keys(data)) {
    postData.append(key, data[key]);
  }

  if (file) postData.append('photo_image', file);

  // return requestImage.post('framework/User/', postData, getToken());
  return aspectRequest.post('/user/', postData, getToken());
};

export const apiUserImport = ({ file }) => {
  const postData = new FormData();

  if (file) postData.append('file', file);

  return requestImage.post('framework/User/Import', postData, getToken());
};
// 修改 user
export const apiUserUpdate = (id, data) => request.put(`framework/User/${id}/`, data, getToken());

// 刪除 user
// 刪除連至 wif-wic-aspect處理
// export const apiUserRemove = ({ id }) => request.delete(`framework/User/${id}/`, getToken());
export const apiUserRemove = ({ id }) => aspectRequest.delete(`/user/${id}/`, getToken());

// export const apiBatchUserRemove = ({ userList }) => request.delete(`framework/User/bulk-delete?user_list=[${userList.join(',')}]`, getToken());
export const apiBatchUserRemove = async ({ userList }) => {
  const delete_ids = [];
  for (const id of userList) {
    await aspectRequest.delete(`/user/${id}/`, getToken());
    delete_ids.push(id);
  }
  return {
    status: 200,
    data: {
      message: 'Success delete users',
    },
  };
};

// 修改 user
export const apiUserEdit = ({ id, data, file }) => {
  const postData = new FormData();

  for (const key of Object.keys(data)) {
    postData.append(key, data[key]);
  }

  if (file) postData.append('photo_image', file);

  return requestImage.patch(`framework/User/${id}/`, postData, getToken());
};
// 查詢單一 user 資訊
export const apiUserInfo = (id) => request.get(`framework/User/${id}/`, getToken());

// 修改 password
export const apiUserPassword = ({ type, data }) => request.put(`framework/User/ResetPassword/${type}`, data, getToken());

export const apiUserPhoto = ({ id, file, username, group_id }) => {
  const postData = new FormData();

  if (file) postData.append('photo_image', file);
  postData.append('username', username);
  postData.append('group_id', group_id);

  return requestImage.put(`framework/User/${id}/`, postData, getToken());
};

export const patchUpdateUser = (id, data) => request.patch(`framework/User/${id}/`, data, getToken());
