export const sidebarItems = {
  dashboard: {
    id: 'Dashboard',
    name: 'DASHBOARD',
  },
  notification: {
    id: 'Notification',
    name: 'NOTIFICATION',
  },
  maps: {
    id: 'Maps',
    name: 'MAPS_MGMT',
  },
  tasks: {
    id: 'Tasks',
    name: 'TASK_MGMT',
  },
  account: {
    id: 'Account',
    name: 'ACCOUNT',
  },
  serial: {
    id: 'Serial',
    name: 'SN_MGMT',
  },
  organization: {
    id: 'Organization',
    name: 'ORGANIZATION_MGMT',
  },
  services: {
    id: 'Services',
    name: 'SERVICE_MGMT',
  },
  order: {
    id: 'Order',
    name: 'ORDER_MGMT',
  },
  script: {
    id: 'Script',
    name: 'SCRIPT_MGMT',
  },
  setting: {
    id: 'Setting',
    name: 'SETTING',
  },
  version: {
    id: 'Versioning',
    name: 'VERSIONING',
  },
  noPermission: {
    id: 'NoPermission',
  },
  inspection: {
    id: 'Inspection',
    name: 'INSPECTION',
  },
  factoryTestMgmt: {
    id: 'FactoryTestMgmt',
    name: 'FACTORYTESTMGMT',
  },
  factorySerial: {
    id: 'FactorySerial',
    name: 'FACTORYSERIAL',
  },
  factoryTestItems: {
    id: 'FactoryTestItems',
    name: 'FACTORYTESTITEMS',
  },
  factoryTestSetting: {
    id: 'FactoryTestSetting',
    name: 'FACTORYTESTSETTING',
  },
  factoryTestReport: {
    id: 'FactoryTestReport',
    name: 'FACTORYTESTREPORT',
  },
  factoryTest: {
    id: 'FactoryTest',
    name: 'FACTORYTEST',
  },
};
