import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
} from '@material-ui/core';
import Warnning from '@/static/icon/Popup/popupWarning.svg';
import {
  GetModalStyle,
  onlyMessageModalStyle,
} from './ModalStyle.js';

const YesNoModal = (props) => {
  const { modalOpen, titleText, messageText, cancelText, confirmText, cancelClick, confirmClick } = props;
  const { t } = useTranslation();
  const [modalStyle] = useState(GetModalStyle);
  const onlyMessageModal = onlyMessageModalStyle();

  return (
    <>
      <Modal
        open={modalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={onlyMessageModal.paper}>
          <div id="simple-modal-description">
            <div className={onlyMessageModal.title}>
              {titleText}
            </div>
            <div className={onlyMessageModal.body}>
              <Warnning width="86px" height="86px" />
              <p className={onlyMessageModal.message}>{messageText}</p>
            </div>
            <div className={onlyMessageModal.footer}>
              <button
                type="button"
                className={onlyMessageModal.cancelBtn}
                onClick={cancelClick}
              >
                {cancelText || t('NO')}
              </button>
              <button
                type="button"
                className={onlyMessageModal.confirmBtn}
                onClick={confirmClick}
              >
                {confirmText || t('YES')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default YesNoModal;
