export const languageValue = [
  {
    value: 'en',
    language: 'en',
    text: 'English',
  },
  {
    value: 'zh-tw',
    language: 'zh',
    text: '繁體中文',
  },
  {
    value: 'zh-cn',
    language: 'ch',
    text: '简体中文',
  },
];
