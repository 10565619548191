import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import actions from '@/actions';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { StyleSheetManager } from 'styled-components';
import Main from './src/Main.jsx';
import store from './src/reducer/store';
import './i18n.js';

import './src/public-path';

const packageName = require('./package.json').name;

const persistor = persistStore(store);

const RouteMain = () => (
  <StyleSheetManager disableCSSOMInjection>
    <Router basename={window.__POWERED_BY_QIANKUN__ ? '/amr' : '/'}>
      <Suspense fallback="">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Main />
          </PersistGate>
        </Provider>
      </Suspense>
    </Router>
  </StyleSheetManager>
);

function render(props) {
  const { container } = props;
  ReactDOM.render(<RouteMain />, container ? container.querySelector('#root') : document.querySelector('#root'));
}

function storeTest(props) {
  if (props) {
    // 注入 actions 实例
    actions.setActions(props);
  }
  console.log('[mount]', {
    ignore: packageName,
    user: {
      name: props.name,
    },
  });
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('[portal_web] react app bootstraped');
  console.log('API_URL: ' + process.env.API_URL);
}

export async function mount(props) {
  console.log('[portal_web] props from main framework', props);
  storeTest(props);
  render(props);
}

export async function unmount(props) {
  try {
    const { container } = props;
    ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
  } catch (ex) {
    console.error(ex);
  }
}
