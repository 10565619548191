import React from 'react';
import {
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  backdrop: { zIndex: 98, color: '#fff', marginTop: 65 },
  fade: { color: '#fff', zIndex: 1000 },
}));

const Loading = (props) => {
  const { open, type = 'backdrop' } = props;
  const classes = useStyles();
  return (
    <Backdrop className={classes[type]} open={open}><CircularProgress color="inherit" /></Backdrop>
  );
};
export default Loading;
