import axios from 'axios';
import { AppConfig } from '@/config/index';
import { refreshHandler } from './refreshHandler.js';

const { API_URL } = AppConfig;

// request URL 與 headers 設定
const request = axios.create({
  baseURL: `${API_URL}/api/`,
});

// 檢查 request 的 token 是否過期需 Refresh token
request.interceptors.response.use(
  (response) => response,
  refreshHandler(request),
);

export { request };
