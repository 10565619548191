import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import color from '@/color/theme';
import { Close } from '@material-ui/icons';

const Paper = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  background-color: ${color.white1};
  box-shadow: 3px 3px 6px #0000003D;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden; // 讓子元件的 border-radius 不超過父元件的 border-radius
`;

const Title = styled.div`
  height: 64px;
  display: flex;
  background-color: ${color.blue1};
  position: relative;
`;

const TitleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  width: 100%;
`;

const TitleIcon = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 55%;
  right:15px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-height: 180px;
  overflow-y: auto;
  color: ${color.gray2};
`;
const Footer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  ${({ noBorder }) => (noBorder ? '' : 'border-top: 1px solid #eeeeee;')};
  /* border-top: 1px solid #eeeeee; */
`;

// AmrModal
const AmrModal = ({ open, title, style, children, onClose }) => (
  <Modal open={open}>
    <Paper style={style}>
      {title != null && (
        <Title>
          <TitleText>{title}</TitleText>
          {onClose && <TitleIcon onClick={onClose}><Close /></TitleIcon>}
        </Title>
      )}
      {children}
    </Paper>
  </Modal>
);

AmrModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  style: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};
AmrModal.defaultProps = {
  title: '',
  style: {},
  onClose: null,
};

// AmrModalBody
export const AmrModalBody = ({ children }) => (
  <Body>{children}</Body>
);

AmrModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

// AmrModalFooter
export const AmrModalFooter = ({ noBorder, children }) => (
  <Footer noBorder={noBorder}>{children}</Footer>
);

AmrModalFooter.propTypes = {
  noBorder: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
AmrModalFooter.defaultProps = {
  noBorder: false,
};

// AmrModalContent
export const AmrModalContent = ({ footer, children }) => (
  <>
    <Body>{children}</Body>
    <Footer>{footer}</Footer>
  </>
);

AmrModalContent.propTypes = {
  footer: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default AmrModal;
