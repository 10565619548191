import axios from 'axios';
import { AppConfig } from '@/config/index';
import { refreshHandler } from './refreshHandler.js';

const { CMS_URL } = AppConfig;

// request URL 與 headers 設定
const requestCMS = axios.create({
  baseURL: `${CMS_URL}/api/v1/`,
});

// 檢查 request 的 token 是否過期需 Refresh token
requestCMS.interceptors.response.use(
  (response) => response,
  refreshHandler(requestCMS),
);

export { requestCMS };
