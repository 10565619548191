const leafletMapColor = {
  Prioritization: '#A1CC57',
  Forbidden: '#DB4242',
  Disinfection: '#DB4242',
  UVCZone: '#E125D5',
  Passage: '#D8BDFE',
  VirtualWall: '#DB4242',
  NarrowGate: '#FF8800',
  NarrowLane: '#FEDCB1',
  IotGate: '#FF6500',
  Select: '#A1FF00',
  Grid: '#028CBA',
  DottedLine: '#006E94',
  LowSpeed: '#1AB3F6',
  Slope: '#FFD836',
  OneWay: '#006E94',
  NonAvoidance: '#B9803F',
};

export default leafletMapColor;
