import { createSlice } from '@reduxjs/toolkit';
import { robotMockData } from '../page/Dashboard/mockData.js';
import { getChargingAreas } from './thunk/dashboardThunk';

export const initDashboardId = {
  dashboardId: {
    site_id: '',
    map_id: '',
  },
};

const initialState = {
  iotGates: [],
  robots: {},
  sites: [],
  maps: [],
  elevators: [],
  chargingGroups: [],
  deviceTable: [],
  ...initDashboardId,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    initIotGates: (state, action) => {
      state.iotGates = action.payload.list;
    },
    initRobots: (state, action) => {
      state.robots = action.payload.list.reduce((acc, val) => {
        acc[val.id] = { ...val, ...robotMockData };
        return acc;
      }, {});
    },
    updateRobotStatus: (state, action) => {
      state.robots[action.payload.id] = { ...state.robots[action.payload.id], ...action.payload.cloudStatus };
    },
    initSites: (state, action) => {
      state.sites = action.payload.list;
    },
    initMaps: (state, action) => {
      state.maps = action.payload.list;
    },
    initElevators: (state, action) => {
      state.elevators = action.payload.list;
    },
    updateElevator: (state, action) => {
      const { id, ...payload } = action.payload;
      const index = state.elevators.findIndex((el) => el.id === id);
      if (index >= 0) {
        state.elevators[index] = {
          ...state.elevators[index],
          ...payload,
        };
      }
    },
    initChargingGroups: (state, action) => {
      state.chargingGroups = action.payload.list;
    },
    setDashboardId: (state, action) => {
      state.dashboardId = { ...state.dashboardId, ...action.payload };
    },
    setDeviceTable: (state, action) => {
      state.deviceTable = action.payload.data.map((item) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        battery: 0,
        map: '',
        position: '',
        state: item.online_status,
        error: '',
        note: '',
      }));
    },
    updateDeviceTableMqtt: (state, action) => {
      const { payload } = action;
      const index = state.deviceTable.findIndex((device) => device.id === payload.device_id);
      const { key, value } = payload.state_measurements[0];
      switch (key) {
        case 'robot_status':
          state.deviceTable[index].battery = value.battery.level;
          const { x = 0, y = 0 } = value.current_pose;
          state.deviceTable[index].position = `(${x.toFixed(2)}, ${y.toFixed(2)})`;
          break;
        case 'task_status':
          break;
        default:
          console.debug(`key '${key}' is not handled`);
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChargingAreas.fulfilled, (state, action) => {
      state.chargingGroups = action.payload;
    });
  },
});

export const {
  initIotGates,
  initRobots,
  updateRobotStatus,
  initSites,
  initMaps,
  initElevators,
  updateElevator,
  initChargingGroups,
  setDashboardId,
  setDeviceTable,
  updateDeviceTableMqtt,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
