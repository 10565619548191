import {
  CircularProgress,
} from '@material-ui/core';
import React from 'react';
import color from '@/color/theme';

export default ({ isLoading }) => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 2000,
      display: isLoading ? 'block' : 'none',
      top: 0,
      left: 0,
      paddingTop: '20%',
    }}
  >
    <CircularProgress
      variant="indeterminate"
      disableShrink
      size={40}
      thickness={3}
    />
    <p
      style={{
        color: `${color.blue1}`,
        textAlign: 'center',
      }}
    >
      Loading...
    </p>
  </div>
);
