import React, { useEffect } from 'react';
import {
  Fade,
  CircularProgress,
} from '@material-ui/core';
import Success from '@/static/image/success.png';
import ErrorImg from '@/static/image/error.png';
import WarnImg from '@/static/image/popup_warning.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  setModal,
} from '@/reducer/notificationSlice';
import AmrModal from './AmrModal.jsx';
import {
  NotificationDiv,
  Image,
  H4,
} from './NotificationStyle.js';

const NotificationModal = () => {
  const { open, status, message, title } = useSelector((x) => x.notificationSlice.modal);
  const dispatch = useDispatch();

  // 關閉 save modal
  const closeModal = () => {
    const close = setTimeout(() => {
      dispatch(setModal({ open: false, status: 'close', message: '', title: '' }));
      clearTimeout(close);
    }, 2000);
  };

  useEffect(() => {
    if (status === 'success' || status === 'fail' || status === 'warning') closeModal();
  }, [status]);

  return (

    <AmrModal
      open={open}
      title={null}
    >
      <NotificationDiv>
        {status === 'success' && <Image alt="success" src={Success} />}
        {status === 'fail' && <Image alt="error" src={ErrorImg} />}
        {status === 'warning' && <Image alt="error" src={WarnImg} />}
        {
              !title
                ? <Fade in style={{ transitionDelay: '0ms' }} unmountOnExit><CircularProgress /></Fade>
                : ''
            }
        <H4>{title}</H4>
        {message && <p>{message}</p>}
      </NotificationDiv>
    </AmrModal>
  );
};

export default NotificationModal;
