export const routes = [
  {
    path: '/',
    name: 'Login',
    exact: true,
    // component: () => import('@/page/Login/Login'),
  },
  {
    path: '/Verify/:token',
    name: 'Verify',
  },
  {
    path: '/ResetPassword/:token',
    name: 'ResetPassword',
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    children: [
      {
        path: '/IotGate',
        name: 'IotGate',
      },
      {
        path: '/Elevator',
        name: 'Elevator',
      },
      {
        path: '/ChargingGroup',
        name: 'ChargingGroup',
      },
      {
        path: '/Robots',
        name: 'Robots',
        children: [
          {
            path: '/groups',
            name: 'RobotsGroups',
          },
          {
            path: '/RobotDetail/:id',
            name: 'RobotsDetail',
          },
          {
            path: '/setting/:id',
            name: 'RobotsSetting',
          },
          {
            path: '/maintenance/:id',
            name: 'RobotsMaintenance',
          },
        ],
      },
      {
        path: '/DashboardMessage',
        name: 'DashboardMessage',
      },
    ],
  },
  {
    path: '/Maps',
    name: 'Maps',
    children: [
      {
        path: '/:name/ViewMap',
        name: 'ViewMap',
      },
      {
        path: '/:name/EditMap',
        name: 'EditMap',
      },
    ],
  },
  {
    path: '/Tasks',
    name: 'Tasks',
    children: [
      {
        path: '/CreateTask',
        name: 'TaskCreate',
      },
      {
        path: '/:id/CopyTask',
        name: 'TaskCopy',
      },
      {
        path: '/:id/EditTask',
        name: 'TaskEdit',
      },
    ],
  },
  {
    path: '/Database',
    name: 'Database',
  },
  {
    path: '/Setting',
    name: 'Setting',
  },
  {
    path: '/Organization',
    name: 'Organization',
    children: [
      {
        path: '/Create',
        name: 'OrganizationCreate',
      },
      {
        path: '/:id/Edit',
        name: 'OrganizationEdit',
      },
      {
        path: '/:id/View',
        name: 'OrganizationView',
      },
      {
        path: '/:id/GroupView',
        name: 'OrganizationGroupView',
      },
    ],
  },
  {
    path: '/Account',
    name: 'Account',
    children: [
      {
        path: '/CreateAccount',
        name: 'CreateAccount',
      },
      {
        path: '/:id/EditAccount',
        name: 'EditAccount',
      },
      {
        path: '/:id/ViewAccount',
        name: 'ViewAccount',
      },
      {
        path: '/:id/AccountSerialNo',
        name: 'AccountSerialNo',
      },
      {
        path: '/:id/AccountDistribute',
        name: 'AccountDistribute',
      },
    ],
  },
  {
    path: '/Personal',
    name: 'Personal',
  },
  {
    path: '/Serial',
    name: 'Serial',
    children: [
      {
        path: '/:id/Allotment',
        name: 'Allotment',
      },
      {
        path: '/:deviceType/:id/DeviceHistory',
        name: 'DeviceHistory',
      },
      {
        path: '/:deviceType/:id',
        name: 'DeviceInfo',
      },
      {
        path: '/:deviceType/:id/DeviceAsmBind',
        name: 'DeviceAsmBind',
      },
    ],
  },
  {
    path: '/Permission',
    name: 'Permission',
  },
  {
    path: '/Allotment',
    name: 'Allotment',
    children: [
      {
        path: '/:orgParent/:organization/:account',
        name: 'AccountByOrganization',
      },
      {
        path: '/:organization',
        name: 'AllotmentAccount',
      },
      {
        path: '/:organization/:account',
        name: 'AllotmentDevice',
      },
    ],
  },
  {
    path: '/Order',
    name: 'Order',
    children: [
      {
        path: '/OrderDetail/:id',
        name: 'OrderDetail',
      },
      {
        path: '/delivery/:id',
        name: 'DeliveryDetail',
      },
      {
        path: '/robots',
        name: 'OrderWorkGroup',
      },
    ],
  },
  {
    path: '/CMS',
    name: 'CMS',
    children: [
      {
        path: '/CMSCloud',
        name: 'CMSCloud',
        children: [
          {
            path: '/CMSViewRecord',
            name: 'CMSViewRecord',
          },
          {
            path: '/:id',
            name: 'CMSCloudEdit',
            children: [
              {
                path: '/CMSViewRecord',
                name: 'CMSViewRecord',
              },
            ],
          },
        ],
      },
      {
        path: '/:id',
        name: 'EditPlayList',
        children: [
          {
            path: '/CMSVideoPreviews',
            name: 'CMSVideoPreviews',
          },
          {
            path: '/CMSSchedule',
            name: 'CMSSchedule',
          },
          {
            path: '/CMSCloudAdd',
            name: 'CMSCloudAdd',
          },
        ],
      },
      {
        path: '/CMSSchedule',
        name: 'CMSSchedule',
      },
      {
        path: '/InspectionPage',
        name: 'InspectionPage',
        children: [
          {
            path: '/:siteId/InspectNotification',
            name: 'InspectNotification',
          },
        ],
      },
    ],
  },
];
