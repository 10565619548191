import mqtt from 'mqtt';
import { AppConfig } from '@/config';
import { useEffect } from 'react';
import {
  setGroupMqtt,
  setElevatorMqtt,
  setTaskMqtt,
  setNotifyMqtt,
  setNotificationMqtt,
  clearGroupMqtt,
  disconnectedMqtt,
  clearElevatorMqtt,
  clearTaskMqtt,
  clearNotifyMqtt,
  clearNotificationMqtt,
  setAmrMqtt,
  clearAmrMqtt,
  setSiteMqtt,
  clearSiteMqtt,
} from '@/reducer/mqttSlice';
import { useDispatch } from 'react-redux';

let client = null;

const Mqtt = () => {
  const { MQTT_HOST, MQTT_USERNAME, MQTT_PASSWORD } = AppConfig;

  const dispatch = useDispatch();

  const init = () => {
    if (MQTT_USERNAME != null && MQTT_PASSWORD != null) {
      console.log('host : ', MQTT_HOST);
      client = mqtt.connect(MQTT_HOST, {
        username: MQTT_USERNAME,
        // clientId: MQTT_USERNAME,
        password: MQTT_PASSWORD,
      });
    } else {
      client = mqtt.connect(MQTT_HOST);
    }

    client.on('error', (err) => {
      console.log('mqtt error:', err);
    });

    client.on('connect', () => {
      console.log('mqtt connected');
    });

    client.on('message', (topic, message) => {
      const data = message.toString();
      const json = JSON.parse(data);
      switch (getMqttDataType(topic)) {
        case 'group':
          dispatch(setGroupMqtt({ data: { ...json } }));
          break;
        case 'elevator':
          dispatch(setElevatorMqtt({ data: { ...json } }));
          break;
        case 'task':
          dispatch(setTaskMqtt({ data: { ...json } }));
          break;
        case 'notify':
          dispatch(setNotifyMqtt({ data: { ...json } }));
          break;
        case 'notification':
          dispatch(setNotificationMqtt({ data: { ...json } }));
          break;
        case 'amr':
          dispatch(setAmrMqtt({ data: { ...json } }));
          break;
        case 'site':
          dispatch(setSiteMqtt({ data: { ...json } }));
          break;
        default:
          break;
      }
    });

    client.on('close', () => {
      dispatch(disconnectedMqtt());
    });
  };

  useEffect(() => {
    init();
    return () => {
      dispatch(disconnectedMqtt());
      client.end();
    };
  }, []);

  return client;
};

export const subscribe = (topics) => {
  // console.log('subscribe:', topics);
  // console.log('client:', client);
  if (!client) return;
  if (!client.connected) {
    client.on('connect', () => {
      console.log('mqtt connected');
    });
  }

  topics.forEach((topic) => {
    client.subscribe(topic, (err) => {
      if (err != null) console.log('subscribe error:', err);
    });
  });
};

const getMqttDataType = (topic) => {
  if (topic.indexOf('group/') === 0) {
    return 'group';
  }
  // if (topic.indexOf('/elevator') === 41) {
  if (topic.indexOf('elevator/') === 0) {
    return 'elevator';
  }
  if (topic.indexOf('task/') === 0) {
    return 'task';
  }
  if (topic.indexOf('notify/') === 0) {
    return 'notify';
  }
  if (topic.indexOf('notification/') === 0) {
    return 'notification';
  }
  if (topic.indexOf('amr/') === 0) {
    return 'amr';
  }
  if (topic.indexOf('site/') === 0) {
    return 'site';
  }
  return '';
};

export const unSubscribe = (topics, dispatch) => {
  if (!client) return;
  topics.forEach((topic) => {
    client.unsubscribe(topic, () => {
      switch (getMqttDataType(topic)) {
        case 'group':
          dispatch(clearGroupMqtt());
          break;
        case 'elevator':
          dispatch(clearElevatorMqtt());
          break;
        case 'task':
          dispatch(clearTaskMqtt());
          break;
        case 'notify':
          dispatch(clearNotifyMqtt());
          break;
        case 'notification':
          dispatch(clearNotificationMqtt());
          break;
        case 'amr':
          dispatch(clearAmrMqtt());
          break;
        case 'site':
          dispatch(clearSiteMqtt());
          break;
        default:
          break;
      }
    });
  });
};

export const publish = (topic, data) => {
  client.publish(topic, JSON.stringify(data), (err) => {
    if (err) {
      console.log('mqtt publish error ', err);
    }
  });
};

export default Mqtt;
