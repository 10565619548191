import styled from 'styled-components';
import {
  OutlinedInput,
} from '@material-ui/core';
// import color from '@/color/theme.js';

export const Password = styled(OutlinedInput)`
    background-color: #fff;
    border: 2px solid #9B9B9B;
    border-radius: 4px;
    width:100%;
    margin-top:10px;
    height:44px;

    &.Mui-focused{
        border:none !important;
    }
 
`;
