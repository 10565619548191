// const token = sessionStorage.getItem('token');

// 檢查是否已登入
export const isLogin = () => {
  let result = false;
  const token = sessionStorage.getItem('token');
  if (token !== null) result = token;

  return result;
};
