import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';

export const Line = styled.div`
   height:1px;
   background-color:#eeeeee;
   width:100%;
`;

export const ElevatorBody = styled.div`
  padding-top:10px;
  position: relative;
  .right{
    width:150px;
    position: absolute;
    right: 20px;
    top: 85px;
    span{
      margin-right:3px;
    }
    p{
      height:10px;
    }
    .MuiInput-root{
      
     height: 30px;
     padding: 2px;
      input{
        width:60px;
        margin-left:5px;
      }
    }
    span{
      width:50px;
    }
   
  }
`;

export const Image = styled.img`
  width: 150px;
  margin-left:20px;

`;

export const AlingIcon = styled.div`
width: 50px;
background-color: ${(props) => (props.isFocus ? '#006E94' : '#ffffff')};
color:${(props) => (props.isFocus ? '#fff' : '#006E94')};
border:solid 1px #006E94;
text-align: center;
border-radius:4px;
font-size: 14px;
padding:6px 4px 4px 4px;
`;

export const AlignDiv = styled.div`
margin: 0 10px;
display: flex;
align-items: center;
flex-direction: column;
.MuiRadio-root:hover{
  background-color: transparent;
}
.MuiInputBase-root {
  width:50px;
  display:${(props) => (props.isDispaly ? 'block' : 'none')};
  padding:2px;
  margin-left:0px;
}
.cm{
  display:${(props) => (props.isDispaly ? 'block' : 'none')};
}
`;

export const AddButton = styled.div`
  align-items: center;
  display: flex;
  padding: 2px;
  margin: 2px;
  width:100px;
  float:right;
  border: 1px solid #E3E3E3;
  background-color: #FFF;
  color: #454545;
  cursor: pointer;
`;

export const AntTabs = styled(Tabs)`
width:400px;

.MuiTabs-indicator{
    background-color: #ffffff;
  }
`;
export const AntTab = styled(Tab)`
  min-width: 0;
  flex:1;
  background-color: #B1E0F0;
  border-bottom: 1px solid #028BBA;
  &.Mui-selected{
    color:#000000;
    border-left: 1px solid #028BBA;
    border-right: 1px solid #028BBA;
    border-top: 1px solid #028BBA;
    background-color: #ffffff;
  }
`;

export const ElevatorTabDiv = styled.div`
   .error{
    color:#fe5f55
   }
   .elevatorDiv{
    display:${(props) => (props.tabId === 1 ? 'block' : 'none')};
   }
   .info{
    display:${(props) => (props.tabId === 3 ? 'none' : 'flex')};
   }
   .info .line{
     display:none;
   }
   .MuiGrid-container{
    display:${(props) => (props.tabId === 1 ? 'flex' : 'none')};
   }
   .elevatorBody{
    display:${(props) => (props.tabId === 2 ? 'block' : 'none')};
   }
   .command{
    display:${(props) => (props.tabId === 3 ? 'flex' : 'none')};
   }
`;

export const ElevatorListDiv = styled.div`
padding: 30px 24px;
  .MuiInput-fullWidth{
    margin-bottom:10px;
  }
  svg{
    width: 30px;
    height: 30px;
    color:#006E94;
    position:absolute;
    top:5px;
    right:5px;
  }
  .saveBtn{
    float:right;
  }
  .title{
    margin-top:50px;
  }
`;
export const ElevatorListScroll = styled.div`
  height:300px;
  overflow-y:auto;
  border-top:1px solid #E3E3E3;

  p{
    padding:10px;
    border-bottom: 1px solid #E3E3E3;
    display:flex;
    align-items:center;
    background-color:#FFFFFF;
    span:first-child{
      background-color:#E3E3E3;
      border-radius:5px;
      padding:5px 10px;
      margin-right:10px;
      width:70px;
      text-align:center;
    }
    span:nth-child(2){
      flex:1;
      text-align:left;
    }
    button{
      visibility:hidden;
      width:70px;
     
    }
  }

  p:hover{
    button{
      color:#006E94;
      border:solid 1px #006E94;
      visibility:visible;
      :hover{
        color:#FFF;
      }
    }
  }

`;
//= ==================icon
const getLeft = (iconSize, width) => (iconSize / 2) - (width / 2);

const getOffset = (offset, align) => {
  switch (align) {
    case 'center':
      return 0;
    case 'left':
      return offset;
    case 'right':
      return -offset;
    default:
      return 0;
  }
};

export const ElevatorIocnStyle = styled.div`
      text-align:center;
      position:relative;
      width:${(props) => (props.iconSize + 'px')};
      height:${(props) => (props.iconSize + 'px')};
    .circle{
      background-color:red;
      width:10px;
      height:10px;
      border-radius:50%;
      position:absolute;
      left:${(props) => (getLeft(props.iconSize, 10) + 'px')};
      top:${(props) => ((props.iconSize / 2) - 5 + 'px')};
      border:solid 1px #ffffff;
    }
    .door{
      background-color:#4494af;
      width:${(props) => (props.data.doorWidth + 'px')};
      height:${(props) => (props.data.doorCm + 'px')};
      left:${(props) => (getLeft(props.iconSize, props.data.doorWidth) + 'px')};//doorWidth
      top:${(props) => ((props.iconSize) / 2 - (props.data.doorCm) + 'px')};
      position: absolute;
      opacity: 0.5;
    }
    .arrow{
      display:block;
      width: 0;
      height: 0;
      border: 5px solid;
      border-color: transparent transparent #006E94 transparent ;
      position: relative;
      margin:auto;
      top: -12px;
    }
    .doorIn{
      position: absolute;
      width:${(props) => (props.data.doorWidth + 'px')};
      height:${(props) => (props.data.doorHeight + 'px')};
      top:${(props) => (props.iconSize / 2 + 'px')};
      left:${(props) => (getLeft(props.iconSize, props.data.doorWidth) + 'px')};
      border:dashed 1px #006E94;
      text-align:center;

    
    }

    .insideArea{
      position: absolute;
      transform: rotate(180deg);
      width:${(props) => (props.data.parkingSize + 'px')};
      height:${(props) => (props.data.parkingSize + 'px')};
      margin:auto;
      border-radius:1px;
      top:${(props) => ((props.iconSize / 2) + props.data.insideCm - (props.data.parkingSize / 2) + 'px')};
      left:${(props) => (getLeft(props.iconSize, props.data.parkingSize) + 'px')};
    } 
    
    .outArea{
      position: absolute;
        transform: rotate(180deg);
        width:${(props) => (props.data.parkingSize + 'px')};
        height:${(props) => (props.data.parkingSize + 'px')};
        border-radius:1px;
        top:${(props) => ((props.iconSize / 2) - (props.data.enterCm + props.data.doorCm) - (props.data.parkingSize / 2) + 'px')};
        left:${(props) => (getLeft(props.iconSize, props.data.parkingSize) + getOffset(props.data.offset, props.data.align) + 'px')};
    }

    .vertical{
      position: absolute;
      height:${(props) => ((props.data.doorHeight * 6) + 'px')};
      width:0.5px;
      top:${(props) => ((props.iconSize / 2) - ((props.data.doorHeight * 6) / 2) + 'px')};;
      left:${(props) => (getLeft(props.iconSize, 0) + 'px')};    
      background-color:#FF6500;
      display: none;
    }

    .horizontal{
      position: absolute;
      width:${(props) => ((props.data.doorWidth * 6) + 'px')};
      height:0.5px;
      left:${(props) => ((props.iconSize / 2) - ((props.data.doorWidth * 6) / 2) + 'px')};;
      top:${(props) => ((props.iconSize / 2) + 'px')};    
      background-color:#FF6500;
      display: none;
    }
`;
