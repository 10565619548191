import { useState, useEffect, useRef } from 'react';
import { isCancel } from 'axios';
import { v4 as uuid } from 'uuid';
import { uploadfile } from '@/cloud_api/cms';
import useSessionStorage from './useSessionStorage.jsx';

export default function useUploadManagement() {
  const controller = useRef(new AbortController());
  const cancelFileUpload = useRef(null);
  const currentId = useRef(null);
  const fileTotal = useRef(null);
  const inUpload = useRef(false);
  const cancelQueue = useRef([]);
  const currPercent = useRef(0);
  const percentAvg = useRef(0);
  const bottomStateRef = useRef({});
  const editStateRef = useRef({});
  const doneTotal = useRef(0);
  const playlistStateRef = useRef({});
  const playlistQueue = useRef([]);
  const playlistTotal = useRef({});
  const playlistAvg = useRef(0);
  const playId = useRef(null);
  const uploadPlayId = useRef(null);
  const donePlayTotal = useRef({});
  const saveLocalfiles = useRef([]);
  const joinVideo = useRef([]);

  const [inputVideoFile, setInputVideoFile] = useState(null);

  const [requestID] = useSessionStorage('request_id', 'request_id', uuid());
  const [bottomState, setBottomState] = useSessionStorage('bottomState', 'storageChange');
  const [editState, setEditState] = useSessionStorage('editState', 'storageChangeEdit', {});
  const [playlistState, setPlaylistState] = useSessionStorage('playlistState', 'storageChangePlaylist', {});

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      currPercent.current = Math.floor((loaded * 100) / total);
      percentAvg.current = Math.floor(((loaded * 100) / total) / fileTotal.current);

      if (currPercent.current > 0) {
        const donePercent = (doneTotal.current * (Math.floor(100 / fileTotal.current)));
        bottomStateRef.current.upload_progress = donePercent + percentAvg.current;
        setBottomState(bottomStateRef.current);

        setToEditState(currentId.current, null, currPercent.current);

        if (currPercent.current === 100) {
          doneTotal.current += 1;
        }

        setToPlayState(uploadPlayId.current, null, currPercent.current, loaded, total);
      }
    },
    signal: controller.current.signal,
  };

  function joinPlaylist(data) {
    if (!uploadPlayId.current) {
      joinVideo.current.push({ ...data });
    }
  }

  function removePlayState(playid, cancel = false) {
    if (cancel) {
      playlistTotal.current[playid].total = playlistQueue.current[playid].id.length;
      if (donePlayTotal.current[playid].total > 0) {
        donePlayTotal.current[playid].total -= 1;
      }
    } else {
      playlistQueue.current[playid].id = arrayRemove(playlistQueue.current[playid].id, currentId.current);
    }

    playlistStateRef.current[playid].upload = playlistQueue.current[playid].id.length;

    if (playlistQueue.current[playid].id.length === 0 && playlistStateRef.current[playid].failed === 0 && cancel) {
      delete playlistStateRef.current[playid];
      setPlaylistState(playlistStateRef.current);
    }
  }

  function cancelAll(playid) {
    const getCancelItem = JSON.parse(sessionStorage.getItem('cancelQueue'));
    getCancelItem.forEach((contentId) => {
      setToEditState(contentId, 'canceled');
      playlistQueue.current[playid].id = arrayRemove(playlistQueue.current[playid].id, contentId);
      if (fileTotal.current > 0) {
        bottomStateRef.current.canceled += 1;
        fileTotal.current -= 1;
      }
    });

    cancelQueue.current = [...new Set([...cancelQueue.current, ...getCancelItem])];
    sessionStorage.setItem('cancelQueue', JSON.stringify([]));
    setBottomState(bottomStateRef.current);
  }

  function setCancel(page) {
    if (page === 'edit') {
      if (uploadPlayId.current) {
        cancelAll(uploadPlayId.current);
        removePlayState(uploadPlayId.current, true);
      }
      if (cancelQueue.current.includes(currentId.current)) {
        controller.current.abort();
        controller.current = new AbortController();
      }
    } else {
      cancelFileUpload.current = true;
      controller.current.abort();
      if (inUpload.current) {
        bottomStateRef.current.canceled = fileTotal.current;
        setInputVideoFile('done');
      }
    }
  }

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele !== value);
  }

  function removeCompletedPlaylist() {
    Object.entries(playlistStateRef.current).forEach(([id]) => {
      if (playlistStateRef.current[id].upload_status === 'done') {
        delete playlistStateRef.current[id];
      }
    });
  }

  function setToPlayState(playid, status, percent = null, loaded = null, total = null) {
    if (playlistStateRef.current.hasOwnProperty(playid)) {
      if (status === 'failed') {
        playlistStateRef.current[playid].failed += 1;
      }

      if (status) {
        removePlayState(playid);
      }
    }

    if (playlistQueue.current.hasOwnProperty(playid) && playlistQueue.current[playid].id.includes(currentId.current)) {
      if (percent === 100) {
        donePlayTotal.current[playid].total += 1;
      }

      if (percent) {
        let progress = 0;
        const donePlayPercent = donePlayTotal.current[playid].total * (Math.floor(100 / playlistTotal.current[playid].total));
        playlistAvg.current = Math.floor(((loaded * 100) / total) / playlistTotal.current[playid].total);
        if (percent < 100) {
          progress = donePlayPercent + playlistAvg.current;
        } else if (percent === 100) {
          progress = donePlayPercent;
        }
        playlistStateRef.current[playid].upload_progress = progress;
      }

      setPlaylistState(playlistStateRef.current);
    } else if (playlistStateRef.current.hasOwnProperty(playid)) {
      if (playlistStateRef.current[playid].upload === 0) {
        if (playlistStateRef.current[playid].failed > 0) {
          playlistStateRef.current[playid].upload_status = 'failed';
        } else {
          playlistStateRef.current[playid].upload_status = status;
        }
      }
      setPlaylistState(playlistStateRef.current);
    } else {
      playlistAvg.current = 0;
    }
  }

  function setToEditState(id, status, progress = null) {
    if (editStateRef.current.hasOwnProperty(id)) {
      if (status) {
        editStateRef.current[id].upload_status = status;
      }

      if (progress) {
        editStateRef.current[id].upload_progress = progress;
      }
      setEditState(editStateRef.current);
    }
  }

  function setDefaultToPlaylist(playid, contentid) {
    playId.current = playid;
    if (Object.keys(playlistStateRef.current).length === 0) {
      playlistStateRef.current = { ...playlistState };
    }

    if (!playlistStateRef.current.hasOwnProperty(playid)) {
      playlistStateRef.current[playid] = {
        upload_status: 'uploading',
        upload: 0,
        failed: 0,
        upload_progress: 0,
      };
      setPlaylistState(playlistStateRef.current);
    } else {
      playlistStateRef.current[playid].upload_status = 'uploading';
      playlistStateRef.current[playid].upload_progress = 0;
      playlistStateRef.current[playid].upload = 0;
      playlistStateRef.current[playid].failed = 0;
      setPlaylistState(playlistStateRef.current);
    }

    if (!playlistQueue.current.hasOwnProperty(playid)) {
      playlistQueue.current[playid] = { id: [] };
    }

    if (!playlistTotal.current.hasOwnProperty(playid)) {
      playlistTotal.current[playid] = { total: 0 };
    }

    if (!donePlayTotal.current.hasOwnProperty(playid)) {
      donePlayTotal.current[playid] = { total: 0 };
    }

    if (!playlistQueue.current[playid].id.includes(contentid)) {
      playlistQueue.current[playid].id.push(contentid);
      playlistTotal.current[playid].total += 1;
      playlistStateRef.current[playid].upload = playlistTotal.current[playid].total;
    }
  }

  function setDefaultToEditState(id) {
    if (Object.keys(editStateRef.current).length === 0) {
      editStateRef.current = { ...editState };
    }

    if (!editStateRef.current.hasOwnProperty(id)) {
      editStateRef.current[id] = {
        upload_status: 'pending',
        upload_progress: 0,
      };
    }
  }

  async function uploadfileToServer(files) {
    const data = new FormData();
    data.append('file', files[0]);
    data.append('filename', files[0].filename);
    data.append('request_id', requestID);
    data.append('content_id', files[0].content_id);

    currentId.current = files[0].content_id;
    uploadPlayId.current = files[0].upload_id;

    try {
      if (!cancelQueue.current.includes(currentId.current)) {
        setToEditState(currentId.current, 'uploading');
        const res = await uploadfile(data, options);
        joinPlaylist(res.data);
        bottomStateRef.current.uploaded += 1;
        setToEditState(currentId.current, 'done');
        setToPlayState(uploadPlayId.current, 'done');
      }
    } catch (err) {
      if (!isCancel(err)) {
        bottomStateRef.current.failed += 1;
        setToEditState(currentId.current, 'failed');
        setToPlayState(uploadPlayId.current, 'failed');
      }
    }

    if (cancelFileUpload.current) {
      bottomStateRef.current.canceled = fileTotal.current - (bottomStateRef.current.uploaded + bottomStateRef.current.failed);
      setBottomState(bottomStateRef.current);
      setInputVideoFile('done');
    } else {
      setBottomState(bottomStateRef.current);
      files.shift();
      if (files.length === 0) {
        inUpload.current = false;
        sessionStorage.setItem('joinVideo', JSON.stringify(joinVideo.current));
        setInputVideoFile('done');
      } else {
        setInputVideoFile(currentId.current);
      }
    }
  }

  function mergeNewFiles(newFiles) {
    newFiles.forEach((files) => {
      saveLocalfiles.current.push(files);
      setDefaultToEditState(files.content_id);
      if (files.upload_id) {
        setDefaultToPlaylist(files.upload_id, files.content_id);
      }
    });
  }

  function runUpload() {
    if (saveLocalfiles.current.length > 0) {
      removeCompletedPlaylist();
      uploadfileToServer(saveLocalfiles.current);
    }
  }

  function setUploadVideo(newFiles) {
    playId.current = null;
    if (cancelFileUpload.current) {
      cancelFileUpload.current = false;
    }

    mergeNewFiles(newFiles);

    bottomStateRef.current = { ...bottomState };

    fileTotal.current = saveLocalfiles.current.length;
    bottomStateRef.current.total = fileTotal.current;

    const donePercent = (doneTotal.current * (Math.floor(100 / fileTotal.current)));
    if (currPercent.current < 100) {
      bottomStateRef.current.upload_progress = donePercent + percentAvg.current;
    } else if (currPercent.current === 100) {
      bottomStateRef.current.upload_progress = donePercent;
    }
    setBottomState(bottomStateRef.current);

    setToPlayState(playId.current, null, 1);

    if (!inUpload.current) {
      setInputVideoFile('triggerStart');
    }
  }

  useEffect(() => {
    if (inputVideoFile !== null && inputVideoFile !== 'done') {
      inUpload.current = true;
      runUpload();
    } else {
      if (bottomStateRef.current.total > 0) {
        bottomStateRef.current.done = true;
        setBottomState(bottomStateRef.current);
      }
      inUpload.current = false;
      currPercent.current = 0;
      percentAvg.current = 0;
      doneTotal.current = 0;
      playlistQueue.current = [];
      cancelQueue.current = [];
      saveLocalfiles.current = [];
      joinVideo.current = [];
      bottomStateRef.current = {};
      playlistTotal.current = {};
      editStateRef.current = {};
      donePlayTotal.current = {};
      playlistStateRef.current = {};
      currentId.current = null;
      playId.current = null;
      uploadPlayId.current = null;
      setInputVideoFile(null);
      setEditState({});
      controller.current = new AbortController();
    }
  }, [inputVideoFile]);

  return { setUploadVideo, setCancel };
}
