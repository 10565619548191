import styled, { css } from 'styled-components';
import color from '@/color/theme';
import { makeStyles, createMuiTheme } from '@material-ui/core';
import { CalendarStyle } from '../Tasks/TasksStyle.js';

export const CmsContainer = styled.div`
  position: relative;
  padding-top: 64px;
  overflow-y: hidden;
  height: 100vh;

  .btn{
    &Primary{
      background-color: ${color.blue1};
      border: 1px solid;
      border-color: ${color.blue1};
      color: #fff;
      height: 36px;
      &:hover {
        border-color: #555;
        background-color: #555;
      }
    }
  }

  .disableScroll {
    height: 90vh;
    overflow: hidden;
  }

  .loading {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 98;
    padding-top: 20%;

    p {
        color: ${color.blue1};
        text-align: center;
    }
  }

  .searchBox {
    height: 76px;
    text-align: left;
    padding: 0 30px;

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .MuiOutlinedInput-root {
      background-color: #fff;
    }

    .MuiOutlinedInput-input {
      padding: 8px 8px;
    }

    .cloudButtonLeft {
      float: left;
      text-align: left;
    }

    .cancelButton {
      background: #7E7E7E 0% 0% no-repeat padding-box;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      width: 68px;
      height: 33px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #FFFFFF;
      margin-top: 22px;
    }

    .dButton {
      background: #7E7E7E 0% 0% no-repeat padding-box;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      width: 124px;
      height: 33px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #FFFFFF;
      margin-top: 22px;
      margin-left: 10px;
    }

    .backButton {
      width: auto;
      height: 33px;
      border-radius: 4px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      margin-top: 22px;
      background: #FFFFFF;
      border: 1px solid #d9d9d9;
      color: #454545;
    }

    .button {
      width: auto;
      height: 33px;
      border: 1px solid #006E94;
      border-radius: 4px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #006E94;
      background: #FFFFFF;
      margin: 22px 10px 0 0;
    }

    .uploadbutton {
      width: auto;
      height: 33px;
      border: 1px solid #006E94;
      border-radius: 4px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #FFFFFF;
      margin-top: 20px;
      margin-left: 10px;
      background: #006e94;
    }

    .muladdButton {
      background: #7E7E7E 0% 0% no-repeat padding-box;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      width: auto;
      height: 33px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #FFFFFF;
      margin-top: 22px;
      margin-left: 10px;
    }

    .selectedItem {
      margin-top: 28px;
      margin-left: 20px;
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #FFFFFF;
    }

    .selectedItemBlack {
      margin-top: 28px;
      margin-left: 20px;
      font: normal normal bold 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #000000;
    }

    .next {
      display: inline-flex;
      margin-bottom: -6px;
    }

    .path {
      margin-top: 28px;
      margin-left: 20px;
      font: normal normal normal 16px/21px Microsoft JhengHei;
      color: #555555;
    }

    .currentpath {
      margin: 28px 0 0 10px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #222222;
    }

    .uploadTitle {
      margin-top: 28px;
      margin-left: 20px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #222222;
    }

    .divider {
      margin: 15px 0;
    }
  }

  .wrapper {
    display: flex;
    align-items: left;
    justify-content: left;
    overflow-y: auto;
    max-height: 78vh;
  }

  .wrapperBasic {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 10px;
    margin-left: 28px;
    width: auto;
    height: auto;
  }

  .item {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 0px;
    margin-left: 10px;
    width: auto;
    height: 240px;
  }

  .wrapperField {
    display: grid;
    margin: 20px;
    grid-row-gap: 1em;
    text-align: right;
  }

  .wrapperInput {
    display: grid;
    margin-top: 35px;
    margin-left: -10px;
    text-align: left;
  }

  .wrapperline {
    display: grid;
    grid-row-gap: 1em;
  }

  .filterBox {
    margin-top: 10px;
    height: 40px;
    padding: 0 30px;

    .siteSelect {
      padding: 8px 8px;
      width: 230px!important;
      height: 35px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #4D4D4D;
      font-size: 16px;
      margin-right: 20px;
      background-color: #FFFFFF;
      text-align: left;
      &:hover {
          border-color: ${color.blue1};
          background-color: ${color.blue2};
      };
    }

    .formLabel {
      font-size: 16px;
      padding-right: 10px;
      color: #454545;
      font-weight: bold;
      padding-top: 8px;
    }

    .cloudformLabel {
      font-size: 16px;
      padding-right: 10px;
      color: #454545;
      font-weight: bold;
      padding-top: 7px;
    }

    .sortbutton {
      width: 65px;
      height: 33px;
      padding: 3px;
      border: 1px solid #006E94;
      border-radius: 4px;
      font: normal normal bold 16px/21px Microsoft JhengHei;
      color: #006E94;
      background: #FFFFFF;
    }
  }
  
  .noPlayList {
    text-align: center;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }

  .noPlayListMsg {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
  }

  .progress {
    width: 320px;
    margin-right: 12px;
    display: inline-block;
  }

  .progressBox {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
  }

  .progressText {
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-top: 14px;
  }

  .video {
    padding-left: 8px;
    padding-top: 45px;
  }

  .verLine {
    border-left: 6px solid #006E94;
    height: 19px;
    width: 3px;
    margin-top: 45px;
    margin-left: 20px;
  }

  .playlistLine {
    border-left: 6px solid #006E94;
    height: 19px;
    width: 3px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .basic {
    font: normal normal bold 20px/27px Microsoft JhengHei;
    color: #222222;
    width: fit-content;
    height: 27px;
    margin-top: 40px;
    margin-left: 0px;
  }

  .playlistbasic {
    font: normal normal bold 20px/27px Microsoft JhengHei;
    color: #222222;
    width: fit-content;
    height: 27px;
    margin-top: 15px;
    margin-left: 10px;
  }

  .textfield {
    font: normal normal normal 16px/21px Microsoft JhengHei;
    color: #454545;
  }

  .inputfield {
    margin-top: 7px;
  }

  .textValue {
    font: normal normal normal 16px/21px Microsoft JhengHei;
    color: #454545;
    margin-top: 13px;
  }

  .dateValue {
    font: normal normal normal 16px/21px Microsoft JhengHei;
    color: #454545;
    margin-top: 20px;
  }

  .timesValue {
    font: normal normal normal 16px/21px Microsoft JhengHei;
    color: #454545;
    margin-top: 16px;
  }

  .totalValue {
    font: normal normal normal 16px/21px Microsoft JhengHei;
    color: #454545;
    margin-top: 13px;
  }

  .viewbutton {
    width: 135px;
    height: 33px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    font: normal normal bold 16px/16px Microsoft JhengHei;
    color: #555555;
    margin-left: 10px;
  }

  .dashedline {
    width: 730px;
    height: 0px;
    border: 1px dashed #999999;
    margin-top: 80px;
    margin-left: 30px;
  }

  .viewjoinButton {
    width: 160px;
    height: 28px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #006E94;
    border-radius: 4px;
    text-align: center;
    font: normal normal bold 16px/21px Microsoft JhengHei;
    color: #006E94;
    margin-top: -5px;
    margin-left: 435px;
  }

  .cloudAddImg {
    position: relative;
    margin-top: 50px;
  }
`;

export const MessageContainer = styled.div`
  .msg {
    &Modal{
    position: Fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    z-index: 100;
      &Content{
        background-color: #F4FBFC;
        position: absolute;
        min-width: 350px;
        width: auto;
        border-radius: 14px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: color.blue1 + ' !important',
    color: color.white1,
  },
  menuItemDate: {
    width: '240px!important',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: color.blue1, // + " !important",
      color: '#fff',
    },
  },
  datePickerApply: {
    width: '50px',
    height: '27px',
    border: '1px solid #E3E3E3',
    backgroundColor: color.blue1,
    color: color.white1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: color.blue3,
      color: '#fff',
    },
  },
  datePickerCancel: {
    width: '55px',
    height: '27px',
    border: '1px solid #E3E3E3',
    backgroundColor: color.white1,
    color: color.blue1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cccccc',
      color: '#fff',
    },
  },
  arrowDropDown: {
    position: 'absolute',
    right: '10px',
  },
  box: {
    display: 'flex',
  },
  containerBox: {
    marginBottom: '10px',
    display: 'flex',
  },
  navigateNext: {
    float: 'right',
    verticalAlign: 'middle',
  },
  input: {
    marginLeft: '10px',
    marginTop: '3px',
    flex: 1,
  },
  disabledButton: {
    backgroundColor: theme.palette.primary,
  },
  doneButton: {
    width: 'auto',
    height: '33px',
    border: '1px solid #006E94',
    borderRadius: '4px',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#006E94',
    background: '#FFFFFF',
  },
  menuItemLabel: {
    width: '220px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const useScheduleStyles = makeStyles(() => ({
  scheduleContainer: {
    paddingTop: '64px',
    paddingBottom: '20px',
  },
  returnBox: {
    display: 'flex',
    margin: 'auto',
    height: '76px',
    textAlign: 'left',
    paddingLeft: '35px',
    paddingRight: '30px',
  },
  returnButtonPos: {
    float: 'left',
    textAlign: 'left',
  },
  returnButton: {
    width: 'auto',
    height: '33px',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#454545',
    marginTop: '22px',
    background: '#FFFFFF',
  },
  pageTitle: {
    marginTop: '28px',
    marginLeft: '20px',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#222222',
  },
  scheduleBox: {
    height: 'auto',
    boxShadow: '0px 3px 6px #00000033',
    borderRadius: '12px',
    margin: '0px 35px 0px 35px',
    backgroundColor: 'white',
  },
  newScheduleBox: {
    display: 'flex',
    height: '280px',
    padding: '20px 20px 0 20px',
  },
  newScheduleContainer: {
    flexDirection: 'column',
    borderBottom: '2px solid #D9D9D9',
  },
  titleBox: {
    height: '50px',
    padding: '20px 20px 0 20px',
    font: 'normal normal bold 20px/27px Microsoft JhengHei',
  },
  saveButton: {
    height: 35,
    '&:hover': {
      backgroundColor: color.blue3,
      color: '#fff',
    },
    color: '#ffffff',
    backgroundColor: '#006E94',
    font: 'normal normal bold 16px/16px Microsoft JhengHei',
    borderRadius: '4px',
  },
  fieldTitleLabel: {
    marginBottom: '5px',
    '& div input': {
      color: '#006E94',
      font: 'normal normal bold 16px/21px Microsoft JhengHei',
    },
  },
  siteLabel: {
    width: '95%',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  nameLabel: {
    width: '95%',
    textAlign: 'left',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  tipLabel: {
    '& div input': {
      color: 'red',
      fontSize: '10px',
    },
  },
  toLabel: {
    width: '40px',
    '& div input': {
      color: '#454545',
      textAlign: 'center',
    },
  },
  gridField: {
    display: 'flex',
    flexDirection: 'column',
    height: '104px',
  },
  datePickerInput: {
    width: '250px',
    height: '35px',
    textAlign: 'left',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    paddingLeft: '8px',
  },
  timepicker: {
    width: '140px',
    height: '36px',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    paddingLeft: '12px',
    font: 'normal normal normal 16px/22px Roboto',
    color: '#555555',
    backgroundColor: '#fff!important',
    '&:active': {
      outline: 'none',
      border: '1px solid #028BBA!important',
    },
    '&:focus': {
      outline: 'none',
      border: '1px solid #028BBA!important',
    },
  },
  calendarBox: {
    height: 'auto',
    padding: '20px',
  },
  contentStyle: {
    textAlign: 'left',
    height: '100px',
    width: '340px',
    padding: '45px 24px 45px 40px',
  },
  publishIcon: {
    '& path': {
      stroke: '#FFFFFF',
    },
  },
  verticalLine: {
    borderLeft: '1px solid #D9D9D9',
    height: '180px',
  },
  columnContainer: {
    flexDirection: 'column',
    padding: '0 0 0 20px',
  },
  siteChangeTip: {
    font: 'normal normal bold 13px Microsoft JhengHei',
    textAlign: 'left',
    color: '#006E94',
  },
}));

export const CustomStyle = css`
   .rbc{
    &-header {
      overflow: hidden;
      flex: 1 0 0%;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      font-size: 90%;
      min-height: 0;
      border-bottom: 1px solid #DDD;
      + .rbc-header {
        border-left: 1px solid #DDD;
      }
      > a, > a:active, > a:visited{
        color: inherit;
        text-decoration: none;
      }
    }
   }
   .calendarHeader-month {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 18px;
    background: #EBFAFF;
  }
  .rbc-time-header-cell > .rbc-header {
    background: #EBFAFF;
    display: flex;
    justify-content: center;
  }
  .rbc-time-header-cell-single-day > .rbc-header {
    background: #EBFAFF;
    display: flex;
    justify-content: left;
  }
  .custom-date-header {
    height: 80px;
    font-size: 16px;
    font-weight: bold;
    padding: 13px 0;
  }
  .rbc-button-link {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
  }
  .date-number {
    font-size: 20px;
    margin-top: 5px;
    line-height: 36px;
  }
  .rbc-header.rbc-today > .rbc-button-link > .custom-date-header {
    color: #4ABFE8;
  }
  .rbc-header.rbc-today > .rbc-button-link > .custom-date-header > .date-number {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    border: 1px solid #006e94;
    background: #028BBA;
    color: #ffffff;
  }
  .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
    background: #EBFAFF !important;
  }
  .rbc-label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px !important;
    height: 100%;
    font-size: 14px;
    background: #EBFAFF;
  }
  .rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 32px;
  }
  .rbc-date-cell.rbc-now > .highlight {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    border: 1px solid #006e94;
    background: #028BBA;
    color: #ffffff;
  }
  .rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    padding-right: 9px;
  }
  .rbc-time-content::-webkit-scrollbar {
    width: 0.4em;
  }

  .rbc-time-content::-webkit-scrollbar-thumb {
    background-color: #9A9A9A;
    border-radius: 30px;
  }
`;

export const CMSCalendar = styled.div`
  width: 100%;
  ${CustomStyle}
  ${CalendarStyle}
`;

export const useTopMenu = makeStyles(() => ({
  topMenuBox: {
    display: 'flex',
    marginBottom: '15px',
  },
  pickerButton: {
    marginLeft: 15,
    width: '85px',
  },
  middleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  leftNext: {
    margin: '0 10px',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
  },
  rightNext: {
    margin: '0 10px',
    cursor: 'pointer',
  },
  middleText: {
    width: '250px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  viewButton: {
    color: '#006E94',
    border: '1px solid #006E94!important',
    fontWeight: 'bold',
  },
  viewTypeBox: {
    marginLeft: 'auto',
  },
}));

export const useEvents = makeStyles(() => ({
  eventGrid: {
    height: '100%',
    cursor: 'pointer',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '7px',
    '&:hover': {
      transform: 'translate(-2px, -2px)',
      boxShadow: '3px 2px 3px rgb(0 0 0 / 15%)',
    },
  },
}));

export const useShowMore = makeStyles(() => ({
  root: {
    width: '250px',
    height: '550px',
    border: '1px solid #D9D9D9',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0px 0px 24px #0000003D',
  },
  iconButton: {
    position: 'absolute',
    marginLeft: -40,
    top: 12,
    color: '#000000',
  },
  list: {
    width: 'auto',
    maxHeight: '60vh',
    height: '350px',
    overflowY: 'auto',
  },
  closeIcon: {
    color: '#006E94',
  },
  titleTypographyProps: {
    font: 'normal normal normal 16px Microsoft JhengHei',
  },
  subheaderTypographyProps: {
    font: 'normal normal bold 21px Segoe UI',
    margin: 'auto',
  },
  subheaderToday: {
    width: '28px',
    height: '28px',
    lineHeight: '28px',
    borderRadius: '50%',
    border: '1px solid #006e94',
    background: '#028BBA',
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  taskName: {
    width: '230px',
    marginLeft: '-6px',
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    font: 'normal normal normal 14px/17px Roboto',
  },
}));

export const useTaskModal = makeStyles(() => ({
  footer: {
    display: 'flex',
    borderTop: '1px solid #eee',
    justifyContent: 'center',
    background: '#F4FBFC',
    height: 80,
  },
  card: {
    width: '500px',
    borderRadius: '10px',
    boxShadow: '0px 0px 24px #0000003D',
  },
  loadingCard: {
    width: '500px',
    borderRadius: '10px',
    height: '475px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardHeaderTitleRoot: {
    marginLeft: '180px',
  },
  cardHeaderRoot: {
    backgroundColor: '#006E94',
    color: '#FFFFFF',
    font: 'normal normal bold 20px/23px Arial',
    height: '64px',
  },
  closeIcon: {
    color: 'white',
  },
  gridImg: {
    display: 'flex',
    height: '100%',
    width: '100%',
    paddingBottom: '20px',
  },
  gridField: {
    display: 'flex',
    height: '30px',
    width: '100%',
  },
  fieldTitleLabel: {
    width: '40%',
    '& div input': {
      color: '#454545',
    },
  },
  fieldLabel: {
    '& div input': {
      color: '#454545',
      font: 'font: normal normal normal 16px/21px Microsoft JhengHei',
    },
  },
  fieldNameLabel: {
    marginLeft: '10px',
    '& div input': {
      width: '400px',
      color: '#454545',
      font: 'normal normal bold 20px/27px Microsoft JhengHei',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  fieldDateLabel: {
    marginLeft: '10px',
    width: '100%',
    '& div input': {
      color: '#454545',
      font: 'normal normal normal 16px/21px Microsoft JhengHei',
    },
  },
  imgStyle: {
    width: '63px',
    height: '63px',
  },
  siteLabel: {
    width: '530px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '5px 0 5px 0',
  },
}));

export const useTaskEdit = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 60,
    width: '500px',
    borderRadius: '10px',
    background: '#F4FBFC',
  },
  iconButton: {
    position: 'absolute',
    right: 8,
    top: 10,
  },
  closeIcon: {
    color: 'white',
  },
  dialogTitle: {
    backgroundColor: '#006E94',
  },
  imgStyle: {
    width: '63px',
    height: '63px',
  },
  titleLabel: {
    marginLeft: '160px',
    '& div input': {
      color: '#FFFFFF',
      font: 'normal normal bold 20px/23px Arial',
    },
  },
  nameLabel: {
    width: '400px',
    textAlign: 'left',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    marginLeft: '10px',
    font: 'normal normal bold 20px/23px Arial',
  },
  fieldLabel: {
    '& div input': {
      color: '#006E94',
      font: 'normal normal bold 16px/21px Microsoft JhengHei',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5px',
    height: '80px',
  },
  datePickerInput: {
    width: '60%',
    height: '35px',
    textAlign: 'left',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    paddingLeft: '8px',
    background: '#FFFFFF',
  },
  timepicker: {
    width: '170px',
    height: '36px',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    paddingLeft: '12px',
    fontSize: '14px',
    backgroundColor: '#fff!important',
    '&:active': {
      outline: 'none',
      border: '1px solid #028BBA!important',
    },
    '&:focus': {
      outline: 'none',
      border: '1px solid #028BBA!important',
    },
  },
  toLabel: {
    width: '25px',
    margin: '0 3px 0 7px',
    '& div input': {
      color: '#454545',
    },
  },
  tipLabel: {
    '& div input': {
      color: 'red',
      fontSize: '10px',
    },
  },
}));

export const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#006E94',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#006E94',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#006E94',
      },
      thumb: {
        border: '14px solid #006E94',
      },
    },
  },
});

export const useStylesCMSCard = makeStyles(() => ({
  portraitImage: {
    width: '127px',
    height: '250px',
    padding: '10px 0',
  },
  landscapeImage: {
    width: '250px',
    height: '127px',
    padding: '0 10px',
  },
  portraitEmpty: {
    border: '1px solid #D9D9D9',
    width: '127px',
    height: '100%',
  },
  landscapeEmpty: {
    border: '1px solid #D9D9D9',
    height: '127px',
    width: '100%',
  },
  item: {
    position: 'relative',
    margin: '0 13px 8px 0',
    cursor: 'pointer',
    userSelect: 'none',
  },
  unCheckedImg: {
    border: '1px solid #D9D9D9',
  },
  textBox: {
    width: '250px',
    height: '95px',
    backgroundColor: 'transparent',
    marginTop: '10px',
  },
  textName: {
    color: '#454545',
    textAlign: 'left',
    font: 'normal normal bold 16px/19px Roboto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '230px',
    whiteSpace: 'nowrap',
  },
  textContent: {
    marginTop: '10px',
    textAlign: 'left',
    font: 'normal normal normal 14px/10px Roboto',
    color: '#454545',
    lineHeight: '20px',
  },
  uploadCover: {
    position: 'absolute',
    top: 0,
    width: '250px',
    height: '250px',
    opacity: 0.9,
    borderRadius: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  uploadText: {
    marginBottom: '10px',
    font: 'normal normal normal 16px/21px Roboto',
    color: '#454545',
  },
  failedText: {
    font: 'normal normal normal 16px/21px Roboto',
    color: '#FFFFFF',
  },
  linearProgress: {
    width: '160px',
    height: '10px',
  },
  colorPrimary: {
    backgroundColor: '#028BBA',
    borderRadius: 6,
  },
  barColorPrimary: {
    backgroundColor: '#aaaaaa',
    borderRadius: 6,
  },
  warning: {
    width: '61px',
    height: '59px',
    marginBottom: '10px',
  },
  publishedBox: {
    display: 'flex',
    position: 'absolute',
    width: '250px',
    height: '250px',
    alignItems: 'end',
    justifyContent: 'right',
    padding: '0 10px 10px 0',
  },
}));

export const useStylesCloudCard = makeStyles(() => ({
  image: {
    width: '299px',
    height: '200px',
    pointerEvents: 'none',
  },
  item: {
    position: 'relative',
    margin: '0 11px 8px 0',
    cursor: 'pointer',
    userSelect: 'none',
  },
  unChecked: {
    border: '3px solid transparent',
    '&:hover': {
      border: '3px solid #006E94',
    },
  },
  textBox: {
    position: 'absolute',
    right: 10,
    bottom: 35,
    width: '44px',
    height: '21px',
    background: '#454545',
    margin: '0px auto',
    font: 'normal normal bold 14px/17px Roboto',
    color: '#FFFFFF',
    padding: '2px 4px',
    borderRadius: '2px',
  },
  textName: {
    paddingTop: '5px',
    textAlign: 'left',
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
    color: '#454545',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '299px',
    whiteSpace: 'nowrap',
  },
}));

export const baseStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    userSelect: 'none',
  },
  button: {
    position: 'absolute',
    display: 'none',
    zIndex: 3,
    padding: '3px 0 0 3px',
  },
  contentButton: {
    position: 'absolute',
    display: 'none',
    zIndex: 2,
    right: 0,
  },
  dragged: {
    boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '& button': {
      opacity: 0,
    },
  },
  showCheckbox: {
    display: 'flex',
  },
  checked: {
    border: '3px solid #006E94',
  },
}));
