import axios from 'axios';
import { AppConfig } from '@/config/index';
import { apiRefresh } from './auth.js';

const { API_URL } = AppConfig;
const { ASPECT_URL } = AppConfig;

// request URL 與 headers 設定
const requestImage = axios.create({
  baseURL: `${API_URL}/api/`,
});
const aspectRequest = axios.create({
  baseURL: `${ASPECT_URL}`,
});

aspectRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const { config } = error;
      const refresh = sessionStorage.getItem('refresh');
      const data = { refresh };
      return apiRefresh(data).then((res) => {
        const { access } = res.data;
        sessionStorage.setItem('token', access);
        config.headers.Authorization = `Bearer ${access}`;
        return aspectRequest(config);
      }).catch((err) => {
        console.log('抱歉，您的登入狀態已失效，請重新登入！');
        return Promise.reject(err);
      });
    }
    return Promise.reject(error.response);
  },
);

// 檢查 request 的 token 是否過期需 Refresh token
requestImage.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const { config } = error;
      const refresh = sessionStorage.getItem('refresh');
      const data = { refresh };
      return apiRefresh(data).then((res) => {
        const { access } = res.data;
        sessionStorage.setItem('token', access);
        config.headers.Authorization = `Bearer ${access}`;
        return requestImage(config);
      }).catch((err) => {
        console.log('抱歉，您的登入狀態已失效，請重新登入！');
        return Promise.reject(err);
      });
    }
    return Promise.reject(error.response);
  },
);

export { requestImage, aspectRequest };
