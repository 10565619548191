import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiChargingAreas } from '@/cloud_api/chargingAreaGroup';
import { apiDeviceInfoList } from '@/cloud_api/device';

export const getChargingAreas = createAsyncThunk(
  'dashboardSlice/getChargingAreas',
  async (_, { rejectWithValue }) => {
    try {
      const result = await apiChargingAreas();
      const chargingListWithDeviceId = [];
      const chargingListWithoutDeviceId = [];

      result.data.forEach((chargingGroup) => {
        chargingGroup.point_list.forEach((charging) => {
          const chargingData = {
            ...chargingGroup,
            ...charging,
            name: `${chargingGroup.name}-${charging.order}`,
            point_name: '',
            id: `${chargingGroup.id}_${charging.point_id}`,
          };
          delete chargingData.point_list;
          if (chargingData.device_id) chargingListWithDeviceId.push(chargingData);
          else chargingListWithoutDeviceId.push(chargingData);
        });
      });

      await Promise.all(
        chargingListWithDeviceId.map(
          async (charging, index) => {
            const device = await apiDeviceInfoList({ device_id: charging.device_id });
            chargingListWithDeviceId[index] = { ...chargingListWithDeviceId[index], point_name: device.data.name };
          },
        ),
      );

      const chargingList = chargingListWithDeviceId.concat(chargingListWithoutDeviceId);

      return chargingList;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
      });
    }
  },
);
