import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { languageValue } from '@/page/Setting/SettingUtils.js';
// const path = require('path');

let language = localStorage.getItem('LANGUAGE');
if (!language) {
  const navigatorLang = navigator.language.toLowerCase();
  const langValue = languageValue.find((x) => x.value === navigatorLang);
  language = langValue ? langValue.language : 'en';
  localStorage.setItem('LANGUAGE', language);
}

const setLanguage = languageValue.find((x) => x.language === language || x.value === language).language;

// 原本是存 languageValue -> value，現在改成存 language
if (languageValue.some((x) => x.value === language)) {
  localStorage.setItem('LANGUAGE', setLanguage);
}

i18n
  .use(Backend)
  // 將 i18next 傳入 react-i18next 裡面
  .use(initReactI18next)
  // 實例化 initReactI18next
  .init({
    backend: {
      loadPath: './src/static/locales/{{lng}}/{{ns}}.json',
    },
    // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
    fallbackLng: 'en',
    // 預設語言
    lng: setLanguage,
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
