/* eslint-disable */
import L from 'leaflet';

export const VirtualGrid = L.FeatureGroup.extend({
  include: L.Mixin.Events,
  options: {
    cellSize: 64,
    delayFactor: 0.5,
    style: {
      stroke: true,
      color: '#aaaaaa',
      dashArray: [0, 0],
      lineCap: null,
      lineJoin: null, 
      weight: 1,
      opacity: 1,

      fill: false,
      fillColor: null, // same as color by default
      fillOpacity: 0,

      clickable: false,
    },
  },
  initialize(options) {
    L.Util.setOptions(this, options);
    L.FeatureGroup.prototype.initialize.call(this, [], options);
  },
  onAdd(map) {
    L.FeatureGroup.prototype.onAdd.call(this, map);
    this._map = map;
    this._cells = [];
    this._setupGrid(map.getBounds());

    map.on('move', this._moveHandler, this);
    map.on('zoomend', this._zoomHandler, this);
    map.on('resize', this._resizeHandler, this);
  },
  onRemove(map) {
    L.FeatureGroup.prototype.onRemove.call(this, map);
    map.off('move', this._moveHandler, this);
    map.off('zoomend', this._zoomHandler, this);
    map.off('resize', this._resizeHandler, this);
  },
  _clearLayer() {
    this._cells = [];
  },
  _moveHandler(e) {
    this._renderCells(e.target.getBounds());
  },
  _zoomHandler(e) {
    this.clearLayers();
    const zoomedCellSize = e.target.getZoomScale(e.target.getZoom(), 0) * this.options.cellSize;
    this._cellSize = zoomedCellSize;
    this._setupSize();
    this._loadedCells = [];
    this._renderCells(e.target.getBounds());
  },
  _renderCells(bounds) {
    const cells = this._cellsInBounds(bounds);
    this.fire('newcells', cells);
    for (let i = cells.length - 1; i >= 0; i -= 1) {
      const cell = cells[i];
      if (this._loadedCells.indexOf(cell.id) === -1) {
        this.addLayer(L.rectangle(cell.bounds, this.options.style));
        this._loadedCells.push(cell.id);
      }
    }
  },
  _resizeHandler() {
    this._setupSize();
  },
  _setupSize() {
    this._rows = Math.ceil(this._map.getSize().x / this._cellSize);
    this._cols = Math.ceil(this._map.getSize().y / this._cellSize);
  },
  _setupGrid(bounds) {
    this._origin = this._map.project(bounds.getNorthWest());
    this._cellSize = this.options.cellSize;
    this._setupSize();
    this._loadedCells = [];
    this.clearLayers();
    this._renderCells(bounds);
  },
  _cellPoint(row, col) {
    const x = this._origin.x + (row * this._cellSize);
    const y = this._origin.y + (col * this._cellSize);
    return new L.Point(x, y);
  },
  _cellExtent(row, col) {
    const swPoint = this._cellPoint(row, col);
    const nePoint = this._cellPoint(row - 1, col - 1);
    const sw = this._map.unproject(swPoint);
    const ne = this._map.unproject(nePoint);
    return new L.LatLngBounds(ne, sw);
  },
  _cellsInBounds(bounds) {
    const offset = this._map.project(bounds.getNorthWest());
    const center = bounds.getCenter();
    const offsetX = this._origin.x - offset.x;
    const offsetY = this._origin.y - offset.y;
    const offsetRows = Math.round(offsetX / this._cellSize);
    const offsetCols = Math.round(offsetY / this._cellSize);
    const cells = [];
    for (let i = 0; i <= this._rows; i += 1) {
      for (let j = 0; j <= this._cols; j += 1) {
        const row = i - offsetRows;
        const col = j - offsetCols;
        const cellBounds = this._cellExtent(row, col);
        const cellId = row + ':' + col;
        cells.push({
          id: cellId,
          bounds: cellBounds,
          distance: cellBounds.getCenter().distanceTo(center),
        });
      }
    }
    cells.sort((a, b) => {
      const distance = a.distance - b.distance;
      return distance;
    });
    return cells;
  },
});

// 自訂 CRS
const mapCRS = (resolution) => {
  const scaleValue = 1 / resolution;
  L.CRS.pr = L.extend({}, L.CRS.Simple, {
    projection: L.Projection.LonLat,
    transformation: new L.Transformation(scaleValue, 0, -scaleValue, 0),
    // Changing the transformation is the key part, everything else is the same.
    // By specifying a factor, you specify what distance in meters one pixel occupies (as it still is CRS.Simple in all other regards).
    // In this case, I have a tile layer with 256px pieces, so Leaflet thinks it's only 256 meters wide.
    // I know the map is supposed to be 2048x2048 meters, so I specify a factor of 0.125 to multiply in both directions.
    // In the actual project, I compute all that from the gdal2tiles tilemapresources.xml,
    // which gives the necessary information about tilesizes, total bounds and units-per-pixel at different levels.

    // Scale, zoom and distance are entirely unchanged from CRS.Simple
    scale: (zoom) => {
      const scale_value = 1 + (zoom / 100);
      return scale_value;
    },

    zoom: (scale) => {
      const zoom_value = (scale - 1) * 100;
      return zoom_value;
    },

    distance: (latlng1, latlng2) => {
      const dx = latlng2.lng - latlng1.lng;
      const dy = latlng2.lat - latlng1.lat;

      return Math.sqrt(dx * dx + dy * dy);
    },
    infinite: true,
  });
  return L.CRS.pr;
};

const virtualGrid = (options) => {
  const gridLayer = new VirtualGrid(options);
  return gridLayer;
};

const MapClass = {
  GridLayer: virtualGrid,
  PGMCRS: mapCRS,
};

export default MapClass;
