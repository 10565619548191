import styled from 'styled-components';
import color from '@/color/theme.js';

export const NotificationDiv = styled.div`
   text-align:center;
   padding:30px 20px;
`;

export const Image = styled.img`
    width: 86px;
    height: 86px;
`;

export const H4 = styled.h4`
  color:${color.gray2};
  font-size: 20px;
  text-align:center;
`;
