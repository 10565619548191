import { request } from './request.js';
import getToken from '../common/getToken.js';

// version  api

// get all version
export const apiVersionList = () => request.get('framework/version/', getToken());

export const apiVersionValueCreate = (data) => request.post('framework/version/', data, getToken());

export const apiVersionValue = (version_name) => request.get(`framework/version/?name=${version_name}`, getToken());

export const apiVersionValueByOne = (version_name, version) => request.get(`framework/version/?name=${version_name}&version=${version}`, getToken());
