import CursorBrushWS from '../../static/icon/MapEdit/cursorBrush_WS.png';
import CursorBrushWM from '../../static/icon/MapEdit/cursorBrush_WM.png';
import CursorBrushWL from '../../static/icon/MapEdit/cursorBrush_WL.png';
import CursorBrushGS from '../../static/icon/MapEdit/cursorBrush_GS.png';
import CursorBrushGM from '../../static/icon/MapEdit/cursorBrush_GM.png';
import CursorBrushGL from '../../static/icon/MapEdit/cursorBrush_GL.png';
import CursorBrushBS from '../../static/icon/MapEdit/cursorBrush_BS.png';
import CursorBrushBM from '../../static/icon/MapEdit/cursorBrush_BM.png';
import CursorBrushBL from '../../static/icon/MapEdit/cursorBrush_BL.png';
import CursorPen from '../../static/icon/MapEdit/cursorPen.png';
import CursorSelect from '../../static/icon/MapEdit/cursorSelect.png';
import RemarkImg from '../../static/icon/MapEdit/Remark.png';
import StoreImg from '../../static/icon/MapEdit/Store.png';
import DeliveryImg from '../../static/icon/MapEdit/Delivery.png';
import TaskPointImg from '../../static/icon/MapEdit/taskPointCursor.png';
import DockImg from '../../static/icon/MapEdit/DockTool.png';
import ElevatorImg from '../../static/icon/MapEdit/ElevatorPoint.png';
import ConnectionPointImg from '../../static/icon/MapEdit/ic_connect_point.png';
import AbandonImg from '../../static/icon/MapEdit/Abandon.png';
import HalfwayImg from '../../static/icon/MapEdit/Halfway.png';
import LandmarkImg from '../../static/icon/MapEdit/Landmark.png';

/** TOOL_TYPE 類別 */
export const markerTypes = Object.freeze(
  ['Remark', 'Store', 'Delivery', 'Home', 'Halfway', 'Landmark', 'ConnectionPoint', 'Abandon'],
);
export const polygonTypes = Object.freeze(
  ['Prioritization', 'Forbidden', 'Disinfection', 'MapArea'],
);
export const polylineTypes = Object.freeze(
  ['NarrowLane', 'VirtualWall', 'NarrowGate', 'Passage'],
);
export const rectangleTypes = Object.freeze(
  ['LowSpeed', 'Slope', 'NonAvoidance', 'OneWay'],
);
export const siteTypes = Object.freeze(
  ['ParkingArea', 'Charger', 'ConnectMap'],
);

export const cursorIcon = {
  cursorBrush_WL: CursorBrushWL,
  cursorBrush_WM: CursorBrushWM,
  cursorBrush_WS: CursorBrushWS,
  cursorBrush_GL: CursorBrushGL,
  cursorBrush_GM: CursorBrushGM,
  cursorBrush_GS: CursorBrushGS,
  cursorBrush_BL: CursorBrushBL,
  cursorBrush_BM: CursorBrushBM,
  cursorBrush_BS: CursorBrushBS,
  cursorPen: CursorPen,
  cursorSelect: CursorSelect,
  cursorRemark: RemarkImg,
  cursorStore: StoreImg,
  cursorDelivery: DeliveryImg,
  cursorElevator: ElevatorImg,
  cursorTask: TaskPointImg,
  cursorDock: DockImg,
  cursorHome: RemarkImg,
  cursorLandmark: LandmarkImg,
  cursorConnectionPoint: ConnectionPointImg,
  cursorHalfway: HalfwayImg,
  ElevatorImg,
  cursorAbandon: AbandonImg,
};
