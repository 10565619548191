import styled from 'styled-components';
import color from '@/color/theme.js';
import { Select as MuiSelect, Input as MuiInput } from '@material-ui/core';

export const LoginButton = styled.button`
      margin-top: 24px;
      height: 36px;
      width:100%;
      border: none;
      border-radius: 8px;
      background-color: ${color.btnColor1};
      color: #ffffff;
      cursor: pointer;
      transition: all .5s;
      font-size: 16px;

      &:hover {
        transform: scale(1.1);
      }
`;

export const Input = styled(MuiInput)`
  height: 36px;
  width: ${(props) => (props.fullWidth ? '100%' : '100px')};
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-color:${(props) => (props.errormsg ? '#ca3e47' : '#D9D9D9')} ;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  :after{
    content:'${(props) => (props.errormsg ? props.errormsg : '')}';
    color:#ca3e47;
    top:36px;
    transform: none;
  }
  & input{
    width:${(props) => (props.inputwidth || '100%')};
  }
`;

export const Select = styled(MuiSelect)`
  height: 36px;
  width: ${(props) => (props.fullWidth ? '100%' : props.width || '150px')};
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-color:${(props) => (props.errormsg ? '#ca3e47' : '#D9D9D9')} ;
  border-radius: 6px;
  font-size: 16px;
  text-align:left;

  .MuiInput-input{
    padding:6px 24px 7px 10px;
  }
  :after{
    content:'${(props) => (props.errormsg ? props.errormsg : '')}';
    color:#ca3e47;
    top:36px;
    transform: none;
  }
  &.Mui-disabled{
    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
  }
`;

export const ListCard = styled.div`
 padding: 84px 40px 20px 40px;
 text-align:left;
  .MuiCard-root {
    border-radius: 4px;
    background-color: #fff !important;
    padding:10px;
  }
  .MuiGrid-container{
    padding:0px !important;
    margin-top:5px;
  }
`;
