import {
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import React, { useState } from 'react';
import PasswordIcon from '@/static/icon/Password/Password.jsx';
import { Password } from './Style.js';

export default (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { showError, value, setValue, placeholder } = props;

  return (
    <Password
      type={showPassword ? 'text' : 'password'}
      value={value}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      error={showError}
      startAdornment={(
        <InputAdornment position="start">
          <PasswordIcon />
        </InputAdornment>
        )}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={(e) => e.preventDefault()}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
        )}
    />
  );
};
