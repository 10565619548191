import styled from 'styled-components';
import color from '@/color/theme.js';
import { Link } from 'react-router-dom';

export const NavLink = styled(Link)`
   :link,:visited,:active{
    color:${color.blue1};
    font-size: 18px;
    font-weight: bold;
   }
`;

export const NavDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  box-shadow: 0px 3px 6px #00202B33;
  overflow: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #fff;
    color: ${color.navFont};
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  .menuBtn {
    width: 64px;
    height: 64px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding-left: 16px;
  }

  .navUser {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    font-size: 16px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    &Select{
      margin-right: 30px;
      margin-top: 1px;
      
      .MuiOutlinedInput-notchedOutline{
        /* border-color: ${color.blue1}; */
        opacity: 0.7;
      }
      .MuiSelect-selectMenu{
        padding-top: 5px;
        padding-right: 25px;
        padding-bottom: 5px;
        min-width: 50px;
        color: ${color.blue1};
      }
      .MuiSelect-iconOutlined{
        right: 0;
      }
    }
  }

  .navVert {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  @media (min-width: 600px) {
    .MuiToolbar-gutters {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const OrganizationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }

  img {
    height: 25px;
    margin-right: 5px;
  }
`;
