import axios from 'axios';
import { AppConfig } from '@/config/index';
import { request } from './request.js';

const { API_URL } = AppConfig;
const { ASPECT_URL } = AppConfig;

// login 相關的 api
const authRequest = axios.create({
  baseURL: `${API_URL}/auth`,
});

const aspectRequest = axios.create({
  baseURL: `${ASPECT_URL}`,
});

export const apiUserLogin = (data) => aspectRequest.post('/login/', data);
export const apiRefresh = (data) => authRequest.post('/jwt/refresh/', data);
export const apiTokenVerify = (data) => authRequest.post('/jwt/refresh/', data);
export const apiCheckEmailToken = (token) => request.get(`/auth/check_email_token_status/${token}`);
export const apiSendEmail = (data) => request.post('/auth/send_email', data);
export const apiResetPassword = (data) => request.post('/auth/reset_password', data);
