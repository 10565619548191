import React from 'react';
import L from 'leaflet';
import ElevatorDivIcon from '@/page/EditMap/components/ElevatorModal/ElevatorDivIcon.jsx';
import { renderToStaticMarkup } from 'react-dom/server';
import Node from '../../static/icon/MapLeaflet/node.png';
import FirstNode from '../../static/icon/MapLeaflet/firstNode.png';
import StoreImg from '../../static/icon/MapLeaflet/Store.png';
import RemarkImg from '../../static/icon/MapLeaflet/remark.png';
import DeliveryImg from '../../static/icon/MapLeaflet/Delivery.png';
import DockImg from '../../static/icon/MapLeaflet/DockTool.png';
import HomeImg from '../../static/icon/MapLeaflet/HomeTool.png';
import DockSelectImg from '../../static/icon/MapLeaflet/dockPointSelected.png';
import HomeSelectImg from '../../static/icon/MapLeaflet/homePointSelected.png';
import TaskPointImg from '../../static/icon/MapLeaflet/taskPoint.png';
import TaskPointSelectImg from '../../static/icon/MapLeaflet/taskPointSelected.png';
import ParkingAreaPoint from '../../static/icon/MapLeaflet/ic_pickup_area.png';
import ChargerImg from '../../static/icon/MapLeaflet/Charger.png';
import ManualChargerImg from '../../static/icon/MapLeaflet/ManualCharger.png';
import WorkingAreaPoint from '../../static/image/image_iotgate_workingArea.png';
import robotImg from '../../static/icon/MapLeaflet/map_robot.png';
import OneWayArrow from '../../static/icon/MapLeaflet/oneWay_arrow.png';
import ScanIconImg from '../../static/icon/MapLeaflet/scanDot.png';
// import TestImg from '../../static/icon/MapLeaflet/test.png';
import ConnectionPointImg from '../../static/icon/MapLeaflet/ic_connect_point.png';
import AbandonImg from '../../static/icon/MapLeaflet/Abandon.png';
import HalfwayImg from '../../static/icon/MapLeaflet/Halfway.png';
import LandmarkImg from '../../static/icon/MapLeaflet/LandMark.png';

// 載入圖片多為非同步的函示，所以偷偷先載入圖片
const chargerImg = new Image();
chargerImg.src = ChargerImg;

const NodeIcon = new L.Icon({
  iconUrl: Node,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  className: 'cursorPointer',
});

const FirstNodeIcon = new L.Icon({
  iconUrl: FirstNode,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [0, 0],
  className: 'cursorPointer',
});

const RemarkIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: RemarkImg,
    iconRetinaUrl: RemarkImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const HalfwayIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: HalfwayImg,
    iconRetinaUrl: HalfwayImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const MapIcon = (scale, image, oneWaySize) => {
  const { height, width } = oneWaySize;
  return L.divIcon({
    html: `<div><img alt='outside' src='${image}' style='transform:rotate(-90deg); opacity: 0.5; height: ${height * scale}px; width: ${width * scale}px; border: 3px solid #4ABFE8'/></div>`,
    // iconSize: [200 * scale, 200 * scale],
    // iconAnchor: [100 * scale, 100 * scale],
    iconSize: [0, 0],
    iconAnchor: [(width / 2) * scale, (height / 2) * scale],
    popupAnchor: [0, 0],
  });
};

const ConnectionPointIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.connectionPoint;
  const icon = new L.Icon({
    iconUrl: ConnectionPointImg,
    iconRetinaUrl: ConnectionPointImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const robotIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.robot;

  const icon = new L.Icon({
    iconUrl: robotImg,
    iconRetinaUrl: robotImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.75],
    popupAnchor: [0, 0],
    isSelect: false,
  });

  return icon;
};

const DeviceNameIcon = (scale = 100, name) => {
  const size = (scale / 100) * baseSize.fontSize;

  const icon = L.divIcon({
    html: `<div style="white-space: nowrap; font-weight: bold; font-size: ${size}px;">${name}</div>`,
  });

  return icon;
};

const StoreIcon = (scale = 100) => {
  console.log('StoreIcon scale', scale);
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: StoreImg,
    iconRetinaUrl: StoreImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const AbandonIcon = (scale = 100) => {
  console.log('scale', scale);
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: AbandonImg,
    iconRetinaUrl: AbandonImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const LandmarkIcon = (scale = 100) => {
  console.log('scale', scale);
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: LandmarkImg,
    iconRetinaUrl: LandmarkImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const DeliveryIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.remark;
  const icon = new L.Icon({
    iconUrl: DeliveryImg,
    iconRetinaUrl: DeliveryImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 10],
  });

  return icon;
};

const DockIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.dock;
  const icon = new L.Icon({
    iconUrl: DockImg,
    iconRetinaUrl: DockImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 10],
    isSelect: false,
  });

  return icon;
};

const DockSelectIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.dockSelect;
  const icon = new L.Icon({
    iconUrl: DockSelectImg,
    iconRetinaUrl: DockSelectImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 10],
    isSelect: true,
  });

  return icon;
};

const HomeIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.home;
  const icon = new L.Icon({
    iconUrl: HomeImg,
    iconRetinaUrl: HomeImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    isSelect: false,
  });

  return icon;
};

const HomeSelectIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.homeSelect;
  const icon = new L.Icon({
    iconUrl: HomeSelectImg,
    iconRetinaUrl: HomeSelectImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    isSelect: true,
  });

  return icon;
};

const TaskIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.task;
  const icon = new L.Icon({
    iconUrl: TaskPointImg,
    iconRetinaUrl: TaskPointImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    isSelect: false,
  });

  return icon;
};

const TaskSelectIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.taskSelect;
  const icon = new L.Icon({
    iconUrl: TaskPointSelectImg,
    iconRetinaUrl: TaskPointSelectImg,
    iconSize: [size, size],
    iconAnchor: [size / 2, size * 0.59],
    popupAnchor: [0, 0],
    isSelect: true,
  });

  return icon;
};

const OneWayArrowIcon = (scale = 100, oneWaySize) => {
  let oneWayArrowSize = baseSize.oneWayArrow;

  if (oneWaySize.width < 0.7 || oneWaySize.long < 1) {
    oneWayArrowSize = 10;
  } else if ((oneWaySize.width < 1.3 && oneWaySize.width >= 0.7) || (oneWaySize.long < 2 && oneWaySize.long >= 1)) {
    oneWayArrowSize = 20;
  }

  const size = (scale / 100) * oneWayArrowSize;
  const icon = new L.Icon({
    iconUrl: OneWayArrow,
    iconRetinaUrl: OneWayArrow,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, 0],
  });

  return icon;
};

const ElevatorIcon = (id) => {
  const iconSize = 20;
  return L.divIcon({
    id,
    html: renderToStaticMarkup(<ElevatorDivIcon id={id} iconSize={iconSize} />),
    iconSize: [iconSize, iconSize],
    iconAnchor: [iconSize / 2, iconSize / 2],
    popupAnchor: [0, 0],
    className: 'elevatorMarker',
  });
};

const IotGateWorkingAreaIcon = (scale = 100) => {
  const size = (scale / 100) * baseSize.iotGate;
  const icon = new L.Icon({
    iconUrl: WorkingAreaPoint,
    iconRetinaUrl: WorkingAreaPoint,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 4],
    popupAnchor: [0, 0],
    isSelect: false,
  });

  return icon;
};

const createParkingAreaPointIcon = (scale) => {
  const icon = new L.Icon({
    iconUrl: ParkingAreaPoint,
    iconRetinaUrl: ParkingAreaPoint,
    iconSize: [30 * scale, 30 * scale],
    iconAnchor: [15 * scale, 15 * scale],
    popupAnchor: [0, 0],
  });

  return icon;
};

const createChargerStationPointIcon = (scale) => new L.Icon({
  iconUrl: ChargerImg,
  iconRetinaUrl: ChargerImg,
  iconSize: [16 * scale, 32 * scale],
  iconAnchor: [16 * scale * 0.5, 32 * scale * 0.9],
  popupAnchor: [0, 0],
});

const ScanDotIcon = new L.Icon({
  iconUrl: ScanIconImg,
  iconSize: [1, 1],
  iconAnchor: [0.5, 0.5],
  popupAnchor: [0, 0],
});

const createManualChargerPointIcon = (scale) => new L.Icon({
  iconUrl: ManualChargerImg,
  iconRetinaUrl: ManualChargerImg,
  iconSize: [16 * scale, 24 * scale],
  iconAnchor: [16 * scale * 0.5, 24 * scale * 0.9],
  popupAnchor: [0, 0],
});

const genChargerSizeAndAnchor = (groupSize, scale) => {
  // 以放大倍率 100% 為基礎，寬度為 16px (80cm)
  const icon = {
    width: 16 * scale,
    height: 32 * scale,
  };
  return {
    iconSize: [icon.width * groupSize, icon.height],
    iconAnchor: [(icon.width * groupSize) / 2, (icon.height * 0.9)],
  };
};

const createChargerIcon = (groupSize, scale) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // 以目前開放最大倍率 (200%) 的實際像素為基礎，產生 canvas image
  ctx.canvas.width = 32 * groupSize;
  ctx.canvas.height = 64;
  for (let i = 0; i < groupSize; i += 1) {
    ctx.drawImage(chargerImg, 32 * i, 0, 32, 64);
  }
  const url = canvas.toDataURL();

  const { iconSize, iconAnchor } = genChargerSizeAndAnchor(groupSize, scale);
  const icon = new L.Icon({
    iconUrl: url,
    iconRetinaUrl: url,
    iconSize,
    iconAnchor,
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 10],
  });
  const center = {
    x: iconAnchor[0],
    y: iconAnchor[1],
  };

  return { icon, center };
};

const baseSize = {
  remark: 14,
  dock: 26,
  dockSelect: 32,
  home: 20,
  homeSelect: 25,
  task: 26,
  taskSelect: 26,
  robot: 29,
  oneWayArrow: 40,
  iotGate: 20,
  connectionPoint: 26,
  fontSize: 16,
};

const IconStyle = {
  NodeIcon,
  FirstNodeIcon,
  baseSize,
  RemarkIcon,
  MapIcon,
  ConnectionPointIcon,
  DockIcon,
  DockSelectIcon,
  HomeIcon,
  HomeSelectIcon,
  TaskIcon,
  TaskSelectIcon,
  OneWayArrowIcon,
  ElevatorIcon,
  StoreIcon,
  DeliveryIcon,
  IotGateWorkingAreaIcon,
  robotIcon,
  createParkingAreaPointIcon,
  createChargerStationPointIcon,
  createManualChargerPointIcon,
  genChargerSizeAndAnchor,
  createChargerIcon,
  ScanDotIcon,
  AbandonIcon,
  HalfwayIcon,
  LandmarkIcon,
  DeviceNameIcon,
};

export default IconStyle;
