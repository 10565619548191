import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mqtt: {},
  navTitleName: {}, // 為了使跳至其它頁時該資料不再顯示，因此使用obj,並且key為[id||uuid]+routerName
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setMqtt: (state, action) => {
      state.mqtt = { ...state.mqtt, [action.payload.topic]: action.payload.data };
    },
    setNavTitleName: (state, action) => {
      state.navTitleName = { ...state.navTitleName, [action.payload.key]: action.payload.value };
    },
    clearNavTitleName: (state) => {
      state.navTitleName = {};
    },
  },
});

export const {
  setMqtt,
  setNavTitleName,
  clearNavTitleName,
} = commonSlice.actions;

export default commonSlice.reducer;
