import { request } from './request.js';
import getToken from '../common/getToken.js';

// ChargingAreaGroup 充電站 相關的 api

export const apiChargingAreas = (query) => request.get('map/ChargingAreaGroup/', {
  ...getToken(),
  ...(query && { params: query }),
});

// 新增 ChargingArea
export const apiPostChargingArea = (data) => request.post('map/ChargingAreaGroup/', data, getToken());

// 刪除 ChargingArea
export const apiDeleteChargingArea = (id) => request.delete(`map/ChargingAreaGroup/${id}/`, getToken());

// 修改 ChargingArea
export const apiPutChargingArea = (id, data) => request.put(`map/ChargingAreaGroup/${id}/`, data, getToken());

// 充電站啟用功能 操作
export const apiPatchChargingAreaPoint = (pointId, data) => request.patch(`map/ChargingAreaGroup/${pointId}/point`, data, getToken());

export const apiGetChargingArea = (id) => request.get(`map/ChargingAreaGroup/${id}/`, getToken());
