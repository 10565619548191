const MapStatus = {
  map: null, // leaflet map
  mapMarker: null, // 底圖的 marker
  mode: null, // 目前的模式 (add/edit)
  centerPosition: null, // leaflet 中心點座標
  toolTip: false, // 目前標記名稱的開關
  grid: null, // flag 網格物件
  layerType: null, // 目前新增或選取的 layer type (ex: Forbidden)
  addLayer: null, // 目前新增的 layer
  addLayerExtra: null, // 暫存用於新增的 layer 所需的變數
  addType: null, // 目前新增的 flag type (ex: polygon)
  currentGate: null, // 目前新增的 Gate
  editLayer: null, // 目前編輯的 layer
  editType: null, // 目前編輯的 flag type (ex: polygon)
  delete: [], // 目前操作中需要刪除的 layer
  currentStep: 0, // 目前的步驟
  steps: [], // 每個步驟的物件陣列
  layerArr: { // 目前顯示在地圖上的 layer 物件
    point: [],
    polyline: [],
    polygon: [],
    UVCZone: [],
    taskPoint: [],
    dockPoint: [],
    homePoint: [],
    parkingAreaPoint: [],
    chargingAreaList: [],
    connectMap: [],
  },
  initArr: {
    initChargingAreaList: [],
  },
  nodeArr: [], // 目前新增 layer 的 node point
  nodeEditArr: [], // 目前編輯 layer 的 node point
  originRectangleNode: [], // RectangleNode 原先的 node point
  dockPrepare: [], // 目前地圖上 dock 對應的 Prepare
  initWorkingArea: [], // init 的 WorkingArea
  initPoint: [], // init 的 point
  initElevator: [], // init 的 initElevator
  initPathData: [], // init 的 path Data
  initDockPoint: [], // init 的 dock Data
  initHomePoint: [], // init 的 home data
  deletePath: [], // 需要刪除的 path
  deleteDockHome: [], // 需要刪除的 dock point , dock prepare 與 home point
  isUVCZoneOrder: false, // 是否開啟 UVC 排序功能
  UVCZoneListData: [], // UVC Zone list data
  UVCZoneArrowLines: [], // UVC Zone 排序箭頭路線
  pathData: [], // path mode 下 目前畫面上的 path layout data
  dockData: [], // path mode 下 目前畫面上的 dock layout data
  homeData: [], // path mode 下 目前畫面上的 home layout data
  selectPath: { // path mode 下 目前所選擇到的 path id 與 task point id
    type: 'currentPoint',
    pathId: '',
    pointId: '',
  },
  pathArrowLines: [], // path mode 下 目前畫面上的箭頭路線
  selectPoint: null, // path mode 下 目前所選擇到的 point
  isPathModeEdit: false, // 路徑規劃頁面是否修改過
  tmpLayer: null,
  mapBound: null,
  scanLayer: {
    scan: null,
    past: null,
    future: null,
  },
  pointObj: {
    pointId: `TaskPoint${1}`,
    actionId: 'new',
    pointName: 'Task Point',
    isOpen: false,
    isEdit: false,
    x: '',
    y: '',
    angle: '',
    actionBtnShow: false,
    actionSelectValue: 'TASK_POINT_SELECT_ACTION',
    actionList: [],
    order: 1,
  },
  pathObj: {
    pathId: 1,
    pathName: 'Path_1',
    isView: true,
    isOpen: false,
    isEdit: false,
    pathApiType: 'post',
    actionApiType: 'post',
    currentPoint: {
      pointId: 1,
      actionId: 'new',
      pointName: 'Current Point',
      isOpen: false,
      x: '',
      y: '',
      angle: 90,
      actionBtnShow: false,
      actionSelectValue: 'TASK_POINT_SELECT_ACTION',
      actionList: [],
    },
    taskPoint: [],
  },
};

export default MapStatus;
