import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  makeStyles,
  withStyles,
  IconButton,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import {
  AddCircleOutline,
  Search,
  HighlightOff,
} from '@material-ui/icons';
import { updatePlaylist } from '@/reducer/playlistSlice';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import color from '@/color/theme.js';
import { updateVideoToPlayList } from '@/cloud_api/cms';
import NoPlayListImage from '@/static/image/no_play_list.png';
import { LoginContext } from '@/Main.jsx';
import Loading from '@/components/Loading/Loading.jsx';
import { setNavTitleName } from '@/reducer/commonSlice.js';
import ReminderMessage from './ReminderMessage.jsx';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 60,
    width: '400px',
    borderRadius: '10px',
  },
  iconButton: {
    position: 'absolute',
    marginLeft: 107,
    top: 7,
    color: color.white1,
  },
  avatar: {
    marginLeft: 10,
  },
  dialogBar: {
    textAlign: 'center',
    backgroundColor: color.blue1,
    font: 'normal normal bold 20px/23px Arial',
    color: '#FFFFFF',
  },
  rootAbsolute: {
    width: '360px',
    height: '36px',
    position: 'relative',
    marginLeft: 20,
    marginBottom: 15,
    border: '1px solid #006E94',
    color: color.blue1,
    font: 'normal normal bold 16px/21px Microsoft JhengHei',
  },
  paper: {
    display: 'flex',
    width: '360px',
    height: '33px',
    textAlign: 'left',
    font: 'normal normal normal 16px/16px Roboto',
    color: '#AAAAAA',
    margin: 'auto',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #E3E3E3',
    marginTop: '10px',
  },
  input: {
    marginLeft: '12px',
    marginTop: '5px',
    flex: 1,
  },
  divider: {
    marginTop: '10px',
    width: 'auto',
  },
  divider2: {
    marginTop: '5px',
    width: 'auto',
  },
  list: {
    width: 'auto',
    maxHeight: '60vh',
    height: '450px',
    overflowY: 'auto',
  },
  divTitle: {
    marginLeft: 20,
    font: 'normal normal bold 14px/19px Segoe UI',
    color: '#444444',
    width: 'auto',
    height: '19px',
  },
  img: {
    position: 'relative',
    width: '200px',
    height: '160px',
    marginLeft: '95px',
    marginTop: '40px',
    marginBottom: '15px',
  },
  noPlayListText: {
    textAlign: 'center',
    font: 'normal normal bold 14px/19px Segoe UI',
    color: '#444444',
    marginBottom: '15px',
  },
  listName: {
    font: 'normal normal normal 16px/21px Segoe UI',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '290px',
    whiteSpace: 'nowrap',
  },
  listDate: {
    font: 'normal normal normal 12px/14px Segoe UI',
    color: '#a5a4a4',
    display: 'inline',
    float: 'left',
  },
  listVideoCount: {
    font: 'normal normal normal 12px/14px Segoe UI',
    color: '#a5a4a4',
    display: 'inline',
    float: 'right',
  },
}));

const ListItems = withStyles({
  root: {
    '&$selected': {
      backgroundColor: color.blue1,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&$selected:hover': {
      backgroundColor: color.blue1,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    '&:hover': {
      backgroundColor: color.blue1,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  },
  selected: {},
})(ListItem);

const JoinPlaylist = (props) => {
  const {
    openJoin,
    setOpenJoin,
    joinVideo,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const contextValue = useContext(LoginContext);
  const playlist = useRef(null);
  const inputRef = useRef('');
  const [searchKey, setSearchKey] = useState('');
  const [nonSearch, setNonSearch] = useState(true);
  const [openDelMsg, setOpenDelMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const playList = useSelector((x) => x.playlistSlice.playList, shallowEqual);

  const leftButtonHandle = () => {
    setOpenDelMsg(false);
    contextValue.setBottomState((prevState) => ({
      ...prevState,
      closeJoinState: false,
      msgType: 'joinPlayList',
    }));
  };

  const rightButtonHandle = () => {
    setOpenDelMsg(false);
    dispatch(setNavTitleName({ key: playlist.current.id + 'CMS', value: playlist.current.name }));
    history.push({
      pathname: `/CMS/${playlist.current.id}`,
      state: {
        action: 'EDIT',
      },
    });
  };

  const joinVideoToPlaylist = async (id) => {
    try {
      setIsLoading(true);
      const videos = joinVideo.map((item) => ({ id: item.id }));

      const { data } = await updateVideoToPlayList(id, videos);
      dispatch(updatePlaylist({ data }));
      setOpenDelMsg(true);
    } catch (err) {
      alert('Failed to join videos.');
      console.log(err);
    }
    setIsLoading(false);
  };

  const getRecently = () => {
    const sortedLast = [...playList];
    sortedLast.sort((x, y) => y.last_updatetime - x.last_updatetime);
    return sortedLast.splice(0, 3);
  };

  const searchFilter = () => {
    if (!searchKey) {
      return playList;
    }

    const searchReg = new RegExp(searchKey + '.*', 'i');
    const filterData = playList.filter((prop) => {
      const myMatch = prop.name.match(searchReg);

      return (myMatch != null);
    });

    return filterData;
  };

  const recently = getRecently();
  const searchItem = searchFilter();

  function ListItemsCard({ recentItem, infoText }) {
    return (
      <ListItems
        key={recentItem.id}
        button
        divider
        onClick={(e) => handleClickCard(e, recentItem)}
      >
        <ListItemAvatar>
          <Avatar
            alt="1"
            src={recentItem.thumbnail}
            imgProps={{ draggable: false }}
            variant="square"
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText primary={infoText} />
      </ListItems>
    );
  }

  const handleClickCard = async (e, prop) => {
    setOpenJoin(false);
    playlist.current = prop;
    await joinVideoToPlaylist(prop.id);
  };

  const handleClose = () => {
    setOpenJoin(false);
  };

  const handleCreateNewPlayList = () => {
    const playlistId = uuid();
    setOpenJoin(false);
    dispatch(setNavTitleName({ key: playlistId + 'CMS', value: t('CMS_ADD_PLAY_LIST') }));
    history.push({
      pathname: `/CMS/${playlistId}`,
      state: {
        id: joinVideo,
        action: 'NEW',
      },
    });
  };

  const searchEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchKey(e.target.value);
      setNonSearch(false);
    } else if (e.key === 'Backspace') {
      if (e.target.value.length <= 1) {
        setSearchKey('');
        setNonSearch(true);
      }
    } else if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
    }
  };

  const searchIconClick = () => {
    setSearchKey(inputRef.current.value);
    setNonSearch(false);
  };

  return (
    <>
      <Loading open={isLoading} />
      <Dialog
        open={openJoin}
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
        scroll="paper"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogTitle className={classes.dialogBar}>
          {t('CMS_ADD_TO')}
          <IconButton className={classes.iconButton} type="button" aria-label="clear" onClick={handleClose}>
            <HighlightOff />
          </IconButton>
        </DialogTitle>
        <FormControl size="small" style={{ alignItems: 'center', margin: 'auto', marginTop: '10px' }}>
          <OutlinedInput
            inputRef={inputRef}
            style={{ width: '360px' }}
            onKeyDown={(e) => searchEnter(e)}
            placeholder={t('CMS_SEARCH')}
            autoFocus
            endAdornment={(
              <InputAdornment position="end">
                <IconButton type="button" aria-label="search" onClick={() => searchIconClick()} style={{ marginRight: '-10px' }}>
                  <Search />
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
        <Divider className={classes.divider} />

        {
          !searchItem?.length
            ? (
              <List className={classes.list}>
                <figure>
                  <img className={classes.img} src={NoPlayListImage} alt="NoPlayListImage" />
                  <figcaption>
                    <div className={classes.noPlayListText}>{t('CMS_NO_PLAY_LIST')}</div>
                  </figcaption>
                  <Button
                    variant="outlined"
                    className={classes.rootAbsolute}
                    startIcon={<AddCircleOutline />}
                    onClick={() => handleCreateNewPlayList()}
                  >
                    {t('CMS_ADD_PLAY_LIST')}
                  </Button>
                </figure>
              </List>
            )
            : (
              <List className={classes.list}>
                <Button
                  variant="outlined"
                  className={classes.rootAbsolute}
                  startIcon={<AddCircleOutline />}
                  onClick={() => handleCreateNewPlayList()}
                >
                  {t('CMS_ADD_PLAY_LIST')}
                </Button>
                {nonSearch && (
                <><div className={classes.divTitle}>{t('CMS_RECENTLY')}</div><Divider className={classes.divider2} />
                  {recently.map((recentItem) => (
                    <ListItemsCard
                      key={recentItem.id}
                      recentItem={recentItem}
                      infoText={(
                        <>
                          <div className={classes.listName}>
                            {recentItem.name}
                          </div>
                        </>
                      )}
                    />
                  ))}
                  <div className={classes.divTitle} style={{ marginTop: 8 }}>{t('CMS_ALL_PLAYLIST')}</div>
                  <Divider className={classes.divider2} />
                </>
                )}
                {searchItem.map((recentItem) => (
                  <ListItemsCard
                    key={recentItem.id}
                    recentItem={recentItem}
                    infoText={(
                      <>
                        <div className={classes.listName}>
                          {recentItem.name}
                        </div>
                        <div className={classes.listDate}>
                          {recentItem.humdate}
                        </div>
                        <div className={classes.listVideoCount}>{recentItem.videoTime_format}</div>
                      </>
                    )}
                  />
                ))}
              </List>
            )
        }
      </Dialog>
      {openDelMsg && (
        <ReminderMessage
          title={t('REMINDER_MESSAGE')}
          leftButtonText={t('CMS_JOIN_PLAYLIST_ONLY')}
          rightButtonText={t('CMS_JOIN_PLAYLIST_EDIT')}
          leftButtonHandle={leftButtonHandle}
          rightButtonHandle={rightButtonHandle}
        >
          {t('CMS_JOIN_PLAYLIST_REM')}
        </ReminderMessage>
      )}
    </>
  );
};

export default JoinPlaylist;
