import React from 'react';
import store from '@/reducer/store';
import MapStatus from '@/components/MapLeaflet/Variable';
import { isNumber } from '@material-ui/data-grid';
import ElevatorPortal from '@/static/icon/MapEdit/ElevatorPortal.png';
import { ElevatorIocnStyle } from './ElevatorStyle.js';

export default ({ id, iconSize }) => {
  const currentPoint = store.getState().mapSlice.points.find((p) => p.id === id);
  const scale = (MapStatus.map.getZoom() + 100) / 100;
  const cmToPx = 5;
  const defaultData = {
    enterCm: 35,
    insideCm: 35,
    doorCm: 20,
    offset: 0,
    align: 'center',
    doorWidth: 80,
    parkingSize: 70,
    doorHeight: Math.max(80, (currentPoint && currentPoint.insideCm) ? currentPoint.insideCm * 2 : 0), // 35=parkingSize/2
    ...currentPoint,
  };

  const pxData = {};
  Object.keys(defaultData).forEach((key) => {
    if (isNumber(defaultData[key])) {
      pxData[key] = (defaultData[key] / cmToPx) * scale;
    } else {
      pxData[key] = defaultData[key];
    }
  });

  return (
    <ElevatorIocnStyle
      iconSize={iconSize}
      scale={scale}
      data={{ ...pxData }}
      id={id}
    >
      <img alt="outside" src={ElevatorPortal} className="outArea" />
      <div className="door">
        <div className="arrow" />
      </div>
      <div className="circle" />
      <div className="doorIn" />
      <img alt="inside" src={ElevatorPortal} className="insideArea" />
      <div className="vertical" />
      <div className="horizontal" />
    </ElevatorIocnStyle>
  );
};
