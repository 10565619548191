import React from 'react';
import PropTypes from 'prop-types';
import { modalWithObjectStyle } from '@/components/Modal/ModalStyle.js';
import {
  Button,
  Divider,
  makeStyles,
} from '@material-ui/core';
import {
  MessageContainer,
} from '../CMSStyle.js';

export const useStyles = makeStyles(() => ({
  leftBtn: {
    width: '90px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '16px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#028BBA',
      color: '#fff',
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#005B7A',
      color: '#fff',
    },
  },
  rightBtn: {
    width: '120px',
    height: '36px',
    backgroundColor: '#fff',
    border: '1px solid #006E94',
    borderRadius: '4px',
    color: '#006E94',
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '16px',
    '&:hover': {
      border: 'none',
      backgroundColor: '#028BBA',
      color: '#fff',
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#005B7A',
      color: '#fff',
    },
  },
  contentStyle: {
    textAlign: 'left',
    minHeight: '100px',
    width: '340px',
    padding: '45px 24px 45px 40px',
  },
}));

const ReminderMessage = (props) => {
  const {
    title,
    leftButtonText,
    rightButtonText,
    leftButtonHandle,
    rightButtonHandle,
    footer,
    customContent,
    className,
  } = props;
  const sModal = modalWithObjectStyle();
  const classes = useStyles();
  const contentStyle = className || classes.contentStyle;

  return (
    <MessageContainer>
      <div className="msgModal">
        <div className="msgModalContent">
          <div className={sModal.title}>
            {title}
          </div>
          <div className={contentStyle}>
            {props.children}
          </div>
          {customContent}
          { footer
            && (
            <div className={sModal.footer}>
              <Divider className="divider" />
              <Button type="button" className={classes.leftBtn} onClick={leftButtonHandle}>
                {leftButtonText}
              </Button>
              <Button type="button" className={classes.rightBtn} onClick={rightButtonHandle}>
                {rightButtonText}
              </Button>
            </div>
            )}
        </div>
      </div>
    </MessageContainer>
  );
};

ReminderMessage.propTypes = {
  footer: PropTypes.bool,
};
ReminderMessage.defaultProps = {
  footer: true,
};

export default ReminderMessage;
