import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import mapReducer from './mapSlice';
import dashboardReducer from './dashboardSlice';
import mapListReducer from './mapListSlice';
import notificationReducer from './notificationSlice';
import commonReducer from './commonSlice';
import mqttReducer from './mqttSlice';
import bindMapReducer from './bindMapSlice';
import playlistReducer from './playlistSlice';

const persistMapConfig = {
  key: 'map',
  storage,
  whitelist: ['currentMapId', 'mapInfo'],
};
const persistMapListConfig = {
  key: 'mapList',
  storage,
  whitelist: ['siteData'],
};
const persistCommonConfig = {
  key: 'common',
  storage,
  whitelist: ['navTitleName'],
};

const persistedMapReducer = persistReducer(persistMapConfig, mapReducer);
const persistedMapListReducer = persistReducer(persistMapListConfig, mapListReducer);

const reducers = combineReducers(
  {
    mapSlice: persistedMapReducer,
    mapListSlice: persistedMapListReducer,
    dashboardSlice: dashboardReducer,
    notificationSlice: notificationReducer,
    commonSlice: persistReducer(persistCommonConfig, commonReducer),
    mqttSlice: mqttReducer,
    bindMapSlice: bindMapReducer,
    playlistSlice: playlistReducer,
  },
);

export default configureStore({
  reducer: reducers,
  middleware: [thunk],
});
