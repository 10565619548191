const color = {
  primary: '#F4F4F4',
  secondary: '#B2B2B2',
  white1: '#F4FBFC', // 白1
  white2: '#CADDE3', // 白2
  white3: '#FFFFFF', // 白2
  blue1: '#006E94', // 藍1
  blue2: '#D3F1FA', // 藍2
  blue3: '#028BBA', // 藍3
  blue4: '#E3F7FF', // 藍4
  gary1: '#4D4D4D', // 灰1
  gray2: '#414141', // 灰2
  gray3: '#45454580', // 灰3
  gray4: '#CDCDCD', // 灰4
  green1: '#A1CC57', // 綠1
  green2: '#1DBE00', // 綠2

  bgColor1: '#F4FBFC', // 白1
  bgColor2: '#006E94', // 藍1

  fontColor1: '#4D4D4D', // 灰1
  fontColor2: '#006E94', // 藍1
  fontColor3: '#A7A7A7',
  fontColor4: '#fff',
  fontColor5: '#5DFF40',
  fontError: '#f44336',

  navFont: '#006E94', // 藍 1

  sideFont: '#FFFFFFCC',
  sideFontActive: '#FFFFFF',

  btnColor1: '#006E94', // 藍 1
  btnColor2: '#9F9F9F',
  btnColor3: '#555555',
  btnColor4: '#4F4F4F',
};

export default color;
