import { useState, useEffect } from 'react';

export default function useSessionStorage(keyName, eventString, defaultValue) {
  const event = new Event(eventString);

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = sessionStorage.getItem(keyName);
      if (value && value !== undefined && value !== 'undefined') {
        return JSON.parse(value);
      }
      sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      sessionStorage.setItem(keyName, JSON.stringify(newValue));
      document.dispatchEvent(event);
    } catch (err) {
      console.log('useSessionStorage error: ', err);
    }
    setStoredValue(newValue);
  };

  const setLocalItem = () => {
    setTimeout(() => {
      const itemValueFromStorage = sessionStorage.getItem(keyName);
      const value = itemValueFromStorage && JSON.parse(itemValueFromStorage);
      setStoredValue(value);
    }, 50);
  };

  useEffect(() => {
    document.addEventListener(
      eventString,
      setLocalItem,
      false,
    );

    return () => document.removeEventListener(eventString, setLocalItem);
  }, []);

  return [storedValue, setValue];
}
