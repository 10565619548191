import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { apiCheckEmailToken, apiResetPassword, apiSendEmail } from '@/cloud_api/auth.js';
import { connectAPI } from '@/common/connectAPI';
import { useDispatch } from 'react-redux';
import LoadingDiv from '@/components/Loading/LoadingDiv.jsx';
import Logo from '@/static/image/logo_login.png';
import Password from '@/components/Input/Password.jsx';
import { LoginButton } from '@/components/componentStyle.js';
import YesNoModal from '@/components/Modal/YesNoModal.js';
import AmrButton from '@/components/Button/AmrButton.jsx';
import { Container } from './VerifyStyle.js';

export default ({ isResetPassword }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [pwdError, setPwdError] = useState('');
  const [comfirmPassword, setComfirmPassword] = useState('');
  const [comfirmError, setComfirmError] = useState('');
  const [openResetModal, setOpenResetModal] = useState(false);
  const [email, setEmail] = useState('');
  const [waitOpen, setWaitOpen] = useState(false);

  useEffect(() => {
    if (!token) {
      goToLogin();
    } else {
      checkToken();
    }
  }, [token]);

  const checkToken = async () => {
    setIsLoading(true);
    try {
      await apiCheckEmailToken(token);
      if (!isResetPassword) {
        setWaitOpen(true);
      }
    } catch (err) {
      const { username } = err.data;
      if (username) {
        setEmail(username);
        setOpenResetModal(true);
      } else {
        goToLogin();
      }
    }
    setIsLoading(false);
  };

  const goToLogin = () => {
    history.push({
      pathname: '/',
    });
  };

  const resendToken = async () => {
    await connectAPI({
      t,
      dispatch,
      api: apiSendEmail,
      postData: { purpose: isResetPassword ? 'reset_password' : 'verify', username: email },
      msg: t('RESEND'),
      onFinish: goToLogin,
    });
  };

  const checkPassword = async () => {
    setPwdError('');
    setComfirmError('');

    if (!password) {
      setPwdError('NEW_PWD_EMPTY');
      return;
    }

    if (!comfirmPassword) {
      setComfirmError('PASSWORD_COMFIRM_EMPTY');
      return;
    }

    if (comfirmPassword !== password) {
      setComfirmError('PASSWORD_COMFIRM_ERROR');
      return;
    }

    await connectAPI({
      t,
      dispatch,
      api: apiResetPassword,
      postData: { token, password },
      msg: t(isResetPassword ? 'CHANGE_PASSWORD' : 'ACTIVATE_ACCOUNT'),
      yesText: t('CHANGE_PASSWORD_MESSAGE', { text: t(isResetPassword ? 'CHANGE_PASSWORD' : 'ACTIVATE_ACCOUNT') }),
      onSuccess: goToLogin,
    });
  };

  return (
    <>
      <Container>
        <LoadingDiv isLoading={isLoading} />
        <div className="loginForm">

          <img src={Logo} alt="" />
          {
            waitOpen && (
              <>
                <h3>{t('ACTIVATE_ACCOUNT')}</h3>
                <p>{t('ACTIVATE_ACCOUNT_MESSAGE')}</p>
                <AmrButton
                  onClick={() => setWaitOpen(false)}
                >{t('DASHBOARD_ENABLE')}
                </AmrButton>
              </>
            )
          }
          {
            !waitOpen && (
              <>
                <h3>{t('SETTING_PASSWORD')}</h3>
                <Password
                  id="password"
                  value={password}
                  setValue={setPassword}
                  showError={!!pwdError}
                  placeholder={t('ENTER_NEW_PASSWORD')}
                />
                {!!pwdError && <p style={{ color: 'red' }}>{t(pwdError)}</p>}
                <Password
                  id="comfirmPassword"
                  value={comfirmPassword}
                  setValue={setComfirmPassword}
                  showError={!!comfirmError}
                  placeholder={t('PASSWORD_COMFIRM')}
                />
                {!!comfirmError && <p style={{ color: 'red' }}>{t(comfirmError)}</p>}
                <LoginButton onClick={checkPassword}>
                  {t('SURE')}
                </LoginButton>
              </>
            )
          }
        </div>
      </Container>
      <YesNoModal
        modalOpen={openResetModal}
        messageText={t(isResetPassword ? 'PASSWORD_RESEND_MESSAGE' : 'CERTIFIED_RESEND_MESSAGE')}
        titleText={t('CERTIFIED_EXPIRED')}
        confirmClick={resendToken}
        confirmText={t('RESEND')}
        cancelText={t('LINK_LOGIN')}
        cancelClick={() => goToLogin()}
      />
    </>

  );
};
