import { AppConfig } from '@/config/index';
import { requestCMS } from './requestCMS.js';
import getToken from '../common/getToken.js';

const { CMS_URL } = AppConfig;

export const getCMSUrl = () => `${CMS_URL}`;

export const getPlayList = () => requestCMS.get('playlist/', getToken());

export const getPlayListByID = (id) => requestCMS.get(`playlist/${id}`, getToken());

export const getSiteList = (query) => requestCMS.get('site/', {
  ...getToken(),
  ...(query && { params: query }),
});

export const getGroupList = (query) => requestCMS.get('group/', {
  ...getToken(),
  ...(query && { params: query }),
});

export const getRobotList = (query) => requestCMS.get('robot/', {
  ...getToken(),
  ...(query && { params: query }),
});

export const postSchedule = (id, data) => requestCMS.post(`playlist/${id}/schedule_group`, data, getToken());

export const getScheduleByPlaylist = (id, query) => requestCMS.get(`playlist/${id}/schedule_children/`, {
  ...getToken(),
  ...(query && { params: query }),
});

export const getScheduleList = (query) => requestCMS.get('schedule/children', {
  ...getToken(),
  ...(query && { params: query }),
});

export const getCloudList = () => requestCMS.get('media/', getToken());

export const updateCloud = (id, data) => requestCMS.put(`media/${id}`, data, getToken());

export const removeCloudfromPlayList = (playlist_id, media_id) => requestCMS.delete(`playlist/${playlist_id}/videos/${media_id}`, getToken());

export const getCloudListByID = (id) => requestCMS.get(`media/${id}`, getToken());

export const delPlaylist = (data) => requestCMS.post('playlist/delete', data, getToken());

export const delPlayListByID = (id) => requestCMS.delete(`playlist/${id}`, getToken());

export const delVideos = (data) => requestCMS.post('media/delete', data, getToken());

export const postPlayList = (data) => requestCMS.post('playlist/', data, getToken());

export const updatePlayList = (data) => requestCMS.put(`playlist/${data.id}`, data, getToken());

export const updateVideoToPlayList = (id, data) => requestCMS.post(`playlist/${id}/videos`, data, getToken());

export const uploadfile = (data, option) => requestCMS.post('media/', data, option);

export const registerUpload = (data) => requestCMS.post('/media/register', data);

export const getTemplates = (type) => requestCMS.get('playlist/templates', {
  ...getToken(),
  ...(type && { params: type }),
});

export const uploadBackgroundFile = (data) => requestCMS.post('playlist/templates', data, getToken());

export const deleteTemplates = (id) => requestCMS.delete(`playlist/templates/${id}`, getToken());

export const getMediaData = (id) => requestCMS.get(`playlist/item/${id}`, getToken());

export const getQrCodeData = (id) => requestCMS.get(`qrcode/${id}`, getToken());

export const getQrCodeImg = (data) => requestCMS.post('qrcode/', data, getToken());

export const getSchedule = (id) => requestCMS.get(`playlist/${id}/schedule_group`, getToken());

export const getBannerData = (id) => requestCMS.get(`banner/${id}`, getToken());

export const getBannerId = (data) => requestCMS.post('banner/', data, getToken());

export const getScheduleById = (id, group_id) => requestCMS.get(`playlist/${id}/schedule_group/${group_id}`, getToken());

export const delScheduleGroup = (id, group_id) => requestCMS.delete(`playlist/${id}/schedule_group/${group_id}`, getToken());

export const updateScheduleGroup = (id, group_id, data) => requestCMS.put(`playlist/${id}/schedule_group/${group_id}`, data, getToken());

export const updateScheduleChild = (id, child_id, data) => requestCMS.put(`playlist/${id}/schedule_children/${child_id}`, data, getToken());

export const deleteScheduleChild = (id, child_id) => requestCMS.delete(`playlist/${id}/schedule_children/${child_id}`, getToken());

export const postPublish = (id) => requestCMS.post(`playlist/${id}/publish`, getToken());

export const postMultiPublish = (data) => requestCMS.post('playlist/publish', data, getToken());
