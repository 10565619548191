import styled from 'styled-components';
import color from '@/color/theme.js';
import Side from '@/static/image/sidemenu.png';

export const SideBar = styled.div`
  background-image: url(${Side});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 100vh;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 3px 0px 6px #00202B;
  z-index: 99;
  /* background: transparent linear-gradient(180deg, #006E94 0%, #006E94 19%, #006E94 25%, #009BAF 79%, #00D8FF 100%) 0% 0% no-repeat padding-box; */

  .sideBarLogo {
    width: 280px;
    padding: 22px;
  }

  .sideBarTitle {
    color: #a9d4de;
  }

  .sideBarOverflow {
    overflow: auto;
    -ms-overflow-style: none;  /\* IE 和 Edge \*/
    scrollbar-width: none;     /\* Firefox \*/
  }

  .sideBarOverflow::-webkit-scrollbar {
    display: none;  /\* Chrome, Safari 和 Opera \*/
  }

  .sideBarGroup {
    display: block;
    width: 100%;
    height: auto;

    .sideBarItem, .sideBarItemActive {
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding-left: 56px;
      text-align: left;
      color: ${color.sideFont};
      background-color: transparent;
      font-size: 16px;
      border: none;
      cursor: pointer;
      /* transition: all .3s; */
      position: relative;

      &:hover {
        background-color: #FFFFFF33;
      }

      &:focus {
        outline: none;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
      }
    }

    .sideBarItemActive {
      color: ${color.sideFontActive};
      background-color: rgba(255, 255, 255, .2)!important;
      border-left: 4px solid #fff;
    }

    .sideBarChild {
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding-left: 70px;
      text-align: left;
      color: ${color.sideFont};
      background-color: transparent;
      font-size: 14px;
      border: none;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: #FFFFFF33;
      }
    }

    .disable {
      opacity: 0.3;
      cursor: not-allowed;
      transition: .2s;
      button {
        cursor: not-allowed;
        transition: .2s;
      }
    }

    .childIcon {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
    }
  }

`;
