// Ref: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f#file-axios-refresh_token-1-js

import { apiRefresh } from './auth';

/**
 * 處理 JWT refresh token 的 callback function。
 * @param {AxiosInstance} request - 以 `axios.create()` 生成的實例。
 * @returns async function。
 */
export const refreshHandler = (request) => {
  let refreshQueue = [];

  const processQueue = (error, token = null) => {
    refreshQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    refreshQueue = [];
  };

  return async (error) => {
    const originalRequest = error.config;

    if (!error.response) return Promise.reject(error);
    if (error.response.status !== 401) return Promise.reject(error.response);
    if (originalRequest._retry) return Promise.reject(error.response);

    // 先註解掉
    // const tokenExpired = sessionStorage.getItem('tokenExpired');
    // if (tokenExpired === 'true') {
    //   alert(sessionStorage.getItem('token_expired'));
    //   window.location.href = '/';
    //   sessionStorage.clear();
    //   return Promise.reject(error);
    // }

    try {
      const refresh = sessionStorage.getItem('refresh');
      const { data: { access } } = await apiRefresh({ refresh });
      sessionStorage.setItem('token', access);
      // isRefreshing = false;
      originalRequest.headers.Authorization = `Bearer ${access}`;
      const newRequest = await request(originalRequest);
      processQueue(null, access);
      return newRequest;
    } catch (err) {
      processQueue(err, null);
      if (err.response?.status === 401) {
        alert(sessionStorage.getItem('token_expired'));
        window.location.href = '/';
        sessionStorage.clear();
      }
      return Promise.reject(err);
    }
  };
};
