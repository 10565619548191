import { request } from './request.js';
import { requestBlob } from './requestBlob.js';
import { requestImage } from './requestImage.js';
import getToken from '../common/getToken.js';

// Maps 相關的 api

// 取得 all Maps 資訊
export const apiMapsList = (query) => request.get('map/Maps/', {
  ...getToken(),
  params: query,
});

// 新增 Map
export const apiMapsCreate = (data) => request.post('map/Maps/', data, getToken());

// 刪除 Map
export const apiMapsRemove = ({ id }) => request.delete(`map/Maps/${id}/`, getToken());

// 修改 Map
export const apiMapsEdit = (id, data) => request.patch(`map/Maps/${id}/`, data, getToken());
// 修改 Map, 因參數傳入方式不同，因此特別開給Maps使用
export const apiMapsEditByMaps = ({ id, data }) => request.patch(`map/Maps/${id}/`, data, getToken());
// 查詢單一 Map 資訊
export const apiMapsInfo = (id) => request.get(`map/Maps/${id}/`, getToken());

// 批次管理 Map
export const apiMapsBatchControl = ({ action, data }) => request.put(`map/Maps/BatchControl/${action}`, data, getToken());

// 取得 Map 縮圖
export const apiMapsThumbnail = (id) => requestBlob.get(`map/Maps/${id}/thumbnail`, getToken());

// 更新 Map 縮圖
export const apiMapsThumbnailUpdate = (id, thumbnail) => {
  const formData = new FormData();
  formData.append('map_file', thumbnail);
  return requestImage.post(`map/Maps/${id}/thumbnail`, formData, getToken());
};

// 取得 Map pgm 檔
export const apiMapsPng = (id) => requestBlob.get(`map/Maps/${id}/image`, getToken());

// 更新 Map pgm 檔
export const apiMapsPngUpdate = (saveData, blobFile) => {
  const formData = new FormData();
  formData.append('original_map_id', saveData.original_map_id);
  formData.append('map_id', saveData.map_id);
  formData.append('map_file', blobFile, 'map.pgm');
  return requestImage.put(`map/Maps/${saveData.map_id}/image`, formData, getToken());
};

// 取得 Map MetaData 資訊
export const apiMapsMetaData = (id) => request.get(`map/Maps/${id}/metadata`, getToken());

// 更新 Map MetaData 資訊
export const apiMapsMetaDataEdit = (id, data) => request.put(`map/Maps/${id}/metadata`, data, getToken());

// 取得 Map 的 missions (paths)
export const apiMapsMissions = (id) => request.get(`map/Maps/${id}/missions`, getToken());

export const apiMaps = (fiterStr = '') => request.get(`map/Maps/?${fiterStr}`, getToken());

export const apiMapsCopy = ({ id, site_id }) => request.post(`map/Maps/${id}/save-as?site_id=${site_id}`, {}, getToken());

// 取得地圖中的裝置列表清單
export const apiGetMapDevices = (query) => request.get('map/Maps/devices', {
  ...getToken(),
  params: query,
});
