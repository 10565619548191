import styled from 'styled-components';
import BackGround from '@/static/image/background.png';

export const Container = styled.div`
   width:100vw;
   height: 100vh;
   background-image: url(${BackGround});
   position: absolute;
   z-index: 1000;
   padding:20px;
   left:0px;
   top:0px;
   
  .loginForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(245, 245, 245, .95);
    width: 500px;
    height: auto;
    border-radius: 20px;
    padding:45px;

    h3{
      margin-top:10px;
      color:rgba(0, 0, 0, 0.87)
    }

    p {
      padding:10px 0px;
    }
  }
`;
