export const robotMockData = {
  timestamp: '2022-11-29 14:32:24.378',
  battery: { level: 90, status: 0, dock_status: 0 },
  dock_id: -1,
  localization_status: 0,
  current_map: 'edc892aa-8330-4bdc-a714-dd72f67f6542/b80437bb-78c1-4008-a078-d78f0bc2034e',
  status: '',
  sw_status: '',
  hw_status: '',
  asm_status: { level: 90, status: 0 },
  lock_status: 0,
  online_status: 1,
};

export const deviceStateMockData = [
  {
    id: 0,
    robotName: 'robot1',
    type: 'UVC',
    battery: 10,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 1,
    robotName: 'robot100',
    type: 'UVC',
    battery: 100,
    map: 'A-Building',
    position: 'C1DF4',
    state: '錯誤',
    error: '軟體啟動失敗(0X00000)',
    note: '',
  },
  {
    id: 2,
    robotName: 'robot2',
    type: 'UVC',
    battery: 20,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 3,
    robotName: 'robot3',
    type: 'UVC',
    battery: 30,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 4,
    robotName: 'robot4',
    type: 'UVC',
    battery: 40,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 5,
    robotName: 'robot5',
    type: 'UVC',
    battery: 50,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 6,
    robotName: 'robot6',
    type: 'UVC',
    battery: 60,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 7,
    robotName: 'robot7',
    type: 'UVC',
    battery: 30,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 8,
    robotName: 'robot8',
    type: 'UVC',
    battery: 40,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 9,
    robotName: 'robot9',
    type: 'UVC',
    battery: 50,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
  {
    id: 10,
    robotName: 'robot10',
    type: 'UVC',
    battery: 60,
    map: 'A-Building',
    position: 'C1DF4',
    state: '正常',
    error: '',
    note: '',
  },
];

export const mockChartList = [
  {
    id: 1,
    title: 'DASHBOARD_ROBOT_RATE',
    percentageRate: '11.8',
    contentString: 'DASHBOARD_USED_RATE',
    unitValue: '99.5',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [5, 12, 3, 5, 7, 3],
    base: [7, 7, 7, 7, 7, 7],
  },
  {
    id: 2,
    title: 'DASHBOARD_COMPLETE_CAL',
    percentageRate: '12.1',
    contentString: 'DASHBOARD_TOTAL_TIME',
    unitValue: '66',
    unit: 'h',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [5, 7, 2, 5, 10, 1],
    base: [7, 7, 7, 7, 7, 7],
  },
  {
    id: 3,
    title: 'DASHBOARD_COMPLETE_TASK_PATH_CAL',
    percentageRate: '9',
    contentString: 'DASHBOARD_TOTAL_PATH',
    unitValue: '75',
    unit: 'pcs',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [0, 3, 1, 5, 6, 3],
    base: [5, 5, 5, 5, 5, 5],
  },
  {
    id: 4,
    title: 'DASHBOARD_COMPLETE_TASK_CAL',
    percentageRate: '3.1',
    contentString: 'DASHBOARD_TOTAL_PATH_POINT',
    unitValue: '88.5',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [1, 3, 5, 7, 9, 11],
    base: [7, 7, 7, 7, 7, 7],
  },
  {
    id: 5,
    title: 'DASHBOARD_MM_RATE',
    percentageRate: '6.3',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '95',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [11, 9, 7, 5, 3, 1],
    base: [7, 7, 7, 7, 7, 7],
  },
  {
    id: 6,
    title: 'DASHBOARD_ADR_RATE',
    percentageRate: '15',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '93.6',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [3, 0, 6, 7, 8, 1],
    base: [6, 6, 6, 6, 6, 6],
  },
  {
    id: 7,
    title: 'DASHBOARD_UVC_RATE',
    percentageRate: '1.2',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '97.8',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [9, 1, 2, 3, 5, 8],
    base: [6, 6, 6, 6, 6, 6],
  },
  {
    id: 8,
    title: 'DASHBOARD_KIOSK_RATE',
    percentageRate: '8.7',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '90.3',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [4, 6, 8, 4, 6, 8],
    base: [6, 6, 6, 6, 6, 6],
  },
  {
    id: 9,
    title: 'DASHBOARD_GUIDING_RATE',
    percentageRate: '7.8',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '86.7',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [3, 6, 3, 6, 1, 5],
    base: [4, 4, 4, 4, 4, 4],
  },
  {
    id: 10,
    title: 'DASHBOARD_ALL_TASK_RATE',
    percentageRate: '10.1',
    contentString: 'DASHBOARD_TOTAL_RATE',
    unitValue: '79.4',
    unit: '%',
    labels: ['1', '2', '3', '4', '5', '6'],
    data: [3, 3, 3, 3, 3, 3],
    base: [2, 2, 2, 2, 2, 2],
  },
];

export const mockSite = [
  {
    id: 1,
    name: 'MAP_ALL_SITE',
  },
  {
    id: 2,
    name: 'wistron',
  },
  {
    id: 3,
    name: 'wistron2',
  },
];

export const mockMap = [
  {
    id: 1,
    name: 'wistron',
  },
  {
    id: 2,
    name: 'wistron2',
  },
];

export const mockTask = [
  {
    id: 1,
    name: 'DASHBOARD_TASK_POINT',
  },
  {
    id: 2,
    name: 'DASHBOARD_TASK_PATH',
  },
];

export const elevatorMockData = [{
  id: 2,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 1, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: 'up', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 3,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 1,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 0,
  direction: 'idle', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 4,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 1,
  door_full_close: 0,
  direction: 'idle', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 5,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 1,
  door_full_open: 0,
  door_full_close: 0,
  direction: 'idle', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 6,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: 'idle', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 7,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: 'up', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 8,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 1, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: 'down', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 9,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 0, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 2, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: 'down', // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 1,
},
{
  id: 1,
  timestamp: '2022-11-29 14:32:24.378',
  active: 0,
  out_of_service: 0, // 電梯是否正常 (0服務未連線, 1服務連線)
  floor_arrived: 0, // 樓層
  door_open: 0,
  door_close: 0,
  door_full_open: 0,
  door_full_close: 1,
  direction: null, // 電梯方向(up向上, down向下, idle 停止, Null目前沒在移動)
  online_status: 0,
},
];

export const notifyMessageMockData = [
  {
    id: 0,
    date: '2022/12/05 19:30',
    site: '東方科學園區',
    map: 'C3-building',
    name: 'Robot 06',
    type: 'UVC',
    state: '錯誤',
    electricity: '85%',
    asm: '70%',
    position: '東科大樓C棟5樓',
    msg: '軟體啟動失敗(0X00000)',
  },
  {
    id: 1,
    date: '2022/12/05 18:54',
    site: '東方科學園區',
    map: 'B2-building',
    name: 'Robot 03',
    type: 'ADR',
    state: '正常',
    electricity: '60%',
    asm: '55%',
    position: 'N/A',
    msg: '',
  },
  {
    id: 2,
    date: '2022/12/05 18:29',
    site: 'iFG',
    map: 'ABC',
    name: 'Robot 05',
    type: 'ADR',
    state: '錯誤',
    electricity: '35%',
    asm: '20%',
    position: 'N/A',
    msg: '軟體啟動失敗(0X00000)',
  },
  {
    id: 3,
    date: '2022/12/05 17:47',
    site: '東方科學園區',
    map: 'A3-building',
    name: 'Robot 02',
    type: 'ADR',
    state: '錯誤',
    electricity: '70%',
    asm: '65%',
    position: 'N/A',
    msg: '載入模組失敗(0X00008)',
  },
  {
    id: 4,
    date: '2022/12/05 17:36',
    site: '東方科學園區',
    map: 'D2-building',
    name: 'Robot 01',
    type: 'UVC',
    state: '錯誤',
    electricity: '25%',
    asm: '10%',
    position: '東科大樓D棟21樓',
    msg: '軟體啟動失敗(0X00000)',
  },
  {
    id: 5,
    date: '2022/12/05 17:23',
    site: '東方科學園區',
    map: 'C1-building',
    name: 'Robot 07',
    type: 'UVC',
    state: '正常',
    electricity: '10%',
    asm: '10%',
    position: 'N/A',
    msg: '',
  },
  {
    id: 6,
    date: '2022/12/05 17:05',
    site: 'iFG',
    map: 'DEF',
    name: 'Robot 10',
    type: 'UVC',
    state: '錯誤',
    electricity: '80%',
    asm: '75%',
    position: 'N/A',
    msg: '系統連線異常(0X00003)',
  },
  {
    id: 7,
    date: '2022/12/05 16:33',
    site: '東方科學園區',
    map: 'D3-building',
    name: 'Robot 12',
    type: 'UVC',
    state: '正常',
    electricity: '45%',
    asm: '30%',
    position: 'N/A',
    msg: '',
  },
  {
    id: 8,
    date: '2022/12/05 16:12',
    site: '東方科學園區',
    map: 'C4-building',
    name: 'Robot 04',
    type: 'ADR',
    state: '正常',
    electricity: '20%',
    asm: '10%',
    position: '東科大樓C棟21樓',
    msg: '',
  },
  {
    id: 9,
    date: '2022/12/05 15:48',
    site: '東方科學園區',
    map: 'A1-building',
    name: 'Robot 09',
    type: 'ADR',
    state: '錯誤',
    electricity: '75%',
    asm: '65%',
    position: '東科大樓A棟13樓',
    msg: '軟體啟動失敗(0X00000)',
  },
  {
    id: 10,
    date: '2022/12/05 15:27',
    site: 'iFG',
    map: 'ABC',
    name: 'Robot 11',
    type: 'ADR',
    state: '錯誤',
    electricity: '5%',
    asm: '7%',
    position: 'N/A',
    msg: '無法正常充電(0X00005)',
  },
  {
    id: 11,
    date: '2022/12/06 15:27',
    site: 'iFG',
    map: 'ABC',
    name: 'Robot 15',
    type: 'ADR',
    state: '錯誤',
    electricity: '45%',
    asm: '17%',
    position: 'N/A',
    msg: 'unknow',
  },
  {
    id: 12,
    date: '2022/12/07 15:27',
    site: 'iFG',
    map: 'C2-building',
    name: 'Robot 08',
    type: 'ADR',
    state: '錯誤',
    electricity: '75%',
    asm: '77%',
    position: '東科大樓B棟10樓',
    msg: '載入模組失敗(0X00008)',
  },
  {
    id: 13,
    date: '2022/12/08 15:27',
    site: 'iFG',
    map: 'DEF',
    name: 'Robot 04',
    type: 'ADR',
    state: '錯誤',
    electricity: '85%',
    asm: '70%',
    position: 'N/A',
    msg: '無法正常充電(0X00005)',
  },
  {
    id: 14,
    date: '2022/12/09 15:27',
    site: 'iFG',
    map: 'D1-building',
    name: 'Robot 01',
    type: 'ADR',
    state: '錯誤',
    electricity: '56%',
    asm: '71%',
    position: '東科大樓B棟10樓',
    msg: '啟動燈管異常(0X00007)',
  },
];
